import React from 'react';
import OptionSelectQuestionForm, {IOptionSelectQuestionForm} from "./elements/OptionSelectQuestionForm";
import OptionsList, {IOptionsListProps} from "./elements/OptionsList";

export type QuestionsByType = {
  OptionForm: (props: IOptionSelectQuestionForm) => JSX.Element,
  OptionsList: (props: IOptionsListProps) => JSX.Element,
}

const QuestionsByType: QuestionsByType = {
  OptionForm: OptionSelectQuestionForm,
  OptionsList: OptionsList
}

export default QuestionsByType;
