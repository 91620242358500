import React, {useMemo} from 'react';
import {Route, Routes} from "react-router-dom";
import {getRoutes} from "./usersRoutes";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {selectUserData} from "../../store/profile/reselect";
import Main from "../../pages/Main";
import Page404 from "../../pages/404";

const AppRouter = () => {

  const {
    userData: {roleId},
  } = useSelector((store: RootState) => ({
    userData: selectUserData(store)
  }))

  const routes = useMemo(() => getRoutes(roleId), [roleId])

  return (
    <Routes>
      {routes.map((UserRoute, id) =>
        <Route key={UserRoute.path + id} path={UserRoute.path} element={UserRoute.element && <UserRoute.element/>}/>
      )}
      <Route path="/" element={<Main />}/>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AppRouter;
