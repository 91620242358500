import {IIdentifier, ListDataWithSelect} from "../common";
import {GetDealersI} from "../DealerServiceType/DealersServiceRequest";
import {OptionData} from "../FilterData";
import {LOADING_STATUS} from "lib/appConst";

/**
 * Интерфейс для статуса запроса
 */
export interface RequestStatus {
  isLoading: LOADING_STATUS,
  isError: boolean,
  errorMessage: string
}

/**
 * Перечисление возможных опций для Select`ов по дилерам
 */
export enum DealersSelectOptions {
  stateOptions = 'stateOptions',
  distOptions = 'distOptions',
  cityOptions = 'cityOptions',
  dealerGroupsOptions = 'dealerGroupsOptions'
}

/**
 * Интерфейс данных для SelectFetch`а
 */
export interface SelectDataStore {
  requestStatus: RequestStatus,
  options: OptionData[],
  /**
   * Количество найденных строк
   */
  optionCount: number,
  pagination: {
    currentPage: number,
    isLastPage: boolean,
    maxPage: number
  }
}

/**
 * Интерфейс слайса для работы с данными о пользователе
 */
export interface DealersState {
  dataList: ListDataWithSelect<GetDealersI & IIdentifier>,
  /**
   * Данные для Select`ов по дилерам
   */
  dealersSelectData: {
    [DealersSelectOptions.stateOptions]: SelectDataStore,
    [DealersSelectOptions.distOptions]: SelectDataStore,
    [DealersSelectOptions.cityOptions]: SelectDataStore,
    [DealersSelectOptions.dealerGroupsOptions]: SelectDataStore
  },
  /**
   * Данные об установке пользователя к дилеру
   */
  putUser: RequestStatus,
  /**
  * Данные об удалении пользователя у дилера
  */
  deleteUser: RequestStatus
}
