import React from 'react';
import s from './s.module.css';

const DragAnchor = () => {
  return (
    <div className={s.container}>
      <div className={s.row}>
        <span className={s.dot}/>
        <span className={s.dot}/>
        <span className={s.dot}/>
      </div>
      <div className={s.row}>
        <span className={s.dot}/>
        <span className={s.dot}/>
        <span className={s.dot}/>
      </div>
    </div>
  );
};

export default DragAnchor;
