import React from 'react';
import s from './s.module.css'
import clsx from "clsx";
import {UIButtonCommonType} from "../../index";


export type EmptyBtnType = {
  children?: JSX.Element
  label?: string
  leftIcon?: any
  rightIcon?: any
}

const EmptyBtn = ({handler, leftIcon, rightIcon,  type = "submit", label, style, title, disabled}: EmptyBtnType & UIButtonCommonType) => {
  return (
    <button
      disabled={disabled}
      style={style}
      type={type}
      className={clsx(s.button, 'button', {
        [s.disabled]: disabled
      })}
      onClick={handler}
      title={title}
    >
      {leftIcon && leftIcon}
      <span
        className={clsx({
          [s.leftMargin]: leftIcon,
          [s.rightMargin]: rightIcon,
        })}
      >{
        label}
      </span>
      {rightIcon && rightIcon}
    </button>
  );
};

export default EmptyBtn;
