import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { IQuestionInstance } from 'models/InstanceInterfaces/IQuestion'
import { IForm } from 'models/InstanceInterfaces/IForm'

import Container from '../FormPass/components/Container'
import ContentBox from '../FormPass/components/ContentBox'
import ProgressBar from '../FormPass/components/ProgressBar'
import Actions from '../FormPass/components/Actions'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import {
  getFormWithQuestionAction,
  getQuestionAction, getTemplateFormWithQuestion,
  setAnswerToQuestionAction,
} from 'store/form/thunk'
import {
  selectFormDetail,
  selectFormRequestStatus,
  selectPreview,
  selectQuestion,
  selectQuestionsPreview,
} from 'store/form/reselect'
import { clearAllFormData } from 'store/form'
import { getValueBeforeSend, reinitFormValue } from '../FormPass/utils'

const defaultValues = { answer: '' }

/**
 * Страница предпросмотра прохождения анкеты
 * @constructor
 */
const FormPreview = () => {
  const { templateFormId, questionId } = useParams()
  const [questionIndex, setQuestionIndex] = useState(0)

  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<any>({ defaultValues })

  const { requestStatus, preview, questions } = useAppSelector(state => ({
    requestStatus: selectFormRequestStatus(state),
    preview: selectPreview(state),
    questions: selectQuestionsPreview(state)
  }))

  useEffect(() => {
    if (questions.length > 0) {
      setQuestionIndex(questions.findIndex((question: any) => question.id === parseInt(questionId as string)))
    }
  }, [questions])

  const question = questions.length > 0 && questions[questionIndex]
  const isShowPrev = questionIndex > 0

  useEffect(() => {
    dispatch(getTemplateFormWithQuestion({ templateFormId }))

    return () => {
      // Очистить стору по тайному покупателю при уходе с этой страницы
      dispatch(clearAllFormData())
    }
  }, [])

  const reInit = () => {
    reset(defaultValues)
  }

  const onSubmit = async () => {
    return goNextQuestion()
  }

  // Вернуться к предыдущему вопросу
  const goPrevQuestion = useCallback(() => {
    setQuestionIndex(p => p - 1)
    reInit()
  }, [])

  // Перейти к след вопросу
  const goNextQuestion = async () => {
    if (question.nextQuestionId === 0) {
      return alert('Это последний вопрос')
    }
    setQuestionIndex(questions.findIndex((el: any) => el.id === question.nextQuestionId))
    reInit()
  }

  if (!preview && !question) return null

  const total = questions.length || 0

  return (
    <Container
      title={question.mainGroup.name}
      hasPassed={false}
      hasStarted={false}
      continueForm={() => {}}
      clearForm={() => {}}
      goBack={() => {}}
      requestStatus={requestStatus}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentBox
          question={question}
          control={control}
          errors={errors}
        />
        <ProgressBar
          currentQuestion={questionIndex + 1}
          total={total}
        />
        <Actions
          isShowPrev={isShowPrev}
          prevHandler={goPrevQuestion}
          isDisabledButtons={false}
        />
      </form>
    </Container>
  )
}

export default FormPreview
