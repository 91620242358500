import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  createFormTemplate, createTemplateMainGroup,
  editFormTemplate,
  getAllFormTemplates,
  getFormTemplateById,
  getFormTemplateByIdNoLoad
} from "./thunk";
import {LOADING_STATUS} from "lib/appConst";
import {ITemplateForm, ITemplateFormInstance, ITemplateGroup} from "models/InstanceInterfaces/ITemplateForm";
import {ListResponse, IResponseExtraData, IIdentifier} from "models/common";
import {IformTemplateEditor} from "models/storeInterface/FormTemplateStore";
import {IIterable, IQuestion} from "models/IQuestion";

const name = 'forms';

export enum TEMPLATE_EDITOR_MODS {
  ADD = 'ADD',
  EDIT = 'EDIT',
  AWAIT = 'AWAIT',
}

const initialState: IformTemplateEditor = {
  mode: TEMPLATE_EDITOR_MODS.AWAIT,
  currentSelectId: undefined,
  selectedTemplateHeader: undefined,
  addMainGroupStatus: LOADING_STATUS.IDLE,
  selectedTemplate: {
    status: LOADING_STATUS.IDLE,
    instance: undefined,
    error: ''
  },
  createNewQuestion: false,
  formTemplates: {
    rows: [],
    count: 0,
    error: '',
    status: LOADING_STATUS.IDLE,
  },
  selectedGroupId: undefined,
  selectedQuestion: {
    id: undefined,
    elementPos: undefined
  },
  selectedPrevQuestion: {
    id: undefined,
    elementPos: undefined
  },
  createTemplateStatus: {
    status: LOADING_STATUS.IDLE,
    error: ''
  },
  toolsState: {
    addQuestion: false,
  }
}

export const formTemplateEditorSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    switchMode: (state, {payload}: { payload: TEMPLATE_EDITOR_MODS }) => {
      state.mode = payload
    },
    selectCurrentTemplate: (state, {payload}: { payload: number }) => {
      state.currentSelectId = payload
      state.selectedTemplateHeader = state.formTemplates.rows.find(el => el.id === payload)
    },
    resetSelection: (state) => {
      state.currentSelectId = initialState.currentSelectId
      state.selectedTemplateHeader = initialState.selectedTemplateHeader
    },
    resetTemplate: (state) => {
      state.selectedTemplate = initialState.selectedTemplate
    },
    modifyQuestionList: (state, {payload}: PayloadAction<{
      targetId: number,
      selectedGroup: number,
      instance: IQuestion & IResponseExtraData & IIdentifier
    }>) => {
      const {targetId, selectedGroup, instance} = payload
      if (state.selectedTemplate.instance) {
        state.selectedTemplate.instance.mainGroup = state.selectedTemplate.instance.mainGroup.map((group) => {
          if(group.id === selectedGroup) {
            //@ts-ignore
            const updateQuestions = group.questions.reduce<IQuestion & IIterable[]>((acc: (IQuestion & IIterable[]), el: IQuestion & IIterable) => {
              if(el.id === targetId) {
                return [...acc, el, instance]
              }
              else {
                return [...acc, el]
              }
            }, [])
            return {...group, questions: updateQuestions}
          }
          return group
        })
      }
    },
    toolsAddQuestionHandle: (state, {payload}: { payload: boolean | undefined }) => {
      state.toolsState.addQuestion = payload !== undefined ? payload : !state.toolsState.addQuestion
    },
    selectQuestion: (state, {payload}) => {
      state.selectedQuestion = payload
    },
    selectPrevQuestion: (state, {payload}) => {
      state.selectedPrevQuestion = payload
    },
    resetSelectedQuestion: (state) => {
      state.selectedQuestion = initialState.selectedQuestion
    },
    selectGroup: (state, {payload}: { payload: number }) => {
      state.selectedGroupId = payload
    },
    resetSelectedGroup: (state) => {
      state.selectedGroupId = initialState.selectedGroupId
    },
    addCreatedQuestionToArray: (state, {
      payload: {
        targetGroup,
        item
      }
    }: { payload: { targetGroup?: number, item: IQuestion } }) => {
      if (state.selectedTemplate.instance) {
        const targetArr = state.selectedTemplate.instance.mainGroup.find(el => el.id === targetGroup)
        if (!targetArr) {
          state.selectedTemplate.instance.mainGroup[0].questions = [item]
          state.selectedGroupId = state.selectedTemplate.instance.mainGroup[0].id
        } else {
          targetArr.questions = targetArr.questions?.length ? [...targetArr.questions, item] : [item]
        }
      }
    }
  },
  extraReducers: {
    [getAllFormTemplates.pending.type]: (state) => {
      state.formTemplates.status = LOADING_STATUS.LOADING
      state.formTemplates.error = initialState.formTemplates.error
    },
    [getAllFormTemplates.fulfilled.type]: (state, {payload}: { payload: ListResponse<ITemplateForm & IIdentifier & IResponseExtraData> }) => {
      state.formTemplates.status = LOADING_STATUS.IDLE
      state.formTemplates.rows = payload.rows
      state.formTemplates.count = payload.count
    },
    [getAllFormTemplates.rejected.type]: (state, {payload}) => {
      state.formTemplates.status = LOADING_STATUS.IDLE
      state.formTemplates.error = `Произошла сетевая ошибка: ${payload || 0}`
    },
    [getFormTemplateById.pending.type]: (state) => {
      state.selectedTemplate.status = LOADING_STATUS.LOADING
      state.selectedTemplate.error = initialState.selectedTemplate.error
    },
    [getFormTemplateById.fulfilled.type]: (state, {payload}: { payload: ITemplateFormInstance & { error?: string } }) => {
      if (payload.error) {
        state.selectedTemplate.instance = initialState.selectedTemplate.instance
        state.selectedTemplate.error = payload.error
      } else {
        state.selectedTemplate.instance = {...payload}
      }
      state.selectedTemplate.status = LOADING_STATUS.IDLE
    },
    [getFormTemplateById.rejected.type]: (state, {payload}: { payload: string }) => {
      state.selectedTemplate.status = LOADING_STATUS.IDLE
      state.selectedTemplate.instance = initialState.selectedTemplate.instance
      state.formTemplates.error = `Произошла сетевая ошибка: ${payload || 0}`
    },
    [getFormTemplateByIdNoLoad.fulfilled.type]: (state, {payload}: { payload: ITemplateFormInstance }) => {
      state.selectedTemplate.instance = {...payload}
    },
    [getFormTemplateByIdNoLoad.rejected.type]: (state, {payload}: { payload: string }) => {
      state.selectedTemplate.instance = initialState.selectedTemplate.instance
      state.formTemplates.error = `Произошла сетевая ошибка: ${payload || 0}`
    },
    [createFormTemplate.pending.type && editFormTemplate.pending.type]: (state) => {
      state.createTemplateStatus.status = LOADING_STATUS.LOADING
      state.createTemplateStatus.error = initialState.selectedTemplate.error
    },
    [createFormTemplate.fulfilled.type && editFormTemplate.fulfilled.type]: (state, {payload}: { payload: ITemplateForm & IIdentifier & IResponseExtraData }) => {
      state.createTemplateStatus.status = LOADING_STATUS.IDLE
      state.currentSelectId = payload.id
      state.selectedTemplate.status = LOADING_STATUS.IDLE
      state.selectedTemplate.instance = initialState.selectedTemplate.instance
      state.selectedTemplateHeader = payload
    },
    [createFormTemplate.rejected.type && editFormTemplate.rejected.type]: (state, {payload}: { payload: string }) => {
      state.createTemplateStatus.status = LOADING_STATUS.IDLE
      state.createTemplateStatus.error = `Произошла сетевая ошибка: ${payload || 0}`
    },
    [createTemplateMainGroup.pending.type]: (state) => {
      state.addMainGroupStatus = LOADING_STATUS.LOADING
    },
    [createTemplateMainGroup.fulfilled.type]: (state, {payload}: { payload?: ITemplateGroup }) => {
      state.addMainGroupStatus = LOADING_STATUS.IDLE
      if (payload && state.selectedTemplate.instance) {
        state.selectedTemplate.instance.mainGroup = [...state.selectedTemplate.instance.mainGroup, payload]
      }
    },
    [createTemplateMainGroup.rejected.type]: (state) => {
      state.addMainGroupStatus = LOADING_STATUS.IDLE
    },
  }

})
export const {
  switchMode,
  selectGroup,
  resetTemplate,
  resetSelection,
  selectQuestion,
  selectPrevQuestion,
  resetSelectedGroup,
  resetSelectedQuestion,
  modifyQuestionList,
  selectCurrentTemplate,
  toolsAddQuestionHandle,
  addCreatedQuestionToArray,
} = formTemplateEditorSlice.actions
export default formTemplateEditorSlice.reducer;
