import { UPLOAD_FORMAT_TYPES } from 'lib/appConst'

const AUDIO = ["audio/mpeg", "audio/wav"]
const VIDEO = [
  "video/x-flv",
  "video/mp4",
  "application/x-mpegURL",
  "video/MP2T",
  "video/3gpp",
  "video/quicktime",
  "video/x-msvideo",
  "video/x-ms-wmv",
]
const IMAGE = [
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/tiff",
  "image/webp",
]
const ARCHIVE = [
  "application/zip",
  "application/vnd.rar",
  "application/x-7z-compressed",
  "application/x-tar",
]
const DOCUMENT = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessing",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.text",
]
const APPLICATION = [
  "application/vnd.android.package-archive",
]

export const UPLOAD_FORMATS = {
  [UPLOAD_FORMAT_TYPES.audio]: AUDIO,
  [UPLOAD_FORMAT_TYPES.video]: VIDEO,
  [UPLOAD_FORMAT_TYPES.image]: IMAGE,
  [UPLOAD_FORMAT_TYPES.archive]: ARCHIVE,
  [UPLOAD_FORMAT_TYPES.document]: DOCUMENT,
  [UPLOAD_FORMAT_TYPES.application]: APPLICATION,
  [UPLOAD_FORMAT_TYPES.all]: [
    ...AUDIO,
    ...VIDEO,
    ...IMAGE,
    ...ARCHIVE,
    ...DOCUMENT,
    ...APPLICATION,
    '*/*'
  ],
};

export const UPLOAD_TITLES = {
  [UPLOAD_FORMAT_TYPES.audio]: 'Добавьте аудио файл',
  [UPLOAD_FORMAT_TYPES.video]: 'Добавьте видео',
  [UPLOAD_FORMAT_TYPES.image]: 'Добавьте изображение',
  [UPLOAD_FORMAT_TYPES.archive]: 'Добавьте файл',
  [UPLOAD_FORMAT_TYPES.document]: 'Добавьте файл',
  [UPLOAD_FORMAT_TYPES.application]: 'Добавьте файл',
  [UPLOAD_FORMAT_TYPES.all]: 'Добавьте файл',
}
