export enum TABLE_CONTROL_CONFIG_FIELD_TYPES {
  DROPDOWN = 'dropdown'
}

export enum USER_STATUS {
  ACTIVE = 1,
  LOCKED = 2
}

export enum LOADING_STATUS {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}
export interface IWithEnumKey {
  enumKey: string
}

export enum QUESTION_TYPES {
  textArea = "textArea",
  text = "text",
  datePick = "datePick",
  timePick = "timePick",
  file = "file",
  image = "image",
  options = "options",
  audio = "audio",
  number = "number",
}

export enum QUESTION_TYPES_NAMES {
  textArea = "Текстовый блок",
  text = "Текст",
  datePick = "Выбор даты",
  timePick = "Выбор времени",
  file = "Файл",
  image = "Изображение",
  options = "Выбор варианта",
  audio = "Аудио запись",
  number = "Число",
}

export enum UPLOAD_FORMAT_TYPES {
  all = 'all',
  audio = 'audio',
  video = 'video',
  image = 'image',
  archive = 'archive',
  document = 'document',
  application = 'application',
}

export enum ERROR_MESSAGE {
  defaultServerError = "Произошла ошибка, обратитесь в поддержку.",
  defaultNetError = "Произошла сетевая ошибка."
}

export enum NOTIFICATOR_TYPE {
  apply = 'apply',
  warning = 'warning',
  error = 'error',
}

export enum INFOFILES_TYPE {
  common = 'common',
  clients = 'clients',
  portal = 'portal'
}

export enum INFOFILES_NAME {
  common = 'Информационные материалы',
  clients = 'Инф.мат. для клиентской части',
  portal = 'Инф.мат. для ТП'
}

export const WSO_LINK = "https://idp.vaz.ru/authenticationendpoint/login.do?client_id=B9VweQMaFl1SwjP2bOdTY9rdnmYa"
