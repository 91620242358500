import React, {useCallback} from 'react';
import AuthBoxView from "./AuthBoxDunb";
import * as yup from "yup";
import {string} from "yup";
import {errorMessages} from "../../lib/errorMessages";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useAppDispatch} from "../../hooks/redux";
import {useSelector} from "react-redux";
import {loginUserAction} from "../../store/profile/thunk";
import {selectUserRequestStatus} from "../../store/profile/reselect";
import {RootState} from "store";
import {AuthConsumer} from "../../providers/authProvider";

export type Credentials = {
  login: string
  password: string
}

const resolverSchema = yup.object({
  login: string().required(errorMessages.common.inputRequired),
  password: string().required(errorMessages.common.inputRequired),
})

/**
 * HOC Блока авторизации
 */
export const AuthBox = () => {

  const {
    userRequestStatus: { errorMessage },
  } = useSelector((store: RootState) => ({
    userRequestStatus: selectUserRequestStatus(store),
  }))

  const {control, handleSubmit, formState } = useForm<Credentials>({
    defaultValues: {
      login: "",
      password: ""
    },
    resolver: yupResolver(resolverSchema)
  })

  const dispatch = useAppDispatch();

  const onSubmit = useCallback((data: Credentials) => dispatch(loginUserAction(data)), [])

  return (
    <AuthConsumer>
      {({login, logout}) =>
        <AuthBoxView
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          control={control}
          formState={formState}
          errorMessage={errorMessage}
          OIDCLogin={login}
          logout={logout}
        />
      }
    </AuthConsumer>
  )
};
