import React, { useState } from 'react';
import s from './s.module.css'
import {IForm} from "models/InstanceInterfaces/IForm";
import { IIdentifier, IResponseExtraData } from 'models/common';
import moment from "moment";
import clsx from "clsx";
import {UIButton} from "components/UI/UIButton";
import UIField from "components/UI/UIField";
import {useForm} from "react-hook-form";
import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest';
import { IStatus } from 'models/Status/Status';
import { StatusService } from 'services/StatusService';
import { getAccessToFormStatusEdit, getAccessToFormStatusEditSpecific } from 'lib/RolesModel';
import { OptionData } from 'models/FilterData';
import { addNotification } from 'lib/addNotification';
import { NOTIFICATOR_TYPE } from 'lib/appConst';
import FormsService from 'services/FormsService';
import {getRole} from "lib/getRole";

export interface IInfoBlock {
  form: (IForm & IIdentifier)
}

const InfoBlock = ({form}: IInfoBlock) => {
  const {id, wave, dealer, visitDate, status} = form
  const dealerAddressConcat =
    `${dealer.cityName}, 
    ${dealer.stateCode}, 
    ${dealer.address}`

  const userRole = getRole()

  const { control, reset } = useForm<any>({
    defaultValues: {
      status: { value: status?.id, name: status?.name }
    }
  })

  const [statusFilter, setStatusFilter] = useState('')
  const [
    statusRowsSelect,
    statusPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IStatus & IResponseExtraData & IIdentifier)>({
    request: StatusService.getAllFormsStatus,
    filter: statusFilter,
  })

  const sendStatus = async (statusId: number) => {
    try {
      await FormsService.putSelectedFormStatus(statusId, {formIds: [ id ]})
      await addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Статус анкеты изменен'})
    } catch (e) {
      console.log('sendStatus: ', e);
    }
  }

  const handleChangeStatus = (option: OptionData) => {
    const isCanEditToThisStatus = getAccessToFormStatusEditSpecific([9, 10, 11, 12, 13, 14, 17], option.value)
    if (isCanEditToThisStatus) {
      sendStatus(option.value)
    } else {
      addNotification({ type: NOTIFICATOR_TYPE.error, text: 'Вы не можете поменять статус на выбранный' })
      reset()
    }
  }

  const isCanEditStatusByRole = getAccessToFormStatusEdit([11, 12, 13, 15, 16, 18], (status as IStatus).id)

  const markAsFixed = () => {
    handleChangeStatus({value: 11, name: 'Пройдена'})
    window.history.back()
  }

  return (
    <div className={s.wrapper}>
      <div className={s.row}>
        <InfoCell label={"ID анкеты"} value={String(id)} />
        <InfoCell label={"Волна"} value={String(wave.name)} />
        <InfoCell label={"Анкета"} />
      </div>
      <div className={s.row}>
        <InfoCell label={"Дата прохождения"} value={formatFate(visitDate)} />
        <InfoCell label={"Дата публикации"} value={formatFate(visitDate)} />
      </div>
      <div className={s.row}>
        {isCanEditStatusByRole ? (
          <UIField.Select
            control={control}
            customStyle={{width: '100%'}}
            name="status"
            options={statusRowsSelect}
            paginateTrigger={statusPaginateTrigger}
            filterSetter={setStatusFilter}
            selectName={'Статус анкеты'}
            onHandleChange={option => handleChangeStatus(option as OptionData)}
          />
        ) : (
          <InfoCell label={"Статус анкеты"} value={status?.name} />
        )}
      </div>
      <div className={s.row}>
        <InfoCell customClass={s.infoCellWrapperFullWidth} label={"Адрес"} value={dealerAddressConcat} />
      </div>
      <div className={clsx(s.row, s.control)}>
      {/*  <UIButton.DefaultBtn label={"EXCEL"} leftIcon={<DownloadIcon />} style={{backgroundColor: "#3DB05D"}} />*/}
      {/*  <UIButton.DefaultBtn label={"ZIP"} leftIcon={<DownloadIcon />} style={{backgroundColor: "#8238E2"}} />*/}
        {
          userRole.id === 5 && (
            <UIButton.DefaultBtn
              label={"Пометить как \"Исправлено\""}
              style={{backgroundColor: "#8238E2"}}
              handler={markAsFixed}
            />
          )
        }
      </div>
    </div>
  );
};

export default InfoBlock;

interface InfoCell {
  label: string
  value?: string
  customClass?: string
  children?: any
}
const InfoCell = ({label, value, customClass, children}: InfoCell) => (
  <div className={clsx(s.infoCellWrapper, customClass)}>
    <p className={s.label}>{label}</p>
    {
      children
        ? children
        : <p className={s.value}>{value === "Invalid date" || !value ? "Отсутствует" : value}</p>
    }
  </div>
)

const formatFate = (date: string) => {
  return moment(date).format('YYYY-MM-DD hh:mm')
}
