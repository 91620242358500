import React from 'react'
import clsx from 'clsx'
import s from '../AnalitycsFormTableCommon/s.module.css'

const index = () => {
  return (
    <table className={s.table}>
      <tbody>
        <tr className={s.tableRow}>
          <td className={s.tableCell}></td>
          <td className={clsx([s.tableCell, s.tableHeadCell])}>% по пунктам</td>
          <td className={clsx([s.tableCell, s.tableHeadCell])}>% по баллам</td>
        </tr>
        <tr className={s.tableRow}>
          <td className={clsx([s.tableCell, s.tableMainCell])}>Группировка  “По процессу”</td>
          <td className={clsx([s.tableCell, s.tableMainCell])}>100</td>
          <td className={clsx([s.tableCell, s.tableMainCell])}>100</td>
        </tr>
        <tr className={s.tableRow}>
          <td className={s.tableCell}>Обращение по почте</td>
          <td className={s.tableCell}>0</td>
          <td className={s.tableCell}>0</td>
        </tr>
        <tr className={s.tableRow}>
          <td className={s.tableCell}>Телефонное обращение</td>
          <td className={s.tableCell}>0</td>
          <td className={s.tableCell}>0</td>
        </tr>
        <tr className={s.tableRow}>
          <td className={clsx([s.tableCell, s.tableMainCell])}>Группировка  “По факторам”</td>
          <td className={clsx([s.tableCell, s.tableMainCell])}>100</td>
          <td className={clsx([s.tableCell, s.tableMainCell])}>100</td>
        </tr>
        <tr className={s.tableRow}>
          <td className={s.tableCell}>Сроки предоставления ответа</td>
          <td className={s.tableCell}>0</td>
          <td className={s.tableCell}>0</td>
        </tr>
        <tr className={s.tableRow}>
          <td className={s.tableCell}>Содержание ответа</td>
          <td className={s.tableCell}>0</td>
          <td className={s.tableCell}>0</td>
        </tr>
        <tr className={s.tableRow}>
          <td className={s.tableCell}>Впечатления клиента</td>
          <td className={s.tableCell}>0</td>
          <td className={s.tableCell}>0</td>
        </tr>
        <tr className={s.tableRow}>
          <td className={s.tableCell}>Приветствие</td>
          <td className={s.tableCell}>0</td>
          <td className={s.tableCell}>0</td>
        </tr>
        <tr className={s.tableRow}>
          <td className={s.tableCell}>Планирование встречи</td>
          <td className={s.tableCell}>0</td>
          <td className={s.tableCell}>0</td>
        </tr>
      </tbody>
    </table>
  )
}

export default index