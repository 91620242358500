import React, {memo} from 'react';
import {Control, FormState} from "react-hook-form";
import s from './s.module.css'
import {UIContainer} from 'components/UI/UIContainer';
import {UIButton} from "components/UI/UIButton";
import UIField from 'components/UI/UIField';
import {Credentials} from "./index";
import UIError from "components/UI/UIError";

const marginB16 = {marginBottom: 16}
const marginB12 = {marginBottom: 12}

interface AuthBoxProps {
  onSubmit: Function
  handleSubmit: Function
  control: Control<Credentials>
  formState: FormState<Credentials>
  errorMessage: string
  OIDCLogin: () => void
  logout: () => void
}

const AuthBoxView = ({
                       handleSubmit,
                       onSubmit,
                       control,
                       formState,
                       errorMessage,
                       OIDCLogin,
                       logout
}: AuthBoxProps) => {
  return (
    <UIContainer.ContentContainer
      header={"Авторизация"}
      containerAdditionClass={s.container}
    >
      <form role="form" className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <UIField.Input
          name={"login"}
          control={control}
          placeholder={"Логин"}
          customStyle={marginB16}
          errors={formState.errors}
        />
        <UIField.Input
          name={"password"}
          control={control}
          type={"password"}
          placeholder={"Пароль"}
          customStyle={marginB12}
          errors={formState.errors}
        />
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <UIButton.DefaultBtn label={"Войти"}/>
          <UIButton.LinkBtn
            style={{marginTop: 10}}
            label={"Войти LADA"}
            type={'button'}
            handler={OIDCLogin}
          />
          {/*<UIButton.LinkBtn*/}
          {/*  style={{marginTop: 10}}*/}
          {/*  label={"Выйти LADA"}*/}
          {/*  type={'button'}*/}
          {/*  handler={logout}*/}
          {/*/>*/}
        </div>
        <UIError errorMessage={errorMessage}/>
      </form>
    </UIContainer.ContentContainer>
  );
};

export default memo(AuthBoxView);
