import React, { useEffect, useState } from 'react';

import { UIContainer } from 'components/UI/UIContainer';
import s from './s.module.css'
import { IInfoFileResponse } from 'models/IInfoFiles';
import FileService from 'services/FileService';
import { INFOFILES_TYPE } from 'lib/appConst';
import { getFileExtension, getFileSize } from 'components/UI/UIField/items/Filepicker/items/File';
import { FileIco } from 'modules/MainPageInfoBlocks/Info/items/InfoFile';
import {getFileByStream} from "../../lib/getFileByStream";


/**
 * Страница для отображения помощи
 * @constructor
 */

export interface IIconCard {
  Icon: JSX.Element
  label: string
  descr: string
  onDownload: () => void
}

const InfoCard = ({Icon, label, descr, onDownload}: IIconCard) => {
  return (
    <li className={s.item}>
      {Icon}
      <div className={s.text}>
        <div className={s.link} onClick={onDownload}>{label}</div>
        <div>{descr}</div>
      </div>
    </li>
  )
}

const Help = () => {

  const [files, setFiles] = useState<IInfoFileResponse[]>([])

  const getFiles = async () => {
    return await FileService.getInfoFiles(INFOFILES_TYPE.portal)
  }

  useEffect(() => {
    getFiles().then((res) => {
      setFiles(res.data)
    })
  }, [])

  const onOpen = (id: number, fileName: string) => {
    getFileByStream(id)
      .then(([_, downloader]) => {
        downloader(fileName)
      })
  }

  return (
    <UIContainer.PageContainer>
      <div>
        <h1 className={s.header}>Информационные материалы</h1>
        <ul className={s.list}>
        {files.length > 0 && files.map((el) => (
          <InfoCard
            key={el.id}
            Icon={<FileIco extension={getFileExtension(el.File.fileName).toLowerCase()} />}
            label={el.name}
            descr={`${getFileExtension(el.File.fileName)}, ${getFileSize(el.File.fileSize)}`}
            onDownload={() => onOpen(el.File.id, el.File.fileName)}
          />
        ))}
        </ul>
      </div>
    </UIContainer.PageContainer>
  );
};

export default Help;
