import {saveGetByPath} from "./saveGetByPath";

/**
 * Обработка ошибок сервера
 * @param e {Error} - Инстанс ошибки
 */
export const netErrorsHandler = (e: Error): string | string[] => {
  const defaultMessage = "Произошла неизвестная ошибка"
  const errorStatus = saveGetByPath(e, 'response.status', 0)
  if(errorStatus >= 500) {
    return defaultMessage
  }
  return saveGetByPath(e, 'response.data.message', defaultMessage)
}
