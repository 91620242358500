import {memo} from 'react';
import { CheckboxRaw } from 'components/UI/UIField/items/Checkbox'
import s from "../../../BindDealerModal/s.module.css";
import {DealerGroupItemPropTypes} from "../../../DealerGroupModal/items/DealerGroupItem";


export const TemplateFormItem = memo(({
     id,
     name,
     checked,
     onHandleSelect,
     disabled,
     ...el
   }: DealerGroupItemPropTypes) => {
  return (
    <div className={s.checkboxItem}>
      <CheckboxRaw
        label={name}
        value={checked}
        disabled={disabled}
        onChange={(event) => {
          onHandleSelect(id, event.target.checked, { ...el, id, name })
        }}
      />
    </div>
  )
})