import React, {useState} from 'react';
import s from './s.module.css'
import clsx from "clsx";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";

export interface ElementOnPageSelect {
  requestCounts: number[]
  disabledTableActivity?: boolean
}

/**
 * Селектор "отображать по..."
 * @param requestCounts - Список вариантов "отображать по..."
 */
const ElementOnPageSelect = ({requestCounts, disabledTableActivity}: ElementOnPageSelect) => {
  const [{count}, setParams] = useEaseSearchParams()

  const [showVariants, setShowVariants] = useState<boolean>(false)
  const showHandler = (isFocus: boolean) => {
    setShowVariants(isFocus)
  }

  const clickHandler = (e:React.MouseEvent, count: number) => {
    e.stopPropagation()
    setParams((p: any) => ({...p, page: 1, count}))
    setShowVariants(false)
  }

  return (
    <div className={s.wrapper}>
      <p>отображать по:
        <button
          className={s.hiddenBtn}
          onClick={() => showHandler(true)}
          onBlur={() => showHandler(false)}
          disabled={disabledTableActivity}
        >
          {count}
          <div className={clsx(s.variantList, {[s.isShow]: showVariants})}>
            <ul style={{listStyle: 'none'}}>
              {
                requestCounts.map((count, id) => <li key={id} onClick={(e) => clickHandler(e, count)}>{count}</li>)
              }
            </ul>
          </div>
        </button>
      </p>
    </div>
  );
};

export default ElementOnPageSelect;
