import React, { useCallback, useState } from 'react'
import {Editable, withReact, useSlate, Slate} from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
} from 'slate'
import clsx from 'clsx'
import s from './s.module.css'
import TextEditorButton from "./components/TextEditorButton";

import {ReactComponent as BoldIcon} from "assets/svg/TextEditor/bold.svg";
import {ReactComponent as ItalicIcon} from "assets/svg/TextEditor/italic.svg";
import {ReactComponent as UnderIcon} from "assets/svg/TextEditor/underline.svg";
import {ReactComponent as OrderListIcon} from "assets/svg/TextEditor/oList.svg";
import {ReactComponent as UnorderedListIcon} from "assets/svg/TextEditor/uList.svg";
import {Controller} from "react-hook-form";

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export type TextEditorPropTypes = ITextEditorRaw & {
  name: string
  control: any
}

const TextEditor = ({name, control, placeholder}: TextEditorPropTypes & ITextEditorRaw) => {
  return (
    <Controller
      name={name}
      control={control}
      render={
        ({field: {onChange, value}}) => {
          return (
            <TextEditorRaw
              onChange={onChange}
              value={value ? value : [{
                //@ts-ignore
                type: 'paragraph',
                children: [{text: ''}]
              }]}
              placeholder={placeholder}
            />
          )
        }}
    />
  )
}
/**
 * Альфа-версия редактора, нужно будет потом TS обработать, сейчас это делать долго.
 * @constructor
 */
export interface ITextEditorRawController {
  onChange?: any
  value: Descendant[]
}
export interface ITextEditorRaw {
  placeholder?: string
  readOnly?: boolean | undefined
}
export const TextEditorRaw = ({ onChange, value, placeholder, readOnly }: ITextEditorRawController & ITextEditorRaw) => {
  //@ts-ignore
  const renderElement = useCallback(props => <Element {...props} />, [])
  //@ts-ignore
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  //@ts-ignore
  const [editor] = useState(() => withReact(createEditor()))

  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      <div className={clsx({ [s.editorWrapper]: !readOnly })}>
        {!readOnly && (
          <div className={s.toolbar}>
            <MarkButton format="bold" icon={<BoldIcon/>}/>
            <MarkButton format="italic" icon={<ItalicIcon/>}/>
            <MarkButton format="underline" icon={<UnderIcon/>}/>
            <div className={s.delimiter}/>
            <BlockButton format="numbered-list" icon={<OrderListIcon/>}/>
            <BlockButton format="bulleted-list" icon={<UnorderedListIcon/>}/>
          </div>
        )}
        <div className={s.areaWrapper}>
          {/*@ts-ignore*/}
          {!(value.length === 1 && value[0].type === 'paragraph' && value[0].children.length === 1 && !value[0].children[0].text) &&
            <span className={s.placeholder}>{placeholder}</span>
          }
          <Editable
            readOnly={readOnly}
            className={clsx({ [s.editArea]: !readOnly })}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            spellCheck
          />
        </div>
      </div>
    </Slate>
  )
}

//@ts-ignore
const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      //@ts-ignore
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      //@ts-ignore
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      //@ts-ignore
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = {type: format, children: []}
    Transforms.wrapNodes(editor, block)
  }
}

//@ts-ignore
const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

//@ts-ignore
const isBlockActive = (editor, format, blockType = 'type') => {
  const {selection} = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        //@ts-ignore
        n[blockType] === format,
    })
  )

  return !!match
}

//@ts-ignore
const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  //@ts-ignore
  return marks ? marks[format] === true : false
}

//@ts-ignore
const Element = ({attributes, children, element}) => {
  const style = {textAlign: element.align}
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'list-item':
      return (
        <li style={{...style, marginLeft: 30}} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

//@ts-ignore
const Leaf = ({attributes, children, leaf}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

//@ts-ignore
const BlockButton = ({format, icon}) => {
  const editor = useSlate()
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  return (
    <TextEditorButton
      active={isActive}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {icon && icon}
    </TextEditorButton>
  )
}

//@ts-ignore
const MarkButton = ({format, icon}) => {
  const editor = useSlate()
  const isActive = isMarkActive(editor, format)
  return (
    <TextEditorButton
      active={isActive}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {icon && icon}
    </TextEditorButton>
  )
}

export default TextEditor
