import React from 'react';
import s from './s.module.css'
import clsx from "clsx";
import {UIButtonCommonType} from "../../index";


export type CircleBtnType = {
  children: JSX.Element
  loading?: boolean
}

const CircleBtn = ({
                     handler,
                     disabled,
                     type = "submit",
                     style,
                     title,
                     children,
                     loading,
}: CircleBtnType & UIButtonCommonType) => {
  return (
    <button
      disabled={disabled}
      type={type}
      style={{...style, opacity: loading ? .3 : 1}}
      className={clsx(s.button, 'button')}
      onClick={handler}
      title={title}
    >
      {children}
      {loading && <div className={s.preloader} />}
    </button>
  );
};

export default CircleBtn;
