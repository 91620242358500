import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'

import { UIContainer } from 'components/UI/UIContainer'
import { UIButton } from 'components/UI/UIButton'
import Select from 'components/UI/UIField/items/select'

import ResetSVG from 'assets/svg/filter/resetIcon.svg'

import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest'
import { useEaseSearchParams } from 'hooks/useEaseSearchParams'
import { useAppDispatch } from 'hooks/redux'
import { parseFilterParams, resetParams, serialiseParams } from 'lib/parseFilterParams'
import { StatusService } from 'services/StatusService'

import { OptionData } from 'models/FilterData'
import { IStatus } from 'models/Status/Status'

import { removeModalToStuck } from 'store/common'

import s from '../FormsPageFilter/s.module.css'

/**
 * Тип данных FormsPageFilter
 */
export type TodosPageFilterCredentials = {
  statusIds: OptionData[],
}

export const defaultTodosFilterValues: TodosPageFilterCredentials = {
  statusIds: [],
}

const TodosPageFilter = () => {
  const dispatch = useAppDispatch()

  const [params, setParams] = useEaseSearchParams()

  const { control, handleSubmit, reset } = useForm({
    defaultValues: parseFilterParams<TodosPageFilterCredentials>(params, defaultTodosFilterValues)
  })

  const customStyle = {
    paddingLeft: 20,
    paddingRight: 20
  }

  const onSubmit = (data: any) => {
    setParams((p: any) => ({ ...p, ...serialiseParams(data), modalId: undefined }))
    dispatch(removeModalToStuck())
  }

  const resetHandle = () => {
    reset(defaultTodosFilterValues)
    setParams((p: any) => ({ ...p, ...resetParams<TodosPageFilterCredentials>(defaultTodosFilterValues), modalId: undefined }))
    dispatch(removeModalToStuck())
  }

  const [statusFilter, setStatusFilter] = useState('')
  const [
    statusRowsSelect,
    statusPaginateTrigger,
  ] = usePaginationItemsFromRequest<IStatus>({
    request: StatusService.getAll,
    filter: statusFilter,
    requestFilter: { forForm: true, code: 'Forms' }
  })

  return (
    <UIContainer.ContentContainer header="Фильтрация" customStyle={customStyle}>
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <Select
          selectName="Статус"
          options={statusRowsSelect}
          paginateTrigger={statusPaginateTrigger}
          filterSetter={setStatusFilter}
          name="statusIds"
          control={control}
          isFilter
          multiSelect
        />
        <div className={s.controls}>
          <UIButton.DefaultBtn label="Показать" />
          <div className={s.resetWrapper} onClick={resetHandle}>
            <div className={s.resetHeader}>Сбросить</div>
            <ReactSVG
              data-testid="resetFilter"
              className={clsx(s.resetElement)}
              src={ResetSVG}
            />
          </div>
        </div>
      </form>
    </UIContainer.ContentContainer>
  )
}

export default TodosPageFilter
