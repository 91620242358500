import React, { useCallback, useEffect, useState } from 'react'
import { LineChart, XAxis, YAxis, CartesianGrid, Line, ReferenceLine, ResponsiveContainer, Tooltip } from 'recharts'
import { useSelector } from 'react-redux'

import { UIContainer } from 'components/UI/UIContainer'

import { AnalyticsGraphControl, AnalyticsSubmenu } from 'lib/tableConfigs/controlsPakages/controls'
import { parseFilterParamsForRequest } from 'lib/parseFilterParams'
import { AnalyticsGraphTableConfig } from 'lib/tableConfigs/configs'

import { useAppDispatch } from 'hooks/redux';
import { useEaseSearchParams } from 'hooks/useEaseSearchParams'

import TableGenerator from 'modules/TableGenerator'
import { IPaginate } from 'models/common'
import { defaultAnalyticsFilterValues, TAnalyticsPageFilter } from 'modules/filters/AnalyticsReportPageFilter/utils'

import { selectGraph } from 'store/analytics/reselect'
import { getGraphActions } from 'store/analytics/thunk'
import { calculationValues } from 'lib/appOptions'

import styles from './styles.module.css'
import s from 'modules/TableGenerator/s.module.css'

class CustomizedLegend extends React.PureComponent {
  render() {
    const { payload }: any = this.props
    return (
      <div className={styles.container}>
        {
          Array.isArray(payload) && payload.length > 0 && payload.map((entry: any, index: any) => (
            <div key={`item-${index}`}>{entry.payload.name}: <span className={styles.value}>{entry.value}</span></div>
          ))
        }
      </div>
    );
  }
}

class CustomizedLabel extends React.PureComponent {
  render() {
    const { x, y, stroke, value }: any = this.props;

    return (
      <text x={x} y={y - 5} dy={-4} fill={stroke} fontSize={14} style={{ fontFamily: 'var(--LadaPragmatica)' }} textAnchor="middle">
        {value}
      </text>
    );
  }
}

class CustomizedAxisTick extends React.PureComponent {
  render() {
    const { x, y, payload }: any = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#4D5964" transform="rotate(-45)" style={{ fontSize: 13, fontFamily: 'var(--LadaPragmatica)' }}>
          {payload.value}
        </text>
      </g>
    );
  }
}

/**
 * Страница для отображения аналитики в виде графика
 * @constructor
 */
const AnalyticsGraph = () => {
  const [params] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState<Partial<TAnalyticsPageFilter>>(parseFilterParamsForRequest(params, defaultAnalyticsFilterValues) as unknown as Partial<TAnalyticsPageFilter>)

  const structureConfig = useSelector(selectGraph)

  const getData = useCallback((params: IPaginate & Partial<TAnalyticsPageFilter>) => {
    const parsedParams = {
      ...params,
      ...(params.typeCalculation ? { typeCalculation: calculationValues[parseInt(params.typeCalculation) ]} : {}),
    }

    return dispatch(getGraphActions(parsedParams as IPaginate & Partial<TAnalyticsPageFilter>));
  }, [])

  useEffect(() => {
    if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultAnalyticsFilterValues) as unknown as Partial<TAnalyticsPageFilter>)) {
      setFilter(parseFilterParamsForRequest(params, defaultAnalyticsFilterValues) as unknown as Partial<TAnalyticsPageFilter>)
    }
  }, [params])

  return (
    <UIContainer.PageContainer>
      <>
        <header className={s.headerContainer}>
          <p className={"title"}>График</p>
          <AnalyticsGraphControl />
        </header>
        <div className={s.submenu}><AnalyticsSubmenu /></div>
        <div className={s.bodyContainer}>
          <ResponsiveContainer width={'100%'} height={450}>
            <LineChart
              data={structureConfig.rows}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="0 0" />
              <ReferenceLine stroke="#E5E9ED" strokeDasharray="3 3" />
              <XAxis dataKey="name" interval={0} height={130} tick={<CustomizedAxisTick />} />
              <YAxis type="number" domain={['dataMin - 10', 'dataMax + 10']} />
              <Tooltip content={<CustomizedLegend />} />
              <Line
                type="linear"
                dataKey="score"
                fill="#E37639"
                stroke="#E37639"
                // В самой доке написано использование <CustomizedLabel />, но без игнора использование label={} ругается
                // @ts-ignore
                label={<CustomizedLabel />}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <TableGenerator
          dataQuery={getData}
          dataSelect={selectGraph}
          tableConfig={AnalyticsGraphTableConfig}
          filter={filter}
          withoutPagination
        />
      </>
    </UIContainer.PageContainer>
  )
}

export default AnalyticsGraph
