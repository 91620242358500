import React, {memo, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import Header from "../Header";
import s from "./s.module.css"
import AppRouter from "modules/AppRouter";
import AuthRouter from "modules/AuthRouter";
import {useSelector} from "react-redux";
import {RootState} from "store";
import {selectUserData, selectUserIsAuth, selectUserRequestStatus} from "store/profile/reselect";
import {updateProfile} from "store/profile/thunk";
import {useAppDispatch} from "hooks/redux";
import clsx from "clsx";
import NotificationSystem from "modules/NotificationSystem";
import {deleteUrlParam} from 'lib/insertUrl'
import ModalController from "modules/ModalController";
import {AuthProvider} from "providers/authProvider";

const MainContent = () => {
  const {pathname} = useLocation()

  const dispatch = useAppDispatch()
  const {
    isAuth,
    userData: {roleId},
    userRequestStatus: {isLoading},
  } = useSelector((store: RootState) => ({
    isAuth: selectUserIsAuth(store),
    userRequestStatus: selectUserRequestStatus(store),
    userData: selectUserData(store)
  }))

  useEffect(() => {
    // const referrer_uri = document.referrer.includes('https://tportal.vaz.ru') // uncomment in future
    const token_uri = new URLSearchParams(window.location.search).get('token')
    if (token_uri) {
      document.cookie = `token=${token_uri};`
      deleteUrlParam('token')
    }
  }, [])

  useEffect(() => {
    if (!isAuth) {
      dispatch(updateProfile())
    }
  }, [])

  useEffect(() => {
    if (isAuth && !roleId) {
      dispatch(updateProfile())
    }
  }, [isAuth, roleId])

  const disableUIForAuditor = pathname.split('/').includes('form-pass') // отключаем шапку если проходим анкету
  return (
    <AuthProvider>
      <div className={s.container}>
        {!disableUIForAuditor && (
          <Header/>
        )}
        {
          !isLoading && (
            <div className={clsx(s.wrapper, {
              [s.flex]: !isAuth
            })}>
              {
                !isAuth
                  ? <AuthRouter />
                  : (
                    <>
                      <ModalController/>
                      <AppRouter/>
                    </>
                  )
              }
            </div>
          )
        }
        <footer style={{padding: 20, textAlign: 'center'}}>
          <p className={s.footerContent}>
            Copyright © 2022 LADA. Права защищены.
          </p>
        </footer>
        <NotificationSystem/>
      </div>
    </AuthProvider>
  );
};

export default memo(MainContent);
