import { createSlice } from '@reduxjs/toolkit'

import { RequestStatus } from 'models/storeInterface/DealersState'
import { FormState } from 'models/storeInterface/FormState'

import {
  clearFormAction,
  getFormWithQuestionAction,
  getQuestionAction, getTemplateFormWithQuestion,
  setAnswerToQuestionAction,
} from 'store/form/thunk'
import {LOADING_STATUS} from "../../lib/appConst";

// Стора для прохождения анкеты Тайным покупателем
const name = 'form';
const initialRequestStatus: RequestStatus = {
  errorMessage: '',
  isError: false,
  isLoading: LOADING_STATUS.IDLE
}
const initialState: FormState = {
  detail: null,
  question: null,
  hasStarted: false,
  requestStatus: initialRequestStatus,
  preview: null,
}

export const formSlice = createSlice({
  name,
  initialState,
  reducers: {
    setHasStarted: (state, { payload }) => {
      state.hasStarted = payload
    },
    clearAllFormData: (state) => {
      state.detail = initialState.detail
      state.question = initialState.question
      state.hasStarted = initialState.hasStarted
    }
  },
  extraReducers: {
    [getFormWithQuestionAction.pending.type]: (state) => {
      state.requestStatus.isLoading = LOADING_STATUS.LOADING
    },
    [clearFormAction.pending.type]: (state) => {
      state.requestStatus.isLoading = LOADING_STATUS.LOADING
    },
    [setAnswerToQuestionAction.pending.type]: (state) => {
      state.requestStatus.isLoading = LOADING_STATUS.LOADING
    },
    [getQuestionAction.pending.type]: (state) => {
      state.requestStatus.isLoading = LOADING_STATUS.LOADING
    },

    [getFormWithQuestionAction.rejected.type]: (state, { payload }) => {
      state.requestStatus.errorMessage = payload
      state.requestStatus.isError = true
      state.requestStatus.isLoading = LOADING_STATUS.IDLE
    },
    [getFormWithQuestionAction.fulfilled.type]: (state, { payload }) => {
      state.detail = payload.detail
      state.question = payload.question
      state.hasStarted = payload.hasStarted
      state.requestStatus.isLoading = LOADING_STATUS.IDLE
    },

    [clearFormAction.fulfilled.type]: (state, { payload }) => {
      state.question = payload
      state.hasStarted = false
      state.requestStatus.isLoading = LOADING_STATUS.IDLE
    },
    [setAnswerToQuestionAction.fulfilled.type]:
      (state, { payload }: { payload: { data: any, key: keyof FormState} }) => {
        state[payload.key] = payload.data
        state.requestStatus.isLoading = LOADING_STATUS.IDLE
    },

    [getQuestionAction.rejected.type]: (state, { payload }) => {
      state.requestStatus.errorMessage = payload
      state.requestStatus.isError = true
      state.requestStatus.isLoading = LOADING_STATUS.IDLE
    },
    [getQuestionAction.fulfilled.type]: (state, { payload }) => {
      state.question = payload
      state.requestStatus.isLoading = LOADING_STATUS.IDLE
    },
    [getTemplateFormWithQuestion.fulfilled.type]: (state, { payload }) => {
      state.preview = payload
    }
  }
})

export const {
  setHasStarted,
  clearAllFormData
} = formSlice.actions
export default formSlice.reducer
