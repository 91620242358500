import { RootState } from '../index'
import { createSelector } from '@reduxjs/toolkit'

const getFormDetail = (store: RootState) => store.formDetailSlice

export const selectFormTemplateDetail = createSelector(getFormDetail, state => state.template.instance);
export const selectFormTemplateDetailStatus = createSelector(getFormDetail, state => state.template.status);
export const selectFormTemplateDetailError = createSelector(getFormDetail, state => state.template.error);

export const selectFormToDetail = createSelector(getFormDetail, state => state.form.instance);
export const selectFormToDetailStatus = createSelector(getFormDetail, state => state.form.status);
export const selectFormToDetailError = createSelector(getFormDetail, state => state.form.error);

