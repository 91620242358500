import {ITableConfig, TABLE_CELL_EDIT_TYPE} from '../../modules/TableGenerator'
import React from 'react'
import {getShortFIO} from '../getShortFIO'
import {ActionsForAdmin, ActionsForAuditor, DownloadReportFile, PrevDealerActions} from './shellComponents'
import {NOTIFICATOR_TYPE, TABLE_CONTROL_CONFIG_FIELD_TYPES} from '../appConst'
import {useDropDownQuery} from 'hooks/useDropDownQuery'
import FormsService from 'services/FormsService'
import plural from 'plural-ru'
import {setModal} from "../setModal";
import {ModalsEnum} from "modules/ModalController/modalManager";
import moment from "moment";
import {
  getFormsActionHelper, goodRequest,
  setAuditorToForm,
  setStatusToForm,
  setWaveToForm
} from "../apiHelpers";
import {Roles} from "models/Roles";
import {StatusCell, StatusCellForms, StatusCellUser, StatusUnload} from 'components/StatusMark';
import {StatusService} from "services/StatusService";
import UsersService from "services/UsersService";
import {TextEditorRaw} from 'components/UI/UIField/items/TextEditor'
import DealersService from "../../services/DealersService";
import {addNotification} from "../addNotification";
import {store} from "../../store";
import {updateResponsible} from "../../store/dealers";
import {IDealer} from "../../models/DealersServiceModel";
import {IIdentifier} from "../../models/common";
import {AxiosError} from "axios";

// функции для formsTableConfig
const _dateFormat = (timestamp?: string) => timestamp && moment(timestamp).format('DD.MM.YYYY')
const _editFormCost = async (val: any) => {
  try {
    await FormsService.edit({...val.row, cost: val.value})
    getFormsActionHelper()
  } catch (e) {
    alert('Непредвиденная ошибка, попробуйте еще раз или обратитесь в поддержку')
  }
}

/**
 * Конфиг для таблицы анкет
 */

export const formsTableConfig: ITableConfig = {
  structureConfig: [
    {name: 'ID анкеты', dataAnchor: 'id', sort: true},
    {
      name: 'Статус анкеты',
      dataAnchor: 'status',
      sortFieldName: 'status',
      component: StatusCellForms,
      sort: true,
    },
    {name: 'Тип анкеты', dataAnchor: 'typeForm.name', sortFieldName: 'typeForm', sort: true},
    {name: 'Название шаблона анкеты', dataAnchor: 'templateForm.name', sortFieldName: 'typeForm', sort: true},
    {name: 'Вид проверки', dataAnchor: 'kindForm.name', sortFieldName: 'kindForm', sort: true},
    {
      name: 'Дата визита',
      dataAnchor: 'visitDate',
      valueDecorator: _dateFormat,
      sort: true,
    },
    {
      name: 'Дата 1-й публикации',
      dataAnchor: 'publishDate',
      valueDecorator: _dateFormat,
      sort: true,
    },
    {
      name: 'Крайняя дата визита',
      dataAnchor: 'lastDate',
      valueDecorator: _dateFormat,
      sort: true,
    },
    {
      name: 'Дата апелляции',
      dataAnchor: 'appealDate',
      valueDecorator: _dateFormat,
      sort: true
    },
    {
      name: 'Комментарий',
      cellCustomStyle: {width: 250},
      dataAnchor: 'comment',
      editable: true,
      editType: TABLE_CELL_EDIT_TYPE.uniqField,
      editHandler: ({row}: any) => setModal(ModalsEnum.FormCommentModal, {formId: row.id}),
      sort: true,
    },
    {
      name: 'Стоимость',
      dataAnchor: 'cost',
      valueDecorator: value => Number(value),
      editable: true,
      editHandler: _editFormCost,
      editType: TABLE_CELL_EDIT_TYPE.numberField,
      sort: true,
    },
    {name: 'Год', dataAnchor: 'year', sort: true},
    {name: 'Волна', dataAnchor: 'wave.name', sortFieldName: 'wave', sort: true},
    {name: 'Квартал', dataAnchor: 'quarter', sort: true},
    {name: 'Название ДЦ', dataAnchor: 'dealer.name', sortFieldName: 'dealer', sort: true},
    {name: 'ИНН ДЦ', dataAnchor: 'dealer.INN', sort: true},
    {name: 'Регион', dataAnchor: 'dealer.distName', sort: true},
    {name: 'Город', dataAnchor: 'dealer.cityName', sort: true},
    {name: 'Адрес', dataAnchor: 'dealer.address', sort: true},
    {
      name: 'Тайный покупатель/аудитор',
      dataAnchor: 'user.fullName',
      sortFieldName: 'user',
      sort: true,
    },
  ],
  controlConfig: [
    {
      label: 'Изменить волну',
      type: TABLE_CONTROL_CONFIG_FIELD_TYPES.DROPDOWN,
      useDataRequest: () => useDropDownQuery(FormsService.getAllWaves),
      handler: setWaveToForm
    },
    {
      label: 'Изменить  аудитора',
      type: TABLE_CONTROL_CONFIG_FIELD_TYPES.DROPDOWN,
      filterKey: 'fullName',
      useDataRequest: () => useDropDownQuery(UsersService.getAuditors, "fullName"),
      handler: setAuditorToForm
    },
    {
      label: 'Изменить статус',
      type: TABLE_CONTROL_CONFIG_FIELD_TYPES.DROPDOWN,
      useDataRequest: () => useDropDownQuery(params => StatusService.getAllFormsStatus({...params, forForm: true})),
      handler: setStatusToForm
    }
  ]
}

/**
 * Конфиг для таблицы архива анкет
 */

export const formsArchiveTableConfig: ITableConfig = {
  structureConfig: [
    {name: 'ID анкеты', dataAnchor: 'id', sort: true},
    {
      name: 'Дата визита',
      dataAnchor: 'visitDate',
      valueDecorator: _dateFormat,
      sort: true,
    },
    {name: 'Название ДЦ', dataAnchor: 'dealer.name', sortFieldName: 'dealer', sort: true},
    {name: 'Регион', dataAnchor: 'dealer.distName', sort: true},
    {name: 'Город', dataAnchor: 'dealer.cityName', sort: true},
    {name: 'Адрес', dataAnchor: 'dealer.address', sort: true},
    {name: 'Комментарий', cellCustomStyle: {width: 250}, dataAnchor: 'comment', sort: true,},
  ],
  controlConfig: [
    {
      label: 'Изменить волну',
      type: TABLE_CONTROL_CONFIG_FIELD_TYPES.DROPDOWN,
      useDataRequest: () => useDropDownQuery(FormsService.getAllWaves),
      handler: setWaveToForm
    },
    {
      label: 'Изменить  аудитора',
      type: TABLE_CONTROL_CONFIG_FIELD_TYPES.DROPDOWN,
      filterKey: 'fullName',
      useDataRequest: () => useDropDownQuery(UsersService.getAuditors, "fullName"),
      handler: setAuditorToForm
    },
    {
      label: 'Изменить статус',
      type: TABLE_CONTROL_CONFIG_FIELD_TYPES.DROPDOWN,
      useDataRequest: () => useDropDownQuery(StatusService.getAllFormsStatus),
      handler: setStatusToForm
    }
  ]
}

/**
 * Конфиг для таблицы диллеров
 */
export const dealersTableConfig: ITableConfig = {
  structureConfig: [
    {name: 'ID', dataAnchor: 'id', sort: true},
    {name: 'Статус', dataAnchor: 'Status', sort: true, component: StatusCell},
    {name: 'ИНН', dataAnchor: 'INN', sort: true},
    {name: 'Код филиала', dataAnchor: 'filialCode', sort: true},
    {name: 'Название', dataAnchor: 'name', sort: true},
    {
      name: 'Ответственный по Аудиту',
      dataAnchor: 'responsible',
      sort: true,
      editable: true,
      maxLength: 1000,
      editType: TABLE_CELL_EDIT_TYPE.multilineTextField,
      editHandler: async ({row: {id}, value}) => {
        try {
          const res = await DealersService.putResponsibleDealer(id, {responsible: value})
          if(goodRequest(res.status)){
            store.dispatch(updateResponsible({dealerId: id, responsible: value}))
          }
        } catch (e) {
          if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
          else {
            if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
            else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
          }
      }
      },
    },
    {name: 'Федеральный округ', dataAnchor: 'stateCode', sort: true},
    {name: 'Регион', dataAnchor: 'distName', sort: true},
    {name: 'Город', dataAnchor: 'cityName', sort: true},
    {name: 'Адрес', dataAnchor: 'address', sort: true},
    {name: 'Категория', dataAnchor: 'category', sort: true},
    {name: 'Предшественник', dataAnchor: 'prevDealer', sort: true, component: PrevDealerActions},
    {
      name: 'Назначенные контролеры',
      dataAnchor: 'Controls',
      sort: false,
      editable: true,
      editType: TABLE_CELL_EDIT_TYPE.uniqField,
      valueDecorator: value => {
        const sub = plural(value.length, 'Пользователь', 'Пользователя', 'Пользователей')
        return `${sub}: ${value.length}`
      },
      editHandler: ({row: {id}}) => {
        setModal(ModalsEnum.DealerUsersModal, {
          dealerId: id,
          filterBy: Roles.controller,
        })
      },
    },
    {
      name: 'Назначенные региональные менеджеры',
      dataAnchor: 'Managers',
      sort: false,
      editable: true,
      editType: TABLE_CELL_EDIT_TYPE.uniqField,
      valueDecorator: (value) => {
        const sub = plural(value.length, 'Пользователь', 'Пользователя', 'Пользователей')
        return `${sub}: ${value.length}`
      },
      editHandler: ({row: {id}}) => {
        setModal(ModalsEnum.DealerUsersModal, {
          dealerId: id,
          filterBy: Roles.regionalManager,
        })
      },
    },
    {
      name: 'Список групп дилерского центра',
      dataAnchor: 'DealerGroups',
      valueDecorator: (value) => {
        return `${value.length || 0} ${plural(value.length, 'группа', 'группы', 'групп')}`
      },
      sort: false,
      editable: true,
      editType: TABLE_CELL_EDIT_TYPE.uniqField,
      editHandler: ({row: {id}}) =>
        setModal(ModalsEnum.DealerGroupModal, {dealerId: id})
    },
  ],
}

/**
 * Конфиг для таблицы групп дилеров
 */

export const dealersGroupsTableConfig: ITableConfig = {
  structureConfig: [
    {name: 'ID', dataAnchor: 'id', sort: true},
    {name: 'Название', dataAnchor: 'name', sort: true},
    {
      name: 'Дилеры входящие в группу',
      dataAnchor: 'Dealer.length',
      sort: true,
      editable: true,
      editType: TABLE_CELL_EDIT_TYPE.uniqField,
      valueDecorator: value => `Дилеры: ${value || 0}`,
      editHandler: ({row: {id}}) => {
        setModal(ModalsEnum.DealerGroupsModal, {id})
      },
    },
  ],
}

/**
 * Конфиг для таблицы пользователей
 */
export const usersTableConfig: ITableConfig = {
  structureConfig: [
    {
      name: 'ID',
      dataAnchor: 'id',
      sort: true,
      editable: true,
      editType: TABLE_CELL_EDIT_TYPE.uniqField,
      editHandler: ({row: {id}}) => {
        setModal(ModalsEnum.UserEditModal, {userId: id})
      },
    },
    {
      name: 'Статус',
      dataAnchor: 'Status.name',
      sortFieldName: 'status',
      component: StatusCellUser,
      sort: true,
      editable: true,
      editType: TABLE_CELL_EDIT_TYPE.uniqField,
      editHandler: ({row: {id}}) => {
        setModal(ModalsEnum.UserEditModal, {userId: id})
      },
    },
    {name: 'ФИО', dataAnchor: 'fullName', valueDecorator: getShortFIO, sort: true},
    {name: 'Логин', dataAnchor: 'login', sort: true},
    {
      name: 'Тип',
      dataAnchor: 'isExternal',
      valueDecorator: (isExternal: boolean) => isExternal ? 'Внешний' : 'Внутренний',
      sort: true
    },
    {name: 'Email', dataAnchor: 'email', sort: true},
    {name: 'Телефон', dataAnchor: 'phone', sort: true},
    {name: 'Роль', dataAnchor: 'Role.name', sortFieldName: 'role', sort: true},
    {name: 'Должность', dataAnchor: 'position', sort: true},
    // {name: 'ДЦ', dataAnchor: 'Role.name',  sort: true},
    {name: 'Последний вход', dataAnchor: 'lastOnlineDate', valueDecorator: _dateFormat, sort: true},
    {name: 'Дата создания', dataAnchor: 'createdAt', valueDecorator: _dateFormat, sort: true},
    {
      name: 'Дилеры',
      dataAnchor: 'Dealers.length',
      sort: false,
      editable: true,
      editType: TABLE_CELL_EDIT_TYPE.uniqField,
      valueDecorator: value => `Дилеры: ${value || 0}`,
      editHandler: ({row: {id}}) => {
        const userId = id
        setModal(ModalsEnum.BindDealersToUserModal, {userId})
      },
    },
    {
      name: 'Шаблоны анкет',
      dataAnchor: 'TemplateForms.length',
      sort: false,
      editable: true,
      editType: TABLE_CELL_EDIT_TYPE.uniqField,
      valueDecorator: value => `Шаблоны: ${value || 0}`,
      editHandler: ({row: {id}}) => {
        const userId = id
        setModal(ModalsEnum.BindUserToTemplateForms, {userId})
      },
    },
  ],
  controlConfig: [
    {
      label: 'Изменить волну',
      type: TABLE_CONTROL_CONFIG_FIELD_TYPES.DROPDOWN,
      useDataRequest: () => {
      },
      handler: (id) => console.log(id),
    },
  ]
}

/**
 * Конфиг для таблицы апелляций
 */
export const appealsTableConfig: ITableConfig = {
  structureConfig: [
    {name: 'ID', dataAnchor: 'id', sort: true},
    {name: 'Текст апелляции', dataAnchor: 'appealText', sort: true},
    {
      name: 'Вопрос',
      dataAnchor: 'Answer.FormQuestion.textQuestion',
      valueDecorator: (data) => {
        try {
          return data && <TextEditorRaw value={JSON.parse(data)} readOnly/>
        }
        catch (e) {
          console.log(data)
        }
      },
      sortFieldName: 'question',
      sort: true
    },
    {name: 'Пользователь', dataAnchor: 'User.fullName', sortFieldName: 'User', sort: true},
    {name: 'Анкета', dataAnchor: 'Answer.Form.templateForm.name', sortFieldName: 'form', sort: true},
    {name: 'Дата первой публикации', dataAnchor: 'Answer.Form.publishDate', valueDecorator: _dateFormat, sort: true},
    {
      name: 'Время визита', dataAnchor: 'Answer.Form.visitDate', valueDecorator: (val: string | null) =>
        val && moment(val).format("YYYY-MM-DD"), sort: true
    },
    {name: 'ФО', dataAnchor: 'Answer.Form.dealer.stateCode', sort: true},
    {name: 'Регион', dataAnchor: 'Answer.Form.dealer.distName', sort: true},
    {name: 'Город', dataAnchor: 'Answer.Form.dealer.cityName', sort: true},
    {name: 'Адрес', dataAnchor: 'Answer.Form.dealer.address', sort: true},
    {name: 'ДЦ', dataAnchor: 'Answer.Form.dealer.name', sortFieldName: 'dealer', sort: true},
    {name: 'Статус', dataAnchor: 'Status.name', sortFieldName: 'status', sort: true},
    {name: 'Осталось дн.', dataAnchor: 'deadline', sort: true},
    {name: 'Волна', dataAnchor: 'Answer.Form.wave.name', sortFieldName: 'wave', sort: true},
    {name: '', dataAnchor: '', component: ActionsForAdmin },
  ],
}

/**
 * Конфиг для таблицы логов
 */
export const loggingTableConfig: ITableConfig = {
  structureConfig: [
    {name: 'ID', dataAnchor: 'id', sort: true},
    {name: 'Пользователь', dataAnchor: 'User.fullName', sortFieldName: 'User', sort: true},
    {name: 'Сообщение', dataAnchor: 'messageTemplate', sort: true},
    {name: 'Дата', dataAnchor: 'createdAt', valueDecorator: _dateFormat, sort: true},
    {name: 'Тип запроса', dataAnchor: 'actionType', sort: true},
    {name: 'Тип объекта', dataAnchor: 'objectType', sort: true},
  ],
}

/**
 * Конфиг таблицы для тайного покупателя
 * */
export const todosTableConfig = {
  structureConfig: [
    {name: 'ID Анкеты', dataAnchor: 'id', sort: true},
    {name: 'Дилер', dataAnchor: 'dealer', component: ({val}: {val: IDealer & IIdentifier}) => {
      return (
        <div>
          <p>{val.name}</p>
          {
            !!val.responsible &&
            (
              <>
                <hr />
                <p>{val.responsible}</p>
              </>
            )
          }
        </div>
      )
      }},
    {name: 'Тип анкеты', dataAnchor: 'typeForm.name', sortFieldName: 'typeForm', sort: true},
    {name: 'Статус', dataAnchor: 'status', sort: true, component: StatusCellForms},
    {name: 'Город', dataAnchor: 'dealer.cityName', sortFieldName: 'cityName', sort: true},
    {name: 'Адрес', dataAnchor: 'dealer.address', sortFieldName: 'address', sort: true},
    {name: 'Крайняя дата визита', dataAnchor: 'lastDate', valueDecorator: _dateFormat, sortFieldName: 'lastDate', sort: true},
    {
      name: 'Комментарий',
      cellCustomStyle: {width: 250},
      dataAnchor: 'comment',
      sort: true
    },
    {name: '', component: ActionsForAuditor, sort: false},
  ]
}

/*
* Конфиг таблицы для аналитики страницы с графиком
* */
export const AnalyticsGraphTableConfig = {
  structureConfig: [
    { name: 'Название', dataAnchor: 'name' },
    { name: 'Очки', dataAnchor: 'score' },
  ]
}


/*
* Конфиг таблицы для аналитики "Выгрузка"
* */
export const AnalyticsUnloadTableConfig = {
  structureConfig: [
    { name: 'Кто выгрузил', dataAnchor: 'User.fullName', sort: true },
    { name: 'Дата выгрузки', dataAnchor: 'createdAt', valueDecorator: _dateFormat, },
    { name: 'Параметры выгрузки', dataAnchor: 'params' },
    { name: 'Статус формирования', dataAnchor: 'Status.name', sort: true, component: StatusUnload },
    { name: '', dataAnchor: 'File', component: DownloadReportFile },
  ]
}

/*
* Конфиг таблицы для аналитики "Отчеты по аппеляциям"
* */
export const AnalyticsAppealsTableConfig = {
  structureConfig: [
    { name: 'ID анкеты', dataAnchor: 'id', sort: true },
    { name: 'Тип анкеты', dataAnchor: 'typeForm.name' },
    { name: 'Вид анкеты', dataAnchor: 'kindForm.name' },
    { name: 'Шаблон анкеты', dataAnchor: 'templateForm.name' },
    { name: 'Волна', dataAnchor: 'wave.name' }, // в эксель этого нету, там это почему-то в колонке Волна
    { name: 'ИНН', dataAnchor: 'dealer.INN' },
    { name: 'Код дилера', dataAnchor: 'dealer.filialCode' },
    { name: 'Название дилера', dataAnchor: 'dealer.name' },
    { name: 'ФО', dataAnchor: 'dealer.stateCode' },
    { name: 'Область', dataAnchor: 'dealer.distName' },
    { name: 'Город', dataAnchor: 'dealer.cityName' },
    { name: 'Адрес', dataAnchor: 'dealer.address' },
    { name: 'Апелляции', dataAnchor: 'appealCount' },
    { name: 'Набранное кол-во по баллам', dataAnchor: 'score' },
    { name: 'Возможное кол-во по баллам', dataAnchor: 'maxScore' },
    { name: 'Общий процент по баллам', dataAnchor: 'percentScore' },
  ]
}


