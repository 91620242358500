import React, {ChangeEvent, CSSProperties, Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {Control, Controller, FieldError, FieldName} from "react-hook-form"
import {ReactSVG} from 'react-svg'
import s from './s.module.css'
import clsx from "clsx"
import SelectSVG from 'assets/svg/filter/selectIcon.svg'
import {OptionData} from "models/FilterData";
import {useInView} from "react-intersection-observer";
import {useDebounce} from "hooks/useDebounce";
import {useAppDispatch} from "hooks/redux";
import {useSelector} from "react-redux";
import {setDefaultCurrentPage} from "store/dealers";
import {selectDealerSelect} from "store/dealers/reselect";
import {selectOptionsCount} from "lib/selectConfig";
import {DealersSelectOptions} from "models/storeInterface/DealersState";
import {LOADING_STATUS} from "lib/appConst";

/**
 * Интерфейс данных для асинхронного запроса данных с сервера
 */
export interface FetchData {
  action: Function,
  /**
   * Тип запрашиваемых опций
   */
  optionType: DealersSelectOptions
}

export type SelectFetchRawPropsType = {
  selectName: string
  customStyle?: CSSProperties | undefined,
  customStyleWrapper?: CSSProperties | undefined,
  customStyleInput?: CSSProperties | undefined,
  name: string
  error?: any | FieldError
  paginateTrigger?: () => void
  onBlur?: () => void
  filterSetter?: Dispatch<SetStateAction<string>>
  createHandler?: (inputValue: string) => void
  /**
   * Данные для асинхронного запроса данных с сервера
   */
  fetchData: FetchData
  extraOnClickHandler?: Function
}

type _ControllerTypes = {
  onChange: (e: ChangeEvent<HTMLInputElement> | OptionData) => void
  value: OptionData
  setDefault: any
}

export type SelectFetchPropsType =
  SelectFetchRawPropsType & {
  setDefault?: any
  control: Control<any>
  name: FieldName<any>
  errors?: any
}

/**
 * Компонент Select с функцией фильтра, запросом данным для опций с сервера и пагинацией
 * @param selectName - название для placeholder
 * @param customStyle - дополнительные стили
 * @param customStyleInput - дополнительные стили для <input> в <SelectRaw>
 * @param options - данные для вариантов
 * @param control - из useForm
 * @param name - имя input формы для useForm
 * @param setDefault - функция сброса, устанавливает начальные значения
 * @param fetchData - данные для запроса опций
 * @constructor
 */
const SelectFetch = ({
                  selectName,
                  customStyle,
                  customStyleInput,
                  customStyleWrapper,
                  control,
                  name,
                  setDefault = () => {},
                  paginateTrigger = () => {},
                  filterSetter = () => {},
                  extraOnClickHandler = () => {},
                  errors,
                  onBlur,
                  createHandler,
                  fetchData
                }: SelectFetchPropsType) => {
  return (
    <Controller
      name={name}
      control={control}
      render={
        ({field: {onChange, value}}) =>
          <SelectFetchRaw
            selectName={selectName}
            setDefault={setDefault}
            customStyle={customStyle}
            customStyleInput={customStyleInput}
            customStyleWrapper={customStyleWrapper}
            name={name}
            onChange={onChange}
            value={value}
            error={errors !== undefined ? errors[name] : undefined}
            paginateTrigger={paginateTrigger}
            filterSetter={filterSetter}
            onBlur={onBlur}
            createHandler={createHandler}
            fetchData={fetchData}
            extraOnClickHandler={extraOnClickHandler}
          />
      }
    />
  )
}

export default SelectFetch;

/**
 * Начинка для компонента Select
 * @param selectName - название для placeholder
 * @param customStyle - дополнительные стили
 * @param options - данные для вариантов
 * @param name - имя input формы для useForm
 * @param onChange - из useForm
 * @param setDefault - функция сброса, устанавливает начальные значения
 * @param fetchData - данные для запроса опций
 * @constructor
 */
const SelectFetchRaw = ({
                     selectName,
                     customStyle,
                     customStyleInput,
                     customStyleWrapper,
                     name,
                     onChange,
                     setDefault,
                     paginateTrigger,
                     filterSetter,
                     value,
                     error,
                     onBlur,
                     createHandler,
                     fetchData,
                     extraOnClickHandler
                   }: SelectFetchRawPropsType & _ControllerTypes) => {
  /**
   * Отображаемое имя в вариантах выбора в фильтре
   */
  const [inputValue, setInputValue] = useState('');
  /**
   * Значение выбранного варианта в фильтре
   */
  const [filter, setFilter] = useState('');
  const debouncedInputValue = useDebounce(filter, 500)
  const [isManualInput, setIsManualInput] = useState(false)
  const [isVisibleOptions, setIsVisibleOptions] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [fetchedOptions, setFetchedOptions] = useState([] as OptionData[]);

  //TODO Это надо передавать как входные данные =(((
  const optionsData = useSelector(selectDealerSelect[fetchData.optionType].options);
  const paginationOptionsData = useSelector(selectDealerSelect[fetchData.optionType].pagination);
  const {isLoading} = useSelector(selectDealerSelect[fetchData.optionType].status);
  //-----------------

  /**
   * Полученные опции для добавления - (true) или для замены текущих - (false)
   */
  // const isAddable: boolean = false;

  useEffect(() => {
    (filterSetter && isManualInput) && setFilter(inputValue)
  }, [inputValue])

  useEffect(() => {
    filterSetter && filterSetter(debouncedInputValue)
  }, [debouncedInputValue])

  useEffect(() => {
    setDefault(() => {
      setInputValue('');
    })
  }, [])

  useEffect(() => {
    if (inputRef?.current) inputRef.current.value = inputValue;
  }, [inputValue])

  /**
   * Обработчик нажатия на select (показывает список опций выбора)
   */
  const selectHandler = () => {
    !isVisibleOptions && inputRef.current?.focus();
    if (!isVisibleOptions && !inputValue && !fetchedOptions.length && fetchData) {
      dispatch(setDefaultCurrentPage(fetchData.optionType));
      fetchOptions(inputValue);
    }
    setIsVisibleOptions(!isVisibleOptions);
  }

  useEffect(() => {
    if (isLoading === LOADING_STATUS.IDLE && optionsData.length) {
      let prevOptions = [] as OptionData[];
      if (paginationOptionsData.currentPage > 1) prevOptions = [...fetchedOptions];
      setFetchedOptions([ ...prevOptions, ...optionsData]) //TODO Проверить точно ли работает добавление
    }
  }, [isLoading])

  /**
   * Запрашивает данные для опций по клику на Select`е
   */
  const fetchOptions = (name: string) => {
    dispatch(
      fetchData.action({
        name, //TODO проверить, пока не работает
        count: selectOptionsCount,
        page: paginationOptionsData.currentPage
      })
    )
  }

  /**
   * Таймер закрытия окна с вариантами
   */
  let timeout: ReturnType<typeof setTimeout>;

  /**
   * Скрывает варианты выбора
   * Очистка инпута если вариант не выбран
   * Если не сделать асинхронным, то при клике на опцию не происходит выбора - onBlur срабатывает прежде optionHandler
   */
  const closeOptions = () => {
    timeout = setTimeout(() => {
      isManualInput && !value && setInputValue('')
      isManualInput && value && setInputValue(value.name)
      setFilter('')
      setIsManualInput(false)
      isVisibleOptions && setIsVisibleOptions(false);
    }, 100)
  }

  /**
   * Останавливает таймер закрытия окна с вариантами
   * Если не сделать асинхронным, то запускается раньше запуска таймера
   */
  const stopClosingOptions = () => {
    setTimeout(() => {
      clearTimeout(timeout)
    }, 10)
  }

  /**
   * Обработчик нажатия на option
   * @param option - instance выбранного элемента
   */
  const optionHandler = (option: OptionData) => {
    setIsVisibleOptions(false);
    setIsManualInput(false)
    setInputValue(option.name);
    onChange(option);
    extraOnClickHandler && extraOnClickHandler();
  }

  useEffect(() => {
    if(value && !isManualInput) {
      setInputValue(value.name)
    }
  }, [value])

  /**
   * Обработчик изменения input
   * @param e - событие на input
   */
  const inputHandle = (e: React.FormEvent<HTMLInputElement>): void => {
    setIsVisibleOptions(true);
    setIsManualInput(true)
    setInputValue(e.currentTarget.value)

    dispatch(setDefaultCurrentPage(fetchData.optionType));
    fetchOptions(e.currentTarget.value);
  }

  const onCreate = (value: string) => {
    if(!value.length) {
      setIsVisibleOptions(false)
    }
    if(value.length && createHandler) {
      createHandler(inputValue)
      closeOptions()
    }
  }

  const {ref, entry} = useInView({
    threshold: 0
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      paginateTrigger && paginateTrigger()
    }
  }, [entry])

  return (
    <div
      className={s.wrapper}
      style={customStyle}
    >
      <div
        className={clsx(s.select)}
        style={customStyleWrapper}
      >
        <div
          className={clsx(s.select__header)}
          onClick={selectHandler}
        >
          {inputValue && <span className={clsx(s.select__subheader)}>{selectName}</span>}
          <input
            style={customStyleInput}
            type={'text'}
            className={`${clsx(s.select__headerName)} ${
              inputValue
                ? clsx(s.select__headerName_filled)
                : clsx(s.select__headerName_empty)
            }`}
            autoComplete={'off'}
            placeholder={selectName}
            name={name}
            value={inputValue}
            onInput={inputHandle}
            ref={inputRef}
            onBlur={() => {
              closeOptions()
              onBlur && onBlur()
            }}
          />
          <ReactSVG
            data-testid="selectControl"
            className={clsx(s.resetElement)}
            src={SelectSVG}
          />
        </div>

        {isVisibleOptions &&
          <div
            className={clsx(s.select__options, name)}>
            {createHandler &&
              <option
                key={'creation'}
                className={clsx(s.select__item, {
                  [s.disableOption]: !inputValue.length,
                })}
                onClick={() => onCreate(inputValue)}
                onMouseDown={stopClosingOptions}
              >
                Создать ({inputValue})
              </option>
            }
            {!!fetchedOptions?.length
              ? fetchedOptions.map(option =>
                <option
                  key={option.value}
                  className={clsx(s.select__item)}
                  onClick={() => optionHandler(option)}
                  value={option.value}
                  onMouseDown={stopClosingOptions}
                >
                  {option.name}
                </option>
              )
              : <div className={clsx(s.noItemPlug)}>Не найдено</div>
            }
            <div ref={ref}/>
          </div>
        }

      </div>
      {error && <p className={s.error}>{error?.message}</p>}
    </div>
  )
}
