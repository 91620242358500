import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'

import DeleteIco from 'assets/svg/fileUpload/delete.svg'
import PdfIco from 'assets/svg/fileExtensions/pdf.svg'
import AudioIco from 'assets/svg/fileExtensions/audio.svg'
import FileIco from 'assets/svg/fileExtensions/file.svg'
// todo нужна иконка для файлов любого типа, нужно написать дизайнеру

import { IFile } from 'models/InstanceInterfaces/IFile'
import { IIdentifier, IResponseExtraData } from 'models/common'
import FileService from 'services/FileService'

import {API_URL, API_URL_CLIENT} from '../../../../../../../http' // don't touch, else it'l be incorrect import
import { UPLOAD_FORMATS } from 'lib/uploadFormats'
import { UPLOAD_FORMAT_TYPES } from 'lib/appConst'
import { getLinkToFileHelper } from 'lib/apiHelpers';

import s from './s.module.css'

export type FileItemPropsType = IIdentifier & {
  onDelete: (id: number) => void
}

export const getFileSize = (fileSize: number): string => {
  return fileSize > 1000 ? `${(fileSize / 1000).toFixed(2)}mb` : `${parseInt(String(fileSize))}kb`
}

export const getFileExtension = (fileName: string): string => {
  return fileName.split('.')[fileName.split('.').length - 1]
}

const FileItem = ({
  id,
  onDelete,
}: FileItemPropsType) => {

  const [file, setFile] = useState<IFile & IResponseExtraData & IIdentifier | null>(null)

  const getFile = () => {
    FileService.getFile(id)
      .then(({ data }) => {
        setFile(data)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    getFile()
  }, [])

  const onHandleDelete = () => {
    onDelete(id)
  }

  if (file === null) {
    return null
  }

  const isAudio = UPLOAD_FORMATS[UPLOAD_FORMAT_TYPES.audio].includes(file.fileType)

  return (
    <div>
      <div className={s.file}>
        <div>
          <ReactSVG
            data-testid="file-extension"
            src={isAudio ? AudioIco : FileIco}
          />
        </div>
        <div className={s.name}>
          <a href={`${getLinkToFileHelper()}${file.url}`} target={'_blank'} referrerPolicy={'no-referrer'}>
            {file.fileName}
          </a>
        </div>
        {file.fileSize && (
          <div className={s.size}>{getFileSize(file.fileSize)}</div>
        )}
        <div className={s.delete} onClick={onHandleDelete}>
          <ReactSVG
            style={{color: "#848E98"}}
            data-testid="delete-file"
            src={DeleteIco}
          />
        </div>
      </div>
      {isAudio && (
        <audio controls src={`${API_URL_CLIENT}${file.url}`}/>
      )}
    </div>
  )
}

export default FileItem
