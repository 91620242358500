import {combineReducers} from "@reduxjs/toolkit";
import common from "./common";
import profile from "./profile";
import users from "./users";
import dealers from "./dealers";
import dealersGroups from "./dealersGroups";
import forms from "./forms";
import formTemplateEditor from "./formTemplateEditor";
import appeals from "./appeals";
import form from "./form";
import analytics from "./analytics";
import logs from './logs'
import statistic from './statistic'
import formDetailSlice from "./formDetail";
import formsArchive from "./formsArchive";

export const rootReducer = combineReducers({
  common,
  profile,
  users,
  dealers,
  dealersGroups,
  forms,
  formTemplateEditor,
  appeals,
  form,
  logs,
  analytics,
  statistic,
  formDetailSlice,
  formsArchive,
})
