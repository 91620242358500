import {useEffect, useState} from 'react'
import {AxiosResponse} from 'axios'
import {
  ISearchByNameWithPaginateAndIDRequest,
  ListResponse,
  IResponseExtraData,
  ISearchByNameWithPaginateRequest,
} from 'models/common'
import {saveGetByPath} from 'lib/saveGetByPath'
import {OptionData} from '../models/FilterData'
import {useDebounce} from './useDebounce'

export interface PaginationItemsFromRequestI<ElementType, RequestFilterType> {
  id?: any
  request: (
    params: ISearchByNameWithPaginateRequest | ISearchByNameWithPaginateAndIDRequest,
  ) => Promise<AxiosResponse<ListResponse<(ElementType & IResponseExtraData) | ElementType>>>
  count?: number
  filter?: string
  filterField?: string
  requestFilter?: RequestFilterType
  dataConverter?: (element: ElementType, id?: number) => OptionData
  disabled?: boolean
}

export type TSelectControl = [
  rowsForSelect: OptionData[],
  paginateTrigger: () => void,
  doRefetch: () => void,
  extraData: any,
  loading: boolean,
  total: number,
]

const usePaginationItemsFromRequest = <ElementType, RequestFilterType = unknown>({
  request,
  requestFilter,
  dataConverter,
  filterField,
  disabled,
  count = 10,
  filter,
  id,
}: PaginationItemsFromRequestI<ElementType, RequestFilterType>): TSelectControl => {
  const [refetch, setRefetch] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [rows, setRows] = useState<(ElementType & IResponseExtraData & {name: string; id: number})[]>([])
  const [rowsForSelect, setRowsForSelect] = useState<OptionData[]>([])
  const [extraData, setExtraData] = useState<any[]>([])
  const filterDebounce = useDebounce(filter, 500)

  const getItems = async (page = 1) => {
    await setLoading(true)

    try {
      const data = await request({
        page,
        count,
        ...(() => (filterField ? {[`${filterField}`]: filter} : {name: filter}))(),
        id,
        ...requestFilter,
      })
      const rows = saveGetByPath(data, 'data.rows', [])
      const total = saveGetByPath(data, 'data.count', [])
      return {rows, total}
    } catch (e) {
      return {rows: [], count: 0}
    }
  }

  const paginateTrigger = () => {
    if (Math.ceil(total / count) > page) {
      setPage(p => p + 1)
    }
  }

  const doRefetch = () => setRefetch(true)

  useEffect(() => {
    if (page === 1 && !disabled && !refetch) {
      getItems()
        .then(({rows, total}) => {
          setRows(rows)
          setTotal(total)
          setExtraData(rows)
          setLoading(false)
        })
    }
  }, [page, filterDebounce, id, refetch])

  useEffect(() => {
    setPage(1)
    setRefetch(false)
  }, [id, refetch])

  useEffect(() => {
    if (page > 1) {
      getItems(page)
        .then(({rows, total}) => {
          setRows(p => [...p, ...rows])
          setTotal(total)
          setExtraData(p => [...p!, ...rows])
          setLoading(false)
        })
    }
  }, [page, filterDebounce])

  useEffect(() => {
    setPage(1)
  }, [filterDebounce])

  useEffect(() => {
    if (rows.length) {
      setRowsForSelect(
        dataConverter
          ? rows.map((el, id) => dataConverter(el, id))
          : rows.map(({name, id}) => ({name, value: Number(id)})),
      )
    } else {
      setRowsForSelect([])
    }
  }, [rows])

  return [rowsForSelect, paginateTrigger, doRefetch, extraData, loading, total]
};

export default usePaginationItemsFromRequest
