import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IProfile} from "../../models/InstanceInterfaces/IUser";
import {UserState} from "../../models/storeInterface/UserState";
import {loginUserAction, logoutUserAction, updateProfile} from "./thunk";
import {IIdentifier} from "../../models/common";

const name = 'profile';
export const initialStateProfile: UserState = {
  isAuth: false,
  requestStatus: {
    isLoading: false,
    isError: false,
    errorMessage: ''
  },
  userData: {} as IProfile & IIdentifier
}

export const profileSlice = createSlice({
  name: name,
  initialState: initialStateProfile,
  reducers: {
    logoutUserSuccess(state: UserState) {
      state.requestStatus = initialStateProfile.requestStatus;
      state.userData = initialStateProfile.userData;
    },
    logoutUserError(state: UserState, action: PayloadAction<string>) {
      state.requestStatus.isLoading = false;
      state.requestStatus.isError = true;
      state.requestStatus.errorMessage = action.payload;
      state.userData = initialStateProfile.userData;
    }
  },
  extraReducers: {
    [loginUserAction.pending.type && updateProfile.pending.type]: (state: UserState) => {
      state.requestStatus.isLoading = true;
      state.requestStatus.isError = false;
      state.requestStatus.errorMessage = '';
    },
    [loginUserAction.fulfilled.type]: (state: UserState, {payload}: PayloadAction<{ token: string, user: IProfile & IIdentifier }>) => {
      document.cookie = `token=${payload.token};`
      state.isAuth = true
      state.requestStatus = initialStateProfile.requestStatus;
      state.userData = payload.user;
    },
    [loginUserAction.rejected.type]: (state: UserState, {payload}: PayloadAction<string>) => {
      document.cookie = 'token=;'
      state.isAuth = false
      state.requestStatus.isLoading = false;
      state.requestStatus.isError = true;
      state.requestStatus.errorMessage = payload;
      state.userData = initialStateProfile.userData;
    },
    [updateProfile.fulfilled.type]: (state: UserState, {payload}: PayloadAction<IProfile & IIdentifier>) => {
      if(payload) {
        state.isAuth = true
        state.userData = payload;
      }
      state.requestStatus = initialStateProfile.requestStatus;
    },
    [updateProfile.rejected.type]: (state: UserState) => {
      state.isAuth = false
      state.requestStatus = initialStateProfile.requestStatus;
      state.userData = initialStateProfile.userData;
    },
    [logoutUserAction.fulfilled.type]: (state: UserState) => {
      state.isAuth = false
      state.requestStatus = initialStateProfile.requestStatus;
      state.userData = initialStateProfile.userData;
    }
  }
  // extraReducers: builder => {
  //   builder
  //     .addCase(loginUserAction.pending, (state: UserState) => {
  //       state.requestStatus.isLoading = true;
  //       state.requestStatus.isError = false;
  //       state.requestStatus.errorMessage = '';
  //     })
  //     .addCase(loginUserAction.fulfilled.type, (state: UserState, { payload }: PayloadAction<{token: string, user: IUser}>) => {
  //       //TODO: Удалить сайд!!!
  //       document.cookie = `token=${payload.token};`
  //       state.isAuth = true
  //       state.requestStatus = initialState.requestStatus;
  //       state.userData = payload.user;
  //     })
  //     .addCase(loginUserAction.rejected.type, (state: UserState, { payload }: PayloadAction<string>) => {
  //       state.requestStatus.isLoading = false;
  //       state.requestStatus.isError = true;
  //       state.requestStatus.errorMessage = payload;
  //       state.userData = initialState.userData;
  //     })
  //     .addCase(logoutUserAction.fulfilled.type, (state: UserState) => {
  //       //TODO: Удалить сайд!!!
  //       document.cookie = 'token=;'
  //       state.isAuth = false
  //       state.requestStatus = initialState.requestStatus;
  //       state.userData = initialState.userData;
  //     })
  //
  // }
})

export default profileSlice.reducer;
