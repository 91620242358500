import React, {useEffect, useState} from 'react';
import {IForm} from "models/InstanceInterfaces/IForm";
import {IIdentifier, IResponseExtraData, ListResponse} from "models/common";
import s from './s.module.css'
import clsx from "clsx";
import FormsService from "../../../services/FormsService";
import {IGroup} from "../../../models/IGroup";
import Preloader from "../../../components/UI/Preloader/Preloader";
import {getPercentFormDetail} from "../QuestionBlock";

export interface IPointsBlock {
  form: (IForm & IIdentifier)
}
const PointsBlock = ({form}: IPointsBlock) => {
  const [factorGroup, setFactorGroup] = useState<ListResponse<IGroup & IResponseExtraData>>()
  const [processGroup, setProcessGroup] = useState<ListResponse<IGroup & IResponseExtraData>>()

  const [totalStaticFactorCount, setTotalStaticFactorCount] = useState(0)
  const [totalStaticFactorScore, setTotalStaticFactorScore] = useState(0)
  const [totalDynamicFactorCount, setTotalDynamicFactorCount] = useState(0)
  const [totalDynamicFactorScore, setTotalDynamicFactorScore] = useState(0)

  const [totalStaticProcessCount, setTotalStaticProcessCount] = useState(0)
  const [totalStaticProcessScore, setTotalStaticProcessScore] = useState(0)
  const [totalDynamicProcessCount, setTotalDynamicProcessCount] = useState(0)
  const [totalDynamicProcessScore, setTotalDynamicProcessScore] = useState(0)

  const generateRows = (rows: IGroup[]) => {
    return rows.map(({
                       name,
                       scorePercent = 0,
                       countPercent = 0
                     }, id) => {
      return (
        <tr className={s.tableRow} key={id}>
          <td className={clsx(s.tableCell)}>{name}</td>
          <td className={clsx(s.tableCell, s.tableCellBorder)}>{countPercent ? `${countPercent.toFixed(2)}%` : "-"}</td>
          <td className={clsx(s.tableCell)}>{scorePercent ? `${scorePercent.toFixed(2)}%` : "-"}</td>
        </tr>
      )
    })
  }

  const getFactorGroup = async () => {
    const {data} = await FormsService.getFactorGroupAll(form.templateFormId, form.id)
    setFactorGroup(data)
  }

  const getProcessGroup = async () => {
    const {data} = await FormsService.getProcessGroupAll(form.templateFormId, form.id)
    setProcessGroup(data)
  }

  useEffect(() => {
    getFactorGroup()
    getProcessGroup()
  }, [])

  useEffect(() => {
    if(factorGroup?.rows.length) {
      factorGroup.rows.map(({
                              score = 0,
                              count = 0,
                              allScore= 0,
                              allCount = 0
                            }) => {
        setTotalStaticFactorCount(p => p + allCount)
        setTotalStaticFactorScore(p => p + allScore)
        setTotalDynamicFactorCount(p => p + count)
        setTotalDynamicFactorScore(p => p + score)
      })
    }
  }, [factorGroup])

  useEffect(() => {
    if(processGroup?.rows.length) {
      processGroup.rows.map(({
                              score = 0,
                              count = 0,
                              allScore= 0,
                              allCount = 0
                            }) => {
        setTotalStaticProcessCount(p => p + allCount)
        setTotalStaticProcessScore(p => p + allScore)
        setTotalDynamicProcessCount(p => p + count)
        setTotalDynamicProcessScore(p => p + score)
      })
    }
  }, [processGroup])

  return factorGroup && processGroup ? (
    <div className={s.container}>
      <table className={s.table}>
        <thead>
          <tr>
            <th></th>
            <th className={clsx(s.tableCell, s.tableCellBorder, s.noTopBorder, s.tableHeader, s.background)}>% по пунктам</th>
            <th className={clsx(s.tableCell, s.tableHeader, s.background)}>% по баллам</th>
          </tr>
        </thead>
        <tbody>
          <tr className={s.tableRow}>
            <td className={clsx(s.tableCell, s.tableCellHeader)}>Группировка  “По процессу”</td>
            <td className={clsx(s.tableCell, s.tableCellBorder)}>{getPercentFormDetail(totalStaticProcessCount, totalDynamicProcessCount)}%</td>
            <td className={clsx(s.tableCell)}>{getPercentFormDetail(totalStaticProcessScore, totalDynamicProcessScore)}%</td>
            <td className={clsx(s.tableCell)}></td>
          </tr>
          {processGroup?.rows && generateRows(processGroup?.rows)}
          <tr className={s.tableRow}>
            <td className={clsx(s.tableCell, s.tableCellHeader)}>Группировка  “По факторам”</td>
            <td className={clsx(s.tableCell, s.tableCellBorder)}>{getPercentFormDetail(totalStaticFactorCount, totalDynamicFactorCount)}%</td>
            <td className={clsx(s.tableCell)}>{getPercentFormDetail(totalStaticFactorScore, totalDynamicFactorScore)}%</td>
            <td className={clsx(s.tableCell)}></td>
          </tr>
          {factorGroup?.rows && generateRows(factorGroup?.rows)}
        </tbody>
      </table>
    </div>
  ) : <Preloader style={{margin: "auto"}}/>;
};

export default PointsBlock;
