import React from 'react'
import clsx from 'clsx'

import FileItem from './File'

import s from './s.module.css'

type UploadedPreviewPropsType = {
  files: number[],
  onDelete: (id: number) => void,
  isNormalFont?: boolean
}


const UploadedPreview = ({
  files,
  onDelete,
  isNormalFont
}: UploadedPreviewPropsType) => {

  return (
    <div>
      <h5 className={clsx(s.title, { [s.normalFont]: isNormalFont })}>Загруженные файлы</h5>
      {files.map((id) => (
        <FileItem
          key={id}
          id={id}
          onDelete={onDelete}
        />
      ))}
    </div>
  )
}

export default UploadedPreview
