import React, {Dispatch, memo, SetStateAction, useEffect, useState} from 'react'
import UICheckbox from 'components/UI/UICheckbox'
import DealersService from 'services/DealersService'
import s from '../s.module.css'
import {getAllDealersGroupsAction} from 'store/dealersGroups/thunk'
import {useAppDispatch} from 'hooks/redux'
import {addNotification} from 'lib/addNotification'
import {NOTIFICATOR_TYPE} from 'lib/appConst'
import { decrementDealerInGroupsById, incrementDealerInGroupsById } from 'store/dealersGroups'

type Props = {
  dealerId: number
  isBinded: boolean
  name: string
  groupId: number
  isAllChecked: boolean | null
  setRows: Dispatch<SetStateAction<any[]>>
  setDealerIdCache: Dispatch<SetStateAction<number[]>>
}

const DealerCheckbox: React.FC<Props> = ({
  dealerId,
  isBinded,
  name,
  groupId,
  isAllChecked,
  setRows,
  setDealerIdCache,
}) => {
  const dispatch = useAppDispatch()
  const [isChecked, setChecked] = useState(isBinded)
  const idStr = String(dealerId)

  const togglerDealerToGroup = async () => {
    if (isAllChecked === null) {
      try {
        setChecked(isChecked => !isChecked)
        if (isChecked) {
          await DealersService.delDealerFromGroup(idStr, {
            groupId,
          })
          addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Дилер успешно удален из группы!'})
          dispatch(decrementDealerInGroupsById({ groupId: Number(groupId), dealerId }))
        } else {
          await DealersService.putSetDealerGroup(idStr, {
            groupId,
          })
          addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Дилер успешно добавлен в группу!'})
          dispatch(incrementDealerInGroupsById({ groupId: Number(groupId), dealerId }))
        }
      } catch (error) {
        addNotification({type: NOTIFICATOR_TYPE.error, text: 'Не удалось устанавить/удалить дилера в группе!'})
      }
    } else {
      dispatch(getAllDealersGroupsAction({page: 1, count: 20}))
      setChecked(isChecked => !isChecked)
      setDealerIdCache(dealers => [...dealers, dealerId])
      return setRows(row =>
        row.map(el => {
          if (el.value === dealerId) {
            return {...el, isBinded: isAllChecked}
          }
          return el
        }),
      )
    }
  }
  //когда выбираем все или снимаем все/ устанавливаем все чекбоксы
  useEffect(() => {
    if (isAllChecked !== null) {
      setChecked(isAllChecked)
    }
  }, [isAllChecked])

  //когда меняется isBinded меняем его в чекбксе
  useEffect(() => {
    setChecked(isBinded)
  }, [isBinded])
  return (
    <div className={s.checkboxItem} key={idStr} onClick={() => {}}>
      <UICheckbox
        id={idStr}
        value={isChecked}
        style={{marginRight: '8px'}}
        handler={() => {
          togglerDealerToGroup()
        }}
      />
      <label htmlFor={idStr}>{name}</label>
    </div>
  )
}

export default memo(DealerCheckbox)
