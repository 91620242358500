import { OptionData } from 'models/FilterData'

/**
 * Тип данных AnalyticsPageFilter
 */
export type AnalyticsPageFilterCredentials = {
  yearFrom?: OptionData | null,
  yearTo?: OptionData | null,
  quarterFrom?: OptionData | null,
  quarterTo?: OptionData | null,
  wavesFrom?: OptionData | null,
  wavesTo?: OptionData | null,
  templateFormId: OptionData | null,
  kindFormIds?: OptionData | null,
  typeFormIds?: OptionData | null,
  dealerId?: OptionData | null,
  stateCode?: OptionData | null,
  distName?: OptionData | null,
  cityName?: OptionData | null,
  group: OptionData | null,
  typeCalculation?: OptionData | null,
  withoutAppeal?: OptionData | null,
}

export type TAnalyticsPageFilter = {
  yearFrom?: string,
  yearTo?: string,
  quarterFrom?: string,
  quarterTo?: string,
  wavesFrom?: string,
  wavesTo?: string,
  templateFormId: string,
  kindFormIds?: string,
  typeFormIds?: string,
  dealerId?: string,
  stateCode?: string,
  distName?: string,
  cityName?: string,
  group: string,
  typeCalculation?: string,
  withoutAppeal?: boolean,
}

export const defaultAnalyticsFilterValues: AnalyticsPageFilterCredentials = {
  yearFrom: null,
  yearTo: null,
  quarterFrom: null,
  quarterTo: null,
  wavesFrom: null,
  wavesTo: null,
  templateFormId: null,
  kindFormIds: null,
  typeFormIds: null,
  dealerId: null,
  stateCode: null,
  distName: null,
  cityName: null,
  group: { value: 3, name: '' },
  typeCalculation: { value: 2, name: '' },
  withoutAppeal: null,
}
