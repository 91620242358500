import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';

import { UIContainer } from 'components/UI/UIContainer'
import UIField from 'components/UI/UIField';
import { UIButton } from 'components/UI/UIButton/index';

import { IForm } from 'models/InstanceInterfaces/IForm';
import { IIdentifier } from 'models/common';
import { IResponseExtraData } from 'models/common';

import FormsService from 'services/FormsService';
import { useEaseSearchParams } from 'hooks/useEaseSearchParams';
import { getFormsActionHelper } from 'lib/apiHelpers';
import { removeModalItem } from 'lib/setModal';

interface ICommentData {
  name: string
}

const FormCommentModal = () => {

  const { control, handleSubmit } = useForm()
  const [ params, setParams ] = useEaseSearchParams()

  const [ formData, setFormData ] = useState<(IForm & IIdentifier & IResponseExtraData) | undefined>()
  const getParams = async (id: number) => await FormsService.getById(id)

  // функция вызывается при монтировании модалки и добавляет в локальный стейт данные из реквеста
  const setDatatoForm = async () => {
    try {
      const value = await getParams(params.formId) // реквест на получение данных, аргумент url-параметр
      setFormData(value.data) // сохраняем данные в локальный стейт formData
    } catch (err) {
      console.log(err)
    }
  }

  // редактироване коммента
  const fetchFormComment = async (value: ICommentData) => {
    try {
      if (formData) {
        await FormsService.edit({id: formData.id, comment: value.name})
      }
      getFormsActionHelper()
    } catch (e) {
      alert('Непредвиденная ошибка, попробуйте еще раз или обратитесь в поддержку')
    }
  }

  const onSubmit = (data: ICommentData) => {
    fetchFormComment(data)
    removeModalItem(setParams)
   }

   useEffect(() => {
    setDatatoForm()
   }, [])

  return (
    <UIContainer.ContentContainer
      header='комментарий'
    >
      <form
        style={{width:'515px'}}
        onSubmit={handleSubmit(onSubmit as () => {})}
      >
        <UIField.Input
          name='name'
          placeholder='Текст комментария'
          control={control}
        />
        <UIButton.DefaultBtn
          style={{marginTop:'32px'}}
          label='Добавить'
        />
      </form>
    </UIContainer.ContentContainer>
  )
}

export default FormCommentModal
