import {Control} from "react-hook-form";
import {LoggingPageFilterCredentials} from "./index";
import {OptionData} from "models/FilterData";
import {UIContainer} from "components/UI/UIContainer";
import s from "../FormsPageFilter/s.module.css";
import Select from "components/UI/UIField/items/select";
import {UIButton} from "components/UI/UIButton";
import {ReactSVG} from "react-svg";
import clsx from "clsx";
import ResetSVG from "assets/svg/filter/resetIcon.svg";
import React, {memo} from "react";
import UIField from "components/UI/UIField";

/**
 * Интерфейс данных для UsersFilterView
 */
interface LoggingFilterViewProps {
  onSubmit: Function
  handleSubmit: Function
  control: Control<LoggingPageFilterCredentials>
  resetHandle: Function

  actionTypeRowSelect: any[]

  objectTypeRowSelect: any[]

  userRowSelect: OptionData[]
  userPaginateTriger: () => void
  setUserFilter: any
}

/**
 * Представление компонента фильтра для страницы с логированием
 * @param onSubmit - функция для кнопки submit формы
 * @param handleSubmit - обработчик onSubmit
 * @param control - из useForm
 * @constructor
 */
const LoggingPageFilterView = ({
                           onSubmit,
                           handleSubmit,
                           control,
                           resetHandle,

                           actionTypeRowSelect,

                           objectTypeRowSelect,

                           userRowSelect,
                           userPaginateTriger,
                           setUserFilter,
                         }: LoggingFilterViewProps) => {

  /**
   * Данные для фильтра
   */
  const loggingFilterData: any[] = [
    {
      selectHeader: 'Тип запроса',
      options: actionTypeRowSelect,
      selectDOMName: 'actionType',
    },
    {
      selectHeader: 'Тип объекта',
      options: objectTypeRowSelect,
      selectDOMName: 'objectType',
    },
    {
      selectHeader: 'Пользователь',
      options: userRowSelect,
      selectDOMName: 'userId',
      isFilter: true,
      paginateTrigger: userPaginateTriger,
      filterSetter: setUserFilter,
    }
  ];

  //TODO Передать контейнеру правильные размеры и внутренние отступы
  return (
    <UIContainer.ContentContainer header={'Фильтрация'} customStyle={{paddingLeft: 20, paddingRight: 20}}>
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        {loggingFilterData.map(select =>
          <Select
            key={select.selectDOMName}
            selectName={select.selectHeader}
            options={select.options}
            name={select.selectDOMName}
            control={control}
            isFilter={select.isFilter}
            paginateTrigger={select.paginateTrigger}
            filterSetter={select.filterSetter}
          />
        )}
        <UIField.Datepicker
          control={control}
          name={'dateFrom'}
          label='Дата начала'
        />
        <UIField.Datepicker
          control={control}
          name={'dateTo'}
          label='Дата окончания'
        />
        <div className={s.controls}>
          <UIButton.DefaultBtn
            label={"Показать"}
          />
          <div className={s.resetWrapper} onClick={() => resetHandle()}>
            <div className={s.resetHeader}>Сбросить</div>
            <ReactSVG
              data-testid="resetFilter"
              className={clsx(s.resetElement)}
              src={ResetSVG}
            />
          </div>
        </div>
      </form>
    </UIContainer.ContentContainer>
  )
}
export default memo(LoggingPageFilterView);
