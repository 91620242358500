import React from 'react';
import s from './s.module.css'
import NotificationElement from "./NotificationElement";
import {useSelector} from "react-redux";
import {RootState} from "store";
import {selectNotificationStack} from "store/common/reselect";

const NotificationSystem = () => {
  const {notificationStack} = useSelector((state: RootState) => ({
    notificationStack: selectNotificationStack(state)
  }))
  return (
    <div className={s.container}>
      { notificationStack.map(el => <NotificationElement key={el.id} {...el}/>) }
    </div>
  );
};

export default NotificationSystem;
