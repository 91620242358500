import {UIContainer} from "components/UI/UIContainer";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Control, FormState} from "react-hook-form";
import clsx from "clsx";
import s from "./s.module.css";
import UIField from "components/UI/UIField";
import {IDealerUsersModalCredentials} from "./index";
import UICheckbox from "components/UI/UICheckbox";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import UsersService from "services/UsersService";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {IUser} from "models/InstanceInterfaces/IUser";
import {IIdentifier} from "models/common";
import {useInView} from "react-intersection-observer";
import {IDealerExtended} from "models/DealersServiceModel";

/**
 * Интерфейс данных для DealerUsersModalView
 */
interface DealerUsersModalViewProps {
  onSubmit: Function
  handleSubmit: Function
  control: Control<IDealerUsersModalCredentials>
  formState: FormState<IDealerUsersModalCredentials>
  dealerId: number,
  checkBoxClickHandler: Function
  dealer?: IDealerExtended
  loadDealer: boolean
}

const DealerUsersModalView = ({
                                onSubmit,
                                handleSubmit,
                                dealerId,
                                checkBoxClickHandler,
                                dealer,
                                loadDealer,
                                // errorMessage
                              }: DealerUsersModalViewProps) => {
  const [{filterBy}] = useEaseSearchParams()


  const [filterUsers, setFilterUsers] = useState('')
  const [
    rowsUsers,
    paginateTriggerUsers,
    ,
    ,
    loading
  ] = usePaginationItemsFromRequest<IUser & IIdentifier>({
    request: UsersService.getAll,
    filter: filterUsers,
    filterField: "fullName",
    requestFilter: {roleIds: [filterBy]},
    dataConverter: (user) => ({name: user.fullName, value: user.id})
  })

  /**
   * Стили для формы
   */
  const formStyle = {
    width: '592px',
    padding: '44px 48px'
  }
  /**
   * Стили для Input 'Поиск по ФИО'
   */
  const inputStyle = {
    marginTop: '-3px'
  }

  const {ref, entry} = useInView({
    threshold: 0
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      paginateTriggerUsers && paginateTriggerUsers()
    }
  }, [entry])

  return (
    <UIContainer.ContentContainer
      header={'Пользователи дилера'}
      customStyle={formStyle}
      loading={loading}
    >
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={clsx(s['user-select-use-form'])}
        >
          <UIField.InputRaw
            name={'fullName'}
            onChange={({target: {value}}: ChangeEvent<HTMLInputElement>) => setFilterUsers(value)}
            placeholder={'Поиск по ФИО'}
            customStyle={inputStyle}
            extraOnChangeHandler={handleSubmit(onSubmit)}
            value={filterUsers}
          />
        </form>

        <div className={clsx(s['checkbox-list'])}>
          {
            !loadDealer && !!rowsUsers.length && rowsUsers.map(user => (
              <div key={user.value} className={clsx(s['checkbox-list-unit'])}>
                <UICheckbox
                  id={String(user.value)}
                  handler={() => {
                    checkBoxClickHandler(
                      dealerId,
                      !![...dealer?.Managers || [], ...dealer?.Controls || []].find((el) => el.id === user.value),
                      user.value)
                  }}
                  value={!![...dealer?.Managers || [], ...dealer?.Controls || []].find((el) => el.id === user.value)}
                />
                <label
                  htmlFor={String(user.value)}
                  className={clsx(s['checkbox-list-unit__label'])}
                >
                  {user.name}
                </label>
              </div>
            ))}
          <div ref={ref}/>
        </div>
      </div>
    </UIContainer.ContentContainer>
  )
}


export default DealerUsersModalView;
