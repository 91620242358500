import AuthService from "services/AuthService";
import {Credentials} from "modules/AuthBox";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {netErrorsHandler} from "../../lib/netErrorsHandler";
import {IUser} from "../../models/InstanceInterfaces/IUser";

/**
 * Посылает запрос на сервер для авторизации пользователя
 */
export const loginUserAction = createAsyncThunk(
  'profile/login',
  async(data: Credentials, { rejectWithValue }): Promise<any> => {
    try {
      const response = await AuthService.login(data);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(netErrorsHandler(e));
    }
  }
)

/**
 * Посылает запрос на сервер для выхода пользователя из приложения
 */
export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (): Promise<IUser | undefined> => {
    try {
      const res = await AuthService.updateProfileData();
      return res.data
    } catch (e) {}
  }
)

/**
 * Посылает запрос на сервер для выхода пользователя из приложения
 */
export const logoutUserAction = createAsyncThunk(
  'profile/logout',
  async (): Promise<any> => {
    const res = await AuthService.logout();
    return res.data
  }
)
