import React from 'react'

import MainPageInfoBlocks from 'modules/MainPageInfoBlocks'
import { INFOFILES_TYPE } from 'lib/appConst'
import { getAccessToMainPageModules } from 'lib/RolesModel'

import s from './s.module.css'
/**
 * Главная страница приложения
 * @constructor
 */
const Main = () => {

  const isNotController = getAccessToMainPageModules()

  // todo можно переложить логику на сами компоненты, в пропсах кидать кому можно смотреть, а roleId они будут содержать внутри
  return (
    <div className={s.container}>
      {isNotController &&
        <>
          <MainPageInfoBlocks.Filter />
          <MainPageInfoBlocks.Status />
        </>
      }

      {isNotController && (
        <MainPageInfoBlocks.Appeals />
      )}
      {isNotController && (
        <MainPageInfoBlocks.Info type={INFOFILES_TYPE.common} />
      )}
        <MainPageInfoBlocks.Info type={INFOFILES_TYPE.clients} />
      {isNotController && (
        <MainPageInfoBlocks.Info type={INFOFILES_TYPE.portal} />
      )}
    </div>
  );
};

export default Main;
