import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from "hooks/redux";
import { getAllLogsAction } from 'store/logs/thunk';
import TableGenerator from "modules/TableGenerator";
import {loggingTableConfig} from "lib/tableConfigs/configs";
import {LoggingTableControl} from "lib/tableConfigs/controlsPakages/controls";
import {UIContainer} from "components/UI/UIContainer";
import { selectLogs } from 'store/logs/reselect';
import { useEaseSearchParams } from 'hooks/useEaseSearchParams';
import { parseFilterParamsForRequest } from 'lib/parseFilterParams';
import { defaultLoggingPageFilterValues } from 'modules/filters/LoggingPageFilter';
import {ISort} from "../../models/common";


/**
 * Страница для отображения логирования
 * @constructor
 */
const Logging = () => {
  const [params] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState(parseFilterParamsForRequest(params, defaultLoggingPageFilterValues))
  const [sort, setSort] = useState<ISort>()
  const getLogsExecute = useCallback((param: {page: number, count: number}) =>
    dispatch(getAllLogsAction({...param})), [])

  useEffect(() => {
    if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultLoggingPageFilterValues))) {
      setFilter(parseFilterParamsForRequest(params, defaultLoggingPageFilterValues))
    }
    if(JSON.stringify(sort) !== JSON.stringify({sort: params.sort, direction: params.direction})) {
      setSort({sort: params.sort, direction: params.direction})
    }
  }, [params])

  return (
    <UIContainer.PageContainer>
      <TableGenerator
        tableName={'Логирование'}
        tableConfig={loggingTableConfig}
        tableControl={LoggingTableControl}
        dataQuery={getLogsExecute}
        dataSelect={selectLogs}
        filter={filter}
        sort={sort}
      />
    </UIContainer.PageContainer>
  );
};

export default Logging;
