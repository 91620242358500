import {UIContainer} from 'components/UI/UIContainer';
import s from './s.module.css'
import React, {ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import FormsService from "services/FormsService";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import {IGroup} from "models/IGroup";
import UIField from "components/UI/UIField";
import {UIButton} from "components/UI/UIButton";
import {ReactComponent as AddIcon} from "assets/svg/button/cross.svg";
import {ReactComponent as EditIcon} from "assets/svg/TableControlFields/edit.svg";
import {ReactComponent as DeleteIcon} from "assets/svg/templateTool/delete.svg";
import {OptionData} from "models/FilterData";
import {goodRequest} from "lib/apiHelpers";
import {ERROR_MESSAGE, NOTIFICATOR_TYPE} from "lib/appConst";
import {useInView} from "react-intersection-observer";
import {AxiosError} from "axios";
import {addNotification} from "../../../../lib/addNotification";

export type GroupingType = "kind" | "factor" | "formTypes" | "formKinds"

const GroupingModificationModal = () => {
  const [{type, selectedTemplate}] = useEaseSearchParams()

  const [editableElId, setEditableElId] = useState<number | undefined>()

  const [filterProcess, setFilterProcess] = useState('')
  const [
    rowsProcess,
    paginateTriggerProcess,
    doRefetchProcess
  ] = usePaginationItemsFromRequest<IGroup>({
    id: selectedTemplate,
    request: FormsService.getProcessGroup,
    filter: filterProcess,
    disabled: type !== "kind"
  })

  const [filterFactor, setFilterFactor] = useState('')
  const [
    rowsFactor,
    paginateTriggerFactor,
    doRefetchFactor
  ] = usePaginationItemsFromRequest<IGroup>({
    id: selectedTemplate,
    request: FormsService.getFactorGroup,
    filter: filterFactor,
    disabled: type !== "factor"
  })

  const [filterFormTypes, setFilterFormTypes] = useState('')
  const [
    rowsFormTypes,
    paginateTriggerFormTypes,
    doRefetchFormTypes
  ] = usePaginationItemsFromRequest<any>({
    id: selectedTemplate,
    request: FormsService.getAllFormTypes,
    filter: filterFormTypes,
    disabled: type !== "formTypes"
  })

  const [filterFormKinds, setFilterFormKinds] = useState('')
  const [
    rowsFormKinds,
    paginateTriggerFormKinds,
    doRefetchFormKinds
  ] = usePaginationItemsFromRequest<any>({
    id: selectedTemplate,
    request: FormsService.getAllFormKinds,
    filter: filterFormKinds,
    disabled: type !== "formKinds"
  })

  const refetch = () => {
    switch (type as GroupingType) {
      case "kind":
        doRefetchProcess()
        break
      case "factor":
        doRefetchFactor()
        break
      case "formTypes":
        doRefetchFormTypes()
        break
      case "formKinds":
        doRefetchFormKinds()
        break
    }
  }

  const resolveTitle = useCallback((type: GroupingType): string => {
    switch (type) {
      case "factor":
        return 'Группировка по факторам'
      case "kind":
        return 'Группировка по Процессам'
      case "formTypes":
        return 'Типы анкет'
      case "formKinds":
        return 'Виды анкет'
      default:
        return 'Не удалось определить'
    }
  }, [type])

  const resolveHandler = useCallback((type: GroupingType) => {
    switch (type) {
      case "kind":
        return ({target: {value}}: ChangeEvent<HTMLInputElement>) => setFilterProcess(value)
      case "factor":
        return ({target: {value}}: ChangeEvent<HTMLInputElement>) => setFilterFactor(value)
      case "formTypes":
        return ({target: {value}}: ChangeEvent<HTMLInputElement>) => setFilterFormTypes(value)
      case "formKinds":
        return ({target: {value}}: ChangeEvent<HTMLInputElement>) => setFilterFormKinds(value)
      default:
        return () => {
        }
    }
  }, [])

  const resolvePagination = () => {
    switch (type) {
      case "kind":
        return paginateTriggerProcess
      case "factor":
        return paginateTriggerFactor
      case "formTypes":
        return paginateTriggerFormTypes
      case "formKinds":
        return paginateTriggerFormKinds
    }
  }

  const resolveFilterValue = useCallback(() => {
    switch (type) {
      case "kind":
        return filterProcess
      case "factor":
        return filterFactor
      case "formTypes":
        return filterFormTypes
      case "formKinds":
        return filterFormKinds
      default:
        return ''
    }
  }, [type, filterFactor, filterProcess, filterFormTypes, filterFormKinds])

  const resolveList = useMemo(() => {
    switch (type) {
      case "kind":
        return rowsProcess
      case "factor":
        return rowsFactor
      case "formTypes":
        return rowsFormTypes
      case "formKinds":
        return rowsFormKinds
      default:
        return []
    }
  }, [type, rowsProcess, rowsFactor, rowsFormTypes, rowsFormKinds])

  const handleSelectForEdit = (id?: number) => () => setEditableElId(id)

  const handleAddNew = async () => {
    try {
      if(type as GroupingType === 'kind' && filterProcess) {
        const {status} = await createFactor()
        if(goodRequest(status)) refetch()
        else alert(`${ERROR_MESSAGE.defaultServerError}`)
      }
      if(type as GroupingType === 'factor' && filterFactor) {
        const {status} = await createProcess()
        if(goodRequest(status)) refetch()
        else alert(`${ERROR_MESSAGE.defaultServerError}`)
      }
      if(type as GroupingType === 'formTypes' && filterFormTypes) {
        const {status} = await createFormType()
        if(goodRequest(status)) refetch()
        else alert(`${ERROR_MESSAGE.defaultServerError}`)
      }
      if(type as GroupingType === 'formKinds' && filterFormKinds) {
        const {status} = await createFormKinds()
        if(goodRequest(status)) refetch()
        else alert(`${ERROR_MESSAGE.defaultServerError}`)
      }
      setFilterProcess('')
      setFilterFactor('')
      setFilterFormTypes('')
      setFilterFormKinds('')
    } catch (e) {
      alert(`${ERROR_MESSAGE.defaultNetError} ${(e as Error).message}`)
    }
  }

  const createProcess = async () =>
     await FormsService.createFactorGroup({templateFormId: selectedTemplate, name: filterFactor})
  const createFactor = async () =>
     await FormsService.createProcessGroup({templateFormId: selectedTemplate, name: filterProcess})
  const createFormType = async () =>
     await FormsService.createNewFormType({templateFormId: selectedTemplate, name: filterFormTypes})
  const createFormKinds = async () =>
     await FormsService.createNewFormKind({templateFormId: selectedTemplate, name: filterFormKinds})

  const {ref, entry} = useInView({
    threshold: .2
  });

  useEffect(() => {
    if(entry?.isIntersecting) {
      const paginate = resolvePagination()
      paginate && paginate()
    }
  }, [entry])

  return (
    <UIContainer.ContentContainer
      header={resolveTitle(type)}
    >
      <div className={s.wrapper}>
        <UIField.InputRaw
          name={'filter'}
          onChange={resolveHandler(type)}
          value={resolveFilterValue()}
          placeholder={"Введите название"}
        />
        <UIButton.BorderBtn
          label={'Добавить'}
          style={buttonCustomStyle}
          leftIcon={<AddIcon/>}
          handler={handleAddNew}
        />
        <ListView
          list={resolveList}
          selectHandler={handleSelectForEdit}
          editableElId={editableElId}
          refetch={refetch}
        >
          <div ref={ref} style={{height: 10}}/>
        </ListView>
      </div>
    </UIContainer.ContentContainer>
  );
};

export default GroupingModificationModal;

const buttonCustomStyle = {
  borderColor: 'var(--color-border)',
  borderWidth: 1,
  backgroundColor: '#fff',
  color: 'var(--color-mainDark)'
}

interface IListView {
  list: OptionData[]
  selectHandler: (id?: number) => () => void
  editableElId: number | undefined
  refetch: () => void
  children: JSX.Element
}

const ListView = ({list, selectHandler, editableElId, refetch, children}: IListView) => {
  return (
    <div className={s.listWrapper}>
      {list.map((el, id) =>
        <ListItem
          key={`${id}${el.value}`}
          selectHandler={selectHandler}
          isEditable={editableElId === el.value}
          refetch={refetch}
          {...el}
        />)}
      {!!list.length && children}
    </div>
  )
}

interface IListItem {
  selectHandler: (id?: number) => () => void
  isEditable: boolean
  refetch: () => void
}

const ListItem = memo(({value, name, selectHandler, isEditable, refetch}: IListItem & OptionData) => {
  const [{type, selectedTemplate}] = useEaseSearchParams()
  const [newVal, setNewVal] = useState(name)
  const [currentVal, setCurrentVal] = useState(name)
  const handleNewVal = ({target: {value}}: ChangeEvent<HTMLInputElement>) => setNewVal(value)

  const input = useRef<HTMLInputElement>(null)

  const blurHandler = (id: number) => async ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
    if(!value || value === name) {
      selectHandler()()
      setNewVal(name)
      return
    }
    if((type as GroupingType) === "kind") {
      const {data, status} = await FormsService.editProcessGroup({id: id, name: value, templateFormId: selectedTemplate})
      if(status === 200 || status === 201) {
        setCurrentVal(data.name)
        refetch()
      }
    }
    if((type as GroupingType) === "factor") {
      const {data, status} = await FormsService.editFactorGroup({id: id, name: value, templateFormId: Number(selectedTemplate)})
      if(status === 200 || status === 201) {
        setCurrentVal(data.name)
        refetch()
      }
    }
    if((type as GroupingType) === "formTypes") {
      const {data, status} = await FormsService.editFormType({id: id, name: value, templateFormId: Number(selectedTemplate)})
      if(status === 200 || status === 201) {
        setCurrentVal(data.name)
        refetch()
      }
    }
    if((type as GroupingType) === "formKinds") {
      const {data, status} = await FormsService.editFormKind({id: id, name: value, templateFormId: Number(selectedTemplate)})
      if(status === 200 || status === 201) {
        setCurrentVal(data.name)
        refetch()
      }
    }
    selectHandler()()
  }

  const deleteHandler = (id: number) => async () => {
    try {
      if((type as GroupingType) === "kind") {
        const {data} = await FormsService.deleteProcessGroup(id)
        if(data) refetch()
      }
      if((type as GroupingType) === "factor") {
        const {data} = await FormsService.deleteFactorGroup(id)
        if(data) refetch()
      }
      if((type as GroupingType) === "formTypes") {
        const {data} = await FormsService.deleteFormType(id)
        if(data) refetch()
      }
      if((type as GroupingType) === "formKinds") {
        const {data} = await FormsService.deleteFormKind(id)
        if(data) refetch()
      }
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }

  useEffect(() => {
    input.current && input.current.focus()
  }, [isEditable])

  return (
    <div className={s.listItem}>
      {
        isEditable
          ? <UIField.InputRaw
            customInputWrapperClassName={s.test}
            customInputStyle={{padding: 0, height: 30}}
            ref={input}
            name={'field'}
            onChange={handleNewVal}
            onBlur={blurHandler((value as number))}
            value={newVal}
          />
          : <p>{currentVal}</p>
      }
      <div className={s.control}>
        {
          !isEditable && (
            <>
              <UIButton.EmptyBtn
                handler={selectHandler((value as number))}
                leftIcon={<EditIcon/>}
              />
              <UIButton.EmptyBtn
                handler={deleteHandler((value as number))}
                leftIcon={<DeleteIcon/>}
              />
            </>
          )
        }
      </div>
    </div>
  )
})
