import s from "../FormsPageFilter/s.module.css";
import {Control} from "react-hook-form";
import {UIContainer} from "components/UI/UIContainer";
import Select from "components/UI/UIField/items/select";
import {UIButton} from "components/UI/UIButton";
import {ReactSVG} from "react-svg";
import clsx from "clsx";
import ResetSVG from "assets/svg/filter/resetIcon.svg";
import React, {Dispatch, memo, SetStateAction} from "react";
import {OptionData} from "models/FilterData";
import UIField from "components/UI/UIField";
import {DealersPageFilterCredentials} from "./index";

/**
 * Интерфейс данных для DealersFilterView
 */
interface DealersFilterViewProps {
  onSubmit: Function
  handleSubmit: Function
  control: Control<DealersPageFilterCredentials>
  resetHandle: () => void

  userRowsSelect: OptionData[]
  userPaginateTrigger: () => void
  setUserFilter: Dispatch<SetStateAction<string>>

  dealerGroupRowsSelect: OptionData[]
  dealerGroupPaginateTrigger: () => void
  setDealerGroupFilter: Dispatch<SetStateAction<string>>

  statusRowsSelect: OptionData[]
  statusPaginateTrigger: () => void
  setStatusFilter: Dispatch<SetStateAction<string>>
}

/**
 * Представление компонента фильтра для страницы с дилерами
 */
const DealersPageFilterView = ({
                                 onSubmit,
                                 handleSubmit,
                                 control,
                                 resetHandle,

                                 userRowsSelect,
                                 userPaginateTrigger,
                                 setUserFilter,

                                 dealerGroupRowsSelect,
                                 dealerGroupPaginateTrigger,
                                 setDealerGroupFilter,

                                 statusRowsSelect,
                                 statusPaginateTrigger,
                                 setStatusFilter,
                               }: DealersFilterViewProps) => {
  return (
    <UIContainer.ContentContainer header={'Фильтрация'} customStyle={{paddingLeft: 20, paddingRight: 20}}>
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <UIField.Input
          placeholder={"Название"}
          name={"name"}
          control={control} />
        <UIField.Input
          placeholder={"ИНН"}
          name={"INN"}
          control={control} />
        <UIField.Input
          placeholder={"Код филиала"}
          name={"filialCode"}
          control={control} />
        <UIField.Input
          placeholder={"Федеральный округ"}
          name={"stateCode"}
          control={control} />
        <UIField.Input
          placeholder={"Регион"}
          name={"distName"}
          control={control} />
        <UIField.Input
          placeholder={"Город"}
          name={"cityName"}
          control={control} />
        <UIField.Input
          placeholder={"Адрес"}
          name={"address"}
          control={control} />
        <Select
          selectName={"Статус"}
          options={statusRowsSelect}
          paginateTrigger={statusPaginateTrigger}
          filterSetter={setStatusFilter}
          name={"statusIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <Select
          selectName={"Группа дилеров"}
          options={dealerGroupRowsSelect}
          paginateTrigger={dealerGroupPaginateTrigger}
          filterSetter={setDealerGroupFilter}
          name={"groupIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <Select
          selectName={"Пользователи"}
          options={userRowsSelect}
          paginateTrigger={userPaginateTrigger}
          filterSetter={setUserFilter}
          name={"userIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <div className={s.controls}>
          <UIButton.DefaultBtn
            label={"Показать"}
          />
          <div className={s.resetWrapper} onClick={resetHandle}>
            <div className={s.resetHeader}>Сбросить</div>
            <ReactSVG
              data-testid="resetFilter"
              className={clsx(s.resetElement)}
              src={ResetSVG}
            />
          </div>
        </div>
      </form>
    </UIContainer.ContentContainer>
  )
}

export default memo(DealersPageFilterView);
