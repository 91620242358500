import {useForm} from "react-hook-form";
import UsersPageFilterView from "./UsersPageFilterDumb";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {parseFilterParams, resetParams, serialiseParams} from "lib/parseFilterParams";
import {OptionData} from "models/FilterData";
import {useState} from "react";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import {IIdentifier} from "models/common";
import DealersService from "services/DealersService";
import {IDealer} from "models/DealersServiceModel";
import {RolesService} from "services/RolesService";
import {IRole} from "models/InstanceInterfaces/IRole";
import FormsService from "services/FormsService";
import moment from "moment";
import {ITemplateForm} from "models/InstanceInterfaces/ITemplateForm";
import {removeModalToStuck} from "../../../store/common";
import {useAppDispatch} from "../../../hooks/redux";

export type TUsersPageFilterCredentials = {
  id: string
  fullName: string
  login: string
  phone: string
  position: string
  roleIds: OptionData[]
  isExternal?: OptionData | string
  email: string
  dealerIds: OptionData[]
  templateIds: OptionData[]
  beginLastOnlineDate: moment.Moment | string | null
  endLastOnlineDate: moment.Moment | string | null
}

export type TUsersPageFilter = {
  id: ''
  fullName: ''
  login: ''
  phone: ''
  position: ''
  roleIds: []
  isExternal?: OptionData
  email: ''
  dealerIds: []
  templateIds: []
  beginLastOnlineDate: null
  endLastOnlineDate: null
}

export const defaultUsersFilterValues: TUsersPageFilterCredentials = {
  id: '',
  fullName: '',
  login: '',
  phone: '',
  position: '',
  roleIds: [],
  isExternal: '',
  email: '',
  dealerIds: [],
  templateIds: [],
  beginLastOnlineDate: null,
  endLastOnlineDate: null
}

export const UsersPageFilter = () => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useEaseSearchParams()
  const { control, handleSubmit, reset } = useForm<TUsersPageFilterCredentials> ({
    defaultValues: parseFilterParams<TUsersPageFilterCredentials>(params, defaultUsersFilterValues)
  })

  const resetHandle = () => {
    reset(defaultUsersFilterValues)
    setParams((p: any) => ({...p, page: 1, ...resetParams<TUsersPageFilterCredentials>(defaultUsersFilterValues), modalId: undefined}))
    dispatch(removeModalToStuck())
  }

  const onSubmit = (data: TUsersPageFilterCredentials) => {
    setParams((p: any) => ({...p, page: 1, ...serialiseParams(data), modalId: undefined}))
    dispatch(removeModalToStuck())
  }

  const [roleFilter, setRoleFilter] = useState('')
  const [
    roleRowsSelect,
    rolePaginateTrigger,
  ] = usePaginationItemsFromRequest<(IRole & IIdentifier)>({
    request: RolesService.getAll,
    filter: roleFilter,
  })

  const [dealerFilter, setDealerFilter] = useState('')
  const [
    dealerRowsSelect,
    dealerPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IDealer & IIdentifier)>({
    request: DealersService.getAll,
    filter: dealerFilter,
  })

  const [formTypeFilter, setFormTypeFilter] = useState('')
  const [
    formTypeRowsSelect,
    formTypePaginateTrigger,
  ] = usePaginationItemsFromRequest<(ITemplateForm & IIdentifier)>({
    request: FormsService.getAllFormTemplates,
    filter: formTypeFilter,
  })

  return (
    <UsersPageFilterView
      control={control}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      resetHandle={resetHandle}

      roleRowsSelect={roleRowsSelect}
      rolePaginateTrigger={rolePaginateTrigger}
      setRoleFilter={setRoleFilter}

      dealerRowsSelect={dealerRowsSelect}
      dealerPaginateTrigger={dealerPaginateTrigger}
      setDealerFilter={setDealerFilter}

      formTypeRowsSelect={formTypeRowsSelect}
      formTypePaginateTrigger={formTypePaginateTrigger}
      setFormTypeFilter={setFormTypeFilter}
    />
  );
};

export default UsersPageFilter;
