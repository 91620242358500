export const filterValueIsSet = (filterObj: any): boolean => {
  const getLinear = Object.values(filterObj) as any[]
  return getLinear.reduce((acc, filterVal) => {
    if(acc) return acc
    if (Array.isArray(filterVal)) acc = !!filterVal.length
    if (typeof filterVal !== 'object') acc = !!filterVal
    else acc = filterValueIsSet(filterVal)

    return acc
  }, false)
}
