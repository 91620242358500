import InfoBlock from "./InfoBlock";
import PointsBlock from "./PointsBlock";
import QuestionBlock from "./QuestionBlock";

export const FormDetailModules = {
  InfoBlock: InfoBlock,
  PointsBlock: PointsBlock,
  QuestionBlock: QuestionBlock,
}

