import {UIContainer} from 'components/UI/UIContainer'
import UIField from 'components/UI/UIField'
import {UIButton} from 'components/UI/UIButton'
import s from './s.module.css'
import {useForm} from 'react-hook-form'
import DealersService from 'services/DealersService'
import {NOTIFICATOR_TYPE} from 'lib/appConst'
import {addNotification} from 'lib/addNotification'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {useEaseSearchParams} from 'hooks/useEaseSearchParams'
import {removeModalItem} from 'lib/setModal'
import {string} from 'yup'
import {errorMessages} from 'lib/errorMessages'
import {getDealerGroupsActionHelper} from 'lib/apiHelpers'

const NewDealerGroupModal = () => {
  const [, setParams] = useEaseSearchParams()
  const schema = yup.object({
    name: string().required(errorMessages.common.inputRequired),
  })

  const {handleSubmit, control, watch, formState} = useForm({
    defaultValues: {name: ''},
    resolver: yupResolver(schema),
  })

  const {name} = watch()
  const onSubmit = async () => {
    try {
      await DealersService.postCreateDealerGroup(name)
      addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Группа успешно создана'})
      await getDealerGroupsActionHelper()
      removeModalItem(setParams)
    } catch (err: any) {
      addNotification({type: NOTIFICATOR_TYPE.error, text: 'Не удалось создать группу'})
    }
  }

  return (
    <UIContainer.ContentContainer header={'Новая группа'}>
      <form onSubmit={handleSubmit(onSubmit)} className={s.wrapper}>
        <UIField.Input
          customInputStyle={{padding: '26px 0 12px'}}
          name='name'
          control={control}
          placeholder={'Укажите имя группы'}
          errors={formState.errors}
        />
        <UIButton.DefaultBtn label={'Создать группу'} style={{marginTop: '24px'}} />
      </form>
    </UIContainer.ContentContainer>
  )
}

export default NewDealerGroupModal
