import {useSelector} from "react-redux";
import {RootState} from "store";
import {getSelectedGroupId, selectCurrentTemplateInstance, selectToolsState} from "store/formTemplateEditor/reselect";
import {useAppDispatch} from "hooks/redux";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {NOTIFICATOR_TYPE, QUESTION_TYPES, QUESTION_TYPES_NAMES} from 'lib/appConst';
import {addCreatedQuestionToArray, selectQuestion} from "store/formTemplateEditor";
import clsx from "clsx";
import s from "../s.module.css";
import QuestionForm from "../QuestionForm";
import {IIterable, IQuestion} from "models/IQuestion";
import {createQuestion, setNextQuestion} from "store/formTemplateEditor/thunk";
import {addNotification} from 'lib/addNotification';
import {AxiosError} from "axios";

const _getPrevQuestionId = (questions?: (IQuestion & IIterable)[]) => {
  if(!questions) return 0
  if(questions.length) {
    return questions[questions.length - 1].id
  }
  return 0
}

export interface IOnQuestionCreate {
  nextQuestionId: number
  templateId: number
  groupId?: number
}
export const OnQuestionCreate = ({
                           nextQuestionId,
                           groupId,
                           templateId,
                         }: IOnQuestionCreate) => {
  const [loading, setLoading] = useState(false)

  const {
    selectedGroup,
    toolsState: {addQuestion},
    currentTemplate,
  } = useSelector((store: RootState) => ({
    toolsState: selectToolsState(store),
    selectedGroup: getSelectedGroupId(store),
    currentTemplate: selectCurrentTemplateInstance(store),
  }))

  const questionRef = useRef<HTMLDivElement>(null)

  const dispatch = useAppDispatch()

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      const reqData = {
        ...data,
        templateFormId: templateId,
        mainGroupId: selectedGroup || groupId,
        typeQuestion: Object.values(QUESTION_TYPES)[data.typeQuestion.value],
        textQuestion: JSON.stringify(data.textQuestion),
        processGroupId: data?.processGroup && data.processGroup.value,
        factorGroupId: data?.factorGroup && data.factorGroup.value,
        nextQuestionId,
      }
      const res = await dispatch(createQuestion(reqData))
      if(res.payload){
        const target = currentTemplate.instance?.mainGroup.find(el => el.id === selectedGroup)
        if(target && currentTemplate.instance) {
          const id = _getPrevQuestionId(target.questions)
          id && dispatch(setNextQuestion({id, nextQuestionId: res.payload.id}))
        }
        await dispatch(addCreatedQuestionToArray({targetGroup: selectedGroup, item: res.payload}))
        setTimeout(() => dispatch(selectQuestion({id: 0, elementPos: questionRef?.current?.offsetTop})), 0)
        addNotification({ text: "Вопрос добавлен", type: NOTIFICATOR_TYPE.apply })
      } else {
        addNotification({type: NOTIFICATOR_TYPE.error, text: 'Ошибка создания вопроса'})
      }
      setLoading(false)
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
      setLoading(false)
    }
  }

  const questionTypeAsList = useMemo(() => Object.values(QUESTION_TYPES).map((el, id) => ({
    value: id,
    enumKey: el,
    name: QUESTION_TYPES_NAMES[el]
  })), [])

  useEffect(() => {
    if(questionRef.current) {
      dispatch(selectQuestion({id: 0, elementPos: questionRef.current.offsetTop}))
    }
  }, [questionRef?.current?.offsetTop])

  return (
    <>
      <div ref={questionRef} />
      <div
        className={clsx(s.container, {
          [s.selected]: addQuestion
        })}>
        <QuestionForm
          isCreate={true}
          loading={loading}
          onSubmit={onSubmit}
          questionTypeAsList={questionTypeAsList}
          templateId={templateId}
        />
      </div>
    </>
  )
}
