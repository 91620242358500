export class Token {
  /**
   * Имя ключа токена в cookie
   */
  static TOKEN_KEY: string = 'token';

  /**
   * Возвращает значение токена из cookie
   * @returns значение токена из cookie
   */
  //TODO document.cookie не читают из Set-cookie
  // https://stackoverflow.com/questions/54214657/not-able-to-read-set-cookie-response-header#:~:text=Set%2DCookie%20is%20a%20forbidden,part%20of%20the%20response%20body).
  static getToken(): string | undefined {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + this.TOKEN_KEY.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
}
