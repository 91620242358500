import React, {ChangeEvent, CSSProperties, Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {Control, Controller, FieldError, FieldName, RegisterOptions} from 'react-hook-form'
import {ReactSVG} from 'react-svg'
import s from './s.module.css'
import clsx from 'clsx'
import SelectSVG from 'assets/svg/filter/selectIcon.svg'
import {OptionData, OptionMultiselectData} from 'models/FilterData'
import {useInView} from 'react-intersection-observer'
import {useDebounce} from 'hooks/useDebounce'

export type SelectRawPropsType = {
  selectName: string
  customStyle?: CSSProperties | undefined
  customStyleWrapper?: CSSProperties | undefined
  customStyleInput?: CSSProperties | undefined
  options: OptionData[]
  name: string
  error?: any | FieldError
  isFilter?: boolean
  paginateTrigger?: () => void
  onBlur?: () => void
  filterSetter?: Dispatch<SetStateAction<string>>
  createHandler?: (inputValue: string) => void
  multiSelect?: boolean
  valueByName?: boolean
  refetch?: () => void
  withClearOption?: boolean
  disabled?: boolean
}

export interface OptionDataByName {
  value: string
  name: string
}

type _ControllerTypes = {
  onChange: (
    e: ChangeEvent<HTMLInputElement> | OptionData | OptionMultiselectData | OptionDataByName | undefined,
  ) => void
  value: OptionData | OptionMultiselectData | string
}

export type SelectPropsType = SelectRawPropsType & {
  control: Control<any>
  name: FieldName<any>
  errors?: any
  onHandleChange?: (
    e: ChangeEvent<HTMLInputElement> | OptionData | OptionMultiselectData | OptionDataByName | undefined,
  ) => void
  rules?: Exclude<RegisterOptions, 'required'>
}

/**
 * Компонент Select с функцией фильтра
 * @param selectName - название для placeholder
 * @param customStyle - дополнительные стили
 * @param customStyleInput - Стили поля ввода
 * @param customStyleWrapper - Стили обертки поля ввода
 * @param options - данные для вариантов
 * @param control - из useForm
 * @param name - имя input формы для useForm
 * @param isFilter - флаг возможности фильтрации вариантов
 * @param paginateTrigger - Триггер пагинации
 * @param filterSetter - Обработчик поля ввода фильтра
 * @param errors - Объект ошибок формы useForm
 * @param onBlur - Обработчик ухода курсора с поля
 * @param onHandleChange - Дополнительный внешний обработчик (сам его задавай и используй как хочешь)
 * @param createHandler - Обработчик создания элемента селекта
 * @param multiSelect - Включить режим мультивыбора
 * @param valueByName - Заменить id в OptionData на name {value: 0, name: "x"} => {value: "X", name: "x"}
 * @param rules - отдельные правила валидации
 * @param refetch - Пере запрос списка
 * нужно для сущностей у которых нет id при получении с сервера
 * @param withClearOption - включить кнопку очистки поля
 * @param disabled - отключить селект
 * @constructor
 */
const Select = ({
  selectName,
  customStyle,
  customStyleInput,
  customStyleWrapper,
  options,
  control,
  name,
  isFilter,
  paginateTrigger = () => {},
  filterSetter = () => {},
  errors,
  onBlur,
  onHandleChange = () => {},
  createHandler,
  multiSelect,
  valueByName,
  refetch,
  withClearOption,
  disabled,
  rules,
}: SelectPropsType) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {onChange, value}}) => (
        <SelectRaw
          isFilter={isFilter}
          selectName={selectName}
          options={options}
          customStyle={customStyle}
          customStyleInput={customStyleInput}
          customStyleWrapper={customStyleWrapper}
          name={name}
          onChange={value => {
            onChange(value)
            onHandleChange(value)
          }}
          value={value}
          error={errors !== undefined ? errors[name] : undefined}
          paginateTrigger={paginateTrigger}
          filterSetter={filterSetter}
          onBlur={onBlur}
          createHandler={createHandler}
          multiSelect={multiSelect}
          valueByName={valueByName}
          refetch={refetch}
          withClearOption={withClearOption}
          disabled={disabled}
        />
      )}
    />
  )
}

export default Select

/**
 * Начинка для компонента Select
 * @param selectName - название для placeholder
 * @param customStyle - дополнительные стили
 * @param customStyleInput - Стили поля ввода
 * @param customStyleWrapper - Стили обертки поля ввода
 * @param options - данные для вариантов
 * @param name - имя input формы для useForm
 * @param onChange - из useForm
 * @param isFilter - флаг возможности фильтрации вариантов
 * @param paginateTrigger - Триггер пагинации
 * @param filterSetter - Обработчик поля ввода фильтра
 * @param errors - Объект ошибок формы useForm
 * @param onBlur - Обработчик ухода курсора с поля
 * @param createHandler - Обработчик создания элемента селекта
 * @constructor
 */
const SelectRaw = ({
  selectName,
  customStyle,
  customStyleInput,
  customStyleWrapper,
  options,
  name,
  onChange,
  isFilter,
  paginateTrigger,
  filterSetter,
  value,
  error,
  onBlur,
  createHandler,
  multiSelect,
  valueByName,
  refetch,
  withClearOption,
  disabled,
}: SelectRawPropsType & _ControllerTypes) => {
  /**
   * Отображаемое имя в вариантах выбора в фильтре
   */
  const [inputValue, setInputValue] = useState('')
  const [filter, setFilter] = useState('')
  const debouncedInputValue = useDebounce(filter, 200)

  /**
   * Значение выбранного варианта в фильтре
   */

  const [isManualInput, setIsManualInput] = useState(false)
  const [isVisibleOptions, setIsVisibleOptions] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (filterSetter && isManualInput) setFilter(inputValue)
  }, [inputValue])

  useEffect(() => {
    if (filterSetter) filterSetter(debouncedInputValue)
  }, [debouncedInputValue])

  useEffect(() => {
    if (inputRef?.current) inputRef.current.value = inputValue
  }, [inputValue])

  /**
   * Обработчик нажатия на select (показывает список опций выбора)
   */
  const toggleHandler = () => {
    refetch && refetch()
    if (!isVisibleOptions) inputRef.current?.focus()
    !multiSelect ? setIsVisibleOptions(p => !p) : setIsVisibleOptions(true)
  }

  /**
   * Скрывает варианты выбора
   * Очистка инпута если вариант не выбран
   * Если не сделать асинхронным, то при клике на опцию не происходит выбора - onBlur срабатывает прежде optionHandler
   */

  const closeOptions = (manualClose?: boolean) => {
    if (!Array.isArray(value)) {
      isManualInput && value && typeof value !== 'string' && setInputValue(value.name)
    }
    if (manualClose || (!Array.isArray(value) && value !== undefined)) {
      setFilter('')
      setIsVisibleOptions(false)
    }
    setIsManualInput(false)
    if (Array.isArray(value) && !filter) {
      value.length ? setInputValue('Выбрано: ' + value.length) : setInputValue('')
    }
  }

  /**
   * Обработчик нажатия на option
   * @param option - instance выбранного элемента
   */
  const optionHandler = (option: OptionData) => {
    if (!multiSelect) {
      setIsVisibleOptions(false)
      setIsManualInput(false)
      setInputValue(option.name)
      if (valueByName) {
        onChange({value: option.name, name: option.name})
      } else {
        onChange(option)
      }
    } else {
      if (Array.isArray(value)) {
        onChange(
          [...value].filter(el => Number(el.value) === Number(option.value)).length
            ? value.filter(el => Number(el.value) !== Number(option.value))
            : [...value, option],
        )
      } else {
        onChange([option])
      }
    }
  }

  /**
   * Обработчик изменения input
   * @param e - событие на input
   */
  const inputHandle = (e: React.FormEvent<HTMLInputElement>): void => {
    setIsVisibleOptions(true)
    setIsManualInput(true)
    setInputValue(e.currentTarget.value)
  }

  const onCreate = (value: string) => {
    if (!value.length) {
      setIsVisibleOptions(false)
    }
    if (value.length && createHandler) {
      createHandler(inputValue)
      closeOptions()
    }
  }

  const dropValue = () => {
    setInputValue('')
    // setParams((p: any) => ({...p, [name]: undefined}))
  }

  const {ref, entry} = useInView({
    threshold: 0,
  })

  const isSelectOption = (valueId: number | string) => {
    return value && Array.isArray(value)
      ? value?.reduce((acc, el) => (!acc ? Number(el?.value) === Number(valueId) : acc), false)
      : typeof value !== 'string' && Number(value?.value) === Number(valueId)
  }

  useEffect(() => {
    if (value && !isManualInput) {
      if (!Array.isArray(value)) {
        if (typeof value === 'string') {
          setInputValue(
            options.reduce((acc, el) => (!acc ? (Number(el.value) === Number(value) ? el.name : '') : acc), ''),
          )
        } else {
          setInputValue(value?.name)
        }
      } else {
        Array.isArray(value) && value.length ? setInputValue('Выбрано: ' + value.length) : setInputValue('')
        setFilter('')
      }
    } else {
      if (typeof value === 'string' && value.length) {
        setInputValue(value)
      }
    }
  }, [value, options])

  useEffect(() => {
    if (entry?.isIntersecting) {
      paginateTrigger && paginateTrigger()
    }
  }, [entry])
  return (
    <>
      {isVisibleOptions && (
        <div
          style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10}}
          onClick={() => closeOptions(true)}
        />
      )}

      <div
        className={clsx(s.wrapper, {
          [s.zIndex20]: isVisibleOptions,
          [s.disabled]: disabled,
        })}
        style={customStyle}
      >
        <div className={clsx(s.select)} style={customStyleWrapper}>
          <div className={clsx(s.select__header)} onClick={() => !disabled && toggleHandler()}>
            {inputValue && <span className={clsx(s.select__subheader)}>{selectName}</span>}
            <input
              style={customStyleInput}
              type={'text'}
              className={`${clsx(s.select__headerName)} ${
                inputValue ? clsx(s.select__headerName_filled) : clsx(s.select__headerName_empty)
              }`}
              autoComplete={'off'}
              placeholder={selectName}
              name={name}
              value={inputValue}
              onInput={inputHandle}
              disabled={disabled}
              ref={inputRef}
              onFocus={() => {
                if (!disabled) {
                  if (multiSelect) {
                    setInputValue('')
                  }
                }
              }}
              onBlur={() => {
                multiSelect && closeOptions()
                onBlur && onBlur()
              }}
              readOnly={!isFilter}
            />
            <ReactSVG data-testid='selectControl' className={clsx(s.resetElement)} src={SelectSVG} />
          </div>
          {isVisibleOptions && (
            <div className={clsx(s.select__options, name)}>
              {createHandler && (
                <option
                  key={'creation'}
                  className={clsx(s.select__item, {
                    [s.disableOption]: !inputValue.length,
                  })}
                  onClick={() => onCreate(inputValue)}
                >
                  Создать ({inputValue})
                </option>
              )}
              {withClearOption && (
                <option
                  key={'reset'}
                  className={clsx(s.select__item, s.select__clear, {
                    [s.disableOptionClear]: !(
                      (Array.isArray(value) && value.length > 0) ||
                      (!Array.isArray(value) && value)
                    ),
                  })}
                  onClick={() => {
                    if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value)) {
                      onChange(Array.isArray(value) ? [] : undefined)
                      dropValue()
                    }
                  }}
                >
                  Очистить поле
                </option>
              )}
              {!!options.length ? (
                options.map(option => (
                  <option
                    key={option.value}
                    className={clsx(s.select__item, {[s.selected]: isSelectOption(option?.value)})}
                    onClick={() => optionHandler(option)}
                    value={option.value}
                  >
                    {option.name}
                  </option>
                ))
              ) : (
                <div className={clsx(s.noItemPlug)}>Не найдено</div>
              )}
              <div ref={ref} />
            </div>
          )}
        </div>
        {error && <p className={s.error}>
          {error?.message
            || error?.name?.message
            || error?.value?.message}
        </p>}
      </div>
    </>
  )
}
