import {Control} from "react-hook-form";
import React, {Dispatch, memo, SetStateAction} from "react";
import {UIContainer} from "components/UI/UIContainer";
import s from "./s.module.css";
import Select from "components/UI/UIField/items/select";
import {UIButton} from "components/UI/UIButton";
import {ReactSVG} from "react-svg";
import clsx from "clsx";
import ResetSVG from "assets/svg/filter/resetIcon.svg";
import {OptionData} from "models/FilterData";
import UIField from "components/UI/UIField";
import {AppealsPageFilterCredentials} from "./index";
import DealersService from "services/DealersService";


/**
 * Интерфейс данных для FormsFilterView
 */
interface AppealsFilterViewProps {
  onSubmit: Function
  handleSubmit: Function
  control: Control<AppealsPageFilterCredentials>
  resetHandle: () => void

  waveRowsSelect: OptionData[]
  wavePaginateTrigger: () => void
  setWaveFilter: Dispatch<SetStateAction<string>>

  statusRowsSelect: OptionData[]
  statusPaginateTrigger: () => void
  setStatusFilter: Dispatch<SetStateAction<string>>

  typeFormRowsSelect: OptionData[]
  typeFormPaginateTrigger: () => void
  setTypeFormFilter: Dispatch<SetStateAction<string>>

  dealerRowsSelect: OptionData[]
  dealerPaginateTrigger: () => void
  setDealerFilter: Dispatch<SetStateAction<string>>
}

/**
 * Представление компонента фильтра для страницы с анкетами
 * @constructor
 */
const AppealsPageFilterView = ({
                               onSubmit,
                               handleSubmit,
                               control,
                               resetHandle,

                               waveRowsSelect,
                               wavePaginateTrigger,
                               setWaveFilter,

                               statusRowsSelect,
                               statusPaginateTrigger,
                               setStatusFilter,

                               typeFormRowsSelect,
                               typeFormPaginateTrigger,
                               setTypeFormFilter,

                               dealerRowsSelect,
                               dealerPaginateTrigger,
                               setDealerFilter,
                             }: AppealsFilterViewProps) =>
  (
    <UIContainer.ContentContainer header={'Фильтрация'} customStyle={{paddingLeft: 20, paddingRight: 20}}>
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <UIField.Datepicker
          control={control}
          name={"dateFrom"}
          label={"Дата начала"}
        />
        <UIField.Datepicker
          control={control}
          name={"dateTo"}
          label={"Дата завершение"}
        />
        <Select
          selectName={"Волна"}
          options={waveRowsSelect}
          paginateTrigger={wavePaginateTrigger}
          filterSetter={setWaveFilter}
          name={"waveId"}
          control={control}
          withClearOption
          isFilter
        />
        <Select
          selectName={"Статус"}
          options={statusRowsSelect}
          paginateTrigger={statusPaginateTrigger}
          filterSetter={setStatusFilter}
          name={"statusId"}
          control={control}
          withClearOption
          isFilter
        />
        <Select
          selectName={"Тип анкеты"}
          options={typeFormRowsSelect}
          paginateTrigger={typeFormPaginateTrigger}
          filterSetter={setTypeFormFilter}
          name={"typeFormId"}
          control={control}
          withClearOption
          isFilter
        />
        <Select
          selectName={"ДЦ"}
          options={dealerRowsSelect}
          paginateTrigger={dealerPaginateTrigger}
          filterSetter={setDealerFilter}
          name={"dealerId"}
          control={control}
          withClearOption
          isFilter
        />
        <UIField.Input
          placeholder={"Федеральный округ"}
          name={"stateCode"}
          control={control}
          suggestQuery={DealersService.getStates}
          suggestUseAnotherGetterField={'stateCode'}
        />
        <UIField.Input
          placeholder={"Регион"}
          name={"distName"}
          control={control}
          suggestQuery={DealersService.getAllDistricts}
          suggestUseAnotherGetterField={'distName'}
        />
        <UIField.Input
          placeholder={"Город"}
          name={"cityName"}
          control={control}
          suggestQuery={DealersService.getAllCities}
          suggestUseAnotherGetterField={'cityName'}
        />
        <UIField.Input
          placeholder={"Адрес"}
          name={"address"}
          control={control}
        />
        <UIField.Input
          placeholder={"ID Анкеты"}
          name={"formId"}
          control={control}
        />
        <div className={s.controls}>
          <UIButton.DefaultBtn
            label={"Показать"}
          />
          <div className={s.resetWrapper} onClick={resetHandle}>
            <div className={s.resetHeader}>Сбросить</div>
            <ReactSVG
              data-testid="resetFilter"
              className={clsx(s.resetElement)}
              src={ResetSVG}
            />
          </div>
        </div>
      </form>
    </UIContainer.ContentContainer>
  )


export default memo(AppealsPageFilterView);
