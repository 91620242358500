/**
 * Идентификатор роли пользователя
 */
export enum Roles {
  systemAdmin = 1,
  agencyAdmin = 2,
  dealer = 3,
  dealerDirector = 4,
  auditor = 5,
  agencyManager = 6,
  controller = 7,
  regionalManager = 8,
  departmentChief = 9,
  divisionChief = 10,
  processingCenter = 11
}
