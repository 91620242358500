import React, {memo} from 'react';
import {AuthConsumer} from "providers/authProvider";
import Preloader from "components/UI/Preloader/Preloader";

const SignInOIDC = () => (
  <AuthConsumer>
    {
      ({signinRedirectCallback}) => {
        signinRedirectCallback()
        return <Preloader />
      }
    }
  </AuthConsumer>
)

export default memo(SignInOIDC);
