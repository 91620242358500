import { createAsyncThunk } from '@reduxjs/toolkit'
import AnalyticsService from 'services/AnalyticsService';
import { TAnalyticsPageFilter } from 'modules/filters/AnalyticsReportPageFilter/utils';
import { IPaginate } from 'models/common';
import { TAnalyticsUnloadPageFilter } from 'modules/filters/AnalyticUnloadPageFilter/utils';
import { TAnalyticsAppealsPageFilter } from 'modules/filters/AnalyticsAppealsPageFilter/utils';

/**
 * Получить отчеты по аналитике
 */
export const getAllReportsActions = createAsyncThunk(
  'analytics/getAllReports',
  async (params: IPaginate & Partial<TAnalyticsPageFilter>) => {
    try {
      if (params.hasOwnProperty('templateFormId') && params.templateFormId !== undefined) {
        const res = await AnalyticsService.report(params)
        return res.data
      } else {
        return { columns: [], rows: [] }
      }
    } catch (e) {
      console.log('analytics/getAllReports', e);
    }
  }
)

/**
 * Получить отчеты по аналитике по аппеляциям
 */
export const getAllReportAppeals = createAsyncThunk(
  'analytics/getAllReportAppeals',
  async (params: Partial<TAnalyticsAppealsPageFilter>) => {
    try {
      if (params.hasOwnProperty('templateFormId') && params.templateFormId !== undefined) {
        const res = await AnalyticsService.appeals(params)
        return res.data
      } else {
        return { columns: [], rows: [] }
      }
    } catch (e) {
      console.log('analytics/getAllReportAppeals', e);
    }
  }
)

/**
 * Получить график
 */
export const getGraphActions = createAsyncThunk(
  'analytics/getGraph',
  async (params: IPaginate & Partial<TAnalyticsPageFilter>) => {
    try {
      const res = await AnalyticsService.graph(params)
      return res.data
    } catch (e) {
      console.log('analytics/getGraph', e);
    }
  }
)

/**
 * Получить отчеты по выгрузке
 */
export const getAllUnloadsActions = createAsyncThunk(
  'analytics/getAllUnloads',
  async (params: IPaginate & Partial<TAnalyticsUnloadPageFilter>) => {
    try {
      const res = await AnalyticsService.unload(params)
      return res.data
    } catch (e) {
      console.log('analytics/getAllUnloadsActions', e);
    }
  }
)
