import {RootState} from '../index'
import {createSelector} from '@reduxjs/toolkit'
import {DealersSelectOptions} from '../../models/storeInterface/DealersState'

const getDealers = (store: RootState) => store.dealers

export const selectDealers = createSelector(getDealers, dealers => dealers.dataList)
export const selectSelectedDealerRow = createSelector(getDealers, dealers => dealers.dataList.selectedRows)
export const selectSelectedDealerExcludeRow = createSelector(getDealers, dealers => dealers.dataList.excludedRows)
export const selectDealerSelectedAllFlag = createSelector(getDealers, dealers => dealers.dataList.selectAll)

/**
 * Возвращает select`ы для select`ов дилеров
 * @param type - тип select`а
 */
const getDealerSelect = (type: DealersSelectOptions) => {
  return {
    options: createSelector(getDealers, dealers => dealers.dealersSelectData[type].options),
    status: createSelector(getDealers, dealers => dealers.dealersSelectData[type].requestStatus),
    pagination: createSelector(getDealers, dealers => dealers.dealersSelectData[type].pagination),
  }
}

/**
 * Select`ы для select`ов дилеров
 */
export const selectDealerSelect = {
  [DealersSelectOptions.stateOptions]: getDealerSelect(DealersSelectOptions.stateOptions),
  [DealersSelectOptions.distOptions]: getDealerSelect(DealersSelectOptions.distOptions),
  [DealersSelectOptions.cityOptions]: getDealerSelect(DealersSelectOptions.cityOptions),
  [DealersSelectOptions.dealerGroupsOptions]: getDealerSelect(DealersSelectOptions.dealerGroupsOptions),
}

export const selectPutUser = createSelector(getDealers, dealers => dealers.putUser)
export const selectDeleteUser = createSelector(getDealers, dealers => dealers.deleteUser)
