import React from 'react'
import {ModalsEnum} from '../ModalController/modalManager'
import DealerUsersModal from './DealerUsersModal'
import {IModalData} from 'models/storeInterface/CommonState'
import AddUserModal from './Users/AddUser'
import BindUserToFormTypeModal from './Users/BindUserToFormType'
import {CommonModals} from './Common'
import NewFormModal from './NewFormModal'
import NewWaveModal from './NewWaveModal'
import BindDealerModal from './BindDealerModal'
import FormCommentModal from './FormCommentModal'
import DealerGroupModal from './DealerGroupModal'
import {FormsTemplateEditorModals} from './FormsTemplateEditorModals'
import {FormDetailModals} from './FormDetailModals'
import FormAppealQuestion from 'modules/Modals/FormAppealQuestion'
import DealerGroupsModal from './DealerGroupsModal'
import NewDealerGroupModal from './NewDealerGroupModal'
import EditUserModal from "./Users/EditUser";
import BindDealersToUserModal from './Users/BindDealersToUserModal'
import BindUserToTemplateForms from "./Users/BindUserToTemplateForms";
import InfoListModal from './InfoListModal'
import FormAuditorAnswerModal from './FormAuditorAnswerModal'
import SetTemplateToUserByFilter from "./SetTemplateToUserByFilter";
import {resetAppealAnswerCache} from "../../store/appeals";
import {resetCacheEditUser} from "../../store/users";

const Modals = ({modalId}: IModalData) => {
  switch (modalId) {
    /**
     * Common modals
     */
    case ModalsEnum.FilterModal:
      return <CommonModals.Filter />
    /**
     *
     */
    case ModalsEnum.DealerUsersModal:
      return <DealerUsersModal />
    case ModalsEnum.UserAddModal:
      return <AddUserModal />
    case ModalsEnum.UserEditModal:
      return [<EditUserModal />, [resetCacheEditUser]]
    case ModalsEnum.BindUserToTemplateForms:
      return <BindUserToTemplateForms />
    case ModalsEnum.BindDealersToUserModal:
      return <BindDealersToUserModal />
    case ModalsEnum.BindUserToFormTypeModal:
      return <BindUserToFormTypeModal id={123} />
    case ModalsEnum.NewFormModal:
      return <NewFormModal />
    case ModalsEnum.NewWaveModal:
      return <NewWaveModal />
    case ModalsEnum.BindDealerModal:
      return <BindDealerModal />
    case ModalsEnum.GroupingModificationModal:
      return <FormsTemplateEditorModals.GroupingModificationModal />
    case ModalsEnum.FormCommentModal:
      return <FormCommentModal />
    case ModalsEnum.FormDetailModalsRegular:
      return [<FormDetailModals.Regular />, [resetAppealAnswerCache]]
    case ModalsEnum.FormDetailModalsAcepted:
      return [<FormDetailModals.Acepted />, [resetAppealAnswerCache]]
    case ModalsEnum.DealerGroupModal:
      return <DealerGroupModal />
    case ModalsEnum.FormAppealQuestion:
      return <FormAppealQuestion />
    case ModalsEnum.DealerGroupsModal:
      return <DealerGroupsModal />
    case ModalsEnum.NewDealerGroupModal:
      return <NewDealerGroupModal />
    case ModalsEnum.InfoListModal:
      return <InfoListModal />
    case ModalsEnum.FormAuditorAnswerModal:
      return <FormAuditorAnswerModal />
    case ModalsEnum.SetTemplateToUserByFilter:
      return <SetTemplateToUserByFilter />
    default:
      return <></>
  }
}

export default Modals
