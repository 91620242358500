import {Control, FormState} from "react-hook-form";
import {EditUserModalCredentials} from "./index";
import {UIContainer} from "components/UI/UIContainer";
import {UIButton} from "components/UI/UIButton";
import React, {useEffect, useState} from "react";
import UIField from "components/UI/UIField";
import {OptionData} from "models/FilterData";
import UIError from "components/UI/UIError";
import UICheckbox from "components/UI/UICheckbox";
import s from './s.module.css'
import clsx from "clsx";
import {ReactComponent as DeleteIcon} from 'assets/svg/templateTool/delete.svg'
import {ModalsEnum} from "../../../ModalController/modalManager";
import {setModal} from "lib/setModal";
import {LOADING_STATUS, USER_STATUS} from "lib/appConst";
import Preloader from "components/UI/Preloader/Preloader";
import {getAccessToUserBlock, IsAgencyManager} from "../../../../lib/RolesModel";

/**
 * Интерфейс данных для EditUserModalView
 */
interface EditUserModalViewProps {
  userStatusLoading: boolean
  onSubmit: Function
  handleSubmit: Function
  setValue: Function
  getValues: Function
  control: Control<EditUserModalCredentials>
  formState: FormState<EditUserModalCredentials>
  roleOptions: OptionData[]
  status: LOADING_STATUS
  data: any,
  errorMessage: string,
  deleteUser: Function
  restoreUser: Function
}

const EditUserModalView = ({
                             userStatusLoading,
                             onSubmit,
                             handleSubmit,
                             control,
                             setValue,
                             getValues,
                             formState,
                             roleOptions,
                             status,
                             data,
                             errorMessage,
                             deleteUser,
                             restoreUser,
                           }: EditUserModalViewProps) => {
  const [changePass, setChangePass] = useState(getValues('hasChangePass'));
  const [isVisiblePasswordInput, setIsVisiblePasswordInput] = useState(!!data?.password)

  useEffect(() => {
  }, [])

  const firstFormElementStyle = {
    minHeight: '46px',
    marginTop: '5px'
  }
  const formElementStyle = {
    minHeight: '46px',
    marginTop: '10px'
  }
  const formStyle = {
    width: '611px',
    padding: '44px 48px',
  }
  const fullNameInputStyle = {
    textTransform: 'uppercase' as any
  }

  return (
    <UIContainer.ContentContainer
      header={'пользователь'}
      customStyle={formStyle}
      loading={status === LOADING_STATUS.LOADING}
    >
      <div className={s.wrapper}>
        {status !== LOADING_STATUS.LOADING &&
          (
            <form
              style={{width: '100%'}}
              onSubmit={handleSubmit(onSubmit)}
            >
              <UIField.Input
                name={'fullName'}
                control={control}
                placeholder={'ФИО'}
                customStyle={firstFormElementStyle}
                customInputStyle={fullNameInputStyle}
                errors={formState.errors}
              />
              <UIField.Input
                name={"login"}
                control={control}
                placeholder={"Логин"}
                customStyle={formElementStyle}
                errors={formState.errors}
              />
              {isVisiblePasswordInput && <UIField.Input
                name={"password"}
                control={control}
                type={"password"}
                placeholder={"Пароль"}
                customStyle={formElementStyle}
                errors={formState.errors}
                disabled={!changePass}
              />}
              <div className={clsx(s['change-pass'])}>
                <UICheckbox
                  id={'changePass'}
                  handler={() => {
                    const valueToSet = !getValues('hasChangePass');
                    setValue('hasChangePass', valueToSet);
                    setChangePass(valueToSet);
                    !isVisiblePasswordInput ? setIsVisiblePasswordInput(true) : setIsVisiblePasswordInput(false);
                  }}
                  value={changePass}
                />
                <label
                  htmlFor={'changePass'}
                >
                  Изменить пароль пользователю
                </label>
              </div>
              <UIField.Input
                name={'phone'}
                control={control}
                placeholder={'Телефон'}
                customStyle={formElementStyle}
                errors={formState.errors}
              />
              <UIField.Input
                name={'position'}
                control={control}
                placeholder={'Должность'}
                customStyle={formElementStyle}
                errors={formState.errors}
              />
              <UIField.Select
                selectName={'Роль'}
                options={roleOptions}
                name={'roleId'}
                isFilter={false}
                control={control}
                errors={formState.errors}
              />
              <UIField.Input
                name={"email"}
                control={control}
                placeholder={"Email"}
                customStyle={formElementStyle}
                errors={formState.errors}
              />
              {
                !IsAgencyManager() && (
                  <UIField.Input
                    name={"dealers"}
                    control={control}
                    placeholder={"Привязка к ДЦ"}
                    customStyle={formElementStyle}
                    errors={formState.errors}
                    readOnly={true}
                    clickHandler={() => setModal(ModalsEnum.BindDealersToUserModal)}
                  />
                )
              }
              {
                !IsAgencyManager() && (
                  <UIField.Input
                    name={"templateForms"}
                    control={control}
                    placeholder={"Привязка к шаблону анкеты"}
                    customStyle={formElementStyle}
                    errors={formState.errors}
                    readOnly={true}
                    clickHandler={() => setModal(ModalsEnum.BindUserToTemplateForms,)}
                  />
                )
              }
              <div style={{display: 'flex', justifyContent: getAccessToUserBlock() ? 'space-around' : 'flex-end'}}>
                {
                  getAccessToUserBlock() && (
                    data.statusId === USER_STATUS.ACTIVE
                      ? (
                        <UIButton.DefaultBtn
                          type={'button'}
                          handler={() => deleteUser()}
                          label={"Удалить"}
                          style={{marginTop: '24px'}}
                          rightIcon={<DeleteIcon/>}
                          leftIcon={userStatusLoading ? <Preloader width={16} height={16}/> : <></>}
                        />
                      ) : (
                        <UIButton.DefaultBtn
                          type={'button'}
                          handler={() => restoreUser()}
                          label={"Востановить"}
                          style={{marginTop: '24px'}}
                          leftIcon={userStatusLoading ? <Preloader width={16} height={16}/> : <></>}
                        />
                      )
                  )
                }
                <UIButton.DefaultBtn
                  label={"Сохранить"}
                  style={{marginTop: '24px'}}
                />
              </div>
              <UIError errorMessage={errorMessage}/>
            </form>
          )}
      </div>
    </UIContainer.ContentContainer>
  )
}

export default EditUserModalView;
