import {OptionData} from 'models/FilterData'
import {TParamsDealerGrous} from '.'

const getParamsForFetch = (params: TParamsDealerGrous) => {
  let obj = {}

  obj = {
    ...(params.stateCode?.name ? {stateCode: params.stateCode.name} : {}),
    ...(params.distName?.name ? {distName: params.distName.name} : {}),
    ...(params.cityName?.name ? {cityName: params.cityName.name} : {}),
    ...(params.name ? {name: params.name} : {}),
    ...(params.groupIds?.value ? {groupIds: [params.groupIds.value]} : {}),
  }
  return obj
}

export const pathGenerator = (filters: any) => {
  let path = '?'
  const filterEntries = Object.entries(filters)
  filterEntries.forEach(filter => {
    path += `${filter[0]}=${filter[1]}&`
  })
  return path.slice(0, -1)
}

export default getParamsForFetch
