import {useForm} from "react-hook-form";
import AddUserModalView from "./AddUserModalDunb";
import {useAppDispatch} from "hooks/redux";
import {useCallback, useEffect, useState} from "react";
import {addUserAction} from "store/users/thunk";
import * as yup from "yup";
import {string} from "yup";
import {errorMessages} from "lib/errorMessages";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {OptionData} from "models/FilterData";
import {Roles} from "models/Roles";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {removeModalItem} from "lib/setModal";
import {IUser} from "models/InstanceInterfaces/IUser";

/**
 * Тип данных AddUser
 */
export type AddUserModalCredentials = {
  /**
   * ФИО
   */
  fullName:	string,
  login:	string,
  password: string,
  email:	string,
  phone:	string,
  /**
   * Должность
   */
  position:	string,
  /**
   * Идентификатор роли пользователя в системе
   */
  roleId:	OptionData,
  /**
   * Статус пользователя в системе: 0 - неактивный, 1 - активный
   * Необходимое поле, сервер без него не принимает
   */
  statusId: number
}

//TODO Нужна проверка на валидность введённого телефонного номера
const resolverSchema = yup.object({
  fullName:	string().required(errorMessages.common.inputRequired),
  login: string().required(errorMessages.common.inputRequired),
  password: string().required(errorMessages.common.inputRequired),
  email: string().required(errorMessages.common.inputRequired).email(errorMessages.common.incorrectEmail),
  position:	string().required(errorMessages.common.inputRequired)
})

/**
 * HOC модального окна по созданию нового пользователя
 * @constructor
 */
const AddUserModal = () => {
  const { control, handleSubmit, formState, watch, setValue } = useForm<AddUserModalCredentials>({
    defaultValues: {
      fullName:	'',
      login:	'',
      password: '',
      email:	'',
      phone:	'',
      position:	'',
      //TODO Вытащить объект из outUserRolesOptions: OptionData[] из файла src/lib/userRoles.ts когда вклеится
      roleId:	{
        name: 'Тайный покупатель / аудитор',
        value: Roles.auditor
      },
      statusId: 1
    },
    resolver: yupResolver(resolverSchema)
  })

  const watchRoleId = watch('roleId')
  const watchPosition = watch('position')

  const [, setParams] = useEaseSearchParams()
  const [manualInput, setManualInput] = useState(false)

  const dispatch = useAppDispatch();
  const onSubmit = useCallback((data: AddUserModalCredentials) => {
    const candidate: IUser = {
      email: data.email,
      statusId: data.statusId,
      roleId: (data.roleId.value as number),
      login: data.login,
      position: data.position,
      fullName: data.fullName
    };
    if (data.password) candidate.password = data.password;
    if (data.phone) candidate.phone = data.phone;

    dispatch(addUserAction(candidate)).then(({error, payload}: any) => {
      if(!error && payload) {
        removeModalItem(setParams)
      }
    })

  }, []);

  useEffect(() => {
    if(!watchPosition || !manualInput) setValue('position', watchRoleId.name)
  }, [watchRoleId])

  return <AddUserModalView
    setManualInput={setManualInput}
    control={control}
    onSubmit={onSubmit}
    handleSubmit={handleSubmit}
    formState={formState}
  />
}

export default AddUserModal;
