import React, { CSSProperties, ChangeEvent, useMemo } from 'react'
import { Control, Controller, FieldError, FieldErrors, FieldName } from 'react-hook-form'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'

import CheckedIco from 'assets/svg/fields/apply.svg'

import getGeneratedId from 'lib/getGeneratedId'

import s from './s.module.css'


export type CheckboxPropsTypes = CheckboxRawPropsTypes & {
  control: Control<any>
  name: FieldName<any>
  handler?: (event: ChangeEvent<HTMLInputElement>) => void
}

const Checkbox = ({
  name,
  control,
  label,
  errors,
  handler = () => {},
  className,
}: CheckboxPropsTypes) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <CheckboxRaw
          onChange={(event) => {
            onChange(event)
            handler(event)
          }}
          {...{
            value,
            label,
            className,
            errors,
          }}
        />
      )}
    />
  );
};

type CheckboxRawPropsTypes = {
  label?: string
  errors?: FieldErrors | undefined,
  className?: string
  disabled?: boolean
}

type CheckboxControlTypes = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: boolean | undefined
}

export const CheckboxRaw = ({
  value,
  onChange,
  label,
  className,
  disabled = false,
}: CheckboxRawPropsTypes & CheckboxControlTypes) => {

  const id = useMemo(() => String(getGeneratedId()), [])

  return (
    <div className={clsx(s.container, className)}>
      <input
        type="checkbox"
        onChange={onChange}
        checked={value}
        id={id}
        className={s.input}
        disabled={disabled}
      />
      <label htmlFor={id} className={s.checkbox}>
        <ReactSVG data-testid="check" src={CheckedIco} />
      </label>
      <label htmlFor={id} className={s.label}>
        {label}
      </label>
    </div>
  );
}

export default Checkbox;
