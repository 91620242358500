import {createSlice} from "@reduxjs/toolkit";
import {LOADING_STATUS} from "lib/appConst";
import {FormsArchiveState} from "models/storeInterface/FormsArchiveState";
import {getAllFormsArchiveAction} from "./thunk";

const name = 'formsArchive';

const initialState: FormsArchiveState = {
  dataList: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
  },
}

export const formsSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {

  },
  extraReducers: {
    [getAllFormsArchiveAction.pending.type]: (state) => {
      state.dataList.status = LOADING_STATUS.LOADING
      state.dataList.error = initialState.dataList.error
    },
    [getAllFormsArchiveAction.fulfilled.type]: (state, { payload }) => {
      state.dataList = {...state.dataList, ...payload}
      state.dataList.status = LOADING_STATUS.IDLE
    },
    [getAllFormsArchiveAction.rejected.type]: (state, { payload }) => {
      state.dataList.error = payload
      state.dataList.status = LOADING_STATUS.IDLE
    },
  }
})
export const {
} = formsSlice.actions
export default formsSlice.reducer;
