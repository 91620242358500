import React, { useState } from 'react'
import {useNavigate} from 'react-router-dom'

import {UIButton} from 'components/UI/UIButton'
import {ReactComponent as DownloadIcon} from 'assets/svg/button/downloadDark.svg'
import {getFormsActionHelper} from 'lib/apiHelpers'

import s from './s.module.css'
import FormsService from "../../services/FormsService";
import {addNotification} from "../addNotification";
import {NOTIFICATOR_TYPE} from "../appConst";
import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest';
import DealersService from 'services/DealersService'
import Select from 'components/UI/UIField/items/select'
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { ReactComponent as EditIcon } from 'assets/svg/TableControlFields/edit.svg';
import { OptionData } from 'models/FilterData';
import { useAppDispatch } from 'hooks/redux';
import { editPrevDealer } from 'store/dealers'
import {IFile} from "../../models/InstanceInterfaces/IFile";
import {IIdentifier} from "../../models/common";
import {getFileByStream} from "../getFileByStream";

export const ActionsForAdmin = (row: any) => {
  const navigate = useNavigate()

  const styles = {
    backgroundColor: '#fff',
    paddingLeft: '27px',
    paddingRight: '27px',
    marginRight: '10px',
    width: 130,
  }

  const linkIsSet = row.row.formId || row.row.Answer.formId

  const goToForm = () => {
    navigate(`/formDetail/${row.row.formId || row.row.Answer.formId}#formQuestionId${row.row.formQuestionId}`)
  }

  return (
    <div className={s.actions}>
      {
        linkIsSet && (
          <UIButton.BorderBtn
            label="к анкете"
            style={styles}
            handler={goToForm}
          />
        )
      }
    </div>
  )
}

export const ActionsForAuditor = (row: any) => {
  const navigate = useNavigate()

  const agreeStyles = {
    border: '1px solid #E0F2E5',
    backgroundColor: '#fff',
    color: '#4AB568',
    paddingLeft: '27px',
    paddingRight: '27px',
    marginRight: '10px',
  }

  const cancelStyles = {
    border: '1px solid #FCEBEC',
    backgroundColor: '#fff',
    color: '#D73231',
  }

  if (row.val.status.id !== 9 && row.val.status.id !== 10) { // TODO поменять потом на 10 ID
    return null
  }

  const goToForm = () => {
    navigate(`/form-pass/${row.val.id}`)
  }

  const handlerRejectForm = async () => {
    try {
      const res = await FormsService.putSelectedFormStatus(13, {formIds: [row.val.id]})
      if(res.data) {
        getFormsActionHelper()
        addNotification({text: "Анкета отклонена", type: NOTIFICATOR_TYPE.apply})
      }
    } catch (e) {
      addNotification({text: "Сетевая ошибка", type: NOTIFICATOR_TYPE.error})
    }
  }

  return (
    <div className={s.actions}>
      <UIButton.BorderBtn
        label="Пройти"
        style={agreeStyles}
        handler={goToForm}
      />
      <UIButton.BorderBtn
        label="Отклонить"
        style={cancelStyles}
        handler={handlerRejectForm}
      />
    </div>
  )
}

export const DownloadReportFile = (row: {[key: string]: IFile & IIdentifier}) => {
  const getFile = async (id: number, fileName: string) => {
    const [_, downloader] = await getFileByStream(id)
    downloader(fileName)
  }
  return (
    <UIButton.BorderBtn
      label={'Скачать'}
      leftIcon={<DownloadIcon/>}
      style={{
        backgroundColor: 'var(--color-white)',
        border: '1px solid var(--color-border)',
        color: 'var(--color-mainDark)'
      }}
      handler={() => getFile(row.val.id, row.val.fileName)}
    />
  )
}

export const PrevDealerActions = (row: any) => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div className={s.wrapper}>
      {!isOpened && (
        <>
          {row.row?.predecessor?.name}
          <div
            onClick={() => setIsOpened(true)}
            className={clsx(s.iconWrapper)}
          >
            <EditIcon/>
          </div>
        </>
      )}
      {isOpened && (
        <ChangePrevDealer
          closeHandler={() => setIsOpened(false)}
          value={row.val}
          currentDealer={row.row.id}
        />
      )}
    </div>
  )
}

const ChangePrevDealer = ({ closeHandler, value, currentDealer }: { closeHandler: () => void, value: string | null, currentDealer: string }) => {
  const dispatch = useAppDispatch()

  const [filter, setFilter] = useState('')
  const [
    rowsSelect,
    paginateTrigger,
  ] = usePaginationItemsFromRequest<any>({
    request: DealersService.getAllPrevDealer,
    filter: filter,
  })

  const { control } = useForm({
    defaultValues: {
      prevDealer: value
    }
  })

  const onChange = async (option: OptionData) => {
    try {
      await DealersService.putPrevDealer(currentDealer, option.value)
      dispatch(editPrevDealer({ currentDealer, prevDealerData: option }))
      closeHandler()
    } catch (e) {
      console.log('DealersService.putPrevDealer error: ', e);
    }
  }

  return (
    <div className={s.selectWrapper}>
      <Select
        selectName={'Выберите предшественника'}
        options={rowsSelect}
        paginateTrigger={paginateTrigger}
        filterSetter={setFilter}
        name={'prevDealer'}
        control={control}
        onHandleChange={(option) => onChange(option as OptionData)}
      />
    </div>
  )
}
