import React, {memo, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {IQuestionOption} from "models/InstanceInterfaces/IQuestionOption";
import s from './s.module.css'
import plural from "plural-ru";
import {UIButton} from "../../../../UI/UIButton";
import {ReactComponent as CrossIco} from "assets/svg/button/cross.svg";
import {ReactComponent as DeleteIco} from "assets/svg/button/close-x.svg";
import QuestionsByType from "../../index";
import {selectCurrentTemplateInstance} from "store/formTemplateEditor/reselect";
import FormsService from "../../../../../services/FormsService";
import {IIdentifier} from "../../../../../models/common";
import {getFormTemplateByIdNoLoadHelper, goodRequest} from "lib/apiHelpers";

export interface IOptionsListProps {
  formQuestionId: number
  questionOptions?: (IQuestionOption & IIdentifier)[] | []
}

const OptionsList = ({formQuestionId, questionOptions}: IOptionsListProps) => {

  const {instance} = useSelector(selectCurrentTemplateInstance)
  const [showForm, setShowForm] = useState(false)
  const [isEditById, setIsEditById] = useState<number>()

  const showFormHandle = () =>
    setShowForm(p => !p)

  const hideAllFormsHandle = () => {
    instance?.id && getFormTemplateByIdNoLoadHelper()
    setShowForm(false)
    setIsEditById(undefined)
  }

  const isEditByIdHandle = (id: number) => {
    setIsEditById(id)
    setShowForm(false)
  }

  const stopEditIdHandle = () =>
    setIsEditById(undefined)

  useEffect(() => {
    if(!questionOptions?.length) {
      setShowForm(true)
    }
  }, [questionOptions])

  return (
    <div className={s.container}>
      {questionOptions?.map((el, id) => <Option
        key={el.id}
        listPos={id}
        defaultValues={el}
        formQuestionId={formQuestionId}
        isEditByIdHandle={isEditByIdHandle}
        handlerAfterRequest={hideAllFormsHandle}
        isEditById={isEditById}
      />)}
      {showForm && (
        <QuestionsByType.OptionForm formQuestionId={formQuestionId} handlerAfterRequest={hideAllFormsHandle}/>
      )}
      {!!questionOptions?.length && (
        <UIButton.BorderBtn
          label={!showForm ? isEditById !== undefined ? "Отменить редактирование" : 'Добавить вариант' : "Отменить добавление"}
          type={"button"}
          style={{borderColor: "#dedede", borderWidth: 1, backgroundColor: '#fff', color: '#4D5964', marginTop: 16}}
          leftIcon={!showForm && isEditById === undefined && <CrossIco/>}
          handler={isEditById !== undefined ? stopEditIdHandle : showFormHandle}
        />
      )}
    </div>
  );
};

export default OptionsList;

interface IOptionAdditionProps {
  listPos: number
  formQuestionId: number
  isEditByIdHandle: (id: number) => void
  defaultValues: IQuestionOption & IIdentifier
  handlerAfterRequest: () => void
  isEditById?: number
}
export const Option = memo(({
                              formQuestionId,
                              listPos,
                              defaultValues,
                              isEditByIdHandle,
                              isEditById,
                              handlerAfterRequest
                            }: IOptionAdditionProps) => {

  const {score, textOption} = defaultValues
  const scorePlural = plural(score, "балл", "балла", "баллов")
  const deleteHandle = async (e: React.MouseEvent) => {
    e.stopPropagation()
    let res;
    if(defaultValues.id) {
      res = await FormsService.deleteQuestionOption(defaultValues.id)
    }
    if(res && goodRequest(res.status)) {
      handlerAfterRequest()
    }
  }
  return isEditById !== listPos
    ? (
      <div className={s.optionContainer} onClick={() => isEditByIdHandle(listPos)}>
        <p className={s.optionText}>{listPos + 1}. {textOption}</p>
        {score && <p>{score} {scorePlural}</p>}
        <div className={s.icoWrapper} onClick={deleteHandle}>
          <DeleteIco />
        </div>
      </div>
    )
    : (
      <QuestionsByType.OptionForm formQuestionId={formQuestionId} defaultValues={defaultValues} handlerAfterRequest={handlerAfterRequest}/>
    )
})
