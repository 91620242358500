import React, {Dispatch, memo, SetStateAction, useEffect, useState} from 'react'
import UICheckbox from 'components/UI/UICheckbox'
import DealersService from 'services/DealersService'
import s from '../s.module.css'
import {useAppDispatch} from 'hooks/redux'
import {addNotification} from 'lib/addNotification'
import {NOTIFICATOR_TYPE} from 'lib/appConst'
import {updateUserDealerBind} from "store/users/thunk";

type Props = {
  dealerId: number
  isBinded: boolean
  name: string
  userId: number
  setInProgress: React.Dispatch<SetStateAction<string[]>>
  inProgress: boolean
  isAllChecked: boolean | null
  setRows: Dispatch<SetStateAction<any[]>>
  setDealerIdCache: Dispatch<SetStateAction<number[]>>
}

const DealerCheckbox: React.FC<Props> = ({
   dealerId,
   isBinded,
   name,
   userId,
   isAllChecked,
   setRows,
   setInProgress,
   inProgress,
   setDealerIdCache,
 }) => {
  const dispatch = useAppDispatch()
  const [isChecked, setChecked] = useState(isBinded)
  const idStr = String(dealerId)
  const togglerDealerToGroup = async () => {
    if (isAllChecked === null) {
      try {
        setInProgress(p =>[...p, idStr])
        setChecked(isChecked => !isChecked)
        isChecked
          ? DealersService.deleteDealerFromUser(idStr, userId)
            .then(
              async () => {
                await dispatch(updateUserDealerBind(userId))
                setInProgress(p => p.filter((el) => el !== idStr))
              }
            ).
            then(
              () => addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Дилер успешно удален!'})
            )
          : DealersService.putDealerToUser(idStr, userId)
            .then(
              async () => {
                await dispatch(updateUserDealerBind(userId))
                setInProgress(p => p.filter((el) => el !== idStr))
              }
            )
            .then(
              () => addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Дилер успешно добавлен!'})
            )
      } catch (error) {
        addNotification({type: NOTIFICATOR_TYPE.error, text: 'Не удалось устанавить/удалить дилера!'})
      }
    } else {
      setChecked(isChecked => !isChecked)
      setDealerIdCache(dealers => [...dealers, dealerId])
      return setRows(row =>
        row.map(el => {
          if (el.value === dealerId) {
            return {...el, isBinded: isAllChecked}
          }
          return el
        }),
      )
    }
  }
  //когда выбираем все или снимаем все/ устанавливаем все чекбоксы
  useEffect(() => {
    if (isAllChecked !== null) {
      setChecked(isAllChecked)
    }
  }, [isAllChecked])

  //когда меняется isBinded меняем его в чекбксе
  useEffect(() => {
    setChecked(isBinded)
  }, [isBinded])
  return (
    <div className={s.checkboxItem} key={idStr} onClick={() => {}}>
      <UICheckbox
        id={idStr}
        disabled={inProgress}
        value={isChecked}
        style={{marginRight: '8px', opacity: inProgress ? .3 : 1}}
        handler={() => {
          togglerDealerToGroup()
        }}
      />
      <label style={{opacity: inProgress ? .3 : 1}} htmlFor={idStr}>{name}</label>
    </div>
  )
}

export default memo(DealerCheckbox)
