import {useForm} from 'react-hook-form'

import {UIContainer} from 'components/UI/UIContainer'
import {UIButton} from 'components/UI/UIButton'
import Input from 'components/UI/UIField/items/Input'
import Filepicker from 'components/UI/UIField/items/Filepicker'

import {useEaseSearchParams} from 'hooks/useEaseSearchParams'

import {IInfoFileForm} from 'models/IInfoFiles'
import FileService from 'services/FileService'
import {removeModalItem} from 'lib/setModal'

import {ReactComponent as DeleteIco} from 'assets/svg/templateTool/delete.svg'

import s from './s.module.css'
import {setNotification} from "../../../store/common";
import {NOTIFICATOR_TYPE} from "../../../lib/appConst";
import {addNotification} from "../../../lib/addNotification";

const InfoListModal = () => {

  const [{ type, id, fileId, name }, setParams] = useEaseSearchParams()
  const { control, handleSubmit } = useForm<IInfoFileForm>({
    defaultValues: {
      fileId: fileId ? typeof fileId === 'string' ? Number(fileId) : fileId : [],
      name: name ? decodeURIComponent(name).split("+").join(" ") : '',
    }
  })

  const onDelete = async () => {
    await FileService.deleteInfoFile(id)
    removeModalItem(setParams)
  }

  const onSubmit = async (values: IInfoFileForm) => {
    try {
      if (id) {
        await FileService.editInfoFile(id, { ...values, type })
        addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Изменение файла успешно'})
      } else {
        await FileService.setInfoFile({ ...values, type })
        addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Добавление файла успешно'})
      }
      removeModalItem(setParams)
    } catch (e) {
      addNotification({type: NOTIFICATOR_TYPE.error, text: 'Неизвестная ошибка'})
    }
  }

  return (
    <UIContainer.ContentContainer header={id ? 'Редактирование файла' : 'Добавление файла'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name={'name'}
          control={control}
          placeholder={'Название файла'}
        />
        <Filepicker
          className={s.picker}
          control={control}
          name={'fileId'}
          isNormalFont
        />
        <div className={s.footer}>
          <UIButton.DefaultBtn
            label={'Сохранить'}
            type={'submit'}
          />
          {id && (
            <UIButton.BorderBtn
              className={s.delete}
              handler={onDelete}
              leftIcon={<DeleteIco />}
              label={'Удалить файл'}
              type={'button'}
            />
          )}
        </div>
      </form>
    </UIContainer.ContentContainer>
  )
}

export default InfoListModal
