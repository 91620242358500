import React, {memo, useEffect, useState} from 'react';
import s from './s.module.css'
import UIField from "components/UI/UIField";
import {useForm} from "react-hook-form";
import FormsService from "services/FormsService";
import {ITypeForm} from "models/InstanceInterfaces/ITypeForm";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import {IKindForm} from "models/InstanceInterfaces/IKindForm";
import {ITemplateFormInstance, ITemplateFormTransfer} from "models/InstanceInterfaces/ITemplateForm";
import {OptionData} from "models/FilterData";
import {LOADING_STATUS} from "lib/appConst";
import clsx from "clsx";

export interface TemplateFormToAddI {
  onCreate?: ({}: ITemplateFormTransfer) => void
  onEdit?: ({}: ITemplateFormTransfer) => void
  templateHeaderData?: ITemplateFormInstance | undefined
  loading?: LOADING_STATUS
}
const TemplateFormToAdd = ({
                             onCreate,
                             onEdit,
                             templateHeaderData,
                             loading,
                           }: TemplateFormToAddI) => {

  const {control, watch, setValue} = useForm<{name: string, templateType: OptionData | undefined, templateView: OptionData | undefined}>({
    defaultValues: {
      name: templateHeaderData?.name || 'Без названия',
      templateView: templateHeaderData?.kindForm?.id
        ? {name: templateHeaderData?.kindForm?.name, value: templateHeaderData?.kindForm?.id}
        : undefined,
      templateType: templateHeaderData?.typeForm?.id
        ? {name: templateHeaderData?.typeForm?.name, value: templateHeaderData?.typeForm?.id}
        : undefined,
    }
  })

  const [templateTypeFilter, setTemplateTypeFilter] = useState('')
  const [templateViewFilter, setTemplateViewFilter] = useState('')

  const [
    templateTypeRowsSelect,
    templateTypePaginateTrigger
  ] = usePaginationItemsFromRequest<ITypeForm>({
    request: FormsService.getAllFormTypes,
    filter: templateTypeFilter})

  const [
    templateViewRowsSelect,
    templateViewPaginateTrigger
  ] = usePaginationItemsFromRequest<IKindForm>({
    request: FormsService.getAllFormKinds,
    filter: templateViewFilter})

  const nameT = watch('name')
  const typeFormId = watch('templateType')
  const kindFormId = watch('templateView')

  useEffect(() => {
    if(nameT && typeFormId && kindFormId && !templateHeaderData && onCreate) {
      onCreate({name: nameT, typeFormId: (typeFormId.value as number), kindFormId: (kindFormId.value as number)})
    }
  }, [nameT, typeFormId, kindFormId])


  useEffect(() => {
    if(templateHeaderData && nameT && kindFormId?.value && typeFormId?.value) {
      if(
        (templateHeaderData?.kindForm?.id !== kindFormId.value
          || templateHeaderData.typeForm.id !== typeFormId.value)
        && onEdit
      ) {
        onEdit({
          id: templateHeaderData.id,
          name: templateHeaderData.name,
          typeFormId: typeFormId.value,
          kindFormId: kindFormId.value
        })
      }
    }
  }, [nameT, typeFormId, kindFormId, templateHeaderData, loading])

  return (
    <div className={clsx(s.wrapper, {[s.loading]: loading === LOADING_STATUS.LOADING})}>
      <UIField.Input
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          !nameT.length && setValue('name', 'Без названия')
          if(templateHeaderData && onEdit) {
            onEdit({
              ...templateHeaderData,
              name: e.target.value
            })
          }

        }}
        disabled={loading === LOADING_STATUS.LOADING}
        type={'text'}
        name={'name'}
        control={control}
        customInputClassName={s.input}
      />
      <UIField.Select
        customStyleWrapper={{width: 200}}
        filterSetter={setTemplateTypeFilter}
        selectName={'Тип анкеты'}
        options={templateTypeRowsSelect}
        name={'templateType'}
        isFilter={true}
        control={control}
        paginateTrigger={templateTypePaginateTrigger}
      />
      <UIField.Select
        customStyleWrapper={{width: 200}}
        filterSetter={setTemplateViewFilter}
        selectName={'Вид анкеты'}
        options={templateViewRowsSelect}
        name={'templateView'}
        isFilter={true}
        control={control}
        paginateTrigger={templateViewPaginateTrigger}
      />
    </div>
  );
};

export default memo(TemplateFormToAdd, (a, b) => JSON.stringify(a) === JSON.stringify(b));

