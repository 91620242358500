import {useForm} from "react-hook-form";
import {useAppDispatch} from "../../../../hooks/redux";
import {useCallback} from "react";
import BindUserToFormTypeModalView from "./BindUserToFormTypeModalDunb";
import {getFormTypesAction} from "../../../../store/forms/thunk";

export interface BindUserToFormTypeModalProps {
  /**
   * Идентификатор пользователя
   */
  id: number
}

/**
 * Тип данных BindUserToFormTypeModalCredentials
 */
export type BindUserToFormTypeModalCredentials = {
  /**
   * Название типа анкеты
   */
  typeName: string,
  /**
   * Номер запрашиваемой страницы
   */
  page: number
}

/**
 * HOC модального окна по привязке пользователя к типу анкеты
 * @param id - id пользователя
 * @constructor
 */
const BindUserToFormTypeModal = ({id}: BindUserToFormTypeModalProps) => {
  const { control, handleSubmit, formState } = useForm<BindUserToFormTypeModalCredentials>({
    defaultValues: {
      typeName: '',
      page: 1 //Увеличивать на 1 при пагинации, приравнивать к 1 при запросах после изменения фильтра
    }
  })

  const dispatch = useAppDispatch();
  const onSubmit = useCallback((data: BindUserToFormTypeModalCredentials) => {
    const filterData: {
      page: number,
      count: number,
      name: string,
    } = {
      page: data ? data.page : 1,
      count: 10,
      name: data ? data.typeName : '',
    }
    dispatch(getFormTypesAction(filterData));
  }, [])

  /**
   * Запускает установку или удаление типа анкеты у пользователя
   * @param formId - идентификатор типа анкеты
   * @param isBinded - привязан (true) / не привязан (false) типа анкеты у пользователя
   */
  const bindUserHandler = (formId: string, isBinded: boolean) => {
    console.log('Нет API для привязки/отвязки пользователей к типам анкет');
    isBinded ?
      console.log('Отвязать', {formId: formId, userId: +id}):
      console.log('Привязать', {formId: formId, userId: +id});
  }

  return (
    <BindUserToFormTypeModalView
      control={control}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      formState={formState}
      userId={id}
      checkBoxClickHandler={bindUserHandler}
      // errorMessage={errorMessage}
    />
  )
}

export default BindUserToFormTypeModal
