import React from 'react'
import clsx from 'clsx'
import s from './s.module.css'


const AnalitycsFormTableCommon = () => {
  return (
    <table className={s.table}>
      <tbody>
        {/* header */}
        <tr className={s.tableBordBot}>
          <td className={s.m}></td>
          <td></td>
          <td></td>
          <td></td>
          <td className={s.xl}></td>
          <td className={clsx([s.tableCell, s.tableHeadCell, s.tableFullBord, s.xs])}>69</td>
          <td className={clsx([s.tableCell, s.tableHeadCell, s.tableFullBord, s.xs])}>69</td>
          <td className={clsx([s.tableCell, s.tableHeadCell, s.tableFullBord, s.xs])}>100%</td>
          <td></td>
          <td></td>
        </tr>
        {/* title */}
        <tr className={s.tableBordBot}>
          <td className={clsx([s.tableCell, s.tableMainCell, s.tableUnboarder])}>Общая информация</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td className={clsx([s.tableCell, s.tableMainCell, s.tableUnboarder])}>69</td>
          <td className={clsx([s.tableCell, s.tableMainCell, s.tableUnboarder])}>69</td>
          <td className={clsx([s.tableCell, s.tableMainCell, s.tableUnboarder])}>100%</td>
          <td></td>
          <td></td>
        </tr>
        {/* standard */}
        <tr className={s.tableRow}>
          <td className={s.tableCell}></td>
          <td className={s.tableCell}></td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}>001</td>
          <td className={s.tableCell}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae, aliquid.</td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}></td>
          <td className={s.tableCell}></td>
        </tr>
        {/* standard */}
        <tr className={s.tableRow}>
          <td className={s.tableCell}></td>
          <td className={s.tableCell}></td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}>001</td>
          <td className={s.tableCell}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae, aliquid.</td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}></td>
          <td className={s.tableCell}></td>
        </tr>
          {/* title */}
          <tr className={s.tableBordBot}>
          <td className={clsx([s.tableCell, s.tableMainCell, s.tableUnboarder])}>Общая информация</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td className={clsx([s.tableCell, s.tableMainCell, s.tableUnboarder])}>69</td>
          <td className={clsx([s.tableCell, s.tableMainCell, s.tableUnboarder])}>69</td>
          <td className={clsx([s.tableCell, s.tableMainCell, s.tableUnboarder])}>100%</td>
          <td></td>
          <td></td>
        </tr>
        {/* standard */}
        <tr className={s.tableRow}>
          <td className={s.tableCell}></td>
          <td className={s.tableCell}></td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}>001</td>
          <td className={s.tableCell}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae, aliquid.</td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}>-</td>
          <td className={s.tableCell}></td>
          <td className={s.tableCell}></td>
        </tr>
      </tbody>
    </table>
  )
}

export default AnalitycsFormTableCommon