import React from 'react'
import UIField from 'components/UI/UIField';
import { UIContainer } from 'components/UI/UIContainer';
import UIPageTitle from 'components/UI/UIPageTitle';
import s from './s.module.css'
import { useForm } from 'react-hook-form';
import { UIButton } from 'components/UI/UIButton/index';
import {ReactComponent as DownloadIcon} from 'assets/svg/button/download.svg'
import clsx from 'clsx';
import AnalitycsFormTableCommon from 'components/AnalitycsFormTableCommon';
import AnalitycsFormTableGroups from 'components/AnalitycsFormTableGroups';

const AnalitycsForm = () => {
  const { control } = useForm()

  return (
    <UIContainer.PageContainer>
      <>
        <UIPageTitle label={"Анкета 24224"} subtitle={"Авалон"} withBackBtn />
        <div className={s.contentWrapper}>
          {/* первый контейнер */}
          <div className={s.dataContainer}>
            <div className={s.grid}>
              <div className={s.dataCell}>
                <div className={s.title}>ID  анкеты</div>
                <div className={s.text}>24224</div>
              </div>
              <div className={s.dataCell}>
                <div className={s.title}>Волна</div>
                <div className={s.text}>2022_1kv_1V</div>
              </div>
              <div className={s.dataCell}>
                <div className={s.title}>Дата прохождения</div>
                <div className={s.text}>2022-01-24 22:02:33</div>
              </div>
              <div className={s.dataCell}>
                <div className={s.title}>Код</div>
                <div className={s.text}>32432424234</div>
              </div>
              <div className={s.dataCell}>
                <div className={s.title}>Аппеляция</div>
                <UIField.Select
                  customStyleWrapper={{padding:'0px'}}
                  customStyle={{padding:'7px 0 5px'}}
                  control={control}
                  options={[]}
                  selectName="name"
                  name="name"
                  isFilter
                />
              </div>
              <div className={s.dataCell}>
                <div className={s.title}>Анкета</div>
                <div className={s.text}>Сервис 2022</div>
              </div>
              <div className={s.dataCell}>
                <div className={s.title}>Наименование</div>
                <div className={s.text}>Авалон</div>
              </div>
              <div className={clsx([s.dataCell, s.dataCellAddress])}>
                <div className={s.title}>Адрес</div>
                <div className={s.text}>Белгород, ЦФО, Белгород, пр-к Б. Хмельницкого, д.209, (3423) 452456</div>
              </div>
            </div>

          <div>
            <div className={s.buttons}>
              <UIButton.DefaultBtn
                label={'excel'}
                style={{backgroundColor: 'var(--color-green)'}}
                leftIcon={<DownloadIcon />}
              />
              <UIButton.DefaultBtn
                label={'zip'}
                style={{backgroundColor: '#8238E2'}}
                leftIcon={<DownloadIcon />}
              />
              <UIButton.DefaultBtn
                label={'pdf'}
                style={{backgroundColor: '#DB5757'}}
                leftIcon={<DownloadIcon />}
              />
            </div>
          </div>
          </div>
          <div className={s.groupContainer}>
            <AnalitycsFormTableGroups/>
          </div>
        </div>
        <div className={s.commonContainer}>
          <AnalitycsFormTableCommon/>
          <div className={s.commonContainerButtons}>
            <UIButton.DefaultBtn
              label={'Опубликовать'}
              style={{backgroundColor: 'var(--color-orange)'}}
            />
            <UIButton.DefaultBtn
              label={'Отменить'}
              style={{backgroundColor: '#DD4141'}}
            />
            <UIButton.EmptyBtn
              label={'Снять с публикации'}
              style={{border:'1px solid var(--color-border)', borderRadius:'30px'}}
            />
          </div>
        </div>
      </>
    </UIContainer.PageContainer>
  )
}

export default AnalitycsForm
