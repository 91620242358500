import {store} from '../store'
import {getAllFormsAction} from '../store/forms/thunk'
import {getAllDealersAction, getAllDealersGroupsAction} from '../store/dealers/thunk'
import React, {SetStateAction} from 'react'
import {AxiosError, AxiosResponse} from 'axios'
import {IDownloadResponse} from '../models/common'
import {getFormTemplateByIdNoLoad} from '../store/formTemplateEditor/thunk'
import FormsService from "../services/FormsService";
import {addNotification} from "./addNotification";
import {NOTIFICATOR_TYPE} from "./appConst";
import {getAllUsersAction} from "../store/users/thunk";
import { parseFilterParamsForRequest } from 'lib/parseFilterParams';
import { defaultFormsFilterValues, TFormsPageFilter } from 'modules/filters/FormsPageFilter';
import { defaultUsersFilterValues, TUsersPageFilter } from 'modules/filters/UsersPageFilter';
import { API_URL_CLIENT } from "../http";
import {getRole} from "./getRole";
import {Roles} from "../models/Roles";

export const goodRequest = (status: number) => status === 200 || status === 201

export const parseStaticParams = () =>
  window.location.search
    .substring(1)
    .split('&')
    .map(el => ({
      [el.split('=')[0]]: decodeURIComponent(decodeURIComponent(el.split('=')[1]))
        .split('+')
        .join(' '),
    }))
    .reduce((acc, el) => ({...acc, ...el}), {}) as any

export const getFormsActionHelper = () => {
  const params = parseStaticParams()
  const {page, count, sort, direction} = params

  const resolveRoleRestrictions = () =>
    !!getRole()
      ? getRole().id === Roles.dealer ||
      getRole().id === Roles.dealerDirector
      : false

  const defaultFormsFilterValuesWithRestrictions = {
    ...defaultFormsFilterValues,
    ...!resolveRoleRestrictions() && {controlUserIds: []}
  }

  store.dispatch(getAllFormsAction({
    ...parseFilterParamsForRequest(params, defaultFormsFilterValuesWithRestrictions) as Partial<TFormsPageFilter>,
    page: page || 1, count: count || 20, sort, direction
  }))
}

export const getDealersActionHelper = () => {
  const params = parseStaticParams()
  store.dispatch(getAllDealersAction({page: params?.page || 1, count: params?.count || 20}))
}
export const getDealerGroupsActionHelper = () => {
  const params = parseStaticParams()
  store.dispatch(getAllDealersGroupsAction({page: params?.page || 1, count: params?.count || 20}))
}

export  const getUsersActionHelper = () => {
  const params = parseStaticParams()
  const {page, count, sort, direction} = params
  const parsed = {
    page: page || 1,
    count: count || 20,
    sort,
    direction,
    ...parseFilterParamsForRequest(params, defaultUsersFilterValues) as unknown as Partial<TUsersPageFilter> }
  store.dispatch(getAllUsersAction(parsed as any))
}

export const syncHelper = async (
  loader: React.Dispatch<SetStateAction<boolean>>,
  request: () => Promise<AxiosResponse>,
  callApi: () => void
) => {
  try {
    loader(true)
    const res = await request()
    if (res.data !== true) alert('Произошла ошибка')
    else {
      callApi()
      alert('Синхронизация успешна')
    }
    loader(false)
  } catch (e) {
    loader(false)
  }
}

/**
 * Юзать либо response, либо link, юзать всю сигнатуру бессмысленно
 * @param response
 * @param link
 */
export const downloadHelper = (response?: AxiosResponse<IDownloadResponse> | undefined, link?: string) => {
  if (response) {
    window.open(API_URL_CLIENT + response.data.url)
  }
  if (link) {
    window.open(API_URL_CLIENT + link)
  }
}

// const fileExt = ['jpg', 'jpeg', 'png', 'gif']
//
// const resolveFileExtToPath = (ext: string) =>
//   fileExt.includes(ext)
//     ? API_URL_CLIENT
//     : API_URL
//
// const getFileExt = (link: string) => {
//   const arrOfElems = link.split('/')
//   return arrOfElems[arrOfElems.length - 1].split('.')[1].toLowerCase()
// }

export const getLinkToFileHelper = () => {
  return window.location.host === 'localhost:3000' ? API_URL_CLIENT : window.location.origin
}

export const getFormTemplateByIdNoLoadHelper = () => {
  const {selectedTemplate} = parseStaticParams()
  if(selectedTemplate) {
    store.dispatch(getFormTemplateByIdNoLoad(Number(selectedTemplate)))
  }
}

/**
 * Изменения статуса в выбранных анкетах
 */
export const setStatusToForm = async (id: number) => {
  const getSelection = {
    formIds: store.getState().forms.dataList.selectedRows,
  }
  const res = await FormsService.putSelectedFormStatus(id, getSelection)
  getFormsActionHelper()
  if(res.data) {
    addNotification({type: NOTIFICATOR_TYPE.apply})
  } else {
    addNotification({type: NOTIFICATOR_TYPE.error})
  }
}

/**
 * Изменения волн в выбранных анкетах
 */
export const setWaveToForm = async (id: number) => {
  const getSelection = {
    formIds: store.getState().forms.dataList.selectedRows,
  }
  try {
    await FormsService.putSelectedFormWave(id, getSelection)
    getFormsActionHelper()
    addNotification({type: NOTIFICATOR_TYPE.apply, text: "Волна изменена"})
  } catch (e) {
    if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
    else {
      if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
      else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
    }
  }
}

/**
 * Изменения волн в выбранных анкетах
 */
export const setAuditorToForm = async (id: number) => {
  const getSelection = {
    formIds: store.getState().forms.dataList.selectedRows,
  }
  const res = await FormsService.putSelectedFormAuditor(id, getSelection)
  getFormsActionHelper()
  if(res.data) {
    addNotification({type: NOTIFICATOR_TYPE.apply})
  } else {
    addNotification({type: NOTIFICATOR_TYPE.error})
  }
}
