import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/fonts.css';
import './assets/styles/vars.css';
import './assets/styles/common.css';
import {Provider} from "react-redux";
import {store} from "./store";
import MainContent from "./layout/MainContent";
import {BrowserRouter} from "react-router-dom";

/**
 * React.StrictMode выключен что бы обеспечить работоспособность react-beautiful-dnd
 * описание возникающей ошибки https://stackoverflow.com/questions/67780314/react-beautiful-dnd-invariant-failed-cannot-find-droppable-entry-with-id
 */

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <MainContent />
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);
