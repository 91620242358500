import {OptionData} from "../models/FilterData";
import {Roles} from "../models/Roles";

/**
 * Роли, которые могут быть присвоены только внешним пользователям (из приложения)
 */
export const outUserRolesOptions: OptionData[] = [
  {
    "value": Roles.auditor,
    "name": "Тайный покупатель / аудитор",
  },
  {
    "value": Roles.agencyManager,
    "name": "Менеджер агентства"
  },
  {
    "value": Roles.controller,
    "name": "Контролер"
  }
];

/**
 * Роли, которые могут быть присвоены только внутренним пользователям (из приложения)
 */
export const innerUserRolesOptions: OptionData[] = [
  {
    "value": Roles.systemAdmin,
    "name": "Администратор системы",
  },
  {
    "value": Roles.agencyManager,
    "name": "Администратор агентства",
  },
  {
    "value": Roles.dealer,
    "name": "Дилерский центр",
  },
  {
    "value": Roles.dealerDirector,
    "name": "Руководитель дилерского центра",
  },
  {
    "value": Roles.auditor,
    "name": "Тайный покупатель / аудитор",
  },
  {
    "value": Roles.agencyManager,
    "name": "Менеджер агентства"
  },
  {
    "value": Roles.controller,
    "name": "Контролер"
  },
  {
    "value": Roles.regionalManager,
    "name": "Региональный менеджер (со стороны АВТОВАЗ)"
  },
  {
    "value": Roles.departmentChief,
    "name": "Руководитель отдела"
  },
  {
    "value": Roles.divisionChief,
    "name": "Руководитель управления"
  },
  {
    "value": Roles.processingCenter,
    "name": "ЦО"
  }
];
