import React, { ChangeEvent, Fragment, useMemo } from 'react'
import { Control, Controller, FieldErrors, RegisterOptions } from 'react-hook-form'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'

import CheckedIco from 'assets/svg/fields/apply.svg'
import UIError from 'components/UI/UIError'

import getGeneratedId from 'lib/getGeneratedId'

import s from '../Checkbox/s.module.css'


export type CheckboxPropsTypes = RadioRawPropsTypes & {
  control: Control<any>
  name: string
  handler?: (event: ChangeEvent<HTMLInputElement>) => void
  options?: any[],
  rules?: RegisterOptions
}

const Radio = ({
  control,
  errors,
  handler = () => {},
  className,
  options,
  name,
  rules,
}: CheckboxPropsTypes) => {

  return (
    <Fragment>
      {options && options.length > 0 && options.map(({ id, label }) => (
        <Controller
          name={name}
          key={`${id}__${label}`}
          control={control}
          rules={rules}
          render={({ field: { onChange, value } }) => (
            <RadioRaw
              onChange={(event) => {
                onChange(event)
                handler(event)
              }}
              valueForInput={`${id}__${label}`}
              {...{
                value,
                label,
                className,
                errors,
              }}
            />
          )}
        />
      ))}
      {
        errors !== undefined
        && errors[name] !== undefined
        && errors[name]?.message
        && <UIError errorMessage={String(errors[name]?.message)} />
      }
    </Fragment>
  )
};

type RadioRawPropsTypes = {
  label?: string
  errors?: FieldErrors | undefined,
  className?: string
}

type RadioControlTypes = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string | undefined,
  valueForInput: string
}

const RadioRaw = ({
  value,
  onChange,
  label,
  className,
  valueForInput,
}: RadioRawPropsTypes & RadioControlTypes) => {

  const id = useMemo(() => String(getGeneratedId()), [])

  return (
    <div className={clsx(s.container, className)}>
      <input
        type="radio"
        onChange={onChange}
        value={valueForInput}
        checked={valueForInput === value}
        id={id}
        className={s.input}
      />
      <label htmlFor={id} className={s.checkbox}>
        <ReactSVG data-testid="check" src={CheckedIco} />
      </label>
      <label htmlFor={id} className={s.label}>
        {label}
      </label>
    </div>
  );
}

export default Radio;
