import React from 'react';
import s from './s.module.css'

interface UIError {
  errorMessage: string
}

const UIError = ({errorMessage}: UIError) => {
  return (
    <p className={s.error}>{errorMessage}</p>
  );
};

export default UIError;
