import React, {useEffect, useState} from 'react';
import {IDataRequestHook} from "../models/common";
import {saveGetByPath} from "../lib/saveGetByPath";

const defaultCount = 20
export const useDropDownQuery = <T, >(queryExecute: (params: any) => Promise<any>, customKey?: string): IDataRequestHook<T> => {
  const [data, setData] = useState<any[]>([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [filterKey, setFilterKey] = useState('name')

  const getData = async ({
                           page = 1,
                           count = defaultCount,
                           name = ""
                         }): Promise<{ data: any[], count: number, error?: string }> => {
    try {
      const res = await queryExecute({page, count, [filterKey || name]: name})
      return {
        data: saveGetByPath(res, 'data.rows', []),
        count: saveGetByPath(res, 'data.count', [])
      }
    } catch (e: any) {
      return {
        data: [],
        count: 0,
        error: e.message
      }
    }
  }

  const pageChange = () =>
    Math.ceil(count / defaultCount) !== page
    && setPage(p => ++p)

  useEffect(() => {
    setLoading(true)
    getData({name: filter})
      .then(({data, count, error = ''}) => {
        setData(customKey ? data.map((el: {[key: string]: string}) => ({id: el.id, name: el[customKey]})) : data)
        setCount(count)
        setError(error)
        setLoading(false)
      })
      .catch(() => {
        setError("Произошла неизвестная ошибка")
      })
    setPage(1)
  }, [filter])

  useEffect(() => {
    if (page > 1) {
      if (page <= Math.ceil(count / defaultCount)) {
        getData({page, count: defaultCount, name: filter})
          .then(({data, count, error = ''}) => {
            setData(p => [...p, ...customKey ? data.map((el: {[key: string]: string}) => ({id: el.id, name: el[customKey]})) : data])
            setCount(count)
            setError(error)
          })
          .catch(() => {
            setError("Произошла неизвестная ошибка")
          })
      }
    }
  }, [page])

  return {
    data,
    error,
    filter,
    loading,
    setFilter,
    setFilterKey,
    pageChange
  }
};
