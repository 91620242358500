import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest'
import {OptionData} from 'models/FilterData'
import React, {useEffect, memo, useState} from 'react'
import {useInView} from 'react-intersection-observer'
import DealersService from 'services/DealersService'
import {useEaseSearchParams} from 'hooks/useEaseSearchParams'
import DealerCheckbox from './DealerCheckbox'
import ButtomButtons from './ButtomButtons'
import {UIModal} from 'components/UI/UIModal'
import {selectDealersGroups} from 'store/dealersGroups/reselect'
import {useAppSelector} from 'hooks/redux'

type Props = {
  params: {
    stateCode?: OptionData
    distName?: OptionData
    cityName?: OptionData
    name?: string
    groupIds?: OptionData
  }
  resetFilterHandler: () => void
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

const DealersCheckbox: React.FC<Props> = ({params, resetFilterHandler, setLoading}) => {
  const {ref, entry} = useInView()
  const [{id}] = useEaseSearchParams()
  const {rows: DealersGroups} = useAppSelector(selectDealersGroups)
  // DG - DealerGroup
  const currentDG = DealersGroups.find(group => group.id == Number(id)) // todo can be undefined and page can crash!!! никто не предусмотрел, поправить
  const [isAllChecked, setAllCheked] = useState<boolean | null>(null)
  const [isAllChekedMode, setAllCheckedMode] = useState(false)
  const [dealerIdCache, setDealerIdCache] = useState<Array<number>>([])
  const [rowsSelect, paginateTrigger, doRefetch, _, loading] = usePaginationItemsFromRequest<any>({
    request: DealersService.getAll,
    count: 20,
    requestFilter: params,
    dataConverter: el => {
      return {
        name: el.name,
        value: el.id,
        isBinded: isAllChecked !== null ? isAllChecked : currentDG.Dealer.some((i: any) => i.id === el.id),
      }
    },
  })
  const [rows, setRows] = useState(rowsSelect)

  useEffect(() => {
    setLoading && setLoading(loading)
  }, [loading])

  useEffect(() => {
    if (rowsSelect.length >= 10 && entry?.isIntersecting) {
      paginateTrigger()
    }
  }, [entry])

  useEffect(() => {
    setRows(rowsSelect)
  }, [rowsSelect])

  // отслеживает изменение полей фильтра и делает новый запрос
  useEffect(() => {
    doRefetch()
  }, [params.cityName, params.groupIds, params.distName, params.stateCode, params.name])

  // функция для лакальной отрисовки чекбоксов выбрать все отменить все
  const checkAllHandler = (isCheked: boolean) => {
    setDealerIdCache([])
    setAllCheked(isCheked)
    setAllCheckedMode(true)
    setRows(row => row.map(el => ({...el, isBinded: isCheked})))
  }

  // для того что бы при прокрутке сохранять статусы чекбоксов
  useEffect(() => {
    if (isAllChekedMode) {
      setRows(row => {
        return row.map(el => {
          if (dealerIdCache.includes(el.value as number)) {
            return {...el, isBinded: !isAllChecked}
          }
          return {...el, isBinded: isAllChecked}
        })
      })
    }
  }, [isAllChecked, rowsSelect])

  return (
    <>
      <UIModal.ListContainer isEmpty={false}>
        {!!rows.length && (
          rows.map((dealer: any) => {
            const {value, isBinded, name} = dealer
            return (
              <DealerCheckbox
                dealerId={value}
                isBinded={isBinded}
                groupId={id}
                name={name}
                key={value}
                isAllChecked={isAllChecked}
                setRows={setRows}
                setDealerIdCache={setDealerIdCache}
              />
            )
          })
        )}
        <div ref={ref} style={{width: '100%', height: '0px'}}></div>
      </UIModal.ListContainer>
      <ButtomButtons
        params={params}
        checkAllHandler={checkAllHandler}
        dealerIdCache={dealerIdCache}
        isAllChecked={isAllChecked}
        setDealerIdCache={setDealerIdCache}
        resetFilterHandler={resetFilterHandler}
      />
    </>
  )
}

export default memo(DealersCheckbox)
