import { RootState } from '../index'
import { createSelector } from '@reduxjs/toolkit'

const getAnalytics = (store: RootState) => store.analytics

export const selectReports = createSelector(getAnalytics, analytics => analytics.reportList)

export const selectAppealsReport = createSelector(getAnalytics, analytics => analytics.appealList)

export const selectGraph = createSelector(getAnalytics, analytics => analytics.graphData)

export const selectUnloads = createSelector(getAnalytics, analytics => analytics.unloadList)
