import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {addUserAction, editUserAction, getAllUsersAction, getUserAction, updateUserDealerBind} from "./thunk";
import {IUser} from "models/InstanceInterfaces/IUser";
import {IIdentifier} from "models/common";
import {LOADING_STATUS} from "lib/appConst";

const name = 'users';
const initialState = {
  users: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [] as (IUser & IIdentifier)[]
  },
  editUserStatus: LOADING_STATUS.IDLE,
  editUser: {} as (IUser & IIdentifier) | any
}

export const usersSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    changeTemplateFormByUser: (state, { payload }) => {
      state.editUser.TemplateForms.map((i: any) => i.id).includes(payload.id)
        ? state.editUser.TemplateForms = current(state.editUser.TemplateForms).filter((i: any) => i.id !== payload.id )
        : state.editUser.TemplateForms.push(payload)
    },
    setCacheEditUser: (state, {payload}) => {
      state.editUser = payload
    },
    resetCacheEditUser: (state) => {
      state.editUser = initialState.editUser
    },
  },
  extraReducers: {
    [getAllUsersAction.pending.type || addUserAction.pending.type]: (state) => {
      state.users.status = LOADING_STATUS.LOADING
      state.users.error = initialState.users.error;
    },
    [getAllUsersAction.fulfilled.type || addUserAction.fulfilled.type]: (state, { payload }) => {
      state.users = {
        ...payload,
        status: LOADING_STATUS.IDLE,
        error: initialState.users.error,
      };
    },
    [getAllUsersAction.rejected.type]: (state, {payload}: PayloadAction<string>) => {
      state.users.status = LOADING_STATUS.IDLE
      state.users.error = payload;
    },

    [addUserAction.pending.type]: (state) => {
      state.users.status = LOADING_STATUS.LOADING
      state.users.error = initialState.users.error;
    },
    [addUserAction.fulfilled.type]: (state) => {
      state.users.status = LOADING_STATUS.IDLE
    },
    [addUserAction.rejected.type]: (state, {payload}: PayloadAction<string>) => {
      state.users.status = LOADING_STATUS.IDLE
      state.users.error = payload;
    },

    [updateUserDealerBind.fulfilled.type]: (state, {payload}) => {
      state.editUser.Dealers = payload.Dealers
    },

    [getUserAction.pending.type]: (state) => {
      state.editUserStatus = LOADING_STATUS.LOADING
      state.users.error = initialState.users.error;
    },
    [getUserAction.fulfilled.type]: (state, { payload }) => {
      console.log(payload, 'payload')
      state.editUserStatus = LOADING_STATUS.IDLE
      state.editUser = payload
    },
    [getUserAction.rejected.type]: (state, {payload}: PayloadAction<string>) => {
      state.editUserStatus = LOADING_STATUS.IDLE
      state.users.error = payload;
    },

    [editUserAction.pending.type]: (state) => {
      state.users.status = LOADING_STATUS.LOADING
      state.users.error = initialState.users.error;
    },
    [editUserAction.fulfilled.type]: (state) => {
      state.users.status = LOADING_STATUS.IDLE
    },
    [editUserAction.rejected.type]: (state, {payload}: PayloadAction<string>) => {
      state.users.status = LOADING_STATUS.IDLE
      state.users.error = payload;
    }
  }
})

export const {
  changeTemplateFormByUser,
  resetCacheEditUser,
  setCacheEditUser,
} = usersSlice.actions
export default usersSlice.reducer;
