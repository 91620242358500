import Logo from 'assets/svg/common/logo-white.svg'
import { UIButton } from 'components/UI/UIButton'

import { RequestStatus } from 'models/storeInterface/DealersState'

import s from './s.module.css'

type ContainerPropsType = {
  children?: JSX.Element
  title?: string
  hasPassed?: boolean
  hasStarted?: boolean
  continueForm: () => void
  clearForm: () => void
  goBack: () => void
  requestStatus: RequestStatus
}

const Container = ({
  children,
  title = '',
  hasPassed,
  hasStarted,
  continueForm,
  clearForm,
  goBack,
  requestStatus,
}: ContainerPropsType) => {
  const { isError, errorMessage } = requestStatus

  const reload = () => {
    window.location.reload()
  }

  const goToTodos = () => {

  }

  return (
    <div className={s.container}>
      <div className={s.header}>
        <h3 className={s.title}>
          {isError ? '' : hasPassed
            ? 'Спасибо за прохождение опроса'
            : hasStarted
              ? ''
              : title
          }
        </h3>
        <img src={Logo} alt="Лада" />
      </div>
      <div className={s.body}>
        {isError ? (
          <div className={s.contentContainer}>
            <div className={s.titleError}>
              Прозошла ошибка
            </div>
            <p className={s.textError}>"{errorMessage}"</p>
            <div className={s.actions}>
              <UIButton.DefaultBtn
                label="Обновить страницу"
                handler={reload}
              />
              <UIButton.BorderBtn
                label="Перейти к списку анкет"
                handler={goToTodos}
                style={{ backgroundColor: '#fff' }}
              />
            </div>
          </div>
        ) : hasPassed ? (
          <div className={s.contentContainer}>
            <div className={s.text}>
              Ваши ответы успешно отправлены. Мы можем связаться с вами для уточнения деталей опроса
            </div>
            <div className={s.actions}>
              <UIButton.BorderBtn
                label="Вернуться к списку"
                handler={goBack}
                style={{ backgroundColor: '#fff' }}
              />
            </div>
          </div>
        ) : hasStarted ? (
          <div className={s.contentContainer}>
            <div className={s.text}>
              Прохождение опроса начато, вы хотите продолжить?
            </div>
            <div className={s.actions}>
              <UIButton.DefaultBtn
                label="Продолжить"
                handler={continueForm}
              />
              <UIButton.BorderBtn
                label="Начать заново"
                handler={clearForm}
                style={{ backgroundColor: '#fff' }}
              />
            </div>
          </div>
        ) : children}
      </div>
    </div>
  )
}

export default Container
