import React, {useEffect} from 'react';
import s from './s.module.css'
import clsx from "clsx";
import {selectModalStuck} from "store/common/reselect";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store";
import Modals from "../Modals";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import closeSVG from 'assets/svg/button/close-x.svg';
import {ReactSVG} from "react-svg";
import {removeModalItem, setModal} from "lib/setModal";
import {ModalsEnum} from "./modalManager";
import {ActionCreator, AnyAction} from "@reduxjs/toolkit";

const ModalController = () => {
  const body = document.querySelector("body")
  const [params, setParams] = useEaseSearchParams()
  const {modalId} = params
  const ResolvedModal = Modals({modalId})
  const dispatch = useDispatch()

  const doCallbackActions = (modal: any) => {
    if(Array.isArray(modal)) {
      const actions = modal[1] as ActionCreator<AnyAction>[]
      actions.map(action => dispatch(action()))
    }
  }

  const {
    modalStuck
  } = useSelector((state: RootState) => ({
    modalStuck: selectModalStuck(state)
  }))

  useEffect(() => {
    if(body) {
      if(modalStuck.length) body.style.overflow = 'hidden'
      else body.style.overflow = 'auto'
    }
  }, [modalStuck])

  const closeModalHandle = () => {
    doCallbackActions(ResolvedModal)
    removeModalItem(setParams)
  }

  /**
   * Загружаем модалку и параметры в URL, если длинна стака > 1, то очищаем старые параметры из URL
   */
  useEffect(() => {
    if (modalStuck.length) {
      const {modalId, data} = modalStuck[modalStuck.length - 1]
      const old = modalStuck[modalStuck.length - 2]
      if (old) {
        const resetOldData = old.data && Object.keys(old.data).reduce((acc, el) => ({...acc, [el]: ''}), {})
        if(resetOldData) {
          setParams((p: any) => ({...p, modalId, ...resetOldData, ...data}))
        }
      } else {
        setParams((p: any) => ({...p, modalId, ...data}))
      }
    } else {
      if(modalId) {
        setModal(modalId)
      } else {
        setParams((p: any) => {
          return ({...p, modalId: ""})
        })
      }
    }
  }, [modalStuck])

  return (
    <div
      onMouseDown={closeModalHandle}
      className={clsx(s.modalContainer, {
        [s.show]: modalId,
        [s.showFilter]: modalId === ModalsEnum.FilterModal
      })}>
      {modalId && (
        <div
          onMouseDown={(e) => e.stopPropagation()}
          className={clsx(s.modalView, {
            [s.filterView]: modalId === ModalsEnum.FilterModal
          })}>
          <>
            {
              Array.isArray(ResolvedModal)
                ? ResolvedModal[0]
                : ResolvedModal
            }
            <div className={clsx(s.modalClose, {[s.modalCloseFilterAnimation]: modalId === ModalsEnum.FilterModal})}>
              <ReactSVG
                onClick={closeModalHandle}
                data-testid="closeModal"
                className={clsx(s.modalCloseBtn)}
                src={closeSVG}/>
            </div></>
        </div>
      )}
    </div>
  );
};

export default ModalController;
