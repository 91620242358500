import React, {FC} from 'react';
import {NavLink} from "react-router-dom";
import s from './s.module.css'
import clsx from "clsx";

export interface INavLink {
  label: string
  path: string
  element?: FC
  inset?: boolean
  defaultParams?: string
}
interface INavigation {
  links: INavLink[] | []
}
const Navigation = ({links}: INavigation) => {
  return (
    <div className={s.wrapper}>
      <ul className={s.list}>
        { links.map((el, id) => !el.inset && (
          <li key={id}>
            <NavLink
              className={({isActive}) =>
                clsx(s.navLink, {[s.focused]: isActive})}
              to={`${el.path}${el.defaultParams ? el.defaultParams : ''}`}
            >
              {el.label}
            </NavLink>
          </li>
        )) }
      </ul>
    </div>
  );
};

export default Navigation;
