import { useState } from "react";
import { useForm } from "react-hook-form";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import { useEaseSearchParams } from "hooks/useEaseSearchParams";
import LogService from "services/LogService";
import UsersService from "services/UsersService";
import LoggingPageFilterView from "./LoggingPageFilterDunb";
import {parseFilterParams, resetParams, serialiseParams} from "lib/parseFilterParams";
import { OptionData } from "models/FilterData";
import { EVENTS } from './events'
import { removeModalToStuck } from 'store/common';
import { useAppDispatch } from 'hooks/redux';

/**
 * Тип данных LoggingPageFilter
 */
export type LoggingPageFilterCredentials = {
  actionType: string,
  objectType: string,
  userId: string,
  dateFrom: string,
  dateTo: string,
}

export const defaultLoggingPageFilterValues : LoggingPageFilterCredentials = {
  actionType: '',
  objectType: '',
  userId: '',
  dateFrom: '',
  dateTo: '',
}

interface IActionsType {
  NAME: string
  MESSAGE: string
}

// Данные для селекторов: тип запроса и тип объекта - берутся из файла с константами
const getActionTypeList = () => {
  const actionsType: IActionsType[] = Object
    .entries(EVENTS)
    .map(i => Object.entries(i[1])
    .map(i => i[1]))
    .flat(Infinity)
    // @ts-ignore
    .map(i => i.NAME)
    .filter(i => i !== '')

  const uniqueActionTypeList = new Set(actionsType)
  return Array.from(uniqueActionTypeList).map((item) => ({ name: item, value: item }))
}
const actionsTypeList = getActionTypeList()
const objectTypeList = Object.keys(EVENTS).map((item) => ({ name: item, value: item }))

export const LoggingPageFilter = () => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useEaseSearchParams()
  const {control, handleSubmit, reset} = useForm<LoggingPageFilterCredentials>({
    defaultValues: parseFilterParams<LoggingPageFilterCredentials>(params, defaultLoggingPageFilterValues)
  })

  const onSubmit = (data: any) => {
    if (data.actionType.name && data.objectType.name) {
      const modifiedData = {...data, actionType: {value: data.actionType.name}, objectType: {value: data.objectType.name}}
      setParams((p: any) => ({...p, page: 1, ...serialiseParams(modifiedData), modalId: undefined }))
    }
    if (data.actionType.name) {
      const modifiedData = {...data, actionType: {value: data.actionType.name}}
      setParams((p: any) => ({...p, page: 1, ...serialiseParams(modifiedData), modalId: undefined }))
    }
    if (data.objectType.name) {
      const modifiedData = {...data, objectType: {value: data.objectType.name}}
      setParams((p: any) => ({...p, page: 1, ...serialiseParams(modifiedData), modalId: undefined }))
    }
    setParams((p: any) => ({...p, page: 1, ...serialiseParams(data), modalId: undefined }))
    dispatch(removeModalToStuck())
  }

  const resetHandle = () => {
    reset(defaultLoggingPageFilterValues)
    setParams((p: any) => ({...p, page: 1, ...resetParams<LoggingPageFilterCredentials>(defaultLoggingPageFilterValues), modalId: undefined }))
    dispatch(removeModalToStuck())
  }

  const [ userFilter, setUserFilter ] = useState()
  const [ userRowSelect, userPaginateTriger ] = usePaginationItemsFromRequest<any>({
    request: UsersService.getAll,
    filter: userFilter,
    filterField: 'fullName',
    dataConverter: (element): OptionData => ({name: element.fullName, value: element.id})
  })


  return (
    <LoggingPageFilterView
      control={control}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      resetHandle={resetHandle}

      actionTypeRowSelect={actionsTypeList}

      objectTypeRowSelect={objectTypeList}

      userRowSelect={userRowSelect}
      userPaginateTriger={userPaginateTriger}
      setUserFilter={setUserFilter}
    />
  );
}
