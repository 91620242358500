import {UIContainer} from "../../../../components/UI/UIContainer";
import {selectorsSettings} from "../../DealerGroupsModal";
import {TParamsDealerGrous} from "../../DealerGroupsModal";
import SearchInput from "../../DealerGroupsModal/SearchInput";
import {useForm} from "react-hook-form";
import Selector from "../../DealerGroupsModal/Selector";
import React, {useState} from "react";
import s from '../../DealerGroupsModal/s.module.css'
import getParamsForFetch from "../../DealerGroupsModal/utils";
import DealersCheckbox from "./DealersCheckbox/DealersCheckbox";

const BindDealersToUserModal = () => {

  const [loading, setLoading] = useState(false)

  const {control, watch, reset} = useForm<TParamsDealerGrous>({
    defaultValues: {
      name: '',
      stateCode: {name: '', value: 0},
      distName: {name: '', value: 0},
      cityName: {name: '', value: 0},
      groupIds: {name: '', value: 0},
    },
  })

  const data = getParamsForFetch(watch())

  const resetFilterHandler = () => {
    reset()
  }

  return (
    <UIContainer.ContentContainer header={'привязать дилера'} loading={loading}>
    <form className={s.container} style={{width: '806px'}}>
      <SearchInput control={control} />
      <div className={s.selectList}>
        {selectorsSettings.map(select => (
          <Selector
            name={select.name}
            isArr={select.isArr}
            control={control}
            fetchMethod={select.fetchMethod}
            placeholder={select.placeholder}
            key={select.name}
          />
        ))}
      </div>
      <DealersCheckbox setLoading={setLoading} params={data} resetFilterHandler={resetFilterHandler} />
    </form>
  </UIContainer.ContentContainer>
  )}


export default BindDealersToUserModal

