import React, {useCallback, useEffect, useState} from 'react';
import TableGenerator from "modules/TableGenerator";
import {useAppDispatch} from "hooks/redux";
import {getAllDealersAction} from "store/dealers/thunk";
import {
  selectDealers
} from "store/dealers/reselect";
import {dealersTableConfig} from "lib/tableConfigs/configs";
import {DealersTableControl} from "lib/tableConfigs/controlsPakages/controls";
import {parseFilterParamsForRequest} from "lib/parseFilterParams";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {defaultDealersFilterValues, IDealerPageFilter} from "modules/filters/DealersPageFilter";
import {ISort} from "models/common";

/**
 * Страница для отображения дилеров
 * @constructor
 */
const Dealers = () => {
  const [params] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState<IDealerPageFilter>(parseFilterParamsForRequest(params, defaultDealersFilterValues) as unknown as IDealerPageFilter)
  const [sort, setSort] = useState<ISort>()

  const getDealersQueryExecute = useCallback((param: {page: number, count: number} & IDealerPageFilter) =>
    dispatch(getAllDealersAction({...param})), [])

  useEffect(() => {
    if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultDealersFilterValues) as unknown as IDealerPageFilter)) {
      setFilter(parseFilterParamsForRequest(params, defaultDealersFilterValues) as unknown as IDealerPageFilter)
    }
    if(JSON.stringify(sort) !== JSON.stringify({sort: params.sort, direction: params.direction})) {
      setSort({sort: params.sort, direction: params.direction})
    }
  }, [params])

  return (
    <TableGenerator
        tableName={'Дилеры'}
        tableConfig={dealersTableConfig}
        tableControl={DealersTableControl}
        dataQuery={getDealersQueryExecute}
        dataSelect={selectDealers}
        filter={filter}
        sort={sort}
      />
  );
};

export default Dealers;
