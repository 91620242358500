import React, {ChangeEvent, CSSProperties} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import s from './s.module.css'
import {Control, Controller, FieldError, FieldName, RegisterOptions} from "react-hook-form";

export interface IUITextAreaProps extends IUITextAreaRawProps{
  control: Control<any>
  name: FieldName<any>
  rules?: RegisterOptions
}

export type ControllerTypes = {
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  value: string
}

const UITextArea = ({name, control, placeholder, rules, customStyle, customFieldWrapperStyle, maxLength, error}: IUITextAreaProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={
        ({field: {onChange, value}}) =>
          <UITextAreaRaw
            error={error}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            customStyle={customStyle}
            customFieldWrapperStyle={customFieldWrapperStyle}
            maxLength={maxLength}
          />
      }
    />
  );
};

export default UITextArea;

export interface IUITextAreaRawProps {
  error?: FieldError
  placeholder?: string
  customStyle?: CSSProperties | undefined
  customFieldWrapperStyle?: any
  maxLength?: number
}
export const UITextAreaRaw = ({value, onChange, placeholder, customStyle, customFieldWrapperStyle, maxLength, error}: ControllerTypes & IUITextAreaRawProps) => {
  return (
    <div className={s.container} style={customStyle}>
      <div style={customFieldWrapperStyle}>
        <TextareaAutosize
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={s.textarea}
          maxLength={maxLength}
        />
      </div>
      {error?.message && <p className={"fieldError"}>{error.message}</p>}
    </div>
  )
}
