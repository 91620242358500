import React from 'react';
import ProfilePlugView from "./ProfilePlugView";
import {useSelector} from "react-redux";
import {selectUserData} from "store/profile/reselect";
import {RootState} from "store";

const ProfilePlug = () => {
  const {
    profileData: {fullName, Role: {name}}
  } = useSelector((store: RootState) => ({
    profileData: selectUserData(store)
  }))
  return (
    <ProfilePlugView fullName={fullName} position={name}/>
  );
};

export default ProfilePlug;
