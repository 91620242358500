import s from './styles.module.css'

const NotFoundContent = () => {

  return (
    <div className={s.container}>
      Страница не найдена
    </div>
  )
}

export default NotFoundContent
