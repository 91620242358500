import { RootState } from '../index'
import { createSelector } from '@reduxjs/toolkit'

const getForm = (store: RootState) => store.form

export const selectFormDetail = createSelector(getForm, form => form.detail);
export const selectQuestion = createSelector(getForm, form => form.question);
export const selectHasStarted = createSelector(getForm, form => form.hasStarted);
export const selectFormRequestStatus = createSelector(getForm, form => form.requestStatus);
export const selectPreview = createSelector(getForm, form => form.preview)
export const selectQuestionsPreview = createSelector(getForm, form => {
  if (form.preview) {
    return form.preview.mainGroup.map((el: any) => {
      return el.questions.map((question: any) => ({ ...question, mainGroup: { name: el.name } }))
    }).flat(1)
  }
  return []
})
