export function deleteUrlParam(key: string): void {
  /* eslint-disable no-restricted-globals */
  if (history) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);

    let newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newurl }, "", newurl);
  }
}
