import { OptionData } from 'models/FilterData'

import { TIMEPICKER_TYPES } from './'

/*
* Функция для генерации options в таймпикере, от 0 (часов/минут) до указанного кол-ва
* @param type - это часы/минуты
* @param count - до какого айтема сделать генерацию (до 23 для часов) (до 59 для минут), возможны иные значения
* @param disabledOptions - какие элементы списка отключить для выбора пользователем (НЕ РЕАЛИЗОВАНО ПОКА, возможно иное решение)
* */
const getTimeOptions = (type: TIMEPICKER_TYPES = TIMEPICKER_TYPES.hours, count: number, disabledOptions?: number[]) => {
  const options: OptionData[] = []

  for (let i = 0; i < count; i++) {
    options.push({ value: i, name: i === 0 && type === TIMEPICKER_TYPES.hours ? 'Полночь' : `${('0' + i).slice(-2)}` })
  }

  return options
}

export { getTimeOptions }
