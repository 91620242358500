import React, {CSSProperties} from 'react'
import './Preloader.css'

export interface IPreloader {
  style?: CSSProperties
  width?: number
  height?: number
  className?: string
}
const Preloader = ({style, width = 100, height = 100, className}: IPreloader) => {
    return (
        <div className={`preloader, ${className}`} style={{...style, width, height}}>
            <div className="preloader__container" style={{width, height}}>
                <span className="preloader__round" style={{width, height}}></span>
            </div>
        </div>
    )
};

export default Preloader
