import {UserManager, Log, UserManagerSettings, WebStorageStateStore, User} from "oidc-client";
import {store} from "../../store";
import {updateProfile} from "../../store/profile/thunk";

const AUTH_URL = "https://idp.vaz.ru"
const META_URL = AUTH_URL + "/oauth2/token/.well-known/openid-configuration"
const CLIENT_ID = "B9VweQMaFl1SwjP2bOdTY9rdnmYa"

const origin = "https://ms-test2.vaz.ru"
const originSignIn = origin + "/signin-oidc"
const originSignOut = origin + "/signout-oidc"
const originUpdateToken = origin + "/renew"

const testVazConf: UserManagerSettings = {
    //server config
    authority: AUTH_URL,
    client_id: CLIENT_ID,
    metadataUrl: META_URL,

    //redirect config
    redirect_uri: originSignIn,
    post_logout_redirect_uri: originSignOut,
    silent_redirect_uri: originUpdateToken,

    //query config
    response_type: 'code',
    automaticSilentRenew: false,
    loadUserInfo: true,
    scope: "openid",
    accessTokenExpiringNotificationTime: 500, //3500 тест, 500 прод
    response_mode: 'query',
    monitorSession: false,
}

const config = testVazConf.client_id;

export default class AuthServiceOIDC {
    UserManager;

    constructor() {
        this.UserManager = new UserManager({
            ...testVazConf,
            userStore: new WebStorageStateStore({store: window.sessionStorage}),
            // metadata: { ...METADATA_OIDC }
        });
        // Logger
        Log.logger = console;
        //Log.level = Log.DEBUG;
        this.UserManager.events.addUserLoaded((user) => {
            console.log(">>>UserLoaded", user);
            this.loadUserProfileAndNav(user);
        });
        // this.UserManager.events.addSilentRenewError((e) => {
        //     // console.log(">>>SilentRenewError", e.message);
        // });

        this.UserManager.events.addAccessTokenExpired(() => {
            // console.log(">>>AccessTokenExpired");
            //this.logout();
        });
    }

    signinRedirectCallback = () => {
        // console.log('***signinRedirectCallback');
        this.UserManager.signinRedirectCallback().then(() => {
            window.history.replaceState({},
              window.document.title,
              window.location.origin + window.location.pathname
            );
        }).catch((err) => {
            console.log(err);
        });
    };

    getUser = async () => {
        //  console.log('***getUser');
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        return user;
    };

    getUserData = () => {
        return this.UserManager.getUser();
    };

    parseJwt = (token: string) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };

    signinRedirect = () => {
        // console.log('***signinRedirect');
        this.UserManager.signinRedirect({state: config});
    };

    login = () => { //WW added
        // console.log('***login');
        return this.UserManager.signinRedirect({state: config});
    }

    loadUserProfileAndNav = (user: User) => {
        //  console.log('***loadUserProfileAndNav');
        document.cookie = `token=${user.access_token};`
        store.dispatch(updateProfile())
    };

    isAuthenticated = () => {
        const str = sessionStorage.getItem(`oidc.user:${testVazConf.authority}:${testVazConf.client_id}`)
        const oidcStorage = JSON.parse(str as string)

        return (!!oidcStorage && !!oidcStorage.access_token)
    };

    signinSilentCallback = () => {
        // console.log('***signinSilentCallback');
        this.UserManager.signinSilentCallback().then(() => {
            "";
            //this.loadUserProfileAndNav();
        });
    };

    logout = () => {
        this.UserManager.clearStaleState()
        this.UserManager.removeUser().then(() => sessionStorage.clear())
        return this.UserManager.signoutRedirect()
    }

    signoutRedirectCallback = () => {
        // console.log('***signoutRedirectCallback');
        this.UserManager.clearStaleState()
        this.UserManager.removeUser()
        return this.UserManager.signoutRedirectCallback()
    };
}
