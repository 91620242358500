import { OptionData } from 'models/FilterData'

/**
 * Тип данных для фильтра на главной странице
 */
export type MainPageFilterCredentials = {
  years?: OptionData[],
  quarters?: OptionData[],
  waveIds?: OptionData[],
  templateFormIds?: OptionData[],
}

export type TMainPageFilter = {
  years?: string[],
  quarters?: string[],
  waveIds?: string[],
  templateFormIds?: string[],
}

export const defaultMainPageFilter: MainPageFilterCredentials = {
  years: [],
  quarters: [],
  waveIds: [],
  templateFormIds: [],
}
