import React, {useEffect, useState} from 'react';
import {UIContainer} from "../UI/UIContainer";
import UIField from "components/UI/UIField";
import {ReactComponent as SearchIcon} from 'assets/svg/fields/search.svg'

import s from './s.module.css'
import clsx from "clsx";
import moment from "moment";
import {ITemplateForm} from "models/InstanceInterfaces/ITemplateForm";
import {IResponseExtraData} from "models/common";
import {useAppDispatch} from "hooks/redux";
import {resetSelectedGroup, resetSelectedQuestion, toolsAddQuestionHandle} from "store/formTemplateEditor";
import {getAllFormTemplates} from "store/formTemplateEditor/thunk";
import {useSelector} from "react-redux";
import {selectTemplates} from "store/formTemplateEditor/reselect";
import {RootState} from "store";
import {useDebounce} from "hooks/useDebounce";
import Pagination from "modules/TableGenerator/TableParts/Pagination";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {parseStaticParams} from "lib/apiHelpers";
import UIContextMenu, {IContextMenuOption} from "components/UI/UIContextMenu";
import FormsService from "services/FormsService";
import {addNotification} from "lib/addNotification";
import {LOADING_STATUS, NOTIFICATOR_TYPE} from "lib/appConst";
import Preloader from "components/UI/Preloader/Preloader";
import {AxiosError} from "axios";

export interface TemplateSelectorI {
  selected: number | null | undefined
  setter: any
}

const TemplateSelector = ({selected, setter}: TemplateSelectorI) => {
  const [, setParams] = useEaseSearchParams()
  const dispatch = useAppDispatch()

  const [{count, page}, setParam] = useEaseSearchParams()
  const {name} = parseStaticParams()

  const [search, setSearch] = useState(name || '')
  const [editFlag, setEditFlag] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadedItem, setLoadedItem] = useState<number | undefined>()

  const debouncedSearch = useDebounce<string>(search, 300)

  const {formTemplates} = useSelector((store: RootState) => ({
    formTemplates: selectTemplates(store),
  }))

  useEffect(() => {
    if(page && count) {
      dispatch(getAllFormTemplates())
    }
  }, [page, count, name])

  useEffect(() => {
    if(debouncedSearch) setParam((p: any) => ({...p, page: editFlag ? 1 : page, name: debouncedSearch}))
    else {
      setParam((p: any) => ({...p, page: editFlag ? 1 : page, name: ''}))
    }
  }, [debouncedSearch])

  useEffect(() => {
    if(Number(count) !== 10) {
      setParam(() => ({page: 1, count: 10}))
    }
  }, [page, count])

  const searchInputHandler = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(value)
    setEditFlag(true)
  }

  const selectHandler = (id: number) => {
    setter(id)
    dispatch(toolsAddQuestionHandle(false))
    dispatch(resetSelectedQuestion())
    dispatch(resetSelectedGroup())
  }

  const copyTemplateHandler = (id: number, name: string) => async () => {
    if(id && name) {
      setLoading(true)
      setLoadedItem(id)
      try {
        const res = await FormsService.copyFormTemplate(id, name + " (Copy)")
        if(res.status === 200) {
          setParams((p: any) => ({...p, selectedTemplate: id, page: 1}))
          dispatch(getAllFormTemplates())
          addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Копирование успешно!'})
        }
      } catch (e) {
        if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
        else {
          if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
          else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
        }
      }
      setLoading(false)
      setLoadedItem(undefined)
    }
  }

  const deleteTemplateHandler = (id: number) => async () => {
    if(id) {
      setLoading(true)
      setLoadedItem(id)
      setParams((p: any) => ({...p, selectedTemplate: undefined}))
      try {
        const res = await FormsService.deleteFormTemplate(id)
        if(res.status === 200) dispatch(getAllFormTemplates())
        addNotification({type: NOTIFICATOR_TYPE.apply, text: `Шаблон №${id} удален!`})
      } catch (e) {
        if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
        else {
          if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
          else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
        }
      }
      setLoading(false)
      setLoadedItem(undefined)
    }
  }

  return (
    <UIContainer.ContentContainer customStyle={{padding: 0, height: '100%'}}>
      <div className={s.container}>
        <div className={s.subContainer}>
          <div className={clsx(s.wrapper, s.border)}>
            <UIField.InputRaw
              type={'text'}
              value={search}
              name={'search'}
              rightIcon={<SearchIcon/>}
              customStyle={{width: 286}}
              onChange={searchInputHandler}
              placeholder={"Поиск по названию"}
            />
          </div>
          <ul className={s.templateList}>
            {formTemplates.status === LOADING_STATUS.LOADING && (
              <div style={{
                minHeight: 510,
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Preloader />
              </div>
            )}
            {
              !!formTemplates.rows.length &&
              formTemplates.status === LOADING_STATUS.IDLE &&
              formTemplates.rows.map((el: ITemplateForm & IResponseExtraData, id) =>
              <Element
                key={id}
                {...el}
                loading={loading}
                copyTemplateHandler={copyTemplateHandler}
                deleteTemplateHandler={deleteTemplateHandler}
                handler={selectHandler}
                currentSelect={selected}
                loadedItem={loadedItem}
              />)
            }
          </ul>
        </div>
        <div className={s.paginationWrapper}>
          {!!formTemplates.count &&
            <Pagination
              total={formTemplates.count}
              customClassName={s.pagination}
              disabledTableActivity={loading}
              emptyBtn />
          }
        </div>
      </div>
    </UIContainer.ContentContainer>
  );
};

interface TemplateForSelectElementI extends ITemplateForm {
  handler: any
  currentSelect: number | null | undefined
  copyTemplateHandler: (id: number, name: string) => () => void
  deleteTemplateHandler: (id: number) => () => void
  id?: number
  loading?: boolean
  loadedItem?: number
}

const Element = ({
                   name,
                   updatedAt,
                   id,
                   handler,
                   currentSelect,
                   copyTemplateHandler,
                   deleteTemplateHandler,
                   loading,
                   loadedItem
}: TemplateForSelectElementI & IResponseExtraData) => {
  const formattedDate = moment(updatedAt).format('DD.MM.YY')

  const contextOptions = [
    {
      name: "Копировать",
      handler: id
        ? copyTemplateHandler(id, name)
        : () => addNotification({type: NOTIFICATOR_TYPE.error, text: 'Шаблон еще не сохранен'})
    },
    {
      name: 'Удалить',
      handler: id
        ? deleteTemplateHandler(id)
        : () => addNotification({type: NOTIFICATOR_TYPE.error, text: 'Шаблон не готов к удалению'})
    }
  ] as IContextMenuOption[]

  return (
    <li className={clsx(s.templateElement, {
        [s.templateElementSelected]: Number(currentSelect) === id,
        })}
        onClick={() => currentSelect !== id && handler(id)}>
      <span className={s.name}>{name}</span>
      {id === loadedItem && <Preloader height={18} width={18} className={s.preloader}/>}
      <span className={clsx(s.date, {[s.marginLeft]: !(loading && loadedItem === id)})}>{formattedDate}</span>
      <UIContextMenu options={contextOptions} disabled={loading}/>
    </li>
  )
}

export default TemplateSelector;
