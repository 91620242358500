import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'

import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest'
import { useEaseSearchParams } from 'hooks/useEaseSearchParams'
import { useAppDispatch } from 'hooks/redux'

import ResetSVG from 'assets/svg/filter/resetIcon.svg'
import Select from 'components/UI/UIField/items/select'
import { UIContainer } from 'components/UI/UIContainer'
import { UIButton } from 'components/UI/UIButton'

import {
  appealOptions,
  calculationOptions, calculationValues,
  groupOptions,
  groupValues,
  quarterOptions,
  yearsOptions,
} from 'lib/appOptions'
import { parseFilterParams, resetParams, serialiseParams } from 'lib/parseFilterParams'
import { errorMessages } from 'lib/errorMessages'

import FormsService from 'services/FormsService'
import DealersService from 'services/DealersService'

import { IWave } from 'models/InstanceInterfaces/IWave'
import { IKindForm } from 'models/InstanceInterfaces/IKindForm'
import { IDealer } from 'models/DealersServiceModel'
import { IIdentifier, IResponseExtraData } from 'models/common'
import { ITypeForm } from 'models/InstanceInterfaces/ITypeForm'
import { OptionData } from 'models/FilterData'
import { IDistrict } from 'models/InstanceInterfaces/IDistrict'
import { ICity } from 'models/InstanceInterfaces/ICity'

import { removeModalToStuck } from 'store/common'

import {
  AnalyticsPageFilterCredentials,
  defaultAnalyticsFilterValues,
} from './utils'

import s from 'modules/filters/FormsPageFilter/s.module.css'


const required = {
  value: true,
  message: errorMessages.common.inputRequired,
};

const rules = {
  required,
}

const AnalyticsGraphPageFilter = () => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useEaseSearchParams()

  const { control, watch, handleSubmit, reset, setValue, formState: { errors } } = useForm<AnalyticsPageFilterCredentials>({
    defaultValues: parseFilterParams<AnalyticsPageFilterCredentials>(params, defaultAnalyticsFilterValues),
  })

  const onSubmit = (values: AnalyticsPageFilterCredentials) => {
    setParams((p: any) => ({ ...p, page: 1, ...serialiseParams(values), modalId: undefined }))
    dispatch(removeModalToStuck())
  }

  const resetHandle = () => {
    reset(defaultAnalyticsFilterValues)
    setParams((p: any) => ({ ...p, page: 1, ...resetParams<AnalyticsPageFilterCredentials>(defaultAnalyticsFilterValues), modalId: undefined }))
    dispatch(removeModalToStuck())
  }

  const [waveFromFilter, setWaveFromFilter] = useState('')
  const [
    waveFromRowsSelect,
    waveFromPaginateTrigger,
    waveFromRefetch,
    waveFromExtraRowsSelect
  ] = usePaginationItemsFromRequest<IWave>({
    request: FormsService.getAllWaves,
    filter: waveFromFilter,
    requestFilter: {
      ...(watch('yearFrom') ? { year: (watch('yearFrom') as OptionData).value } : {}),
      ...(watch('quarterFrom') ? { quarter: (watch('quarterFrom') as OptionData).value } : {})
    },
  })

  const [waveToFilter, setWaveToFilter] = useState('')
  const [
    waveToRowsSelect,
    waveToPaginateTrigger,
    waveToRefetch,
    waveToExtraRowsSelect,
  ] = usePaginationItemsFromRequest<IWave>({
    request: FormsService.getAllWaves,
    filter: waveToFilter,
    requestFilter: {
      ...(watch('yearTo') ? { year: (watch('yearTo') as OptionData).value } : {}),
      ...(watch('quarterTo') ? { quarter: (watch('quarterTo') as OptionData).value } : {})
    }
  })

  const [templateFormFilter, setTemplateFormFilter] = useState('')
  const [
    templateFormRowsSelect,
    templateFormPaginateTrigger,
  ] = usePaginationItemsFromRequest<(ITypeForm)>({
    request: FormsService.getAllFormTemplates,
    filter: templateFormFilter,
  })

  const [kindFormFilter, setKindFormFilter] = useState('')
  const [
    kindFormRowsSelect,
    kindFormPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IKindForm)>({
    request: FormsService.getAllFormKinds,
    filter: kindFormFilter,
  })

  const [typeFormFilter, setTypeFormFilter] = useState('')
  const [
    typeFormRowsSelect,
    typeFormPaginateTrigger,
  ] = usePaginationItemsFromRequest<(ITypeForm)>({
    request: FormsService.getAllFormTypes,
    filter: typeFormFilter,
  })

  const [dealerFilter, setDealerFilter] = useState('')
  const [
    dealerRowsSelect,
    dealerPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IDealer & IResponseExtraData & IIdentifier)>({
    request: DealersService.getAll,
    filter: dealerFilter,
  })

  const [stateFilter, setStateFilter] = useState('')
  const [
    stateRowsSelect,
    statePaginateTrigger,
  ] = usePaginationItemsFromRequest<any>({
    request: DealersService.getStates,
    filter: stateFilter,
    dataConverter: (element, id): OptionData => ({ name: element.stateCode, value: id! })
  })

  const [distFilter, setDistFilter] = useState('')
  const [
    distRowsSelect,
    distPaginateTrigger,
  ] = usePaginationItemsFromRequest<IDistrict>({
    request: DealersService.getAllDistricts,
    filter: distFilter,
    dataConverter: (element, id): OptionData => ({ name: element.distName, value: id! })
  })

  const [cityFilter, setCityFilter] = useState('')
  const [
    cityRowsSelect,
    cityPaginateTrigger,
  ] = usePaginationItemsFromRequest<ICity>({
    request: DealersService.getAllCities,
    filter: cityFilter,
    dataConverter: (element, id): OptionData => ({ name: element.cityName, value: id! })
  })

  return (
    <UIContainer.ContentContainer
      header="Фильтрация"
      customStyle={{
        paddingLeft: 20,
        paddingRight: 20
      }}
    >
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <Select
          selectName={"Начальный год"}
          options={yearsOptions}
          name={"yearFrom"}
          control={control}
          withClearOption
          onHandleChange={waveFromRefetch}
        />
        <Select
          selectName={"Конечный год"}
          options={yearsOptions}
          name={"yearTo"}
          control={control}
          withClearOption
          onHandleChange={waveToRefetch}
        />
        <Select
          selectName={"Начальный квартал"}
          options={quarterOptions}
          name={"quarterFrom"}
          control={control}
          withClearOption
          onHandleChange={waveFromRefetch}
        />
        <Select
          selectName={"Конечный квартал"}
          options={quarterOptions}
          name={"quarterTo"}
          control={control}
          withClearOption
          onHandleChange={waveToRefetch}
        />
        <Select
          selectName={"Начальная волна"}
          options={waveFromRowsSelect}
          paginateTrigger={waveFromPaginateTrigger}
          filterSetter={setWaveFromFilter}
          name={"wavesFrom"}
          control={control}
          isFilter
          onHandleChange={(option) => {
            waveFromExtraRowsSelect.forEach(({ id, year, quarter }: any) => {
              if (id === (option as OptionData).value) {
                setValue('yearFrom', { value: year, name: String(year) })
                setValue('quarterFrom', { value: quarter, name: String(quarter) })
              }
            })
          }}
        />
        <Select
          selectName={"Конечная волна"}
          options={waveToRowsSelect}
          paginateTrigger={waveToPaginateTrigger}
          filterSetter={setWaveToFilter}
          name={"wavesTo"}
          control={control}
          isFilter
          onHandleChange={(option) => {
            waveToExtraRowsSelect.forEach(({ id, year, quarter }: any) => {
              if (id === (option as OptionData).value) {
                setValue('yearTo', { value: year, name: String(year) })
                setValue('quarterTo', { value: quarter, name: String(quarter) })
              }
            })
          }}
        />
        <Select
          selectName={"Шаблон анкеты"}
          options={templateFormRowsSelect}
          paginateTrigger={templateFormPaginateTrigger}
          filterSetter={setTemplateFormFilter}
          name={"templateFormId"}
          control={control}
          isFilter
        />
        <Select
          selectName={"Вид анкеты"}
          options={kindFormRowsSelect}
          paginateTrigger={kindFormPaginateTrigger}
          filterSetter={setKindFormFilter}
          name={"kindFormIds"}
          control={control}
          isFilter
        />
        <Select
          selectName={"Тип анкеты"}
          options={typeFormRowsSelect}
          paginateTrigger={typeFormPaginateTrigger}
          filterSetter={setTypeFormFilter}
          name={"typeFormIds"}
          control={control}
          withClearOption
          isFilter
        />
        <Select
          selectName={"ФО"}
          options={stateRowsSelect}
          paginateTrigger={statePaginateTrigger}
          filterSetter={setStateFilter}
          name={"stateCode"}
          control={control}
          withClearOption
          isFilter
        />
        <Select
          selectName={"Область"}
          options={distRowsSelect}
          paginateTrigger={distPaginateTrigger}
          filterSetter={setDistFilter}
          name={"distName"}
          control={control}
          withClearOption
          isFilter
        />
        <Select
          selectName={"Город"}
          options={cityRowsSelect}
          paginateTrigger={cityPaginateTrigger}
          filterSetter={setCityFilter}
          name={"cityName"}
          control={control}
          withClearOption
          isFilter
        />
        <Select
          selectName={"Дилер"}
          options={dealerRowsSelect}
          paginateTrigger={dealerPaginateTrigger}
          filterSetter={setDealerFilter}
          name={"dealerId"}
          control={control}
          withClearOption
          isFilter
        />
        <Select
          selectName={"Тип подсчета *"}
          options={calculationOptions}
          name={"typeCalculation"}
          control={control}
          withClearOption
          errors={errors}
          rules={rules}
        />
        <Select
          selectName={"Учет аппеляции *"}
          options={appealOptions}
          name={"withoutAppeal"}
          control={control}
          errors={errors}
          rules={rules}
        />
        <div className={s.controls}>
          <UIButton.DefaultBtn label="Показать" />
          <div className={s.resetWrapper} onClick={resetHandle}>
            <div className={s.resetHeader}>Сбросить</div>
            <ReactSVG
              data-testid="resetFilter"
              className={clsx(s.resetElement)}
              src={ResetSVG}
            />
          </div>
        </div>
      </form>
    </UIContainer.ContentContainer>
  )
}

export default AnalyticsGraphPageFilter
