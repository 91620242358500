import { UIContainer } from "components/UI/UIContainer";
import React, {useEffect, useState} from "react";
import {Control, FormState} from "react-hook-form";
import UIField from "components/UI/UIField";
import SelectAllSVG from 'assets/svg/button/select-all.svg';
import ClearAllSVG from 'assets/svg/button/clear-all.svg';
import clsx from "clsx";
import s from './s.module.css'
import {ReactSVG} from "react-svg";
import {BindUserToFormTypeModalCredentials} from "./index";
import UICheckbox from "components/UI/UICheckbox";
import {selectFormTypesList} from "store/forms/reselect";
import {useSelector} from "react-redux";
import {getIsBinded} from "lib/getIsBinded";
import {LOADING_STATUS} from "lib/appConst";

/**
 * Интерфейс данных для AddUserModalView
 */
interface BindUserToFormTypeModalViewProps {
  onSubmit: Function
  handleSubmit: Function
  control: Control<BindUserToFormTypeModalCredentials>
  formState: FormState<BindUserToFormTypeModalCredentials>
  userId: number
  checkBoxClickHandler: Function
  // errorMessage: string
}

const BindUserToFormTypeModalView = ({
                                        onSubmit,
                                        handleSubmit,
                                        control,
                                        formState,
                                        userId,
                                        checkBoxClickHandler,
                                        // errorMessage
                                    }: BindUserToFormTypeModalViewProps) => {
  const [formTypesList, setFormTypesList] = useState([] as {name: string, isBinded: boolean, id: number}[]);
  const fetchedFormTypes = useSelector(selectFormTypesList);

  useEffect(() => {
    onSubmit();
  }, [])

  useEffect(() => {
    if (fetchedFormTypes.status === LOADING_STATUS.IDLE) {
      const formTypesData: { name: string, isBinded: boolean, id: number }[] = fetchedFormTypes.rows.map(type => {
        return {
          name: type.name,
          isBinded: type.Users ? getIsBinded(userId, type.Users) : false,
          id: type.id
        }
      })
      setFormTypesList(formTypesData);
    }
  }, [fetchedFormTypes.status])

  /**
   * Стили для формы
   */
  const formStyle = {
    width: '567px',
    padding: '44px 48px'
  }

  /**
   * Стили для Input 'Поиск по типам'
   */
  const inputStyle = {
    marginTop: '-3px'
  }

  /**
   * Привязывает все типы
   */
  const selectAll = () => {
    //TODO не понятно как реализовать, на сервере endPoint`а нет
    console.log('Selected All!!!');
  }

  /**
   * Отвязывает все типы
   */
  const clearAll = () => {
    //TODO не понятно как реализовать, на сервере endPoint`а нет
    console.log('Cleared All!!!');
  }

  return (
    <UIContainer.ContentContainer
      header={'Привязать к типу анкеты'}
      customStyle={formStyle}
    >
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={clsx(s['form-type-select-use-form'])}
        >
          <UIField.Input
            name={'typeName'}
            control={control}
            placeholder={'Поиск по типам'}
            customStyle={inputStyle}
            errors={formState.errors}
            extraOnChangeHandler={handleSubmit(onSubmit)}
          />
        </form>

        <div className={clsx(s['checkbox-list'])}>
          {formTypesList.map(type => <div key={type.name} className={clsx(s['checkbox-list-unit'])}>
            <UICheckbox
              id={`${type.id}`}
              handler={() => {
                checkBoxClickHandler(type.id, type.isBinded);
              }}
              value={type.isBinded}
            />
            <label
              htmlFor={`${type.id}`}
              className={clsx(s['checkbox-list-unit__label'])}
            >
              {type.name}
            </label>
          </div>)}
        </div>

        <div className={clsx(s['control-panel'])}>
          <div
            className={clsx(s['control-panel__button'])}
            onClick={selectAll}
          >
            <ReactSVG src={SelectAllSVG} />
            <span>Выбрать всё</span>
          </div>

          <div
            className={clsx(s['control-panel__button'])}
            onClick={clearAll}
          >
            <ReactSVG src={ClearAllSVG} />
            <span>Снять выбор</span>
          </div>
        </div>
      </div>
    </UIContainer.ContentContainer>
  )
}

export default BindUserToFormTypeModalView;
