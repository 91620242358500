import {createAsyncThunk} from "@reduxjs/toolkit";
import FormsService from "services/FormsService";
import {
  IMainGroup,
  ITemplateForm,
  ITemplateFormTransfer,
  ITemplateGroup
} from "models/InstanceInterfaces/ITemplateForm";
import {IResponseExtraData, ListResponse} from "models/common";
import {IQuestReorderData} from "../../models/IQuestion";
import {parseStaticParams} from "../../lib/apiHelpers";
import {addNotification} from "../../lib/addNotification";
import {NOTIFICATOR_TYPE} from "../../lib/appConst";
import {AxiosError} from "axios";


/**
 * Получить шаблоны анкет
 */
export const getAllFormTemplates = createAsyncThunk(
  'formTemplateEditor/getAllFormTemplates',
  async(): Promise<ListResponse<ITemplateForm & IResponseExtraData> | undefined> => {
    try {
      const params = parseStaticParams()
      const response = await FormsService.getAllFormTemplates(params)
      return response.data;
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)

/**
 * Получить шаблон по id
 */
export const getFormTemplateById = createAsyncThunk(
  'formTemplateEditor/getFormTemplateById',
  async(id: number): Promise<(ITemplateForm & IResponseExtraData) | {error?: string} | undefined> => {
    try {
      const response = await FormsService.getFormTemplateById(id)
      return response.data;
    } catch (e) {
      if(e instanceof Error) {
        addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        return {error: e.message}
      } else {
        addNotification({type: NOTIFICATOR_TYPE.error, text: "Неизвестная ошибка"})
        return {error: "Неизвестная ошибка"}
      }
    }
  }
)

/**
 * Получить шаблон по id без индикации загрузки
 * Нужно для скрытой синхронизации после изменения вопросов/групп внутри шаблона
 * Это необходимо для имитации бесшовной работы
 */
export const getFormTemplateByIdNoLoad = createAsyncThunk(
  'formTemplateEditor/getFormTemplateByIdNoLoad',
  async(id: number): Promise<ITemplateForm & IResponseExtraData | undefined> => {
    try {
      const response = await FormsService.getFormTemplateById(id)
      return response.data;
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)

/**
 * Создать шаблон
 */
export const createFormTemplate = createAsyncThunk(
  'formTemplateEditor/createFormTemplate',
  async(params: ITemplateFormTransfer): Promise<ITemplateForm & IResponseExtraData | undefined> => {
    try {
      const response = await FormsService.createNewFormTemplate(params)
      return response.data
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)

/**
 * Редактировать шаблон
 */
export const editFormTemplate = createAsyncThunk(
  'formTemplateEditor/createFormTemplate',
  async(params: ITemplateFormTransfer): Promise<ITemplateForm & IResponseExtraData | undefined> => {
    try {
      const response = await FormsService.editFormTemplate(params)
      return response.data
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)

/**
 * Создать группу для вопросов
 */
export const createTemplateMainGroup = createAsyncThunk(
  'formTemplateEditor/createTemplateMainGroup',
  async(data: IMainGroup): Promise<ITemplateGroup | undefined> => {
    try {
      const res = await FormsService.addMainGroup(data)
      if(res.data) {
        addNotification({type: NOTIFICATOR_TYPE.apply, text: "Раздел создан"})
        return res.data
      }
      else {
        addNotification({type: NOTIFICATOR_TYPE.error, text: "Что то пошло не так"})
      }
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)

/**
 * Создать вопрос
 */
export const createQuestion = createAsyncThunk(
  'formTemplateEditor/createQuestion',
  async(data: any): Promise<any | undefined> => {
    try {
      const res = await FormsService.createNewQuestion(data)
      return res.data
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)

/**
 * Редактировать вопрос
 */
export const editQuestion = createAsyncThunk(
  'formTemplateEditor/editQuestion',
  async(data: any): Promise<any | undefined> => {
    try {
      const res = await FormsService.editQuestion(data)
      return res.data
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)

/**
 * Редактировать вопрос
 */
export const setNextQuestion = createAsyncThunk(
  'formTemplateEditor/setNextQuestion',
  async(data: {id: number, nextQuestionId: number}): Promise<any | undefined> => {
    try {
      const res = await FormsService.setNextQuestion(data)
      return res.data
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)

/**
 * DND обработчик перемещения вопроса
 */
export const reorderQuestion = createAsyncThunk(
  'formTemplateEditor/reorderQuestion',
  async(data: IQuestReorderData): Promise<any | undefined> => {
    try {
      const res = await FormsService.reorderQuestion(data)
      return res.data
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)
