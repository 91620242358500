import React, {memo} from 'react';
import {IStructureConfig} from "../../index";
import s from './s.module.css'
import commonStyle from '../../s.module.css'
import clsx from "clsx";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";

export interface ITableHeader {
  disabledTableActivity?: boolean
  structureConfig: IStructureConfig[]
  selectable?: boolean
  rows: any[]
}

/**
 * Заголовки таблицы
 * @param structureConfig - Структурный конфиг полей
 * @param selectable
 */
const TableHeader = ({disabledTableActivity, structureConfig, selectable}: ITableHeader) => {
  const [{sort, direction}, setParams] = useEaseSearchParams()
  const clickHandle = (field: string) => {
    const sortDirection = sort === field && direction !== 'ASC' ? 'ASC' : 'DESC'

    if (field === sort && direction === 'ASC') {
      setParams((p: any) => ({...p, sort: '', direction: ''}))
      return
    }
    setParams((p: any) => ({...p, sort: field, direction: sortDirection}))
  }

  const completedHeadings = [
    selectable && <th key={'special'} className={"selectableTableCell"}/>,
    ...structureConfig.map((element, id) => (
      <TableHeaderElement
        disabledTableActivity={disabledTableActivity}
        key={id}
        id={id}
        element={element}
        clickHandle={clickHandle}
        direction={direction}
        sort={sort}
        isSort={!!element.sort}
      />
    ))]

  return (
    <thead className={s.tableHeader}>
      <tr>
        { completedHeadings }
      </tr>
    </thead>
  )
};

interface ITableHeaderElement {
  disabledTableActivity?: boolean
  id: number
  clickHandle: any
  element: any
  direction: string
  sort: boolean
  isSort: boolean
}

const TableHeaderElement = memo(({disabledTableActivity, clickHandle, element, direction, sort, isSort}: ITableHeaderElement) => {
  const sortField = element.sortFieldName
    ? element.sortFieldName
    : element.dataAnchor?.split('.')[element.dataAnchor?.split('.').length - 1]
  return (
    <th>
      <div className={s.tableHeaderCell}>
        <p
          onClick={() => isSort && !disabledTableActivity ? clickHandle(sortField) : null}
          className={clsx({
            [s.isSort]: element.sort,
            [s.isActive]: sort === sortField,
            [commonStyle.disabled]: disabledTableActivity,
          })}>
          {element.name}
        </p>
      {
        isSort && <div className={clsx({
            [commonStyle.arrowDown]: sort !== sortField,
            [commonStyle.arrowDownActive]: sort === sortField && direction === 'DESC',
            [commonStyle.arrowUpActive]: sort === sortField && direction === 'ASC',
            [commonStyle.disabled]: disabledTableActivity
          })}/>
      }
      </div>
    </th>
  )
})

export default TableHeader;
