import { UIButton } from 'components/UI/UIButton'

import SelectAllImg from 'assets/svg/button/select-all.svg'
import ClearAllImg from 'assets/svg/button/clear-all.svg'

import s from './s.module.css'


type UIModalFooterPropsTypes = {
  handleCheck?: () => void,
  handleUncheck?: () => void,
  handleBack?: () => void,
  handleClearFilters?: () => void,
}

export const UIModalFooter = ({
  handleCheck,
  handleUncheck,
  handleBack,
  handleClearFilters,
}: UIModalFooterPropsTypes) => {
  return (
    <div className={s.footer}>
      {handleCheck && (
        <div className={s.iconsItem} onClick={handleCheck}>
          <img src={SelectAllImg} alt={'Выбрать все'} />
          Выбрать всё
        </div>
      )}
      {handleUncheck && (
        <div className={s.iconsItem} onClick={handleUncheck}>
          <img src={ClearAllImg} alt={'Снять выбор'} />
          Снять выбор
        </div>
      )}
      <div className={s.controlButtons}>
        {handleClearFilters && (
          <UIButton.BorderBtn label={'Сброс фильтров'} type={'button'} handler={handleClearFilters} />
        )}
        <UIButton.DefaultBtn label={'Вернуться'} type={'button'} handler={handleBack} />
      </div>
    </div>
  )
}
