import React from 'react';
import s from './s.module.css'
import clsx from "clsx";
import {UIButtonCommonType} from "../../index";

export type DefaultBtnType = {
  label: string
}

const LinkBtn = ({
                   label,
                   handler,
                   style = {},
                   type = "submit",
                   title,
                   disabled
                 }: DefaultBtnType & UIButtonCommonType) => {
  return (
    <button
      style={style}
      type={type}
      className={clsx(s.button, 'button')}
      onClick={handler}
      disabled={disabled}
      title={title}
    >
      <span> { label } </span>
    </button>
  );
};

export default LinkBtn;
