interface AppealTableRow {
  dealer: {
    address: string
  },
  appealCount: number,
  questions: QuestionRow[]
}

interface QuestionRow {
  answer: number
}

interface QuestionServerRow {
  formQuestionId: number,
  answer: string
  appeal: number
}

// здесь описана малая часть от того, что приходит с сервера, но обязательная для работы функции ниже
interface FormServerRow {
  appealCount: number
  questions: QuestionServerRow[]
}


// Подсчет общего кол-ва апеляций для последней строки в таблице отчета по апелляциям
export const countTotalAppeals = (forms: FormServerRow[]): AppealTableRow => {
  // данные в dealer.address и questions.answer согласно выводу в таблице по якорю конфига
  const arr: AppealTableRow = { dealer: { address: 'Итого:' }, appealCount: 0, questions: [] }

  forms.map((form, index: number) => {
    if (index === 0) {
      arr.questions.push(...form.questions.map((el) => ({ answer: el.appeal })))
    } else {
      form.questions.map((el, index: number) => {
        arr.questions[index].answer += el.appeal
      })
    }
    arr.appealCount += form.appealCount
  })

  return arr
}
