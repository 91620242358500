import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'

import { UIContainer } from 'components/UI/UIContainer'
import { UIButton } from 'components/UI/UIButton'
import Select from 'components/UI/UIField/items/select'
import Input from 'components/UI/UIField/items/Input'

import {
  AnalyticsUnloadPageFilterCredentials,
  defaultAnalyticsUnloadFilterValues,
} from 'modules/filters/AnalyticUnloadPageFilter/utils'

import { useAppDispatch } from 'hooks/redux'
import { useEaseSearchParams } from 'hooks/useEaseSearchParams'
import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest'

import { parseFilterParams, resetParams, serialiseParams } from 'lib/parseFilterParams'
import UsersService from 'services/UsersService'

import { removeModalToStuck } from 'store/common'

import { IUser, IUserFilter } from 'models/InstanceInterfaces/IUser'
import { IIdentifier } from 'models/common'
import { OptionData } from 'models/FilterData'

import ResetSVG from 'assets/svg/filter/resetIcon.svg'

import s from 'modules/filters/FormsPageFilter/s.module.css'

const AnalyticUnloadPageFilter = () => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useEaseSearchParams()
  const { control, handleSubmit, reset, formState: { errors } } = useForm<AnalyticsUnloadPageFilterCredentials>({
    defaultValues: parseFilterParams<AnalyticsUnloadPageFilterCredentials>(params, defaultAnalyticsUnloadFilterValues)
  })

  const onSubmit = (values: AnalyticsUnloadPageFilterCredentials) => {
    setParams((p: any) => ({ ...p, page: 1, ...serialiseParams(values), modalId: undefined }))
    dispatch(removeModalToStuck())
  }

  const resetHandle = () => {
    reset(defaultAnalyticsUnloadFilterValues)
    setParams((p: any) => ({ ...p, page: 1, ...resetParams<AnalyticsUnloadPageFilterCredentials>(defaultAnalyticsUnloadFilterValues), modalId: undefined }))
    dispatch(removeModalToStuck())
  }

  const [userFilter, setUserFilter] = useState('')
  const [
    userRowsSelect,
    userPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IUser & IIdentifier), IUserFilter>({
    request: UsersService.getAll,
    filter: userFilter,
    filterField: 'fullName',
    dataConverter: (element): OptionData => ({name: element.fullName, value: element.id})
  })

  return (
    <UIContainer.ContentContainer
      header="Фильтрация"
      customStyle={{
        paddingLeft: 20,
        paddingRight: 20
      }}
    >
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <Select
          selectName={"Пользователь"}
          options={userRowsSelect}
          paginateTrigger={userPaginateTrigger}
          filterSetter={setUserFilter}
          name={"userId"}
          control={control}
          isFilter
          errors={errors}
        />
        <Input
          name={'name'}
          control={control}
          placeholder="Имя отчета"
        />
        <Input
          name={'params'}
          control={control}
          placeholder="Параметры отчета"
        />
        <div className={s.controls}>
          <UIButton.DefaultBtn label="Показать" />
          <div className={s.resetWrapper} onClick={resetHandle}>
            <div className={s.resetHeader}>Сбросить</div>
            <ReactSVG
              data-testid="resetFilter"
              className={clsx(s.resetElement)}
              src={ResetSVG}
            />
          </div>
        </div>
      </form>
    </UIContainer.ContentContainer>
  )
}

export default AnalyticUnloadPageFilter
