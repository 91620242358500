import React from 'react';

const FullSizeCenterWrapper = ({children}: {children: JSX.Element}) => {
  return (
    <div style={{width: '100%', height: '100%', display: "flex", justifyContent: "center", alignItems: "center"}}>
      {children}
    </div>
  );
};

export default FullSizeCenterWrapper;
