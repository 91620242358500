import React, {useState} from 'react'
import s from './s.module.css'
import {UIButton} from 'components/UI/UIButton'
import {ReactComponent as DownloadIcon} from 'assets/svg/button/download.svg'
import {ReactComponent as FilterIcon} from 'assets/svg/button/filter.svg'
import {ReactComponent as GroupIcon} from 'assets/svg/button/group.svg'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {setModal} from 'lib/setModal'
import {ModalsEnum} from 'modules/ModalController/modalManager'
import {FILTER_IDS} from 'modules/Modals/Common/Filter'
import {
  getDealersActionHelper,
  getUsersActionHelper,
  parseStaticParams,
  syncHelper,
} from 'lib/apiHelpers'
import DealersService from 'services/DealersService'
import UsersService from 'services/UsersService'
import AnalyticsService from 'services/AnalyticsService'
import {defaultAnalyticParams, defaultGraphParams, defaultPaginationParams} from 'modules/AppRouter/usersRoutes'
import FormsService from 'services/FormsService'
import {parseFilterParamsForRequest} from 'lib/parseFilterParams'
import {defaultFormsFilterValues, TFormsPageFilter} from 'modules/filters/FormsPageFilter'
import {useEaseSearchParams} from 'hooks/useEaseSearchParams'
import {defaultUsersFilterValues, TUsersPageFilter} from 'modules/filters/UsersPageFilter'
import {
  formsCreationAccess,
  formsCreationTypesWithKindsAccess,
  getAccessToUserSetTemplateByFilter,
  getAccessToUserSync
} from '../../RolesModel';
import {defaultAnalyticsFilterValues, TAnalyticsPageFilter} from 'modules/filters/AnalyticsReportPageFilter/utils';
import {calculationValues, groupValues} from 'lib/appOptions';
import clsx from 'clsx'
import {defaultDealersFilterValues, IDealerPageFilter} from 'modules/filters/DealersPageFilter';
import {
  defaultAnalyticsAppealsFilterValues,
  TAnalyticsAppealsPageFilter,
} from 'modules/filters/AnalyticsAppealsPageFilter/utils';
import {getRole} from "../../getRole";
import {Roles} from "../../../models/Roles";
import {getFileByStream} from "../../getFileByStream";
import {addNotification} from "../../addNotification";
import {NOTIFICATOR_TYPE} from "../../appConst";

export const UsersTableControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  const [params] = useEaseSearchParams()
  const [loadingUserSync, setLoadingUserSync] = useState<boolean>(false)
  const [isLoadingExcel, setIsLoadingExcel] = useState(false)

  const accessToUserSync = getAccessToUserSync()
  const accessToUserSetTemplateByFilter = getAccessToUserSetTemplateByFilter()

  return (
    <div className={s.controlDefaultContainer}>
      {accessToUserSync && (
        <UIButton.BorderBtn
          label={'Синхронизировать'}
          disabled={loadingUserSync || disabledTableActivity}
          handler={() => syncHelper(setLoadingUserSync, UsersService.sync, getUsersActionHelper)}
        />
      )}
      <UIButton.DefaultBtn
        label={'Добавить пользователя'}
        handler={() => setModal(ModalsEnum.UserAddModal)}
        disabled={disabledTableActivity}
      />
      <UIButton.DefaultBtn
        label={isLoadingExcel ? "Загрузка..." : 'Скачать'}
        disabled={isLoadingExcel || disabledTableActivity}
        handler={() => {
          setIsLoadingExcel(true)
          UsersService.downloadExcel({
            ...parseFilterParamsForRequest(params, defaultUsersFilterValues),
          } as Partial<TUsersPageFilter>)
            .then(response => {
              getFileByStream(response.data.id)
                .then(([_, downloader]) => {
                  downloader(response.data.fileName)
                })
              setIsLoadingExcel(false)
            })
            .catch((e) => {
              if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
              else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла ошибка скачивания'})
            })
            .finally(() => setIsLoadingExcel(false))
        }
        }
        style={{backgroundColor: 'var(--color-green)'}}
        leftIcon={<DownloadIcon/>}
      />
      <UIButton.DefaultBtn
        label={'Фильтрация'}
        style={{backgroundColor: '#4D5964'}}
        leftIcon={<FilterIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.UsersFilter})}
        disabled={disabledTableActivity}
      />
      {
        accessToUserSetTemplateByFilter && (
          <UIButton.DefaultBtn
            label={'Установить шаблон'}
            style={{backgroundColor: 'rgba(57, 135, 227, 1)'}}
            handler={() => setModal(ModalsEnum.SetTemplateToUserByFilter)}
            disabled={disabledTableActivity}
          />
        )
      }
    </div>
  )
}

export const DealersTableControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  const nav = useNavigate()
  const [params] = useEaseSearchParams()
  const [isLoadingExcel, setIsLoadingExcel] = useState(false)

  const [loadingDealerSync, setLoadingDealerSync] = useState<boolean>(false)

  return (
    <div className={s.controlDefaultContainer}>
      <UIButton.EmptyBtn
        label={'Группы дилеров'}
        leftIcon={<GroupIcon/>}
        handler={() => nav('/dealers/groups' + defaultPaginationParams)}
        disabled={disabledTableActivity}
      />
      <UIButton.BorderBtn
        label={'Синхронизировать'}
        handler={() => syncHelper(setLoadingDealerSync, DealersService.sync, getDealersActionHelper)}
        disabled={loadingDealerSync || disabledTableActivity}
      />
      <UIButton.DefaultBtn
        label={isLoadingExcel ? 'Загрузка...' : 'Скачать'}
        disabled={isLoadingExcel || disabledTableActivity}
        style={{backgroundColor: 'var(--color-green)'}}
        leftIcon={<DownloadIcon/>}
        handler={() => {
          setIsLoadingExcel(true)
          DealersService.downloadExcel({
            ...parseFilterParamsForRequest(params, defaultDealersFilterValues),
          } as Partial<IDealerPageFilter>)
            .then((response) => {
              getFileByStream(response.data.id)
                .then(([_, downloader]) => {
                  downloader(response.data.fileName)
                })
            })
            .catch((e) => {
              if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
              else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла ошибка скачивания'})
            })
            .finally(() => setIsLoadingExcel(false))
        }}
      />
      <UIButton.DefaultBtn
        label={'Фильтрация'}
        style={{backgroundColor: '#4D5964'}}
        leftIcon={<FilterIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.DealersFilter})}
        disabled={disabledTableActivity}
      />
    </div>
  )
}

export const DealersGroupTableControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  return (
    <div className={s.controlDefaultContainer}>
      <UIButton.DefaultBtn
        label={'Создать группу'}
        handler={() => setModal(ModalsEnum.NewDealerGroupModal, {})}
        disabled={disabledTableActivity}
      />
    </div>
  )
}

export const FormsTableControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  const [params] = useEaseSearchParams()
  const [isLoadingExcel, setIsLoadingExcel] = useState(false)

  const resolveRoleRestrictions = () =>
    !!getRole()
      ? getRole().id === Roles.dealer ||
      getRole().id === Roles.dealerDirector
      : false

  const defaultFormsFilterValuesWithRestrictions = {
    ...defaultFormsFilterValues,
    ...!resolveRoleRestrictions() && {controlUserIds: []}
  }

  return (
    <div className={s.controlDefaultContainer}>
      {formsCreationTypesWithKindsAccess() &&
        <>
          <UIButton.EmptyBtn
            label={'Типы анкет'}
            handler={() => {
              setModal(ModalsEnum.GroupingModificationModal, {type: 'formTypes'})
            }}
            disabled={disabledTableActivity}
          />
          <UIButton.EmptyBtn
            label={'Виды анкет'}
            handler={() => {
              setModal(ModalsEnum.GroupingModificationModal, {type: 'formKinds'})
            }}
            disabled={disabledTableActivity}
          />
        </>
      }
      {formsCreationAccess() &&
        <UIButton.DefaultBtn
          label={'Создать анкету'}
          handler={() => setModal(ModalsEnum.NewFormModal, {})}
          disabled={disabledTableActivity}
        />
      }
      <UIButton.DefaultBtn
        label={isLoadingExcel ? 'Загрузка...' : 'Скачать'}
        style={{backgroundColor: 'var(--color-green)'}}
        disabled={isLoadingExcel || disabledTableActivity}
        leftIcon={<DownloadIcon/>}
        handler={() => {
          setIsLoadingExcel(true)
          FormsService.downloadExcel({
            ...parseFilterParamsForRequest(params, defaultFormsFilterValuesWithRestrictions),
          } as Partial<TFormsPageFilter>)
            .then(response => {
              getFileByStream(response.data.id)
                .then(([_, downloader]) => {
                  downloader(response.data.fileName)
                })
              setIsLoadingExcel(false)
            })
            .catch((e) => {
              if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
              else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла ошибка скачивания'})
            })
            .finally(() => setIsLoadingExcel(false))
        }}
      />
      <UIButton.DefaultBtn
        label={'Фильтрация'}
        style={{backgroundColor: '#4D5964'}}
        leftIcon={<FilterIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.FormFilter})}
        disabled={disabledTableActivity}
      />
    </div>
  )
}

export const TodosTableControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  return (
    <div className={s.controlDefaultContainer}>
      <UIButton.DefaultBtn
        label={'Фильтрация'}
        style={{backgroundColor: '#4D5964'}}
        leftIcon={<FilterIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.TodosFilter})}
        disabled={disabledTableActivity}
      />
    </div>
  )
}

export const AnalyticsGraphControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  return (
    <div className={s.controlDefaultContainer}>
      <UIButton.DefaultBtn
        label={'Фильтрация'}
        style={{backgroundColor: '#4D5964'}}
        leftIcon={<FilterIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.AnalyticGraphFilter})}
        disabled={disabledTableActivity}
      />
    </div>
  )
}

export const AnalyticsUnloadControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  return (
    <div className={s.controlDefaultContainer}>
      <UIButton.DefaultBtn
        label={'Сформировать'}
        style={{backgroundColor: 'var(--color-green)'}}
        leftIcon={<DownloadIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.AnalyticUnloadCreate})}
        disabled={disabledTableActivity}
      />
      <UIButton.DefaultBtn
        label={'Фильтрация'}
        style={{backgroundColor: '#4D5964'}}
        leftIcon={<FilterIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.AnalyticUnloadFilter})}
        disabled={disabledTableActivity}
      />
    </div>
  )
}

export const AnalyticsSubmenu = () => {
  const location = useLocation()

  const contextOptions = [
    {name: "Отчеты по вопросам", path: '/analytics/reports' + defaultAnalyticParams},
    {name: "График", path: '/analytics/graph' + defaultGraphParams},
    {name: "Выгрузка", path: '/analytics/unload' + defaultPaginationParams},
    {name: 'Отчеты по аппеляциям', path: '/analytics/appeals'},
  ]

  return (
    <>
      {contextOptions.map((el) => (
        <Link key={el.path} to={el.path} className={clsx({[s.activePath]: el.path.includes(location.pathname)})}>
          {el.name}
        </Link>
      ))}
    </>
  )
}

export const AnalyticsReportTableControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const [params] = useEaseSearchParams()
  return (
    <div className={s.controlDefaultContainer}>
      <UIButton.DefaultBtn
        label={isDownloading ? 'Загрузка...' : 'Скачать'}
        disabled={isDownloading || disabledTableActivity}
        handler={() => {
          const data = parseFilterParamsForRequest(params, defaultAnalyticsFilterValues) as unknown as Partial<TAnalyticsPageFilter>

          const parsedParams = {
            ...data,
            ...(data.group ? {group: groupValues[parseInt(data.group) - 1]} : {}),
            ...(data.typeCalculation ? {typeCalculation: calculationValues[parseInt(data.typeCalculation) - 1]} : {}),
          }

          setIsDownloading(true)
          AnalyticsService.reportXls(parsedParams)
            .then((response) => {
              getFileByStream(response.data.id)
                .then(([_, downloader]) => {
                  downloader(response.data.fileName)
                })
              setIsDownloading(false)
            })
            .catch((e) => {
              if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
              else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла ошибка скачивания'})
            })
            .finally(() => setIsDownloading(false))
        }}
        style={{backgroundColor: isDownloading ? '#aaa' : 'var(--color-green)'}}
        leftIcon={<DownloadIcon/>}
      />
      <UIButton.DefaultBtn
        label={'Фильтрация'}
        style={{backgroundColor: '#4D5964'}}
        leftIcon={<FilterIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.AnalyticReportFilter})}
        disabled={disabledTableActivity}
      />
    </div>
  )
}

export const AnalyticsAppealsTableControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const [params] = useEaseSearchParams()
  return (
    <div className={s.controlDefaultContainer}>
      <UIButton.DefaultBtn
        label={isDownloading ? 'Загрузка...' : 'Скачать'}
        disabled={isDownloading || disabledTableActivity}
        handler={() => {
          const data = parseFilterParamsForRequest(params, defaultAnalyticsAppealsFilterValues) as unknown as Partial<TAnalyticsAppealsPageFilter>
          setIsDownloading(true)
          AnalyticsService.appealsExcel(data)
            .then((response) => {
              getFileByStream(response.data.id)
                .then(([_, downloader]) => {
                  downloader(response.data.fileName)
                })
              setIsDownloading(false)
            })
            .catch((e) => {
              if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
              else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла ошибка скачивания'})
            })
            .finally(() => setIsDownloading(false))
        }}
        style={{backgroundColor: isDownloading ? '#aaa' : 'var(--color-green)'}}
        leftIcon={<DownloadIcon/>}
      />
      <UIButton.DefaultBtn
        label={'Фильтрация'}
        style={{backgroundColor: '#4D5964'}}
        leftIcon={<FilterIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.AnalyticAppealsFilter})}
        disabled={disabledTableActivity}
      />
    </div>
  )
}

export const FormsTemplateControlGroup =
  ({
     groupByProcessHandler,
     groupByProcessFact,
     addTemplateHandler,
   }: {
    addTemplateHandler: any
    groupByProcessFact: any
    groupByProcessHandler: any
  }) =>
    () => {
      const {selectedTemplate} = parseStaticParams()

      return (
        <div className={s.controlDefaultContainer}>
          {selectedTemplate && <UIButton.EmptyBtn label={'Группировка по процессам'} handler={groupByProcessHandler}/>}
          {selectedTemplate && <UIButton.EmptyBtn label={'Группировка по факторам'} handler={groupByProcessFact}/>}
          <UIButton.DefaultBtn label={'Создать шаблон'} handler={addTemplateHandler}/>
        </div>
      )
    }

export const AppealsTableControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  return (
    <div className={s.controlDefaultContainer}>
      <UIButton.DefaultBtn
        label={'Фильтрация'}
        style={{backgroundColor: '#4D5964'}}
        leftIcon={<FilterIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.AppealsFilter})}
        disabled={disabledTableActivity}
      />
    </div>
  )
}

export const LoggingTableControl = ({disabledTableActivity}: {disabledTableActivity?: boolean}) => {
  return (
    <div className={s.controlDefaultContainer}>
      <UIButton.DefaultBtn
        label={'Фильтрация'}
        style={{backgroundColor: '#4D5964'}}
        leftIcon={<FilterIcon/>}
        handler={() => setModal(ModalsEnum.FilterModal, {filterId: FILTER_IDS.LoggingFilter})}
        disabled={disabledTableActivity}
      />
    </div>
  )
}
