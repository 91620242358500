import React, {memo, useCallback, useEffect, useState} from 'react';
import TableGenerator, {IStructureConfig, ITableConfig} from "modules/TableGenerator";
import {useAppDispatch} from "hooks/redux";
import {getAllUsersAction} from "store/users/thunk";
import {selectUsers} from "store/users/reselect";
import {usersTableConfig} from "lib/tableConfigs/configs";
import {UsersTableControl} from "lib/tableConfigs/controlsPakages/controls";
import {parseFilterParamsForRequest} from "../../lib/parseFilterParams";
import {useEaseSearchParams} from "../../hooks/useEaseSearchParams";
import {defaultUsersFilterValues, TUsersPageFilter} from "../../modules/filters/UsersPageFilter";
import {ISort} from "../../models/common";
import {IsAgencyManager} from "../../lib/RolesModel";

/**
 * Страница для отображения пользователей
 * @constructor
 */
const Users = () => {
  const [params] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const getUsersQueryExecute = useCallback((param: {page: number, count: number}) => dispatch(getAllUsersAction(param)), [])
  const [filter, setFilter] = useState<Partial<TUsersPageFilter>>(parseFilterParamsForRequest(params, defaultUsersFilterValues) as Partial<TUsersPageFilter>)
  const [sort, setSort] = useState<ISort>()

  useEffect(() => {
    if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultUsersFilterValues) as unknown as Partial<TUsersPageFilter>)) {
      setFilter(parseFilterParamsForRequest(params, defaultUsersFilterValues) as unknown as Partial<TUsersPageFilter>)
    }
    if(JSON.stringify(sort) !== JSON.stringify({sort: params.sort, direction: params.direction})) {
      setSort({sort: params.sort, direction: params.direction})
    }
  }, [params])

  const excludedForManager = ['Дилеры', 'Шаблоны анкет']

  const cutColumnByRole = (excluded: string[]): ITableConfig => {
    return {
      ...usersTableConfig,
      structureConfig: usersTableConfig.structureConfig.reduce((acc: IStructureConfig[], column) => {
        if(excluded.includes(column.name)) return acc
        return [...acc, column]
      }, [])
    }
  }

  return (
    <>
      <TableGenerator
        tableName={'Пользователи'}
        dataQuery={getUsersQueryExecute}
        dataSelect={selectUsers}
        tableConfig={IsAgencyManager() ? cutColumnByRole(excludedForManager) : usersTableConfig}
        tableControl={UsersTableControl}
        filter={filter}
        sort={sort}
      />
    </>
  );
};

export default memo(Users);
