import { UIButton } from 'components/UI/UIButton'

import s from './s.module.css'

type ActionsPropsType = {
  prevHandler: () => void
  nextHandler?: () => void
  isShowPrev: boolean,
  isDisabledButtons: boolean
}

const Actions = ({
  prevHandler,
  nextHandler = () => {},
  isShowPrev,
  isDisabledButtons,
}: ActionsPropsType) => {

  const commonStyles = {
    height: 44,
    paddingLeft: 28,
    paddingRight: 28,
  }

  return (
    <div className={s.container}>
      {isShowPrev ? (
        <UIButton.BorderBtn
          label="Вернуться"
          handler={prevHandler}
          disabled={isDisabledButtons}
          type="button"
          style={{
            color: "#89929B",
            backgroundColor: "#fff",
            border: "1px solid #DCDEE0",
            ...commonStyles,
          }}
        />
      ) : (
        <div />
      )}
      <UIButton.DefaultBtn
        label="Продолжить"
        style={commonStyles}
        handler={nextHandler}
        disabled={isDisabledButtons}
        type="submit"
      />
    </div>
  )
}

export default Actions
