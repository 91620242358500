import {useState, useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form';

import DealersService from 'services/DealersService';
import {useEaseSearchParams} from 'hooks/useEaseSearchParams';
import {removeModalItem} from 'lib/setModal';
import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest';
import {useInView} from 'react-intersection-observer';
import {useDebounce} from 'hooks/useDebounce';

import s from './s.module.css'

import {useDispatch, useSelector} from 'react-redux';
import {
  setBindedDealer,
  checkAllBindedDealers,
  uncheckAllBindedDealers,
  toggleBindedDealer,
  setBindedDealersFilters,
} from 'store/forms';
import {
  selectFormBindedDealers,
  selectFormBindedDealersFilters,
  selectAllCheckedBindenDealers, selectNewFormData
} from 'store/forms/reselect';

import {IBindedDealer} from 'models/BindedDealers';
import {ICity} from 'models/InstanceInterfaces/ICity';
import {OptionData} from 'models/FilterData';
import {IDistrict} from 'models/InstanceInterfaces/IDistrict';
import {IState} from 'models/InstanceInterfaces/IState';
import {ITemplateFormType} from 'models/InstanceInterfaces/ITemplateForm';

import UICheckbox from 'components/UI/UICheckbox';
import {UIContainer} from 'components/UI/UIContainer'
import UIField from 'components/UI/UIField'
import {UIModal} from 'components/UI/UIModal'
import {RootState} from "../../../store";
import {IDealer} from "../../../models/DealersServiceModel";
import {IIdentifier} from "../../../models/common";
import {filterValueIsSet} from "../../../lib/filterValueIsSet";

export const defaultFilters = {
  name: '',
  stateCode: {name: ''},
  distName: {name: ''},
  cityName: {name: ''},
  groupIds: {name: ''},
}

const BindDealerModal = () => {

  const {
    formData,
    dealers,
    filters,
    isAllChecked,
  } = useSelector((state: RootState) => ({
    formData: selectNewFormData(state),
    dealers: selectFormBindedDealers(state),
    filters: selectFormBindedDealersFilters(state),
    isAllChecked: selectAllCheckedBindenDealers(state),
  }))
  const dispatch = useDispatch()

  const [params, setParams] = useEaseSearchParams()
  const {control, watch, reset} = useForm({defaultValues: defaultFilters})

  // Debounce
  const {name} = watch()
  const debouncedInputValue = useDebounce(name, 500)
  useEffect(() => {
    filterDealers()
  }, [debouncedInputValue])


  // Фильтрация
  const filterDealers = () => {
    const {stateCode, distName, cityName, groupIds, name} = watch()
    dispatch(setBindedDealersFilters({stateCode, distName, cityName, groupIds, name}))
  }

  const clearFilterInputs = () => {
    reset()
    dispatch(setBindedDealersFilters({}))
    dispatch(uncheckAllBindedDealers())
  }


  // Обработка чекбоксов
  const handleToggleCheckbox = (data: IBindedDealer) => {
    dispatch(toggleBindedDealer(data))
  }
  const handleCheckAll = async () => {
    const res = await DealersService.getAll({...params, ...memoParseFilterParams, count: total})
    const arr = dealerDataConverter(res.data.rows)
    const newArr = arr.map((item: any) => ({...item, isBinded: true}))
    dispatch(setBindedDealer(newArr))
    dispatch(checkAllBindedDealers())
  }
  const handleUncheckAll = () => {
    dispatch(uncheckAllBindedDealers())
  }

  const removeModal = () => {
    removeModalItem(setParams)
  }

  // Логика селекторов
  const [stateFilter, setStateFilter] = useState('')
  const [
    stateRowsSelect,
    statePaginateTrigger,
  ] = usePaginationItemsFromRequest<IState>({
    request: DealersService.getStates,
    filter: stateFilter,
    dataConverter: (element, id): OptionData => ({name: element.stateCode, value: id!})
  })
  const [distFilter, setDistFilter] = useState('')
  const [
    distRowsSelect,
    distPaginateTrigger,
  ] = usePaginationItemsFromRequest<IDistrict>({
    request: DealersService.getAllDistricts,
    filter: distFilter,
    dataConverter: (element, id): OptionData => ({name: element.distName, value: id!})
  })
  const [cityFilter, setCityFilter] = useState('')
  const [
    cityRowsSelect,
    cityPaginateTrigger,
  ] = usePaginationItemsFromRequest<ICity>({
    request: DealersService.getAllCities,
    filter: cityFilter,
    dataConverter: (element, id): OptionData => ({name: element.cityName, value: id!})
  })
  const [dealerGroupFilter, setDealerGroupFilter] = useState('')
  const [
    dealerGroupRowsSelect,
    dealerGroupPaginateTrigger,
  ] = usePaginationItemsFromRequest<ITemplateFormType>({
    request: DealersService.getAllDealerGroup,
    filter: dealerGroupFilter,
    dataConverter: (element): OptionData => ({name: element.name, value: element.id})
  })


  // Список дилеров
  const dealerDataConverter = (arr: any) =>
    arr.map((dealer: any) => ({
      id: dealer.id,
      name: dealer.name,
      isBinded: isAllChecked,
      stateCode: dealer.stateCode,
      distName: dealer.distName,
      cityName: dealer.cityName,
      groupIds: dealer.DealerGroups.map((i: any) => (i.id)),
    }))

  const memoParseFilterParams = useMemo(() => {
    let obj = {}
    if (filters) {
      obj = {
        ...(filters.stateCode?.name ? {stateCode: filters.stateCode.name} : {}),
        ...(filters.distName?.name ? {distName: filters.distName.name} : {}),
        ...(filters.cityName?.name ? {cityName: filters.cityName.name} : {}),
        ...(filters.groupIds?.value ? {groupIds: [filters.groupIds.value]} : {}),
        ...(filters.name ? {name: filters.name} : {}),
      }
      return obj
    }
    return {}
  }, [filters])

  // Пагинация диллеров
  const [, dealerPaginationRequest, doRefetch, dealerExtraData, loading, total] = usePaginationItemsFromRequest({
    request: DealersService.getAllForModal,
    count: 20,
    requestFilter: memoParseFilterParams,
  })
  // При изменении филтров идет загрузка актуального списка диллеров
  const {ref, entry} = useInView();

  const [filterIsBeingSet, setFilterIsBeingSet] = useState(false)
  useEffect(() => {
    if(!filterValueIsSet(filters)) setFilterIsBeingSet(false)
    if(filterValueIsSet(filters) || filterIsBeingSet) {
      doRefetch()
      setFilterIsBeingSet(true)
    }
  }, [filters])

  // IntersectionObserver
  useEffect(() => {
    if (!isAllChecked) {
      if (entry?.isIntersecting) {
        dealerPaginationRequest()
        if (dealerExtraData !== undefined) {
          const dealerData = dealerDataConverter(dealerExtraData)
          dispatch(setBindedDealer(dealerData))
        }
      }
    }
  }, [entry])


  useEffect(() => {
    if (dealerExtraData !== undefined) {
      const dealerData = dealerDataConverter(dealerExtraData)
      dispatch(setBindedDealer(dealerData))
    }
  }, [dealerExtraData])

  // При первом рендера отключаем все ранее отмеченнеы чекбоксы и сбрасываем фильтры
  useEffect(() => {
    dispatch(uncheckAllBindedDealers())
    dispatch(setBindedDealersFilters({}))
  }, [])
  return (
    <UIContainer.ContentContainer
      header={'привязать дилера'}
      loading={loading}
    >
      <form className={s.container}>
        <UIField.Input
          customInputStyle={{padding: "26px 0 12px"}}
          name={'name'}
          control={control}
          placeholder={'Поиск по имени ДЦ'}
        />
        <div className={s.selectList}>
          <UIField.Select
            control={control}
            selectName={'ФО'}
            name={"stateCode"}
            options={stateRowsSelect}
            withClearOption
            isFilter
            paginateTrigger={statePaginateTrigger}
            filterSetter={setStateFilter}
            onHandleChange={filterDealers}
          />
          <UIField.Select
            control={control}
            selectName={'Область'}
            name={"distName"}
            options={distRowsSelect}
            withClearOption
            isFilter
            paginateTrigger={distPaginateTrigger}
            filterSetter={setDistFilter}
            onHandleChange={filterDealers}
          />
          <UIField.Select
            control={control}
            selectName={'Город'}
            name={"cityName"}
            options={cityRowsSelect}
            withClearOption
            isFilter
            paginateTrigger={cityPaginateTrigger}
            filterSetter={setCityFilter}
            onHandleChange={filterDealers}
          />
          <UIField.Select
            control={control}
            selectName={'Зона'}
            name={"groupIds"}
            options={dealerGroupRowsSelect}
            withClearOption
            isFilter
            paginateTrigger={dealerGroupPaginateTrigger}
            filterSetter={setDealerGroupFilter}
            onHandleChange={filterDealers}
          />
        </div>
        <UIModal.ListContainer isEmpty={false}>
          {
            dealers?.map((dealer: IBindedDealer) => (
              <div className={s.checkboxItem} key={dealer.id}>
                <UICheckbox
                  id={String(dealer.id)}
                  value={formData.dealers.find((el: IDealer & IIdentifier) => el.id === dealer.id)}
                  style={{marginRight: '8px'}}
                  handler={() => handleToggleCheckbox(dealer)}
                />
                <label htmlFor={String(dealer.id)}>
                  {dealer.name}
                </label>
              </div>
            ))
          }
          <div ref={ref} style={{width: "100%", height: "0px"}}></div>
        </UIModal.ListContainer>
        <UIModal.Footer
          handleCheck={handleCheckAll}
          handleUncheck={handleUncheckAll}
          handleBack={removeModal}
          handleClearFilters={clearFilterInputs}
        />
      </form>
    </UIContainer.ContentContainer>
  )
}

export default BindDealerModal
