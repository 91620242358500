import {UIContainer} from 'components/UI/UIContainer'
import StatisticCard from 'components/StatisticCard'

import {useAppSelector} from 'hooks/redux'
import {selectForms} from 'store/statistic/reselect'

import sCommon from "../sCommon.module.css"
import s from "../../../pages/Main/s.module.css"

const Status = () => {

  const forms = useAppSelector(selectForms)

  return (
    <UIContainer.ContentContainer header={'Статус'} containerAdditionClass={sCommon.container}>
      <div className={s.statisticContainer}>
        {forms.length > 0 && forms.map((el, i) => { return <StatisticCard key={i} count={el.count} name={el?.status?.name}/> })}
      </div>
    </UIContainer.ContentContainer>
  );
};

export default Status;
