import React, {useEffect, useState} from 'react';
import s from './s.module.css'
import {UIButton} from "../UI/UIButton";
import {ReactComponent as AddIcon} from "assets/svg/templateTool/add.svg";
import {ReactComponent as GroupIcon} from "assets/svg/templateTool/group.svg";
import {ReactComponent as ViewIcon} from "assets/svg/templateTool/view.svg";
import {ReactComponent as DownloadIcon} from "assets/svg/templateTool/download.svg";
import {ReactComponent as CopyIcon} from "assets/svg/templateTool/copy.svg";
import {ReactComponent as DeleteIcon} from "assets/svg/templateTool/delete.svg";
import {useAppDispatch} from "hooks/redux";
import {modifyQuestionList, selectPrevQuestion, selectQuestion, toolsAddQuestionHandle} from "store/formTemplateEditor";
import {useSelector} from "react-redux";
import {RootState} from "store";
import {
  getSelectedGroupId,
  getSelectedPrevQuestion,
  getSelectedQuestion,
  selectAddMainGroupStatus,
  selectToolsState
} from "store/formTemplateEditor/reselect";
import FormsService from "services/FormsService";
import {getFormTemplateByIdNoLoadHelper} from '../../lib/apiHelpers';
import {useEaseSearchParams} from 'hooks/useEaseSearchParams';
import {LOADING_STATUS, NOTIFICATOR_TYPE} from "../../lib/appConst";
import {getFileByStream} from "../../lib/getFileByStream";
import {AxiosError} from "axios";
import {addNotification} from "../../lib/addNotification";

export interface ITemplateTools {
  addGroupHandle: any
}

const TemplateTools = ({addGroupHandle}: ITemplateTools) => {

  const [disabledGroupAdd, setDisabledGroupAdd] = useState(false)
  const [loadingFormDownload, setLoadingFormDownload] = useState(false)
  const [loadingQuestionCopy, setLoadingQuestionCopy] = useState(false)
  const [{selectedTemplate}] = useEaseSearchParams()
  const dispatch = useAppDispatch()

  const {
    toolsState: {addQuestion},
    addMainGroupStatus,
    selectedQuestion,
    selectedPrevQuestion,
    selectedGroup,
  } = useSelector((store: RootState) => ({
    selectedQuestion: getSelectedQuestion(store),
    selectedPrevQuestion: getSelectedPrevQuestion(store),
    addMainGroupStatus: selectAddMainGroupStatus(store),
    toolsState: selectToolsState(store),
    selectedGroup: getSelectedGroupId(store),
  }))

  const [toolsPos, setToolsPos] = useState<number | undefined>()

  const addGroupDecorator = (callback: () => void) => () => {
    setDisabledGroupAdd(true)
    callback()
  }

  const addQuestionHandle = () => {
    if (!addQuestion) {
      dispatch(selectPrevQuestion(selectedQuestion))
    } else {
      dispatch(selectQuestion(selectedPrevQuestion))
      dispatch(selectPrevQuestion(null))
    }
    dispatch(toolsAddQuestionHandle())
  }

  const deleteQuestionHandle = async () => {
    try {
      if (selectedQuestion.id) {
        const r = await FormsService.deleteQuestion(selectedQuestion.id)
        if (r.status === 200) {
          getFormTemplateByIdNoLoadHelper()
          setToolsPos(0)
        } else {
          alert('Удалить вопрос не удалось, обратитесь в поддержку.')
        }
      }
    } catch (e) {
      const err = e as Error
      alert('Сетевая ошибка: ' + err.message)
    }
  }

  const goToPreview = () => {
    if (selectedQuestion?.id && selectedTemplate) {
      window.open(`/form-preview/${selectedTemplate}/${selectedQuestion.id}`, '_blank')
    }
  }

  const getFile = async () => {
    setLoadingFormDownload(true)
    const response = await FormsService.excelFormTemplate(selectedTemplate)
    const {id, fileName} = response.data
    const [_, downloader] = await getFileByStream(id)
    downloader(fileName)
    setLoadingFormDownload(false)
  }

  const copyQuestion = async () => {
    if(selectedQuestion.id && selectedGroup) {
      try {
        setLoadingQuestionCopy(true)
        const newQuestion = await FormsService.copyQuestion(selectedQuestion.id)
        dispatch(modifyQuestionList({targetId: selectedQuestion.id, selectedGroup, instance: newQuestion.data}))
        addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Вопрос успешно копирован'})
      } catch (e) {
        if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
        else {
          if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
          else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
        }
        setLoadingQuestionCopy(false)
      }
    }
    setLoadingQuestionCopy(false)
  }

  useEffect(() => {
    if (selectedQuestion) {
      setToolsPos(selectedQuestion.elementPos)
    }
  }, [selectedQuestion])

  useEffect(() => {
    if (addMainGroupStatus !== LOADING_STATUS.LOADING) setDisabledGroupAdd(false)
  }, [addMainGroupStatus])

  return (
    <div className={s.wrapper} style={{marginTop: toolsPos ? toolsPos - (108 + 34.8 + 16) : 0}}>
      <UIButton.CircleBtn
        handler={addQuestionHandle}
        style={{
          backgroundColor: addQuestion ? "#444" : "#fff",
          border: "1px solid #E5E9ED",
          transform: addQuestion ? 'rotate(45deg)' : 'rotate(0deg)',
          transition: 'all .3s',
        }}
        title={"Добавить вопрос"}
      >
        <AddIcon/>
      </UIButton.CircleBtn>
      <UIButton.CircleBtn
        disabled={disabledGroupAdd}
        handler={addGroupDecorator(addGroupHandle)}
        style={{backgroundColor: "#fff", border: "1px solid #E5E9ED"}}
        title={"Добавить раздел"}
      >
        <GroupIcon/>
      </UIButton.CircleBtn>
      <UIButton.CircleBtn
        style={{backgroundColor: "#fff", border: "1px solid #E5E9ED"}}
        title={"Режим просмотра"}
        handler={goToPreview}
      >
        <ViewIcon/>
      </UIButton.CircleBtn>
      <UIButton.CircleBtn
        loading={loadingFormDownload}
        style={{
          backgroundColor: "#fff",
          border: "1px solid #E5E9ED",
        }}
        handler={getFile}
        title={"Скачать анкету"}
      >
        <DownloadIcon/>
      </UIButton.CircleBtn>
      <UIButton.CircleBtn
        loading={loadingQuestionCopy}
        style={{backgroundColor: "#fff", border: "1px solid #E5E9ED"}}
        title={"Копировать вопрос"}
        handler={copyQuestion}
      >
        <CopyIcon />
      </UIButton.CircleBtn>
      <UIButton.CircleBtn
        handler={deleteQuestionHandle}
        style={{backgroundColor: "#fff", border: "1px solid #E5E9ED", color: "#848E98"}}
        title={"Удалить вопрос"}
      >
        <DeleteIcon/>
      </UIButton.CircleBtn>
    </div>
  );
};

export default TemplateTools;
