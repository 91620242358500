import {OptionData} from "models/FilterData";
import moment from "moment";

export interface IExtraSignature {
  [key: string]: OptionData[] | string | undefined | moment.Moment | null | OptionData
}

/**
 * Преобразование фильтра из параметров URL для формы фильтра
 * @param params - URL параметры
 * @param filterValues - Набор ключей стандартных значений фильтра
 */
export const parseFilterParams = <T, >(params: any, filterValues: T & IExtraSignature): T => {
  const filterKeys = Object.keys(filterValues)
  return filterKeys.reduce((acc, el) =>
      Array.isArray(filterValues[el])
        ? {...acc, [el]: params[el]?.split("_").map((el: string) => ({name: '', value: Number(el)})) || []}
        : params[el] && params[`${el}FieldName`]
          ? {
            ...acc,
            [el]: {
              value: decodeURIComponent(decodeURIComponent(params[el])).split("+").join(" "),
              name: decodeURIComponent(decodeURIComponent(params[`${el}FieldName`])).split("+").join(" "),
            },
          }
          : params[el] ? {...acc, [el]: decodeURIComponent(decodeURIComponent(params[el])).split("+").join(" ")} : {...acc, [el]: ''}
    , {} as T)
}

/**
 * Функция сброса URL параметров из defaultValue фильтра
 * (пустые данные подменяются на undefined для удаления из URL)
 * @param defaultValues - Стандартные значения формы фильтра
 */
export const resetParams = <T>(defaultValues: T & IExtraSignature): T =>
  Object.keys(defaultValues).reduce((acc, el) =>
    ({...acc, [el]: !!defaultValues[el] && !Array.isArray(defaultValues[el]) ? defaultValues[el] : undefined}), {} as T)

/**
 * Преобразование фильтра из параметров URL для параметров запроса
 * @param params - URL параметры
 * @param filterValues - Набор ключей стандартных значений фильтра
 */
export const parseFilterParamsForRequest = <T, >(params: any, filterValues: T & IExtraSignature): T => {
  const filterKeys = Object.keys(filterValues)
  return filterKeys.reduce((acc, el) =>
      Array.isArray(filterValues[el])
        ? {...acc, [el]: params[el]?.split("_").map((el: string) => el) || undefined}
        : params[el]
          ? {...acc, [el]: decodeURIComponent(decodeURIComponent(params[el])).split("+").join(" ")}
          : {...acc, [el]: undefined}
    , {} as T)
}

/**
 * Преобразование данных фильтра для URL параметров
 * @param data - Данные onSubmit формы фильтра
 */
export const serialiseParams = (data: any) => {
  return Object.keys(data).reduce((acc, el) => {
      if (typeof data[el] === "object" && !Array.isArray(data[el])) {
        if (moment.isMoment(data[el])) {
          return {
            ...acc, [el]: String(moment(data[el]))
              .replace("+", "+")
              .replace("-", "+")
              .split("+")[0]
          }
        }
        return {
          ...acc,
          [el]: data[el]?.value
            ? data[el]?.value
            : data[el]?.value === 0
              ? String(data[el]?.value)
              : data[el] || undefined,
          [`${el}FieldName`]: data[el]?.name
        }
      }
      return Array.isArray(data[el])
        ? {...acc, [el]: data[el]?.map((el: any) => el?.value || el).join('_')}
        : {
          ...acc,
          [el]: data[el]?.value ? data[el]?.value : data[el]?.value === 0 ? String(data[el]?.value) : data[el] || undefined
        }
    }
    , {})
}

