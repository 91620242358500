import React, {Dispatch, SetStateAction, useState, useEffect} from 'react'
import ClearAllImg from 'assets/svg/button/clear-all.svg'
import SelectAllImg from 'assets/svg/button/select-all.svg'
import s from '../s.module.css'
import {UIButton} from 'components/UI/UIButton'
import {OptionData} from 'models/FilterData'
import {useEaseSearchParams} from 'hooks/useEaseSearchParams'
import {removeModalItem} from 'lib/setModal'
import DealersService from 'services/DealersService'
import {addNotification} from 'lib/addNotification'
import {NOTIFICATOR_TYPE} from 'lib/appConst'
import {getUsersActionHelper} from "../../../../../lib/apiHelpers";

type Props = {
  params: {
    stateCode?: OptionData
    distName?: OptionData
    cityName?: OptionData
    name?: string
    dealerGroup?: OptionData
  }
  checkAllHandler: (isCheked: boolean) => void
  dealerIdCache: number[]
  isAllChecked: boolean | null
  setDealerIdCache: Dispatch<SetStateAction<number[]>>
  resetFilterHandler: () => void
  id: string
  totalDealers: number
}

const FooterButtons: React.FC<Props> = ({
  params,
  checkAllHandler,
  isAllChecked,
  dealerIdCache,
  setDealerIdCache,
  resetFilterHandler,
  id,
  totalDealers
}) => {
  const [_, setParams] = useEaseSearchParams()

  const setAllUsersHandler = (isCheked: boolean) => {
    checkAllHandler(isCheked)
  }


  const saveHandler = async () => {
    const getExcludesIds = () => {
      const excludeIds = {excludeIds: dealerIdCache.map(id => Number(id))}
      return excludeIds.excludeIds.length ? excludeIds : null
    }
    removeModalItem(setParams)
    if (isAllChecked !== null) {
      try {
        isAllChecked
          ? DealersService.putSetMultipleDealersToUser(id, params, getExcludesIds())
            .then(() => addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Дилеры успешно добавлены!'}))
            .then(() => getUsersActionHelper())
          : DealersService.delSetMultipleDealersToUser(id, params, getExcludesIds())
            .then(() => addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Дилеры успешно удаленны!'}))
            .then(() => getUsersActionHelper())
      } catch (error) {
        addNotification({ type: NOTIFICATOR_TYPE.error, text: 'Не удалось сохранить добавленых/снятых дилеров!', })
      }
    }
    setDealerIdCache([])
  }

  return (
    <div className={s.icons}>
      <div className={s.iconsItem} onClick={() => setAllUsersHandler(true)}>
        <img src={SelectAllImg} />
        Выбрать всё
      </div>
      <div className={s.iconsItem} onClick={() => setAllUsersHandler(false)}>
        <img src={ClearAllImg} />
        Снять выбор
      </div>
      <div className={s.footer__button_wrapper}>
        <UIButton.BorderBtn label={'Сброс фильтров'} style={{ backgroundColor: '#fff' }} type={'button'} handler={resetFilterHandler} />
        {isAllChecked !== null && (
          <UIButton.DefaultBtn
            label={'Сохранить'}
            type={'button'}
            style={{marginLeft: 'auto', marginRight: '8px'}}
            handler={saveHandler}
          />
        )}
      </div>
    </div>
  )
}

export default FooterButtons
