import UIField from 'components/UI/UIField'
import { TextEditorRaw } from 'components/UI/UIField/items/TextEditor'

import { QUESTION_TYPES, UPLOAD_FORMAT_TYPES } from 'lib/appConst'

import s from './s.module.css'
import { errorMessages } from 'lib/errorMessages';


type ContentBoxPropsType = {
  question?: any
  control: any
  errors: any
}

enum FieldType {
  textArea = 'Input',
  text = 'Input',
  number = 'Input',
  datePick = 'Datepicker',
  timePick = 'Timepicker',
  file = 'FilePicker',
  image = 'FilePicker',
  audio = 'FilePicker',
  options = 'Radio',
}

const required = {
  value: true,
  message: errorMessages.common.inputRequired,
};

const ContentBox = ({ question, control, errors }: ContentBoxPropsType) => {

  const getProps = () => {
    return {
      ...(question.typeQuestion === UPLOAD_FORMAT_TYPES.image ? { type: UPLOAD_FORMAT_TYPES.image } : {}),
      ...([QUESTION_TYPES.number, QUESTION_TYPES.audio].includes(question.typeQuestion)
        ? { type: question.typeQuestion }
        : {}),
      ...([QUESTION_TYPES.textArea, QUESTION_TYPES.text, QUESTION_TYPES.number].includes(question.typeQuestion)
        ? { placeholder: 'Ответ' }
        : {}),
      ...(question.required ? { rules: { required } } : {}),
      ...(question && question.option ? {
        options: question.option.map((el: any) => ({
          ...el,
          label: el.textOption
        }))
      } : {})
    }
  }

  return (
    <div className={s.container}>
      {question && question.textQuestion && (
        <>
          <div className={s.titleQuestion}>
            <TextEditorRaw
              value={JSON.parse(question.textQuestion)}
              readOnly
            />
          </div>
          <div className={s.content}>
            {/*// @ts-ignore*/}
            {UIField[FieldType[question.typeQuestion]]({
              name: 'answer',
              control,
              errors,
              ...getProps(),
            })}
            <p className={s.comment}>{question.textComment}</p>
          </div>
        </>
      )}
    </div>
  )
}

export default ContentBox
