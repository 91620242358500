export const EVENTS = {

    'User' : { 
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал нового пользователя',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал профиль пользователя',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'заблокировал пользователя',
        },
        'IMPORT' : {
          'NAME': 'IMPORT_USERS',
          'MESSAGE': 'сделал запрос на импорт внутренних пользователей',
        },        
      },
    'UserToTemplateForms':{ 
        'PUT' : {
          'NAME': 'ADD_TEMPLATE_FORM',
          'MESSAGE': 'добавил шаблон формы',
        },
        'DELETE' : {
          'NAME': 'DELETE_TEMPLATE_FORM',
          'MESSAGE': 'удалил шаблон формы',
        }
      },

    'Role' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал роль',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'изменил роль',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил роль',
        }
      },

    'Status' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал статус',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'изменил статус',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил статус',
        }
      },
      
      'Form' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал анкету',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'изменил анкету',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил анкету',
        }
      },      
      'Appeal' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'подал апелляцию ',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'изменил апелляцию ',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил анкету',
        }
      },   
      'AppealAnswer' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'ответил на апелляцию ',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал комментарий',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил комментарий',
        }
      },   
      'Wave' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал волну ',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал волну',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил волну',
        }
      },    
      'TypeForm' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал тип анкеты ',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал тип анкеты',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил тип анкеты',
        }
      },     
      'KindForm' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал вид анкеты ',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал вид анкеты',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил вид анкеты',
        }
      },          
      'TemplateForm' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал шаблон анкеты ',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал шаблон анкеты ',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил шаблон анкеты ',
        }
      },       
      'FormQuestion' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал вопрос ',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал вопрос ',
        },
        'SET_NEXT_QUESTION' : {
          'NAME': 'SET_NEXT_QUESTION',
          'MESSAGE': 'для вопроса ',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил вопрос ',
        }
      },         
      'OptionQuestion' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'добавил вариант ответа',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал вариант ответа',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил вариант ответа',
        }
      },         
      'MainGroup' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал основной раздел ',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал основной раздел',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил основной раздел',
        }
      },   
      'ProcessGroup' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал группировку по процессам ',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал группировку по процессам',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил группировку по процессам',
        }
      },     
      'FactorGroup' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал группировку по факторам',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'отредактировал группировку по факторам',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил группировку по факторам',
        }
      },       
      'Dealer' : { 
        'IMPORT' : {
          'NAME': 'IMPORT_DEALERS',
          'MESSAGE': 'сделал запрос на импорт дилеров',
        },    
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': 'установил предшественника',
        },         
      },         
      'DealerGroup' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'создал группу дилеров',
        },
        'PUT' : {
          'NAME': 'EDIT',
          'MESSAGE': '',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': '',
        }
      },  
      'DealerToGroup' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': '',
        },
        'PUT' : {
          'NAME': 'SET_GROUP',
          'MESSAGE': 'установил группу ',
        },
        'DELETE' : {
          'NAME': 'DELETE_GROUP',
          'MESSAGE': 'удалил группу ',
        }
      },        
      'DealerToUser' : { 
        'GET' : {
          'NAME': 'GET',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': '',
        },
        'PUT' : {
          'NAME': 'SET_USER',
          'MESSAGE': 'установил пользователя ',
        },
        'DELETE' : {
          'NAME': 'DELETE_USER',
          'MESSAGE': 'удалил пользователя ',
        }
      },                           
      'File' : { 
        'GET_USER_EXCEL' : {
          'NAME': 'GET_USER_EXCEL',
          'MESSAGE': 'сделал запрос на получение списка пользователей в Excel',
        },          
        'GET_FORM_EXCEL' : {
          'NAME': 'GET_FORM_EXCEL',
          'MESSAGE': 'сделал запрос на получение анкет в Excel',
        },                  
      },          
      'Answer' : { 
        'GET' : {
          'NAME': '',
          'MESSAGE': '',
        },
        'POST' : {
          'NAME': 'CREATE',
          'MESSAGE': 'ответил на вопрос ',
        },
        'PUT' : {
          'NAME': '',
          'MESSAGE': '',
        },
        'DELETE' : {
          'NAME': 'DELETE',
          'MESSAGE': 'удалил все ответы на вопросы',
        }
      },         
}
