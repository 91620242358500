import React, {CSSProperties, useEffect, useState} from 'react';
import {IFile} from "models/InstanceInterfaces/IFile";
import s from './s.module.css'
import {IIdentifier} from "models/common";
import {ReactComponent as FileGlyph} from "assets/svg/glyph/file.svg";
import {ReactComponent as AudioGlyph} from "assets/svg/glyph/audio.svg";
import clsx from "clsx";
import {getFileByStream} from "lib/getFileByStream";
import Preloader from "../UI/Preloader/Preloader";

export interface IFileResolver {
  fileData: (IFile & IIdentifier)[] | (IFile & IIdentifier)
  customContainer?: CSSProperties
}
const FileResolver = ({fileData, customContainer}: IFileResolver) => {

  const isFilePack = Array.isArray(fileData)
  if(fileData === undefined) return <></>
  return (
    <div className={s.container} style={customContainer}>
      {isFilePack
        ? fileData.map(file => <FileView key={file.id} {...file} />)
        : <FileView {...fileData} />
      }
    </div>
  );
};

export default FileResolver;

const FileView = ({fileName, id}: IFile & IIdentifier) => {
  const [loading, setLoading] = useState(false)
  const [URL, setURL] = useState<string>()
  const [selectedUrl, setSelectedUrl] = useState("")
  const resolveExtension = fileName?.split('.').reverse()[0].toLowerCase()

  const selectGlyph = () => {
    switch (resolveExtension) {
      case "jpeg":
      case "jpg":
      case "png":
        return ""
      case "mp3":
        return <AudioGlyph />
      default:
        return <FileGlyph />
    }
  }

  useEffect(() => {
    setLoading(true)
    getFileByStream(id)
      .then(([url, _]) => {
        setURL(url)
        setLoading(false)
      })
  }, [])

  const handler = async () => {
    const [,downloader] = await getFileByStream(id)
    downloader(fileName)
  }

  const handleSelectURL = () => URL && setSelectedUrl(URL)
  const handleClearSelectURL = () => setSelectedUrl("")

  const Glyph = selectGlyph()
  return (
    <>
      <div className={s.wrapper}>
        {Glyph}
        {
          Glyph
            ? (
              <p className={clsx("link", s.fileName)} onClick={handler} >
                {fileName.split('.')[0]}<span>{`.${resolveExtension}`}</span>
              </p>
            )
            : (
              <div style={{height: 50, width: 40, display: "flex", justifyContent: 'center'}}>
                {
                  loading ? (
                    <Preloader height={30} width={30} />
                  ) : (
                    <img
                      onClick={handleSelectURL}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: 'cover'
                      }}
                      src={URL}
                      alt={fileName}/>
                  )
                }
              </div>
            )
        }
      </div>
      {
        selectedUrl && (
          <div className={s.imageViewModal} onClick={handleClearSelectURL}>
            {
              URL && <img onClick={e => e.preventDefault()} style={{maxHeight: "90vh"}} src={URL} alt={fileName}/>
            }
          </div>
        )
      }
    </>
  )
}
