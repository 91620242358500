import React from 'react';
import Input, {InputRaw} from "./items/Input";
import Select from "./items/select";
import SelectFetch from "./items/SelectFetch";
import TextEditor from "./items/TextEditor";
import Datepicker from "./items/Datepicker";
import Timepicker from "./items/Timepicker";
import Filepicker from './items/Filepicker';
import Checkbox from './items/Checkbox';
import Radio from './items/Radio';

const UIField = {
  Input: Input,
  InputRaw: InputRaw,
  Select: Select,
  SelectFetch: SelectFetch,
  TextEditor: TextEditor,
  Datepicker: Datepicker,
  Timepicker: Timepicker,
  FilePicker: Filepicker,
  Checkbox: Checkbox,
  Radio: Radio,
}

export default UIField
