import {createAsyncThunk} from "@reduxjs/toolkit";
import FormsService from "services/FormsService";
import {IPaginate, ISort} from 'models/common';
import { TFormsPageFilter } from 'modules/filters/FormsPageFilter';


/**
 * Получить архив анкет
 */
export const getAllFormsArchiveAction = createAsyncThunk(
  'formsArchive/getAllFormsArchive',
  async(params: IPaginate & ISort & Partial<TFormsPageFilter>): Promise<any> => {
    try {
      const response = await FormsService.getArchive(params)
      return response.data;
    } catch (e: any) {
    }
  }
)
