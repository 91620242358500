import React, {useState} from 'react'
import {UIContainer} from 'components/UI/UIContainer'
import {useForm} from 'react-hook-form'
import s from './s.module.css'
import SearchInput from './SearchInput'
import Selector from './Selector'
import DealersCheckbox from './DealersCheckbox'
import DealersService from 'services/DealersService'
import getParamsForFetch from './utils'
import {OptionData} from 'models/FilterData'

// Запрошеный функцианал существует 2 режима
// 1) при на нажатии на чекбокс сразу отправляется запрос на бек при нажатии на сохранить не чего не происходит
// 2) при нажатии на выбрать все или снять все включается другой режим. Запрос отправляется только после нажатия на кнопку сохранить
// в этом режиме нажатия отдельно на чекбоксы не отправляют запрос на бекенд, а добовляются в массив и потом после нажатия конопки сохранить отправляюстся как исключения

export const selectorsSettings = [
  {fetchMethod: DealersService.getStates, placeholder: 'ФО', name: 'stateCode'},
  {fetchMethod: DealersService.getAllDistricts, placeholder: 'Область', name: 'distName'},
  {fetchMethod: DealersService.getAllCities, placeholder: 'Город', name: 'cityName'},
  {fetchMethod: DealersService.getAllDealerGroup, placeholder: 'Зона', name: 'groupIds', isArr: true},
]
export type TParamsDealerGrous = {
  stateCode?: OptionData
  distName?: OptionData
  cityName?: OptionData
  name?: string
  groupIds?: OptionData
}

const DealerGroupsModal: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const {control, watch, reset} = useForm<TParamsDealerGrous>({
    defaultValues: {
      name: '',
      stateCode: {name: '', value: 0},
      distName: {name: '', value: 0},
      cityName: {name: '', value: 0},
      groupIds: {name: '', value: 0},
    },
  })
  const data = getParamsForFetch(watch())
  const resetFilterHandler = () => {
    reset()
  }

  return (
    <UIContainer.ContentContainer header={'Добавить дилера в группу'} loading={loading}>
      <form className={s.container}>
        <SearchInput control={control} />
        <div className={s.selectList}>
          {selectorsSettings.map(select => (
            <Selector
              name={select.name}
              isArr={select.isArr}
              control={control}
              fetchMethod={select.fetchMethod}
              placeholder={select.placeholder}
              key={select.name}
            />
          ))}
        </div>
        <DealersCheckbox params={data} setLoading={setLoading} resetFilterHandler={resetFilterHandler} />
      </form>
    </UIContainer.ContentContainer>
  )
}

export default DealerGroupsModal
