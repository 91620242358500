import {useForm} from "react-hook-form";
import FormsPageFilterView from "./FormsPageFilterDunb";
import FormsService from "services/FormsService";
import {IWave} from "models/InstanceInterfaces/IWave";
import {useState} from "react";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import {IUser, IUserFilter} from "models/InstanceInterfaces/IUser";
import UsersService from "services/UsersService";
import {Roles} from "models/Roles";
import {OptionData} from "models/FilterData";
import { IIdentifier, IResponseExtraData } from 'models/common';
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {parseFilterParams, resetParams, serialiseParams} from "lib/parseFilterParams";
import {ITypeForm} from "models/InstanceInterfaces/ITypeForm";
import {IKindForm} from "models/InstanceInterfaces/IKindForm";
import DealersService from "services/DealersService";
import {IDealer} from "models/DealersServiceModel";
import {removeModalToStuck} from "store/common";
import {useAppDispatch} from "hooks/redux";
import {ITemplateForm} from "models/InstanceInterfaces/ITemplateForm";
import {StatusService} from "services/StatusService";
import {IStatus} from "models/Status/Status";
import {getRole} from "../../../lib/getRole";

/**
 * Тип данных FormsPageFilter
 */
export type TFormsPageFilterCredentials = {
  waveIds: OptionData[],
  controlUserIds?: OptionData[],
  typeFormIds: OptionData[],
  kindFormIds: OptionData[],
  dealerIds: OptionData[],
  templateFormIds: OptionData[],
  statusIds: OptionData[],
}

export type TFormsPageFilter =  {
  waveIds: []
  controlUserIds?: [] | number[]
  typeFormIds: []
  kindFormIds: []
  dealerIds: []
  templateFormIds: []
  statusIds: []
}

export const defaultFormsFilterValues: TFormsPageFilterCredentials = {
  waveIds: [],
  controlUserIds: [],
  typeFormIds: [],
  kindFormIds: [],
  dealerIds: [],
  templateFormIds: [],
  statusIds: [],
}



/**
 * Компонент фильтр для страницы с анкетами
 * @constructor
 */
export const FormsPageFilter = () => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useEaseSearchParams()

  const resolveRoleRestrictions = () =>
    !!getRole()
      ? getRole().id === Roles.dealer ||
      getRole().id === Roles.dealerDirector
      : false

  const defaultFormsFilterValuesWithRestrictions = {
    ...defaultFormsFilterValues,
    ...!resolveRoleRestrictions() && {controlUserIds: []}
  }

  const { control, handleSubmit, reset } = useForm<TFormsPageFilterCredentials>({
    defaultValues: parseFilterParams<TFormsPageFilterCredentials>(params, defaultFormsFilterValuesWithRestrictions)
  });

  const resetHandle = () => {
    reset(defaultFormsFilterValuesWithRestrictions)
    setParams((p: any) => ({...p, page: 1, ...resetParams<TFormsPageFilterCredentials>(defaultFormsFilterValuesWithRestrictions), modalId: undefined}))
    dispatch(removeModalToStuck())
  }

  const onSubmit = (data: any) => {
    setParams((p: any) => ({...p, page: 1, ...serialiseParams(data), modalId: undefined}))
    dispatch(removeModalToStuck())
  }

  const [waveFilter, setWaveFilter] = useState('')
  const [
    waveRowsSelect,
    wavePaginateTrigger,
  ] = usePaginationItemsFromRequest<IWave>({
    request: FormsService.getAllWaves,
    filter: waveFilter,
  })

  const [controlUserFilter, setControlUserFilter] = useState('')
  const [
    controlUserRowsSelect,
    controlUserPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IUser & IIdentifier), IUserFilter>({
    request: UsersService.getAll,
    filter: controlUserFilter,
    filterField: 'fullName',
    requestFilter: {roleIds: [Roles.auditor]},
    dataConverter: (element): OptionData => ({name: element.fullName, value: element.id})
  })

  const [kindFormFilter, setKindFormFilter] = useState('')
  const [
    kindFormRowsSelect,
    kindFormPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IKindForm)>({
    request: FormsService.getAllFormKinds,
    filter: kindFormFilter,
  })

  const [typeFormFilter, setTypeFormFilter] = useState('')
  const [
    typeFormRowsSelect,
    typeFormPaginateTrigger,
  ] = usePaginationItemsFromRequest<(ITypeForm)>({
    request: FormsService.getAllFormTypes,
    filter: typeFormFilter,
  })

  const [dealerFilter, setDealerFilter] = useState('')
  const [
    dealerRowsSelect,
    dealerPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IDealer & IResponseExtraData & IIdentifier)>({
    request: DealersService.getAll,
    filter: dealerFilter,
  })


  const [templateFormFilter, setTemplateFormFilter] = useState('')
  const [
    templatesRowsSelect,
    templatesPaginateTrigger,
  ] = usePaginationItemsFromRequest<(ITemplateForm & IIdentifier & IResponseExtraData)>({
    request: FormsService.getAllFormTemplates,
    filter: templateFormFilter,
  })

  const [statusFilter, setStatusFilter] = useState('')
  const [
    statusRowsSelect,
    statusPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IStatus & IResponseExtraData & IIdentifier)>({
    request: StatusService.getAllFormsStatus,
    filter: statusFilter,
  })

  // на автоматизацию постройки селектов не было времени
  return (
    <FormsPageFilterView
      control={control}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      resetHandle={resetHandle}

      waveRowsSelect={waveRowsSelect}
      wavePaginateTrigger={wavePaginateTrigger}
      setWaveFilter={setWaveFilter}

      controlUserRowsSelect={controlUserRowsSelect}
      controlUserPaginateTrigger={controlUserPaginateTrigger}
      setControlUserFilter={setControlUserFilter}
      controlUserDisabled={resolveRoleRestrictions()}

      typeFormRowsSelect={typeFormRowsSelect}
      typeFormPaginateTrigger={typeFormPaginateTrigger}
      setTypeFormFilter={setTypeFormFilter}

      kindFormRowsSelect={kindFormRowsSelect}
      kindFormPaginateTrigger={kindFormPaginateTrigger}
      setKindFormFilter={setKindFormFilter}

      dealerRowsSelect={dealerRowsSelect}
      dealerPaginateTrigger={dealerPaginateTrigger}
      setDealerFilter={setDealerFilter}

      templatesRowsSelect={templatesRowsSelect}
      templatesPaginateTrigger={templatesPaginateTrigger}
      setTemplateFormFilter={setTemplateFormFilter}

      statusRowsSelect={statusRowsSelect}
      statusPaginateTrigger={statusPaginateTrigger}
      setStatusFilter={setStatusFilter}
    />
  )
}
