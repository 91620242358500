import {createAsyncThunk} from "@reduxjs/toolkit";
import FormsService from "services/FormsService";
import {IPaginate, ISort} from 'models/common';
import { TFormsPageFilter } from 'modules/filters/FormsPageFilter';


/**
 * Получить анкеты
 */
export const getAllFormsAction = createAsyncThunk(
  'forms/getAllForms',
  async(params: IPaginate & ISort & Partial<TFormsPageFilter>): Promise<any> => {
    try {
      const response = await FormsService.getAll(params)
      console.log(response, 'response')
      return response.data;
    } catch (e: any) {
    }
  }
)

/**
 * Получить анкеты
 */
export const getFormTypesAction = createAsyncThunk(
  'forms/getFormTypes',
  async(params: {page: number, count: number, name: string}): Promise<any> => {
    try {
      const response = await FormsService.getAllFormTypes(params);
      return response.data;
    } catch (e: any) {
    }
  }
)
