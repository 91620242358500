import React, {useContext, useEffect} from 'react';
import s from "./s.module.css";
import UIField from "components/UI/UIField";
import UISwitch from "components/UI/UISwitch";
import {UIButton} from "components/UI/UIButton";
import {ReactComponent as ApplyIcon} from "assets/svg/button/apply.svg";
import {useForm} from "react-hook-form";
import {OptionData} from "models/FilterData";
import FormsService from "services/FormsService";
import {IWithEnumKey, QUESTION_TYPES, QUESTION_TYPES_NAMES} from "lib/appConst";
import {errorMessages} from "lib/errorMessages";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {string} from "yup";
import {IGroup} from "models/IGroup";
import {AxiosResponse} from "axios";
import {IIdentifier, IResponseExtraData} from "models/common";
import QuestionsByType from "./QuestionsByType";
import {IQuestionOption} from "models/InstanceInterfaces/IQuestionOption";
import {SelectContext} from "pages/FormsTemplateEditor";

const resolverSchema = yup.object({
  num:	string().required(errorMessages.common.inputRequired),
})

interface _IForm {
  num: string
  typeQuestion: (OptionData & IWithEnumKey)
  processGroup: OptionData
  factorGroup: OptionData
  textQuestion: any
  textComment: string
  required: boolean

}
export interface IQuestionForm {
  isCreate?: boolean
  onSubmit: any
  questionTypeAsList: any
  templateId: any
  loading?: boolean
  questionId?: number
  children?: JSX.Element
  questionOptions?: (IQuestionOption & IIdentifier)[] | []
  defaultValues?: any
}

const QuestionForm = ({
                        isCreate,
                        loading,
                        questionId,
                        onSubmit,
                        defaultValues,
                        questionTypeAsList,
                        templateId,
                        questionOptions,
                        children
                      }: IQuestionForm) => {

  const defaultValuesLocal = {
    num: '',
    required: false,
    typeQuestion: {
      value: 0,
      enumKey: QUESTION_TYPES.textArea,
      name: QUESTION_TYPES_NAMES.textArea,
    },
    textComment: '',
    //@ts-ignore
    textQuestion: [{
      //@ts-ignore
      type: 'paragraph',
      children: [{text: ''}]
    }]
  }
  const {control, handleSubmit, setValue, reset, formState: {errors, isSubmitSuccessful}, watch} = useForm<_IForm>({
    defaultValues: defaultValues || defaultValuesLocal,
    resolver: yupResolver(resolverSchema)
  })

  useEffect(() => {
    if(isSubmitSuccessful && isCreate) {
      reset(defaultValuesLocal)
    }
  }, [isSubmitSuccessful])

  const formType = watch('typeQuestion')


  const createGroup = (
    templateFormId: number,
    request: (data: IGroup) => Promise<AxiosResponse<IGroup & IIdentifier & IResponseExtraData>>,
    refetch: any,
    targetFormValue: 'processGroup' | 'factorGroup'
  ) => async (name: string) => {
    try {
      const {data} = await request({templateFormId, name})
      if (data && data.id && data.name) {
        setValue(targetFormValue, {value: data.id, name: data.name})
        refetch()
      }
    } catch (e) {
    }
  }
  const {
    process: {
      setTemplateProcessFilter,
      templateProcessRowsSelect,
      templateProcessPaginateTrigger,
      doRefetchProcess
    },
    factor: {
      setTemplateFactorFilter,
      templateFactorRowsSelect,
      templateFactorPaginateTrigger,
      doRefetchFactor
    }
  } = useContext(SelectContext)

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={s.formWrapper} style={{...loading ? {opacity: .3} : {}}}>
      {children}
      <div className={s.row} style={{gap: 16, alignItems: 'flex-start'}}>
        <UIField.Input
          disabled={loading}
          name={'num'}
          control={control}
          placeholder={"Номер"}
          customStyle={{ flexGrow: 1, width: 70, paddingTop: 11 }}
          maxLength={6}
          errors={errors}
        />
        <UIField.Select
          disabled={loading}
          selectName={'Тип ответа'}
          options={questionTypeAsList}
          name={'typeQuestion'}
          isFilter={true}
          control={control}
          customStyle={{flexGrow: 4, width: 'auto'}}
        />
        <UIField.Select
          disabled={loading}
          selectName={'Груп. по процессам'}
          options={templateProcessRowsSelect}
          filterSetter={setTemplateProcessFilter}
          paginateTrigger={templateProcessPaginateTrigger}
          refetch={doRefetchProcess}
          name={'processGroup'}
          isFilter={true}
          control={control}
          customStyle={{flexGrow: 4, width: 'auto'}}
          createHandler={createGroup(templateId, FormsService.createProcessGroup, doRefetchProcess, "processGroup")}
        />
        <UIField.Select
          disabled={loading}
          selectName={'Груп. по факторам'}
          options={templateFactorRowsSelect}
          filterSetter={setTemplateFactorFilter}
          paginateTrigger={templateFactorPaginateTrigger}
          refetch={doRefetchFactor}
          name={'factorGroup'}
          isFilter={true}
          control={control}
          customStyle={{flexGrow: 4, width: 'auto'}}
          createHandler={createGroup(templateId, FormsService.createFactorGroup, doRefetchFactor, 'factorGroup')}
        />
      </div>
      <div className={s.row}>
        <UIField.TextEditor
          readOnly={loading}
          name={'textQuestion'}
          placeholder={"Текст вопроса"}
          control={control}/>
      </div>
      <div className={s.row}>
        {defaultValues && questionId
          ? _resolveQuestionAdditionFields(formType.enumKey, questionId, questionOptions)
          : formType.enumKey === QUESTION_TYPES.options && <p className={s.attention}>Для добавления вариантов ответа сохраните вопрос‍</p>}
      </div>
      <div className={s.row}>
        <UIField.Input
          disabled={loading}
          name={'textComment'}
          control={control}
          placeholder={"Комментарий для тайного покупателя"}
        />
      </div>
      <div className={s.row} style={{marginTop: 22, justifyContent: 'space-between', alignItems: 'center'}}>
        <UISwitch
          name={'required'}
          control={control}
          label={"Обязательный вопрос"}
        />
        <UIButton.EmptyBtn
          disabled={loading}
          type={"submit"}
          rightIcon={<ApplyIcon/>}
        />
      </div>
    </form>
  );
};

export default QuestionForm;

function _resolveQuestionAdditionFields(type: string, questionId: number, questionOptions?: (IQuestionOption & IIdentifier)[]) {
  switch (type) {
    case QUESTION_TYPES.options:
      return <QuestionsByType.OptionsList
        formQuestionId={questionId}
        questionOptions={questionOptions}
      />
  }
}
