import React, {useEffect, useState} from 'react';
import {UIContainer} from "components/UI/UIContainer";
import UIPageTitle from "components/UI/UIPageTitle";
import {FormsTemplateControlGroup} from "lib/tableConfigs/controlsPakages/controls";

import s from './s.module.css'
import TemplateSelector from "components/TemplateSelector";
import TemplateFormToAdd from "components/TemplateFormToAdd";
import TemplateTools from "components/TemplateTools";
import {ITemplateFormTransfer} from "models/InstanceInterfaces/ITemplateForm";
import {
  createFormTemplate,
  createTemplateMainGroup,
  editFormTemplate,
  getAllFormTemplates,
  getFormTemplateById
} from "store/formTemplateEditor/thunk";
import {useAppDispatch} from "hooks/redux";
import {useSelector} from "react-redux";
import {selectCurrentTemplateInstance, selectMode} from "store/formTemplateEditor/reselect";
import {RootState} from "store";
import {
  resetSelectedGroup,
  resetSelectedQuestion,
  resetSelection,
  resetTemplate,
  switchMode,
  TEMPLATE_EDITOR_MODS, toolsAddQuestionHandle
} from "store/formTemplateEditor";

import FormsTemplateEditorQuestionGroup from "modules/FormsTemplateEditorQuestionGroup";
import {LOADING_STATUS} from "lib/appConst";
import {setModal} from "lib/setModal";
import {ModalsEnum} from "modules/ModalController/modalManager";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import {IGroup} from "models/IGroup";
import FormsService from "services/FormsService";
import {OptionData} from "models/FilterData";


export interface ISelectContext {
  process: {
    setTemplateProcessFilter: () => void
    templateProcessRowsSelect: OptionData[]
    templateProcessPaginateTrigger: () => void
    doRefetchProcess: () => void
  },
  factor: {
    setTemplateFactorFilter: () => void
    templateFactorRowsSelect: OptionData[]
    templateFactorPaginateTrigger: () => void
    doRefetchFactor: () => void
  }
}

export const SelectContext = React.createContext<ISelectContext>({
  process: {
    setTemplateProcessFilter: () => {
    },
    templateProcessRowsSelect: [],
    templateProcessPaginateTrigger: () => {
    },
    doRefetchProcess: () => {
    }
  },
  factor: {
    setTemplateFactorFilter: () => {
    },
    templateFactorRowsSelect: [],
    templateFactorPaginateTrigger: () => {
    },
    doRefetchFactor: () => {
    }
  }
})
/**
 * Страница для отображения анкет
 * @constructor
 */
const FormsTemplateEditor = () => {
  const [{selectedTemplate}, setParams] = useEaseSearchParams()
  const dispatch = useAppDispatch()

  const {
    mode,
    currentTemplate,
  } = useSelector((store: RootState) => ({
    mode: selectMode(store),
    currentTemplate: selectCurrentTemplateInstance(store),
  }))

  const isWaitMode = mode === TEMPLATE_EDITOR_MODS.AWAIT
  const isAddMode = mode === TEMPLATE_EDITOR_MODS.ADD
  const isEditMode = mode === TEMPLATE_EDITOR_MODS.EDIT

  const createThenUpdate = (data: ITemplateFormTransfer) => {
    dispatch(createFormTemplate(data))
      .then((r: any) => {
        dispatch(resetSelectedGroup())
        dispatch(getAllFormTemplates())
        setParams((p: any) => ({...p, selectedTemplate: r.payload?.id}))
        dispatch(switchMode(TEMPLATE_EDITOR_MODS.EDIT))
        addGroupHandle({templateFormId: r.payload?.id})()
        dispatch(getFormTemplateById(r.payload?.id))
      })
  }

  const editThenUpdate = (data: ITemplateFormTransfer) => {
    dispatch(editFormTemplate(data))
      .then(() => {
        Promise.all([
          dispatch(getAllFormTemplates()),
        ])
          .then(() => {
            dispatch(switchMode(TEMPLATE_EDITOR_MODS.EDIT))
            dispatch(getFormTemplateById(selectedTemplate))
          })
      })
  }

  const selectTemplate = (id: number) => {
    setParams((p: any) => ({...p, selectedTemplate: id}))
  }

  useEffect(() => {
    switch (mode) {
      case TEMPLATE_EDITOR_MODS.ADD:
        dispatch(resetSelection())
    }
  }, [mode])

  useEffect(() => {
    if (!selectedTemplate) {
      dispatch(resetTemplate())
    } else {
      dispatch(getFormTemplateById(selectedTemplate))
    }
  }, [selectedTemplate])

  useEffect(() => {
    if(!currentTemplate.error && currentTemplate.instance) {
      dispatch(switchMode(TEMPLATE_EDITOR_MODS.EDIT))
    }
  }, [currentTemplate])

  useEffect(() => {
    return () => {
      dispatch(switchMode(TEMPLATE_EDITOR_MODS.AWAIT))
      dispatch(resetTemplate())
    }
  }, [])

  const addGroupHandle = ({templateFormId}: { templateFormId: number }) => async () => {
    await dispatch(createTemplateMainGroup({name: '', code: '', templateFormId}))
  }

  const [waitRender, setWaitRender] = useState(true)
  const [loadStart, setLoadStart] = useState(false)

  useEffect(() => {
    setTimeout(() => setWaitRender(false), 300)
  }, [])

  useEffect(() => {
    if (currentTemplate.status === LOADING_STATUS.LOADING) {
      setLoadStart(true)
    }
    if (currentTemplate.status === LOADING_STATUS.IDLE && loadStart) {
      const unsub = setTimeout(() => setWaitRender(false), 200)
      return () => clearTimeout(unsub)
    } else {
      setWaitRender(true)
    }
  }, [currentTemplate, loadStart])

  const [templateProcessFilter, setTemplateProcessFilter] = useState('')
  const [templateFactorFilter, setTemplateFactorFilter] = useState('')

  const [
    templateProcessRowsSelect,
    templateProcessPaginateTrigger,
    doRefetchProcess
  ] = usePaginationItemsFromRequest<IGroup>({
    id: currentTemplate.instance?.id || 0,
    request: FormsService.getProcessGroup,
    filter: templateProcessFilter
  })

  const [
    templateFactorRowsSelect,
    templateFactorPaginateTrigger,
    doRefetchFactor
  ] = usePaginationItemsFromRequest<IGroup>({
    id: currentTemplate.instance?.id || 0,
    request: FormsService.getFactorGroup,
    filter: templateFactorFilter
  })

  const contextData = {
    process: {
      setTemplateProcessFilter,
      templateProcessRowsSelect,
      templateProcessPaginateTrigger,
      doRefetchProcess
    },
    factor: {
      setTemplateFactorFilter,
      templateFactorRowsSelect,
      templateFactorPaginateTrigger,
      doRefetchFactor
    }
  } as ISelectContext

  const groupByProcessHandler = () => setModal(ModalsEnum.GroupingModificationModal, {type: 'kind'})

  const groupByProcessFact = () => setModal(ModalsEnum.GroupingModificationModal, {type: 'factor'})

  const addTemplateHandler = () => {
    dispatch(resetSelectedQuestion())
    dispatch(switchMode(TEMPLATE_EDITOR_MODS.ADD))
    dispatch(toolsAddQuestionHandle(false))
    setParams((p: any) => ({...p, selectedTemplate: undefined}))
  }

  const isLoading = currentTemplate.status === LOADING_STATUS.LOADING

  return (
    <UIContainer.PageContainer>
      <>
        <UIPageTitle
          label={"Шаблоны анкет"}
          Controls={FormsTemplateControlGroup(
            {
              groupByProcessHandler,
              groupByProcessFact,
              addTemplateHandler,
            })}
        />
        <div className={s.wrapper}>
          <TemplateSelector
            selected={selectedTemplate}
            setter={selectTemplate}
          />
          {isWaitMode && <Plug/>}
          {
            !isWaitMode && (
              <div className={s.editor}>
                {isAddMode && <TemplateFormToAdd onCreate={createThenUpdate}/>}
                {isEditMode && !isLoading && (
                  <SelectContext.Provider value={contextData}>
                    <>
                      {
                        currentTemplate.instance && (
                          <TemplateFormToAdd
                            onEdit={editThenUpdate}
                            templateHeaderData={currentTemplate.instance}
                            loading={currentTemplate.status}/>
                        )
                      }
                      {selectedTemplate
                        && currentTemplate.instance
                        && <FormsTemplateEditorQuestionGroup
                          waitRender={waitRender}
                          templateInstance={currentTemplate.instance}
                          loadingStatus={currentTemplate.status}
                          templateId={selectedTemplate}/>
                      }
                    </>
                  </SelectContext.Provider>
                )}
              </div>
            )
          }

          {isEditMode
            && selectedTemplate
            && <TemplateTools addGroupHandle={addGroupHandle({templateFormId: selectedTemplate})}/>}
        </div>
      </>
    </UIContainer.PageContainer>
  );
};

const Plug = () => (
  <div className={s.content}>
    <p>
      Выберите шаблон из списка слева или создайте новый
    </p>
  </div>
)

export default FormsTemplateEditor;
