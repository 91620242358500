import {NOTIFICATOR_TYPE} from "./appConst";
import {store} from "../store";
import {setNotification} from "store/common";

export interface IAddNotificationSignature {
  text?: string,
  type: NOTIFICATOR_TYPE
}

export const addNotification = ({
                                  text,
                                  type
                                }: IAddNotificationSignature) => {
  store.dispatch(setNotification({id: Math.random(), text, type}))
}
