import React, {useCallback, useEffect, useState} from 'react';
import {UIContainer} from "components/UI/UIContainer";
import UIField from "components/UI/UIField";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import FormsService from "services/FormsService";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {useSelector} from "react-redux";
import {selectEditUser} from "store/users/reselect";
import {useAppDispatch} from "hooks/redux";
import UsersService from "services/UsersService";
import {useDebounce} from "hooks/useDebounce";
import {removeModalItem} from "lib/setModal";
import {useInView} from "react-intersection-observer";
import {TemplateFormItem} from './items'
import {GroupItem} from "../../DealerGroupModal";
import {addNotification} from "lib/addNotification";
import {NOTIFICATOR_TYPE} from "lib/appConst";
import {changeTemplateFormByUser,} from "store/users";
import {UIModal} from "components/UI/UIModal";
import {getUserAction} from "store/users/thunk";
import {getUsersActionHelper} from "lib/apiHelpers";


const BindUserToTemplateForms = () => {
  const dispatch = useAppDispatch()
  const [{userId}, setParams] = useEaseSearchParams()
  const editUserData = useSelector(selectEditUser);
  const [data, setData] = useState<any>([])
  const [searchName, setSearchName] = useState<string>('')
  const {ref, entry} = useInView()
  const [disabledItems, setDisabledItems] = useState<number[]>([])
  const debouncedSearch = useDebounce<string>(searchName, 500)

  const [, paginationTrigger, doRefetch, extraData, loading] = usePaginationItemsFromRequest({
    request: FormsService.getAllFormTemplates,
    count: 20,
    requestFilter: {name: searchName}
  })

  useEffect(() => {
    userId && dispatch(getUserAction(userId))
  }, [userId])

  useEffect(() => {
    setData(extraData)
  }, [extraData])

  useEffect(() => {
    doRefetch()
  }, [debouncedSearch])

  useEffect(() => {
    if (entry?.isIntersecting) {
      paginationTrigger()
    }
  }, [entry])

  const removeModal = () => {
    removeModalItem(setParams)
  }

  const onHandleSelect = useCallback(async (value: number, status: boolean, item: Partial<GroupItem>) => {
    await setDisabledItems(disabledItems => [...disabledItems, value])
    const currentId = userId || editUserData.id
    try {
      status
        ? await UsersService.addTemplateFormToUser(currentId, value).then(() => getUsersActionHelper())
        : await UsersService.removeTemplateFormToUser(currentId, value).then(() => getUsersActionHelper())
      addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Изменение прошло успешно'})
      await dispatch(changeTemplateFormByUser(item))
      await setDisabledItems([])
    } catch (err: any) {
      addNotification({type: NOTIFICATOR_TYPE.error, text: err.response.data.message})
      await setDisabledItems([])
    }
  }, [])

  const isEmpty = data.length === 0 && !loading

  return (
    <UIContainer.ContentContainer
      header={'Привязать к шаблону анкеты'}
      loading={loading}
    >
      <UIField.InputRaw
        customInputStyle={{padding: "26px 0 12px"}}
        name="name"
        placeholder={'Поиск по шаблонам'}
        value={searchName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value)}
      />
      <UIModal.ListContainer isEmpty={isEmpty}>
        {
          !!data.length &&
            data.map(({name, id, ...el}: any) => (
              <TemplateFormItem
                key={id}
                checked={
                  Array.isArray(editUserData.TemplateForms)
                    ? editUserData.TemplateForms.map((i: any) => i.id).includes(id)
                    : false
                }
                disabled={disabledItems.includes(id)}
                onHandleSelect={onHandleSelect}
                {...{id, name, ...el}}
              />
            ))
        }
        <div ref={ref} style={{width: "100%", height: "10px"}}/>
      </UIModal.ListContainer>

      <UIModal.Footer handleBack={removeModal}/>
    </UIContainer.ContentContainer>
  );
};

export default BindUserToTemplateForms
