import Forms from 'pages/Forms';
import Todos from 'pages/Todos';
import Help from 'pages/Help';
import Appeals from 'pages/Appeals';
import Dealers from 'pages/Dealers';
import Users from 'pages/Users';
import Logging from 'pages/Logging';
import AnalyticsReport from 'pages/AnalyticsReport';
import AnalyticsAppeals from 'pages/AnalyticsAppeals';
import AnalyticsGraph from 'pages/AnalyticsGraph';
import AnalyticsUnload from 'pages/AnalyticsUnload';
import DealersGroups from 'pages/DealersGroups';
import FormsTemplateEditor from 'pages/FormsTemplateEditor';
import FormPass from 'pages/FormPass';
import { Roles } from 'models/Roles';
import { INavLink } from '../Navigation';
import { defaultTableElementsCounts } from '../TableGenerator';
import FormDetail from "pages/FormDetail";
import AnalitycsForm from 'pages/AnalitycsForm';
import FormPreview from 'pages/FormPreview';
import { appealOptions, calculationOptions, groupOptions } from 'lib/appOptions';
import FormsArchive from "../../pages/FormArchive";

export const defaultPaginationParams = `?page=1&count=${defaultTableElementsCounts[0]}`
export const defaultPaginationFormEditorParams = `?page=1&count=10`
export const defaultAnalyticParams = `${defaultPaginationParams}&group=${groupOptions[2].value}&groupFieldName=${groupOptions[2].name}&typeCalculation=${calculationOptions[0].value}&typeCalculationFieldName=${calculationOptions[0].name}`
export const defaultGraphParams = `${defaultPaginationParams}&typeCalculation=${calculationOptions[0].value}&typeCalculationFieldName=${calculationOptions[0].name}&withoutAppeal=0&withoutAppealFieldName=${appealOptions[0].name}`

export const getRoutes = (roleId: number): INavLink[] => {
  const forAllRoles = Object.values(Roles)
  const forAdmin = [
    Roles.systemAdmin,
    Roles.agencyAdmin,
  ]
  const routes = [
    {path: '/forms', defaultParams: defaultPaginationParams, element: Forms, label: 'Анкеты', availableForRoles: [
        ...forAdmin,
        Roles.dealer,
        Roles.dealerDirector,
        Roles.agencyManager,
        Roles.controller,
        Roles.regionalManager,
        Roles.departmentChief,
        Roles.divisionChief,
        Roles.processingCenter,
      ]},
    {path: '/formsArchive', defaultParams: defaultPaginationParams, element: FormsArchive, label: 'Архив анкеты', availableForRoles: [Roles.auditor]},
    {path: '/formDetail/:id', element: FormDetail, label: 'Анкета', availableForRoles: forAllRoles, inset: true},
    {path: '/formsTemplate', defaultParams: defaultPaginationFormEditorParams, element: FormsTemplateEditor, label: 'Шаблоны анкет', availableForRoles: [
        ...forAdmin
      ]},
    {path: '/form-preview/:templateFormId/:questionId', element: FormPreview, label: 'Предпросмотр анкеты', availableForRoles: forAdmin, inset: true},
    { path: '/', label: 'Задания', defaultParams: defaultPaginationParams, element: Todos, availableForRoles: [Roles.auditor] },
    {path: '/help', defaultParams: defaultPaginationParams, element: Help, label: 'Помощь', availableForRoles: [Roles.auditor]},
    {path: '/form-pass/:id', element: FormPass, label: 'Прохождение анкеты', availableForRoles: [...forAdmin, Roles.auditor], inset: roleId !== 5 },
    {
      path: '/appeals', defaultParams: defaultPaginationParams, element: Appeals, label: 'Апелляции', availableForRoles: [
        ...forAdmin,
        Roles.dealerDirector,
        Roles.agencyManager,
        Roles.regionalManager,
        Roles.divisionChief,
        Roles.processingCenter,
      ]
    },
    {path: '/dealers/groups', defaultParams: defaultPaginationParams, element: DealersGroups, label: 'Дилеры', availableForRoles: forAdmin, inset: true},
    {path: '/dealers', defaultParams: defaultPaginationParams, element: Dealers, label: 'Дилеры', availableForRoles: forAdmin},
    {path: '/users', defaultParams: defaultPaginationParams, element: Users, label: 'Пользователи', availableForRoles: [...forAdmin, Roles.agencyManager]},
    {path: '/logging', defaultParams: defaultPaginationParams, element: Logging, label: 'Логирование', availableForRoles: forAdmin},
    {
      path: '/analytics/reports', defaultParams: defaultAnalyticParams, element: AnalyticsReport, label: 'Аналитика', availableForRoles: [
        ...forAdmin,
        Roles.dealerDirector,
        Roles.dealer,
        Roles.agencyManager,
        Roles.regionalManager,
        Roles.divisionChief,
        Roles.processingCenter,
      ]
    },
    {
      path: '/analytics/appeals', element: AnalyticsAppeals, label: 'Аналитика по аппеляциям', availableForRoles: [
        ...forAdmin,
        Roles.dealerDirector,
        Roles.dealer,
        Roles.agencyManager,
        Roles.regionalManager,
        Roles.divisionChief,
        Roles.processingCenter,
      ], inset: true
    },
    {
      path: '/analytics/graph', element: AnalyticsGraph, defaultParams: defaultGraphParams, label: 'График', availableForRoles: [
        ...forAdmin,
        Roles.dealerDirector,
        Roles.dealer,
        Roles.agencyManager,
        Roles.regionalManager,
        Roles.divisionChief,
        Roles.processingCenter,
      ], inset: true
    },
    {
      path: '/analytics/unload', element: AnalyticsUnload, defaultParams: defaultPaginationParams, label: 'Выгрузка', availableForRoles: [
        ...forAdmin,
        Roles.dealerDirector,
        Roles.dealer,
        Roles.agencyManager,
        Roles.regionalManager,
        Roles.divisionChief,
        Roles.processingCenter,
      ], inset: true
    },
    {
      path: '/analytics/form', element: AnalitycsForm, label: 'Аналитика', availableForRoles: [
        ...forAdmin,
        Roles.dealerDirector,
        Roles.agencyManager,
        Roles.regionalManager,
        Roles.divisionChief,
        Roles.processingCenter,
      ], inset: true
    }
  ]
  return routes.filter(el => el.availableForRoles.includes(roleId))
}

export const getNavigation = (roleId: number) => {
  if (roleId === Roles.auditor) {
    return [
      { path: '/', label: 'Задания', defaultParams: defaultPaginationParams },
      { path: '/formsArchive', label: 'Архив', defaultParams: defaultPaginationParams },
      { path: '/help', label: 'Помощь', defaultParams: defaultPaginationParams },
    ]
  }

  return getRoutes(roleId)
}
