import React, {CSSProperties, useEffect, useState} from 'react';
import s from './s.module.css'
import clsx from "clsx";
import {IIdentifier} from "models/common";
import {IQuestion} from "models/IQuestion";
import {TextEditorRaw} from "components/UI/UIField/items/TextEditor";
import {IForm} from "models/InstanceInterfaces/IForm";
import {QUESTION_TYPES} from "lib/appConst";
import {IGroup} from "models/IGroup";
import Preloader from "components/UI/Preloader/Preloader";
import FileResolver from "components/FileResolver";
import {setModal} from "lib/setModal";
import {ModalsEnum} from "modules/ModalController/modalManager";
import {IAppeal} from "models/InstanceInterfaces/IAppeal";
import moment from "moment";
import {IUser} from "models/InstanceInterfaces/IUser";
import {useAppDispatch} from "hooks/redux";
import {getTemplateToDetail} from "store/formDetail/thunk";
import {useSelector} from "react-redux";
import {selectFormTemplateDetail} from "store/formDetail/reselect";
import {RootState} from "store";
import {useLocation} from 'react-router-dom';
import {IsAgency, IsAgencyManager, IsAuditor, IsDealer, IsSystemAdmin} from 'lib/RolesModel';
import {IStatus} from 'models/Status/Status';
import {getShortFIO} from "lib/getShortFIO";
import FullSizeCenterWrapper from "../../../components/UI/FullSizeCenterWrapper";

export interface IQuestionBlock {
  form: (IForm & IIdentifier)
}

export const getPercentFormDetail = (staticScore: number, dynamicScore: number) => staticScore !== 0
  ? Number((-1 * (100 - 100 - (dynamicScore / staticScore) * 100))).toFixed(2)
  : 0

const QuestionBlock = ({form}: IQuestionBlock) => {
  const dispatch = useAppDispatch()
  const {template} = useSelector((store: RootState) => ({
    template: selectFormTemplateDetail(store)
  }))
  const [totalScore, setTotalScore] = useState(0)
  const [totalStaticScore, setTotalStaticScore] = useState(0)

  const handleTotalScore = (val: number) => setTotalScore(val)
  const handleTotalStaticScore = (val: number) => setTotalStaticScore(val)

  useEffect(() => {
    dispatch(getTemplateToDetail(form.templateFormId))
  }, [])

  const isActualData = form.templateFormId === template?.id

  return template && isActualData ? (
    <div className={s.container}>
      <table className={s.table}>
        <thead>
        <tr>
          <th colSpan={IsAuditor() || IsAgency() && form.status?.id === 12 ? 5 : 4}></th>
          <th
            className={clsx(s.tableCell, s.tableCellBorder, s.noTopBorder, s.tableHeader, s.background)}>{totalScore}</th>
          <th
            className={clsx(s.tableCell, s.tableCellBorder, s.noTopBorder, s.tableHeader, s.background)}> {totalStaticScore}</th>
          <th
            className={clsx(s.tableCell, s.tableHeader, s.background)}>{getPercentFormDetail(totalStaticScore, totalScore)}%
          </th>
        </tr>
        </thead>
        <tbody>
        {template && template.mainGroup
          && <QuestionGroupDraw
            idForm={form.id}
            groups={template.mainGroup}
            answers={form.answer}
            questionsFromTemplate={form.templateForm.questions}
            handleTotalScore={handleTotalScore}
            handleTotalStaticScore={handleTotalStaticScore}
            formStatus={form.status}
          />
        }
        </tbody>
      </table>
    </div>
  ) : (
    <FullSizeCenterWrapper>
      <Preloader/>
    </FullSizeCenterWrapper>
  );
};

export default QuestionBlock;

interface IQuestionGroupDraw {
  idForm: number,
  groups: any[]
  answers?: any[]
  questionsFromTemplate?: any[]
  handleTotalScore: (val: number) => void
  handleTotalStaticScore: (val: number) => void
  formStatus: IStatus | undefined
}

const QuestionGroupDraw = ({
                             idForm,
                             groups,
                             answers,
                             questionsFromTemplate,
                             handleTotalScore,
                             handleTotalStaticScore,
                             formStatus,
                           }: IQuestionGroupDraw) => {
  let {hash} = useLocation()
  const [totalDynamicScoreInGroup, setTotalDynamicScoreInGroup] = useState<{ [key: string]: number }>({})
  const [totalStaticScoreInGroup, setTotalStaticScoreInGroup] = useState<{ [key: string]: number }>({})

  const throwAppeal = (id: number) => () => setModal(ModalsEnum.FormDetailModalsRegular, {questionId: id, idForm})
  const throwAppealRequest = (id: number, apId: number) => () => setModal(ModalsEnum.FormDetailModalsAcepted, {
    questionId: id,
    idForm,
    apId
  })
  const openEditAnswerModal = (id: number) => () => setModal(ModalsEnum.FormAuditorAnswerModal, {
    questionId: id,
    idForm
  })

  useEffect(() => {
    const findIdByHash = hash.replace('#', '')

    if (findIdByHash) {
      const elem = document.getElementById(findIdByHash)

      if (elem) {
        elem.scrollIntoView();
      }
    }
  }, [])

  // calculate total dynamic score by group
  useEffect(() => {
    setTotalDynamicScoreInGroup(
      groups.reduce((acc, group: IGroup) =>
          ({
            ...acc,
            [group.name]: group.questions ? group.questions.reduce((acc2: number, question: IQuestion & IIdentifier) => {
                if (answers) {
                  return acc2 + (+answers.find((answer) => answer.formQuestionId === question.id)?.score || 0)
                }
                return acc2
              }, 0)
              : 0
          })
        , {})
    )
  }, [])
  // calculate total static score by group
  useEffect(() => {
    setTotalStaticScoreInGroup(
      groups.reduce((acc, group: IGroup) =>
          ({
            ...acc,
            [group.name]: group.questions ? group.questions.reduce((acc2: number, question: IQuestion & IIdentifier) => {
                const answer = answers?.find(answer => answer.formQuestionId === question.id)
                if (answer?.score === null) {
                  return acc2
                }
                return acc2 + question.option.reduce((acc3, opt: any) => {
                  return acc3 < opt.score ? opt.score : acc3
                }, 0)
              }, 0)
              : 0
          })
        , {})
    )
  }, [])

  // calculate total static score
  useEffect(() => {
    const scores = Object.values(totalDynamicScoreInGroup)
    if (scores.length) {
      handleTotalScore(scores.reduce((acc, el) => acc + +el, 0))
    }
  }, [totalDynamicScoreInGroup])

  // calculate total static score
  useEffect(() => {
    const scores = Object.values(totalStaticScoreInGroup)
    if (scores.length) {
      handleTotalStaticScore(scores.reduce((acc, el) => acc + +el, 0))
    }
  }, [totalStaticScoreInGroup])

  return (
    <>
      {
        groups.map((el: IGroup, id) => {
          return (
            <React.Fragment key={id}>
              <tr className={clsx(s.tableRow, s.textAlignCenter)}>
                <td colSpan={IsAuditor() || IsAgency() && formStatus?.id === 12 ? 5 : 4}
                    className={clsx(s.tableCell, s.tableCellHeader)}
                    style={{textAlign: 'left'}}>{el.name}</td>
                <td className={clsx(s.tableCell, s.tableCellHeader, s.tableCellBorder)}>
                  {totalDynamicScoreInGroup[el.name]}
                </td>
                <td className={clsx(s.tableCell, s.tableCellHeader, s.tableCellBorder)}>
                  {totalStaticScoreInGroup[el.name]}
                </td>
                <td className={clsx(s.tableCell, s.tableCellHeader, s.tableCellBorder)}>
                  {getPercentFormDetail(totalStaticScoreInGroup[el.name], totalDynamicScoreInGroup[el.name])}%
                </td>
              </tr>
              {!!el.questions && el.questions.map((el: IQuestion & IIdentifier) => {
                const answerOnQuestion = answers && answers.find((answer) => answer.formQuestionId === el.id)
                const appealsOnQuestion = questionsFromTemplate && questionsFromTemplate.find((question) => question.id === el.id)?.appeal
                const canAppealEditAdmin = IsSystemAdmin() && appealsOnQuestion.some((el: any) => el.Status.id === 5 || el.Status.id === 6)
                const canAppealEditManager = !canAppealEditAdmin && IsAgencyManager() && appealsOnQuestion.some((el: any) => el.Status.id === 5)

                const maxFromQuestion = el.typeQuestion === QUESTION_TYPES.options
                  ? el.option.reduce((acc, opt) => acc < Number(opt.score) ? opt.score : acc, 0)
                  : "-"
                return (
                  <tr className={s.tableRow} key={el.id} id={`formQuestionId${el.id}`}>
                    <td className={clsx(s.tableCell)}
                        style={{wordWrap: 'break-word', display: 'flex', flexDirection: 'column'}}>
                      {
                        (!canAppealEditAdmin || !canAppealEditManager)
                        && (IsDealer() || IsSystemAdmin()) &&
                        <p className={"linkUpper"}
                           style={{cursor: "pointer", textAlign: "center", padding: 10}}
                           onClick={throwAppeal(el.id)}
                        >
                          Подать апелляцию
                        </p>
                      }
                      {appealsOnQuestion && appealsOnQuestion.map((appeal: IAppeal & IIdentifier) => {
                        return (
                          <AppealText key={appeal.id} appeal={appeal} user={appeal?.User}>
                            {
                              (canAppealEditAdmin || canAppealEditManager) && (
                                <p className={"linkUpper"}
                                   style={{cursor: "pointer", textAlign: "center"}}
                                   onClick={throwAppealRequest(el.id, appealsOnQuestion.find((el: any) =>
                                     canAppealEditAdmin
                                       ? (el.Status.id === 5 || el.Status.id === 6)
                                       : el.Status.id === 5).id)}
                                >
                                  Ответить на апелляцию
                                </p>
                              )
                            }
                          </AppealText>
                        )
                      }).flat()}
                    </td>
                    {
                      (IsAuditor() || IsAgency()) && formStatus?.id === 12 && (
                        <td className={clsx(s.tableCell, s.tableCellBorder)}>
                          <p className={"linkUpper"}
                             style={{cursor: "pointer", textAlign: "center"}}
                             onClick={openEditAnswerModal(el.id)}
                          >Изменить ответ</p>
                        </td>
                      )
                    }
                    <td className={clsx(s.tableCell, s.tableCellBorder)}>{el.num}</td>
                    <td className={clsx(s.tableCell, s.tableCellBorder)}>
                      <TextEditorRaw value={JSON.parse(el.textQuestion)} readOnly/>
                    </td>
                    <td className={clsx(s.tableCell, s.tableCellBorder, {
                      [s.green]: ((answerOnQuestion && answerOnQuestion?.answerString) || "").toLowerCase() === "да",
                      [s.red]: ((answerOnQuestion && answerOnQuestion?.answerString) || "").toLowerCase() === "нет",
                    })}
                    >
                      {answerOnQuestion && answerOnQuestion?.answerString ? answerOnQuestion?.answerString : answerOnQuestion?.answerFloat}
                    </td>
                    <td className={clsx(s.tableCell, s.tableCellBorder)}>
                      {answerOnQuestion?.score === null ? "-" : answerOnQuestion?.score}
                    </td>
                    <td className={clsx(s.tableCell, s.tableCellBorder)}>
                      {answerOnQuestion?.score !== null &&
                      el.typeQuestion === QUESTION_TYPES.options
                        ? el.option.reduce((acc, opt) => {
                          return acc < Number(opt.score) ? opt.score : acc
                        }, 0)
                        : "-"
                      }
                    </td>
                    <td className={clsx(s.tableCell, s.tableCellBorder)}>
                      {
                        answerOnQuestion?.score !== null ?
                          typeof maxFromQuestion === "string"
                            ? maxFromQuestion
                            : `${getPercentFormDetail(maxFromQuestion, answerOnQuestion?.score || 0)}%`
                          : "-"
                      }
                    </td>
                    <td className={clsx(s.tableCell, s.tableCellBorder)}>{el.textComment}</td>
                    <td className={clsx(s.tableCell)} style={{width: 300}}>
                      {answerOnQuestion && <FileResolver fileData={answerOnQuestion?.Files}/>}
                    </td>
                  </tr>
                )
              })}
            </React.Fragment>
          )
        })
      }
    </>
  )
}

interface IAppealTextProps {
  appeal?: IAppeal
  user: IUser
  children?: JSX.Element
}

const customFileContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: 5,
} as CSSProperties

const AppealText = ({appeal, user, children}: IAppealTextProps) => {
  if (appeal) {
    const {AppealAnswer} = appeal
    return (
      <div className={clsx(s.appealTextWrapper, {
        [s.rejectedWrapper]: appeal.Status.id !== 5,
        [s.acceptedWrapper]: appeal.Status.id === 8
      })}>
        <div className={s.description}>
          <div className={s.user}>
            <div className={s.bold}>{user.position}</div>
            <div className={s.bold}>{getShortFIO(user.fullName)}</div>
          </div>
          <div>Дата: {moment(appeal.createdAt).format("DD.MM.YYYY HH:mm:ss")}</div>
          <div>Статус: {appeal.Status.name}</div>
        </div>
        <p className={s.text}>{appeal.appealText}</p>
        {!!appeal.File.length && (
          <FileResolver fileData={appeal.File} customContainer={customFileContainer}/>
        )}
        {
          children && (
            <div style={{padding: 10}}>
              {children}
            </div>
          )
        }

        {
          !!AppealAnswer?.length && (
            AppealAnswer.map(({User, createdAt, appealAnswerText, File, id}) => (
              <React.Fragment key={id}>
                <hr/>
                <div className={clsx(s.appealAnswerTextWrapper)}>
                  <div className={s.description}>
                    <div style={{marginBottom: 3}}>Ответ</div>
                    <div className={s.user}>
                      <div className={s.bold}>{user.position}</div>
                      <div className={s.bold}>{getShortFIO(User.fullName)}</div>
                    </div>
                    <div>Дата: {moment(createdAt).format("DD.MM.YYYY HH:mm:ss")}</div>
                  </div>
                  <p className={s.text}>{appealAnswerText}</p>
                  {!!File.length && (
                    <FileResolver fileData={File} customContainer={customFileContainer}/>
                  )}
                </div>
              </React.Fragment>
            ))
          )
        }
      </div>
    )
  }
  return <></>
}
