import {ListResponse, IPaginate, IResponseExtraData, IIdentifier} from "../models/common";
import {AxiosResponse} from "axios";
import $api from "../http";
import {IAppealFilter} from "../models/FilterData";
import {IAppeal, IAppealEdit} from "../models/InstanceInterfaces/IAppeal";

/**
 * Класс для работы с апелляциями
 */
export default class AppealsService {

  static getAll = (params: (IPaginate & IAppealFilter)): Promise<AxiosResponse<ListResponse<IAppeal & IResponseExtraData & IIdentifier>>> =>
    $api.get('/appeal', {params})

  static create = (params: IAppeal): Promise<AxiosResponse<IAppeal & IIdentifier>> =>
    $api.post('/appeal', params)

  static edit = (id: number, data: IAppealEdit): Promise<AxiosResponse<ListResponse<IAppeal & IResponseExtraData & IIdentifier>>> =>
    $api.put(`/appeal/${id}`, data)

  static changeStatus = (id: number, statusId: number): Promise<AxiosResponse<ListResponse<IAppeal & IResponseExtraData & IIdentifier>>> =>
    $api.put(`/appeal/statusForm/${id}`, {statusId})

  static getById = (id: number): Promise<AxiosResponse<IAppeal>> =>
    $api.get(`/appeal/${id}`)

  static answerOnAppeal = (appealId: number, params: {appealAnswerText: string, fileIds: number[]}): Promise<AxiosResponse<IAppeal & IIdentifier>> =>
    $api.post(`/appeal/${appealId}/answer`, params)

  static getByQuestionId = (params: IIdentifier & IPaginate): Promise<AxiosResponse<IAppeal>> =>
    $api.get(`/appeal/answer/${params.id}`, {params})

  static getByDealerId = (params: IIdentifier & IPaginate): Promise<AxiosResponse<IAppeal>> =>
    $api.get(`/appeal/dealer/${params.id}`, {params})

  static setAppealAnswer = (appealId: number, appealAnswerText: string): Promise<AxiosResponse<IAppeal>> =>
    $api.post(`/appeal/${appealId}/answer`, {appealAnswerText})

  static editAppealAnswer = (appealId: number, id: number, appealAnswerText: string): Promise<AxiosResponse<IAppeal>> =>
    $api.put(`/appeal/${appealId}/answer/${id}`, {appealAnswerText})

  static deleteAppealAnswer = (appealId: number, id: number): Promise<AxiosResponse<IAppeal>> =>
    $api.delete(`/appeal/${appealId}/answer/${id}`);
}
