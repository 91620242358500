import { AxiosResponse } from 'axios'

import { IFile } from 'models/InstanceInterfaces/IFile'
import {IIdentifier, IPaginate, IResponseExtraData} from 'models/common'

import $api, {$apiFetch} from '../http'
import { INFOFILES_TYPE } from 'lib/appConst';
import { IInfoFileRequest, IInfoFileResponse } from 'models/IInfoFiles';

/*
* Класс для работы с файлами
* */
export default class FileService {

  static sendFile = (file: FormData, onUploadProgress: (progressEvent: ProgressEvent) => void): Promise<AxiosResponse<IFile & IResponseExtraData & IIdentifier>> =>
    $api.post('/file/upload', file, { onUploadProgress })

  static downloadFileById = (id: number, params?: IPaginate): Promise<Response> =>
    $apiFetch.get(`/file/download/${id}`, {params})

  static getFile = (id: number): Promise<AxiosResponse<IFile & IResponseExtraData & IIdentifier>> =>
    $api.get(`/file/${id}`)

  static getInfoFiles = (type?: INFOFILES_TYPE): Promise<AxiosResponse<IInfoFileResponse[]>> =>
    $api.get(`/info-files`, { params: { type } })

  static setInfoFile = (data: IInfoFileRequest): Promise<AxiosResponse<any>> =>
    $api.post(`/info-files`, data)

  static editInfoFile = (id: number, data: IInfoFileRequest): Promise<AxiosResponse<any>> =>
    $api.put(`/info-files/${id}`, data)

  static deleteInfoFile = (id: number): Promise<AxiosResponse<any>> =>
    $api.delete(`/info-files/${id}`)
}
