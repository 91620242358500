import { memo } from 'react'
import { CheckboxRaw } from 'components/UI/UIField/items/Checkbox'

import { IIdentifier } from 'models/common'
import { IDealerGroup } from 'models/IDealerGroup'
import { GroupItem } from '..'

import s from 'modules/Modals/BindDealerModal/s.module.css'


export type DealerGroupItemPropTypes = IIdentifier & IDealerGroup & {
  checked: boolean,
  disabled: boolean,
  onHandleSelect: (id: number, status: boolean, groupItem: Partial<GroupItem>) => void
}


export const DealerGroupItem = memo(({
  id,
  name,
  checked,
  onHandleSelect,
  disabled,
  ...el
}: DealerGroupItemPropTypes) => {
  return (
    <div className={s.checkboxItem}>
      <CheckboxRaw
        label={name}
        value={checked}
        disabled={disabled}
        onChange={(event) => {
          onHandleSelect(id, event.target.checked, { ...el, id, name })
        }}
      />
    </div>
  )
})
