export enum ModalsEnum {
  FilterModal = 'FilterModal',
  DealerUsersModal = 'DealerUsersModal',
  DealerGroupModal = 'DealerGroupModal',
  FormAppealQuestion = 'FormAppealQuestion',
  DealerGroupsModal = 'DealerGroupsModal',
  UserAddModal = 'UserAddModal',
  UserEditModal ='UserEditModal',
  BindUserToTemplateForms = 'BindUserToTemplateForms',
  BindDealersToUserModal ='BindDealersToUserModal',
  BindDealerToUserModal = 'BindDealerToUserModal',
  BindUserToFormTypeModal = 'BindUserToFormTypeModal',
  NewFormModal = 'NewFormModal',
  NewWaveModal = 'NewWaveModal',
  BindDealerModal = 'BindDealerModal',
  FormCommentModal = 'FormCommentModal',
  FromTypeModal = 'FromTypeModal',
  FromKindModal = 'FromKindModal',
  GroupingModificationModal = 'GroupingModificationModal',
  FormDetailModalsRegular = 'FormDetailModalsRegular',
  FormDetailModalsAcepted = 'FormDetailModalsAcepted',
  FormAuditorAnswerModal = 'FormAuditorAnswerModal',
  NewDealerGroupModal = 'NewDealerGroupModal',
  InfoListModal = 'InfoListModal',
  SetTemplateToUserByFilter = 'SetTemplateToUserByFilter',
}
