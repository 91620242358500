import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import { UIContainer } from 'components/UI/UIContainer'
import { UIButton } from 'components/UI/UIButton'

import { INFOFILES_NAME, INFOFILES_TYPE } from 'lib/appConst'
import { setModal } from 'lib/setModal'
import { getAccessToUploadInfoFiles } from 'lib/RolesModel'

import { ModalsEnum } from 'modules/ModalController/modalManager'
import InfoFile from 'modules/MainPageInfoBlocks/Info/items/InfoFile'

import FileService from 'services/FileService'

import { useEaseSearchParams } from 'hooks/useEaseSearchParams'

import { IInfoFileResponse } from 'models/IInfoFiles'

import { ReactComponent as CrossIcon } from 'assets/svg/button/cross.svg'

import s from "pages/Main/s.module.css"
import sCommon from '../sCommon.module.css'


type TInfoProps = {
  type: INFOFILES_TYPE
}

const Info = ({ type }: TInfoProps) => {

  const [params] = useEaseSearchParams()
  const [files, setFiles] = useState<IInfoFileResponse[]>([])
  const refresh = useRef(1)

  const getFiles = async () => {
    return await FileService.getInfoFiles(type)
  }

  useEffect(() => {
    if (refresh.current === 1) {
      getFiles().then((res) => {
        setFiles(res.data)
        refresh.current = 0
      })
    }
  }, [refresh.current])

  useEffect(() => {
    // 2 шаг, после закрытия окна (изменения params) и простановки 1 шага, мы указываем что надо запросить список заново
    if (refresh.current === 2) {
      refresh.current = 1
    }

    // 1. Если открыли модалку из текущего компонента с указанным типом, то ко 2 шагу (после закрытия окна)
    // мы будем знать что открыто было именно это
    if (params.hasOwnProperty('type') && params.type === type) {
      refresh.current = 2
    }
  }, [params])

  const isCanEdit = getAccessToUploadInfoFiles()

  return (
    <UIContainer.ContentContainer
      header={INFOFILES_NAME[type]}
      containerAdditionClass={clsx(sCommon.container, sCommon.files)}
    >
      <div className={s.filesOverlay}>
        <div className={s.files}>
          {files.length > 0 && files.map((el) => (
            <InfoFile key={el.id} file={el} isCanEdit={isCanEdit} />
          ))}
        </div>
      </div>

      {isCanEdit && (
        <UIButton.BorderBtn
          label={'Добавить файл'}
          className={s.createFileButton}
          leftIcon={<CrossIcon/>}
          handler={() => setModal(ModalsEnum.InfoListModal, { type })}
        />
      )}
    </UIContainer.ContentContainer>
  );
};

export default Info;
