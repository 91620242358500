import React from 'react';
import s from './s.module.css'
import ElementOnPageSelect from "../ElementOnPageSelect";
import Pagination from "../Pagination";
import {LOADING_STATUS} from "lib/appConst";
import Preloader from "components/UI/Preloader/Preloader";


export interface ITableFooter {
  count: number
  status: LOADING_STATUS
  requestCounts: number[]
  disabledTableActivity?: boolean
}

/**
 * Футер таблицы
 * @param status
 * @param count - Колличество строк (total из запроса)
 * @param requestCounts - текущее значение "отображать по..."
 * @constructor
 */
const TableFooter = (
  {
    status,
    count,
    requestCounts,
    disabledTableActivity,
  }: ITableFooter) => {
  return (
    <div className={s.footer}>
      <div style={{display: "flex", flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
        <p style={{whiteSpace: 'nowrap'}}>Всего: {count}</p>
        {status === LOADING_STATUS.LOADING && (
          <div style={{position: "absolute", right: -30, top: -3}}>
            <Preloader width={20} height={20}/>
          </div>
        )}
      </div>
      { !!count && <Pagination total={count} disabledTableActivity={disabledTableActivity} /> }
      <ElementOnPageSelect
        requestCounts={requestCounts}
        disabledTableActivity={disabledTableActivity}
      />
    </div>
  );
};

export default TableFooter;
