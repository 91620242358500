import {ListResponse, IPaginate, IResponseExtraData, IIdentifier} from "../models/common";
import {AxiosResponse} from "axios";
import $api from "../http";
import {IStatus, IStatusFilter} from "../models/Status/Status";

/**
 * Класс по работе со статусами
 */
export class StatusService {

  static getAll = (params: IPaginate & IStatusFilter): Promise<AxiosResponse<ListResponse<IStatus & IResponseExtraData & IIdentifier>>> =>
    $api.get('/status', {params})

  static getAllFormsStatus = (params: IPaginate & IStatusFilter): Promise<AxiosResponse<ListResponse<IStatus & IResponseExtraData & IIdentifier>>> =>
    $api.get('/status', {params: {...params, code: "Forms"}})

  static getAllAppealsStatus = (params: IPaginate & IStatusFilter): Promise<AxiosResponse<ListResponse<IStatus & IResponseExtraData & IIdentifier>>> =>
    $api.get('/status', {params: {...params, code: "Appeals"}})

  static getAllDealersStatus = (params: IPaginate & IStatusFilter): Promise<AxiosResponse<ListResponse<IStatus & IResponseExtraData & IIdentifier>>> =>
    $api.get('/status', {params: {...params, code: "Dealers"}})

  static getAllUsersStatus = (params: IPaginate & IStatusFilter): Promise<AxiosResponse<ListResponse<IStatus & IResponseExtraData & IIdentifier>>> =>
    $api.get('/status', {params: {...params, code: "Users"}})

  static create = (data: IStatus): Promise<AxiosResponse<IStatus>> =>
    $api.post('/status', data)

  static getById = (id: number): Promise<AxiosResponse<IStatus & IResponseExtraData>> =>
    $api.get(`/status/${id}`)

  static edit = (data: IIdentifier & IStatus): Promise<AxiosResponse<IStatus>> =>
    $api.put<IStatus>(`/status/${data.id}`, data)

  static delete = (id: number): Promise<AxiosResponse<IStatus>> =>
    $api.delete<IStatus>(`/status/${id}`)
}
