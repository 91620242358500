import {AxiosResponse} from "axios";
import $api from "../http";
import {IUser, IUserModify} from "../models/InstanceInterfaces/IUser";
import {GetUsersRequest} from "../models/GetUsersRequest";
import {IDownloadResponse, IIdentifier, ListResponse} from "../models/common";
import {TUsersPageFilter, TUsersPageFilterCredentials} from "../modules/filters/UsersPageFilter";


/**
 * Класс для работы с пользователями
 */
export default class UsersService {

  static getAll = (params: GetUsersRequest | TUsersPageFilterCredentials): Promise<AxiosResponse<ListResponse<IUser & IIdentifier>>> =>
    $api.get('/users', {params})

  static create = (data: IUser): Promise<AxiosResponse<IUser>> =>
    $api.post('/users', data)

  static getById = (id: number): Promise<AxiosResponse<IUser>> =>
    $api.get(`/users/${id}`)

  static getAuditors = (params: GetUsersRequest): Promise<AxiosResponse<ListResponse<IUser & IIdentifier>>> =>
    $api.get('/users', {params: {...params, roleIds: [5]}})

  static editUser = (id: number, data: IUserModify): Promise<AxiosResponse<IUser & IIdentifier>> =>
    $api.put(`/users/${id}`, data)

  static sync = (): Promise<AxiosResponse<boolean>> =>
    $api.post('/users/import')

  static downloadExcel = (params: Partial<TUsersPageFilter>): Promise<AxiosResponse<IDownloadResponse>> =>
    $api.get('/users/excel', {params})

  static removeUser = (id: string): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/users/${id}`)

  static addTemplateFormToUser = (id: string, data: number): Promise<AxiosResponse<0 | 1>> =>
    $api.put(`/users/${id}/userToTemplateForm`, {templateFormId: data})

  static removeTemplateFormToUser = (id: string, data: number): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/users/${id}/userToTemplateForm`, {data: {templateFormId :data}})

  static usersToTemplateForm = (templateFormId: string | number, data: any): Promise<AxiosResponse<0 | 1>> =>
    $api.put(`/users/${templateFormId}/usersToTemplateForm`, data)
}
