import {Control, FormState} from "react-hook-form";
import {AddUserModalCredentials} from "./index";
import {UIContainer} from "components/UI/UIContainer";
import {UIButton} from "components/UI/UIButton";
import React from "react";
import UIField from "components/UI/UIField";
import {OptionData} from "models/FilterData";
import {Roles} from "models/Roles";
import {IsAgencyManager} from "../../../../lib/RolesModel";
import {allowEditUserRolesForManager, deleteRoles} from "../EditUser";


/**
 * Интерфейс данных для AddUserModalView
 */
interface AddUserModalViewProps {
  setManualInput: React.Dispatch<React.SetStateAction<boolean>>
  onSubmit: Function
  handleSubmit: Function
  control: Control<AddUserModalCredentials>
  formState: FormState<AddUserModalCredentials>
}

const AddUserModalView = ({
                         setManualInput,
                         onSubmit,
                         handleSubmit,
                         control,
                         formState,
                       }: AddUserModalViewProps) => {
  /**
   * Роли, которые могут быть присвоены только внешним пользователям (из приложения)
   */
  const roleOptions: OptionData[] = [
    {
      "value": Roles.auditor,
      "name": "Тайный покупатель / аудитор",
    },
    {
      "value": Roles.agencyManager,
      "name": "Менеджер агентства"
    },
    {
      "value": Roles.controller,
      "name": "Контролер"
    }
  ];
  const firsFormElementStyle = {
    minHeight: '46px',
    marginTop: '5px'
  }
  const formElementStyle = {
    minHeight: '46px',
    marginTop: '10px'
  }
  const formStyle = {
    width: '511px',
    padding: '44px 48px'
  }

  const roleOptionsByAccess = deleteRoles(
    roleOptions,
    IsAgencyManager() ? allowEditUserRolesForManager : []
  )
  return (
    <UIContainer.ContentContainer
      header={'Новый пользователь'}
      customStyle={formStyle}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <UIField.Input
          name={'fullName'}
          control={control}
          placeholder={'ФИО'}
          customStyle={firsFormElementStyle}
          errors={formState.errors}
        />
        <UIField.Input
          name={"login"}
          control={control}
          placeholder={"Логин"}
          customStyle={formElementStyle}
          errors={formState.errors}
        />
        <UIField.Input
          name={"password"}
          control={control}
          type={"password"}
          placeholder={"Пароль"}
          customStyle={formElementStyle}
          errors={formState.errors}
        />
        <UIField.Input
          name={"email"}
          control={control}
          placeholder={"Email"}
          customStyle={formElementStyle}
          errors={formState.errors}
        />
        <UIField.Input
          name={'phone'}
          control={control}
          placeholder={'Телефон'}
          customStyle={formElementStyle}
          errors={formState.errors}
        />
        <UIField.Input
          extraOnChangeHandler={() => setManualInput(true)}
          name={'position'}
          control={control}
          placeholder={'Должность'}
          customStyle={formElementStyle}
          errors={formState.errors}
        />
        <UIField.Select
          selectName={'Роль'}
          options={roleOptionsByAccess}
          name={'roleId'}
          isFilter={false}
          control={control}
          errors={formState.errors}
        />

        <UIButton.DefaultBtn
          label={"Добавить"}
          style={{marginTop: '24px'}}
        />

        {/*<UIError errorMessage={errorMessage}/>*/}
      </form>
    </UIContainer.ContentContainer>
  )
}

export default AddUserModalView;
