import {createAsyncThunk} from "@reduxjs/toolkit";
import UsersService from "../../services/UsersService";
import {netErrorsHandler} from "../../lib/netErrorsHandler";
import {IUser, IUserModify} from "../../models/InstanceInterfaces/IUser";
import {IIdentifier} from "../../models/common";
import {addNotification} from "../../lib/addNotification";
import {NOTIFICATOR_TYPE} from "../../lib/appConst";
import  { AxiosError } from 'axios';
import {TUsersPageFilterCredentials} from "../../modules/filters/UsersPageFilter";



export interface IGetUser {
  page: number,
  count: number,
  fullName?: string,
  login?: string,
  email?: string,
  phone?: string,
  position?: string,
  beginLastOnlineDate?: string,
  endLastOnlineDate?: string,
  isExternal?: boolean,
  roleIds?: string[]
}
/**
 * Посылает запрос на сервер получения данных пользователей
 */
export const getAllUsersAction = createAsyncThunk(
  'users/getAll',
  async(params: IGetUser | TUsersPageFilterCredentials): Promise<any> => {
    try {
      const response = await UsersService.getAll(params);
      return response.data;
    } catch (e: any) {
    }
  }
)

/**
 * Посылает запрос на сервер для добавления нового пользователя
 */
export const addUserAction = createAsyncThunk(
  'users',
  async (params: IUser, { rejectWithValue }): Promise<any> => {
    try {
      const response = await UsersService.create(params);
      if(response.status === 200 || 201) {
        addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Пользователь успешно добавлен!'})
      }
      return response.data;
    }catch (e: any) {
        addNotification({type: NOTIFICATOR_TYPE.error, text: e.response.data.message || 'Не удалось добавить пользователя!'})
      return rejectWithValue(netErrorsHandler(e));
    }
  }
)

/**
 * Посылает запрос на сервер для получения данных о пользователе
 */
export const getUserAction = createAsyncThunk(
  'get/user',
  async (id: number, { rejectWithValue }): Promise<any> => {
    try {
      const response = await UsersService.getById(id);
      return response.data;
    }catch (e: any) {
      return rejectWithValue(netErrorsHandler(e));
    }
  }
)

export const updateUserDealerBind = createAsyncThunk(
  'get/userUpdate',
  async (id: number, { rejectWithValue }): Promise<any> => {
    try {
      const response = await UsersService.getById(id);
      return response.data;
    }catch (e: any) {
      return rejectWithValue(netErrorsHandler(e));
    }
  }
)

/**
 * Посылает запрос на сервер для изменения данных о пользователе
 */
export const editUserAction = createAsyncThunk(
  'edit/user',
  async (params: IUser & IIdentifier): Promise<(IUser & IIdentifier) | undefined> => {
    try {
      const response = await UsersService.editUser(params.id, {...params} as IUserModify);
      return response.data;
    }catch (e: any) {
      if(e instanceof AxiosError) {
        addNotification({type: NOTIFICATOR_TYPE.error, text: e.response?.data?.message || "Неизвестная ошибка"})
      }
    }
  }
)
