import { OptionData } from 'models/FilterData'

/**
 * Тип данных AnalyticsUnloadPageFilter
 */
export type AnalyticsUnloadPageFilterCredentials = {
  userId?: OptionData | null,
  name?: string | null,
  params?: string | null,
}

export type TAnalyticsUnloadPageFilter = {
  userId?: string,
  name?: string,
  params?: string,
}

export const defaultAnalyticsUnloadFilterValues: AnalyticsUnloadPageFilterCredentials = {
  userId: null,
  name: null,
  params: null,
}
