import React from 'react';
import s from './s.module.css'
import {IControlConfig} from "../../index";
import {TableControlFields} from "../TableControlFields";
import {TABLE_CONTROL_CONFIG_FIELD_TYPES} from "../../../../lib/appConst";
import {tableControlAccess} from "../../../../lib/RolesModel";

export interface ITableSelectedItemsControl {
  controlConfig?: IControlConfig[]
  onRowSelect?: any
  excludedRows?: number[]
  selectedRows?: number[]
  isAllSelected?: boolean
  count?: number
}

const TableSelectedItemsControl = ({
                                     controlConfig,
                                     // excludedRows,
                                     // selectedRows,
                                     // isAllSelected,
                                     // onRowSelect,
                                     // count
}: ITableSelectedItemsControl) => {
  return (
    <div className={s.container}>
      {/*<div className={s.fieldBtn}>*/}
      {/*  <TableControlFields.SelectAll*/}
      {/*    label={"Выбрать все"}*/}
      {/*    isAllSelected={isAllSelected}*/}
      {/*    count={count || 0}*/}
      {/*    handler={onRowSelect}*/}
      {/*    selectedRows={selectedRows}*/}
      {/*    excludedRows={excludedRows}*/}
      {/*  />*/}
      {/*</div>*/}
      {
        controlConfig && controlConfig.map(({type, label, useDataRequest, handler, filterKey}, id) => {
          if(tableControlAccess(label)) {
            switch (type) {
              case TABLE_CONTROL_CONFIG_FIELD_TYPES.DROPDOWN:
                return (
                  <div key={id} className={s.fieldBtn}>
                    <TableControlFields.Dropdown label={label} useDataRequest={useDataRequest} handler={handler} filterKey={filterKey}/>
                  </div>
                )
            }
          }
        })
      }
    </div>
  );
};

export default TableSelectedItemsControl;
