import { useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { UIContainer } from 'components/UI/UIContainer'
import { UIModal } from 'components/UI/UIModal'
import UIField from 'components/UI/UIField'

import { DealerGroupItem } from './items/DealerGroupItem'

import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest'
import { useEaseSearchParams } from 'hooks/useEaseSearchParams'
import { useDebounce } from 'hooks/useDebounce'
import { useAppDispatch } from 'hooks/redux'
import { removeModalItem } from 'lib/setModal'

import DealersService from 'services/DealersService'

import { changeGroupsByDealer } from 'store/dealers'

import { IDealerGroup } from 'models/IDealerGroup'
import { IIdentifier } from 'models/common'
import { IDealer } from 'models/DealersServiceModel'


export type GroupItem = IDealerGroup & IIdentifier & {
  Dealer: Partial<IDealer & IIdentifier>[]
}


const DealerGroupModal = () => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useEaseSearchParams()
  const { ref, entry } = useInView()

  const [searchName, setSearchName] = useState<string>('')
  const [data, setData] = useState<GroupItem[]>([])
  const [disabledItems, setDisabledItems] = useState<number[]>([])

  const debouncedSearch = useDebounce<string>(searchName, 200)

  const [ _, paginationTrigger, doRefetch, extraData, loading ] = usePaginationItemsFromRequest({
    request: DealersService.getAllDealerGroup,
    count: 20,
    requestFilter: { name: searchName }
  })

  useEffect(() => {
    setData(extraData)
  }, [extraData])

  useEffect(() => {
    doRefetch()
  }, [debouncedSearch])

  useEffect(() => {
    if (entry?.isIntersecting) {
      paginationTrigger()
    }
  }, [entry])

  const removeModal = () => {
    removeModalItem(setParams)
  }

  const onHandleSelect = useCallback(async (value: number, status: boolean, item: Partial<GroupItem>) => {
    await setDisabledItems(disabledItems => [...disabledItems, value])

    try {
      const response = status
        ? await DealersService.putDealerToGroup(params.dealerId, value)
        : await DealersService.deleteDealerFromGroup(params.dealerId, value)

      dispatch(changeGroupsByDealer({
        id: params.dealerId,
        operation: status ? 'increment' : 'decrement',
        groupItem: item
      }))

      setData(data => data.map((el) => {
        return el.id === item.id ? {
          ...el,
          Dealer: status ? [...el.Dealer, { id: params.dealerId }] : el.Dealer.filter((it) => it.id !== params.dealerId)
        } : el
      }))
    } catch (e) {

    }

    await setDisabledItems(disabledItems => disabledItems.filter(el => el !== value))
  }, [])

  const isEmpty = data.length === 0 && !loading

  return (
    <UIContainer.ContentContainer header="Список групп">
      <UIField.InputRaw
        customInputStyle={{ padding: "26px 0 12px" }}
        name="name"
        placeholder={'Поиск по имени группы'}
        value={searchName}
        onChange={(event) => setSearchName(event.target.value)}
      />

      <UIModal.ListContainer isEmpty={isEmpty}>
        {data.length > 0 && data.map(({ id, name, Dealer, ...el }: GroupItem) => (
          <DealerGroupItem
            key={id}
            checked={Dealer.map(({ id }: Partial<IDealer & IIdentifier>) => id).includes(params.dealerId)}
            disabled={disabledItems.includes(id)}
            onHandleSelect={onHandleSelect}
            {...{ id, name, ...el }}
          />
        ))}
        <div ref={ref} style={{ width: "100%", height: "10px" }} />
      </UIModal.ListContainer>

      <UIModal.Footer handleBack={removeModal} />
    </UIContainer.ContentContainer>
  )
}

export default DealerGroupModal
