import React, {useState} from 'react';
import clsx from 'clsx'
import s from './s.module.css'

export interface IContextMenuOption {
  name: string
  handler: (e: React.MouseEvent<HTMLDivElement>) => void
}
export interface IUIContextMenuProps {
  options: IContextMenuOption[]
  name?: string
  className?: string
  disabled?: boolean
}
const UIContextMenu = ({ name, className, options, disabled}: IUIContextMenuProps) => {
  const [isShow, setIsShow] = useState(false)
  const toggleHandler = (check?: boolean) => () => {
    setIsShow(p => check !== undefined ? check : !p)
  }
  const handlerDecorator = ({handler}: IContextMenuOption) => (e: React.MouseEvent<HTMLDivElement>) => {
    toggleHandler(false)()
    e.stopPropagation()
    handler(e)
  }
  return (
    <button
      type={"button"}
      className={clsx(s.wrapper, className, {
        [s.disabled]: disabled
      })}
      onBlur={toggleHandler(false)}
      disabled={disabled}
      onClick={e => {
        toggleHandler()()
        e.stopPropagation()
      }}
    >
      <div className={s.titleMenu}>
        {name && (
          <span>
            {name}
          </span>
        )}
        <div className={s.contextMenu}>
          <span/>
          <span/>
          <span/>
        </div>
      </div>
      {isShow &&
        <div className={s.optionContainer}>
          {options.map((el, id) => <OptionElement key={id} {...el} handler={handlerDecorator(el)}/>)}
        </div>
      }
    </button>
  );
};

export default UIContextMenu;

const OptionElement = ({name, handler}: IContextMenuOption) => {
  return (
    <div className={s.option} onClick={handler}>
      <p>{name}</p>
    </div>
  )
}
