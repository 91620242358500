import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'

import { UIContainer } from 'components/UI/UIContainer'

import { useAppDispatch } from 'hooks/redux'
import { useEaseSearchParams } from 'hooks/useEaseSearchParams'

import {
  AnalyticsAppealsTableControl,
  AnalyticsSubmenu,
} from 'lib/tableConfigs/controlsPakages/controls'
import { parseFilterParamsForRequest } from 'lib/parseFilterParams'
import { AnalyticsAppealsTableConfig } from 'lib/tableConfigs/configs'

import TableGenerator from 'modules/TableGenerator'
import { TAnalyticsAppealsPageFilter, defaultAnalyticsAppealsFilterValues } from 'modules/filters/AnalyticsAppealsPageFilter/utils';

import { getAllReportAppeals } from 'store/analytics/thunk'
import { selectAppealsReport } from 'store/analytics/reselect'
import FormsService from 'services/FormsService'

import s from '../AnalyticsReport/s.module.css'

/**
 * Страница для отображения отчетов аналитики по аппеляциям
 * @constructor
 */
const AnalyticsAppeals = () => {
  const [params, setParams] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState<Partial<TAnalyticsAppealsPageFilter>>(parseFilterParamsForRequest(params, defaultAnalyticsAppealsFilterValues) as unknown as Partial<TAnalyticsAppealsPageFilter>)
  const [isLeftScrolled, setIsLeftScrolled] = useState<boolean>(true)
  const [isRightScrolled, setIsRightScrolled] = useState<boolean>(false)

  const id = 'table'

  const structureConfig = useSelector(selectAppealsReport).columns
  const analyticsTableConfig = {
    structureConfig: [
      ...AnalyticsAppealsTableConfig.structureConfig,
      ...structureConfig
    ]
  }

  const getData = useCallback((params: Partial<TAnalyticsAppealsPageFilter>) => {
    return dispatch(getAllReportAppeals(params as Partial<TAnalyticsAppealsPageFilter>));
  }, [])

  useEffect(() => {
    async function getDefaultTemplate() {
      // @ts-ignore
      return FormsService.getAllFormTemplates()
    }

    if (!params.hasOwnProperty('templateFormId')) {
      getDefaultTemplate().then((res) => {
        setParams({ ...params, templateFormId: res.data.rows[0].id, templateFormIdFieldName: res.data.rows[0].name }, true)
      })
    } else {
      if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultAnalyticsAppealsFilterValues) as unknown as Partial<TAnalyticsAppealsPageFilter>)) {
        setFilter(parseFilterParamsForRequest(params, defaultAnalyticsAppealsFilterValues) as unknown as Partial<TAnalyticsAppealsPageFilter>)
      }
    }
  }, [params])

  useEffect(() => {
    const element: HTMLElement | null = document.getElementById(id)

    function checkScrollPosition() {
      if (element) {
        if (element.scrollLeft === 0) {
          setIsLeftScrolled(true)
        } else {
          setIsLeftScrolled(false)
        }

        if (element.scrollLeft + element.clientWidth >= element.scrollWidth) {
          setIsRightScrolled(true)
        } else {
          setIsRightScrolled(false)
        }
      }
    }

    if (element) {
      element.addEventListener("scroll", checkScrollPosition, false)
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", checkScrollPosition)
      }
    }
  }, [])

  return (
    <UIContainer.PageContainer>
      {/*<div className={clsx(s.tableContainer, {*/}
      {/*  [s.isLeftScrolled]: isLeftScrolled,*/}
      {/*  [s.isRightScrolled]: isRightScrolled,*/}
      {/*})}>*/}
        {params.hasOwnProperty('templateFormId') && (
          <TableGenerator
            tableName="Отчет по апелляциям"
            dataQuery={getData}
            dataSelect={selectAppealsReport}
            tableConfig={analyticsTableConfig}
            tableControl={AnalyticsAppealsTableControl}
            subMenu={AnalyticsSubmenu}
            filter={filter}
            withoutPagination
            id={id}
          />
        )}
      {/*</div>*/}
    </UIContainer.PageContainer>
  );
};

export default AnalyticsAppeals;
