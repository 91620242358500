import React from 'react';
import s from './s.module.css'

const Page404 = () => {
  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <p className={"title"}>
          Запрошенная страница не найдена.
        </p>
      </div>
    </div>
  );
};

export default Page404;
