import React, {CSSProperties, useEffect} from 'react'
import TableGeneratorView from './TableGeneratorView'
import {useSelector} from 'react-redux'
import {useEaseSearchParams} from '../../hooks/useEaseSearchParams'
import {RootState} from '../../store'
import {LOADING_STATUS, TABLE_CONTROL_CONFIG_FIELD_TYPES} from '../../lib/appConst'
import {ISort} from "../../models/common";

export enum TABLE_CELL_EDIT_TYPE {
  textField = 'textField',
  multilineTextField = 'multilineTextField',
  numberField = 'numberField',
  fileLoad = 'fileLoad',
  uniqField = 'uniqField',
}

export interface ITableGenerator {
  tableName?: string
  dataQuery: any
  dataSelect: any
  tableConfig: any
  requestCounts?: number[]
  tableControl?: (props: any) => JSX.Element
  subMenu?: (props: any) => JSX.Element
  selectable?: boolean
  selectedRowsLink?: any
  excludedRowsLink?: any
  onRowSelect?: any
  isAllSelectFlagLink?: any
  filter?: any
  sort?: ISort
  withoutPagination?: boolean
  id?: string
}

/**
 * Вероятно расширение этого API в будущем
 *
 * name - Имя поля отображаемое в <th>
 * dataAnchor - адрес поля в объекте данных (поддерживает маппинг через точку пример: user.name)
 * valueDecorator - принимает функцию для операций над данными, то что функция вернет, попадает в ячейку таблицы
 * cellCustomStyle - кастомные стили для ячейки
 * component - принимает компонент, который будет отображен вместо значения в ячейке таблицы
 * sort - делает поле сортируемым
 * editable - позволяет редактирование ячейки
 * editHandler - обработчик редактирования ячейки
 */
export interface IStructureConfig {
  name: string
  dataAnchor?: string
  valueDecorator?: (value: any) => any
  cellCustomStyle?: CSSProperties
  component?: any
  sort?: boolean
  sortFieldName?: string
  editable?: boolean
  editType?: TABLE_CELL_EDIT_TYPE
  editHandler?: (val: any) => void
  maxLength?: number
}

export interface IControlConfig {
  label: string
  type: TABLE_CONTROL_CONFIG_FIELD_TYPES
  useDataRequest: any
  handler: (val: any) => void
  filterKey?: string
}

export interface ITableConfig {
  structureConfig: IStructureConfig[]
  controlConfig?: IControlConfig[]
}

function debounceFetch(fn: any, delay: number) {
  return () => {
    //@ts-ignore
    if(debounceFetch.timeout) clearTimeout(debounceFetch.timeout)
    //@ts-ignore
    debounceFetch.timeout = setTimeout(() => fn(), delay)
  }
}

export const defaultTableElementsCounts = [20, 50, 100]
/**
 * Генератор таблиц
 * @param tableName - Имя таблицы
 * @param dataQuery - Функция диспатча экшана запроса
 * @param dataSelect - Реселект ссылка на данные в store
 * @param tableConfig - Конфигурация таблицы
 * @param tableControl - Компонент объединения кнопок
 * @param selectedRows - Ссылка на выбранные строки в store
 * @param selectedRowsLink - Обработчик селекта строки
 * @param selectable - Позволяет выделять строки таблицы
 * @param requestCounts - Список вариантов "отображать по..."
 */
const TableGenerator = ({
  tableName,
  dataQuery,
  dataSelect,
  tableConfig,
  tableControl,
  selectedRowsLink,
  excludedRowsLink,
  onRowSelect,
  selectable,
  isAllSelectFlagLink,
  requestCounts = defaultTableElementsCounts,
  filter,
  sort,
  withoutPagination,
  id,
  subMenu
}: ITableGenerator) => {
  const [{page, count}, setParam] = useEaseSearchParams()

  const {
    data,
    selectedRows,
    excludedRows,
    isAllSelected
  } = useSelector((store: RootState) => ({
    data: dataSelect(store),
    selectedRows: selectedRowsLink && selectedRowsLink(store),
    excludedRows: excludedRowsLink && excludedRowsLink(store),
    isAllSelected: isAllSelectFlagLink && isAllSelectFlagLink(store)
  }))

  const doFetch = () => {
    if (page && count) {
      if (Number(page) === 0) {
        setParam({page: 1, count: requestCounts[0], ...sort})
      }
      if (!requestCounts?.includes(Number(count))) {
        setParam({page: 1, count: requestCounts[0], ...sort})
      }
      dataQuery({page, count, ...filter, ...sort})
    } else {
      setParam({page: 1, count: requestCounts[0], ...sort})
    }
  }

  const request = debounceFetch(doFetch, 16)

  useEffect(() => {
    request()
  }, [page, count, filter, sort])

  return (
    <TableGeneratorView
      disabledTableActivity={data?.status === LOADING_STATUS.LOADING}
      tableName={tableName}
      tableConfig={tableConfig}
      TableControl={tableControl}
      Submenu={subMenu}
      selectable={selectable}
      requestCounts={requestCounts}
      onRowSelect={onRowSelect}
      selectedRows={selectedRows}
      excludedRows={excludedRows}
      isAllSelected={isAllSelected}
      data={data}
      withoutPagination={withoutPagination}
      id={id}
    />
  )
}

export default TableGenerator
