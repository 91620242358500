import React, {ChangeEvent, useEffect, useState} from 'react';
import {IStructureConfig, TABLE_CELL_EDIT_TYPE} from "../../index";
import {saveGetByPath} from "lib/saveGetByPath";
import UICheckbox from "components/UI/UICheckbox";
import s from './s.module.css'
import clsx from "clsx";
import UIField from "components/UI/UIField";
import {UIButton} from "components/UI/UIButton";
import {ReactComponent as ApplyIco} from "assets/svg/TableControlFields/apply.svg";
import {ReactComponent as CancelIco} from "assets/svg/TableControlFields/cancel.svg";
import {ReactComponent as EditIcon} from "assets/svg/TableControlFields/edit.svg";
import UITextArea, {UITextAreaRaw} from "../../../../components/UI/UITextArea";

export interface ITableBody {
  structureConfig: IStructureConfig[]
  selectable?: boolean
  rows: any[]
  onRowSelect?: any
  selectedRows?: number[]
  excludedRows?: number[]
  isAllSelected?: boolean
}

interface IRowProps {
  field: IStructureConfig
  row: any
  rowId: number
  cellId: number
  editHandle: (key: string) => void
  cancelEditHandle: () => void
  isEdit?: string
}

const Row = ({
               field,
               row,
               rowId,
               cellId,
               isEdit,
               editHandle,
               cancelEditHandle,
             }: IRowProps) => {
  let targetValue
  if(field.dataAnchor) {
    targetValue = saveGetByPath(row, field.dataAnchor, '')
  } else {
    targetValue = row
  }
  // const targetValue = saveGetByPath(row, field.dataAnchor, '')
  const Component = field.component

  if (field.component) {
    return (
      <TableCell
        id={cellId}
        row={row}
        rowId={rowId}
        field={field}
        isEdit={isEdit}
        editHandle={editHandle}
        cancelEditHandle={cancelEditHandle}
        targetValue={targetValue}
      >
        <Component {...{val: targetValue, row}}/>
      </TableCell>
    )
  }
  if (field.valueDecorator) {
    const value = field.valueDecorator(targetValue)
    return (
      <TableCell
        id={cellId}
        row={row}
        rowId={rowId}
        field={field}
        isEdit={isEdit}
        editHandle={editHandle}
        cancelEditHandle={cancelEditHandle}
        targetValue={targetValue}
      >
        {
          typeof value === "string" ||
          typeof value === "number"
            ? <p>{field.valueDecorator(targetValue)}</p>
            : field.valueDecorator(targetValue)
        }
      </TableCell>
    )
  }
  return (
    <TableCell
      id={cellId}
      row={row}
      rowId={rowId}
      field={field}
      isEdit={isEdit}
      editHandle={editHandle}
      cancelEditHandle={cancelEditHandle}
      targetValue={targetValue}
    >
      <p>{targetValue}</p>
    </TableCell>
  )
}

interface ITableCellProps {
  id: number
  row: any
  rowId: number
  targetValue: any
  field: IStructureConfig
  editHandle: (cellKey: string) => void
  cancelEditHandle: () => void
  children: JSX.Element | string | number
  isEdit?: string
}

const TableCell = ({
                     targetValue,
                     id,
                     field,
                     row,
                     rowId,
                     isEdit,
                     editHandle,
                     cancelEditHandle,
                     children
                   }: ITableCellProps) => {

  const cellKey = `${targetValue}${id}${rowId}`
  const editType = field.editType || field.editable && TABLE_CELL_EDIT_TYPE.textField
  const [value, setValue] = useState<string | number>()

  const changeTextHandle = ({target: {value}}: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => setValue(value)
  const changeNumberHandle = ({target: {value}}: ChangeEvent<HTMLInputElement>) => !value.match(/\D/) && setValue(Number(value))
  const handleEdit = () => {
    field.editHandler && field.editHandler({row, value})
    cancelEditHandle()
  }

  useEffect(() => {
    if (isEdit === cellKey) {
      setValue(targetValue)
    }
  }, [isEdit])

  useEffect(() => {
    if (editType === TABLE_CELL_EDIT_TYPE.numberField && !targetValue) {
      setValue(0)
    }
  }, [isEdit])

  if(isEdit !== cellKey) {
    switch (editType) {
      case TABLE_CELL_EDIT_TYPE.fileLoad:
      case TABLE_CELL_EDIT_TYPE.uniqField:
        return (
          <td key={cellKey}>
            <div className={s.wrapper}>
              {children}
              {
                field.editable && isEdit !== cellKey &&
                <div
                  onClick={handleEdit}
                  className={clsx(s.iconWrapper)}>
                  <EditIcon/>
                </div>
              }
            </div>
          </td>
        )
      default:
        return (
          <td key={cellKey}>
            <div className={s.wrapper}>
              {children}
              {
                field.editable && !field.component && isEdit !== cellKey &&
                <div
                  onClick={() => editHandle(cellKey)}
                  className={clsx(s.iconWrapper)}>
                  <EditIcon/>
                </div>
              }
            </div>
          </td>
        )
    }
  }

  switch (editType) {
    case TABLE_CELL_EDIT_TYPE.numberField:
    case TABLE_CELL_EDIT_TYPE.textField:
      return (
        <td key={cellKey}>
          <div className={s.wrapper}>
            <UIField.InputRaw
              name={'cell'}
              onChange={editType === TABLE_CELL_EDIT_TYPE.textField
                ? changeTextHandle
                : changeNumberHandle
              }
              value={String(value)}
              customInputStyle={{padding: 0}}
              customInputWrapperClassName={s.noRelative}
            />
            <div className={s.btnBlock}>
              <UIButton.EmptyBtn
                handler={cancelEditHandle}
                style={{padding: 0}}
                leftIcon={<CancelIco/>}/>
              <UIButton.EmptyBtn
                handler={handleEdit}
                style={{padding: 0}}
                leftIcon={<ApplyIco/>}/>
            </div>
          </div>
        </td>
      )
    case TABLE_CELL_EDIT_TYPE.multilineTextField:
      return (
        <td key={cellKey}>
          <div className={s.wrapper}>
            <UITextAreaRaw
              onChange={changeTextHandle}
              value={String(value)}
              maxLength={field?.maxLength}
            />
            <div className={s.btnBlock}>
              <UIButton.EmptyBtn
                handler={cancelEditHandle}
                style={{padding: 0}}
                leftIcon={<CancelIco/>}/>
              <UIButton.EmptyBtn
                handler={handleEdit}
                style={{padding: 0}}
                leftIcon={<ApplyIco/>}/>
            </div>
          </div>
        </td>
      )
    case TABLE_CELL_EDIT_TYPE.fileLoad:
    case TABLE_CELL_EDIT_TYPE.uniqField:
    default:
      return (
        <td key={cellKey}>
          <div className={s.wrapper}>
            {children}
            {
              field.editable && !field.component && isEdit !== cellKey &&
              <div
                onClick={() => editHandle(cellKey)}
                className={clsx(s.iconWrapper)}>
                <EditIcon/>
              </div>
            }
          </div>
        </td>
      )
  }
}

/**
 * Строитель полей таблицы
 * @param row - Инстанс строки таблицы
 * @param rowId - id строки
 * @param editHandle - обработчик режима редактирования
 * @param cancelEditHandle - обработчик отмены режима редактирования
 * @param config - Массив назначенных конфигурацией полей для отображения
 * @param isEdit - ссылка на редактируемую ячейку
 */

const getRowsByConfig = (
  row: any,
  rowId: number,
  editHandle: (key: string) => void,
  cancelEditHandle: () => void,
  config: IStructureConfig[],
  isEdit?: string,
): any[] =>
  config.map((el, id) =>
    <Row
      key={`${id}`}
      rowId={rowId}
      field={el}
      row={row}
      cellId={id}
      isEdit={isEdit}
      editHandle={editHandle}
      cancelEditHandle={cancelEditHandle}
    />)

const additionCheckbox = (
  elementId: number,
  handler?: any,
  selectedRows?: number[],
  excludedRows?: number[],
  isAllSelected?: boolean
) => (
  <td key={'special'} className={"selectableTableCell"} style={{textAlign: 'center'}}>
    <UICheckbox
      style={{marginBottom: -2}}
      handler={handler}
      value={isAllSelected ? !excludedRows?.includes(elementId) : selectedRows?.includes(elementId)}
      valId={elementId}
    />
  </td>
)

/**
 * Тело таблицы
 * @param structureConfig - Структурный конфиг полей
 * @param rows - Массив строк таблицы
 * @param onRowSelect - Обработчки селекта
 * @param selectable - Маркер выделения поля, если true позволяет помечать строку как выделенную
 * @param selectedRows - Массив выделенных строк
 * @param excludedRows
 * @param isAllSelected
 */
const TableBody = ({
                     structureConfig,
                     rows,
                     selectable,
                     onRowSelect,
                     selectedRows,
                     excludedRows,
                     isAllSelected
                   }: ITableBody) => {
  const [isEdit, setIsEdit] = useState<string | undefined>()

  const editHandle = (key: string) => setIsEdit(key)
  const cancelEditHandle = () => setIsEdit(undefined)
  const completedRows = (row: any, id: number) =>
    [
      !!selectable && additionCheckbox(row.id, onRowSelect, selectedRows, excludedRows, isAllSelected),
      ...getRowsByConfig(row, id, editHandle, cancelEditHandle, structureConfig, isEdit)
    ]

  if(!rows?.length) return <></>
  return (
    <tbody>
    {rows.map((row, id) => <tr key={id}>{completedRows(row, id)}</tr>)}
    </tbody>
  );
};

export default TableBody;
