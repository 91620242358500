import {UIContainer} from 'components/UI/UIContainer'
import {UIButton} from "components/UI/UIButton";
import {useForm} from "react-hook-form";
import React, {useState} from "react";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import {ITemplateForm} from "models/InstanceInterfaces/ITemplateForm";
import {IIdentifier, IResponseExtraData} from "models/common";
import FormsService from "services/FormsService";
import Select from "components/UI/UIField/items/select";
import {useSelector} from "react-redux";
import {selectUsers} from "store/users/reselect";
import {RootState} from "store";
import s from './s.module.css'
import UsersService from "services/UsersService";
import {OptionData} from "models/FilterData";
import {useEaseSearchParams} from "../../../hooks/useEaseSearchParams";
import {addNotification} from "../../../lib/addNotification";
import {NOTIFICATOR_TYPE} from "../../../lib/appConst";
import plural from "plural-ru";
import {goodRequest} from "../../../lib/apiHelpers";
import Preloader from "../../../components/UI/Preloader/Preloader";
import {AxiosError} from "axios";
import {parseFilterParamsForRequest} from "../../../lib/parseFilterParams";
import {defaultUsersFilterValues, TUsersPageFilterCredentials} from "../../filters/UsersPageFilter";
import {getAllUsersAction} from "../../../store/users/thunk";
import {useAppDispatch} from "../../../hooks/redux";

interface IForm {
  templateFormId: OptionData
}

const pluralUser = (n: number) =>
  plural(n, 'пользователю', 'пользователям')

const SetTemplateToUserByFilter = () => {
  const dispatch = useAppDispatch()
  const [params] = useEaseSearchParams()
  const { control, handleSubmit, watch, formState: {isSubmitting} } = useForm<IForm>({
    defaultValues: {
      templateFormId: undefined
    },
  })

  const templateWatcher = watch('templateFormId')

  const {users: {count}} = useSelector((state: RootState) => ({
    users: selectUsers(state)
  }))

  const [templateFormFilter, setTemplateFormFilter] = useState('')
  const [
    templatesRowsSelect,
    templatesPaginateTrigger,
  ] = usePaginationItemsFromRequest<(ITemplateForm & IIdentifier & IResponseExtraData)>({
    request: FormsService.getAllFormTemplates,
    filter: templateFormFilter,
  })

  const onSubmit = async ({templateFormId}: IForm) => {
    try {
      const res = await UsersService.usersToTemplateForm(templateFormId.value, parseFilterParamsForRequest(params, defaultUsersFilterValues),
      )
      if(goodRequest(res.status)){
        addNotification({type: NOTIFICATOR_TYPE.apply, text: "Изменение успешно"})
        dispatch(getAllUsersAction(parseFilterParamsForRequest<TUsersPageFilterCredentials>(params, defaultUsersFilterValues)))
      }
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }

  return (
    <UIContainer.ContentContainer
      header={'Установить шаблон по фильтру'}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <Select
          selectName={"Список шаблонов"}
          options={templatesRowsSelect}
          paginateTrigger={templatesPaginateTrigger}
          filterSetter={setTemplateFormFilter}
          name={"templateFormId"}
          control={control}
          disabled={isSubmitting}
          withClearOption
          isFilter
        />
        {templateWatcher && <p className={s.count}>Шаблон будет применен к {count} {pluralUser(count)}</p>}
        <UIButton.DefaultBtn
          disabled={!templateWatcher}
          style={{marginTop:'32px'}}
          label='Сохранить'
          leftIcon={isSubmitting && <Preloader width={16} height={16} /> }
        />
      </form>
    </UIContainer.ContentContainer>
  )
}

export default SetTemplateToUserByFilter
