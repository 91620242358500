import {Route, Routes} from "react-router-dom";
import React from "react";
import {AuthBox} from "../AuthBox";
import SignInOIDC from "pages/SignInOIDC";

const AuthRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthBox />}/>
      <Route path="/signin-oidc" element={<SignInOIDC />}/>
    </Routes>
  );
};

export default AuthRouter;
