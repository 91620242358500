import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import DealerUsersModalView from "./DealerUsersModalDunb";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {getDealersActionHelper} from "lib/apiHelpers";
import {IDealerExtended} from "models/DealersServiceModel";
import DealersService from "services/DealersService";

export interface IDealerUsersModal {
  dealerId: number
}

/**
 * Тип данных IDealerUsersModalCredentials
 */
export type IDealerUsersModalCredentials = {
  fullName:	string,
  role: {
    name: string
    value: number | undefined
  },
  isExternal: {
    name: string
    value: boolean | undefined
  },
  page: number
}

/**
 * HOC модального окна по привязке пользователей к дилеру
 * @constructor
 */
const DealerUsersModal = () => {
  const [{dealerId}] = useEaseSearchParams()

  const [dealer, setDealer] = useState<IDealerExtended>()
  const [loadDealer, setLoadDealer] = useState(true)

  const { control, handleSubmit, formState } = useForm<IDealerUsersModalCredentials>({
    defaultValues: {
      isExternal: {
        name: '',
        value: undefined
      },
      fullName: '',
      role: {
        name: '',
        value: undefined
      },
      page: 1 //Увеличивать на 1 при пагинации, приравнивать к 1 при запросах после изменения фильтра
    }
  })

  const onSubmit = useCallback((data: IDealerUsersModalCredentials) => {
    const filterData: {
      page: number,
      count: number,
      fullName?: string,
      isExternal?: boolean
    } = {
      page: data ? data.page : 1,
      count: 10,
      fullName: data ? data.fullName : '',
    }
    if (data) {
      if (data.isExternal.value !== undefined) filterData.isExternal = data.isExternal.value;
    }
  }, [])

  /**
   * Перетащить в либу и переделать - добавить userId
   * @param dealerId
   * @param isBinded
   * @param userId
   */
  const bindUserHandler = (dealerId: string, isBinded: boolean, userId: number) => {
    isBinded
      ? DealersService.deleteDealerFromUser(dealerId, userId).then(() => getDealersActionHelper()).then(getDealer)
      : DealersService.putDealerToUser(dealerId, +userId).then(() => getDealersActionHelper()).then(getDealer)
  }

  useEffect(() => {
    getDealer()
  }, [dealerId])

  const getDealer = () => {
    if(dealerId) DealersService.get(dealerId).then((r) => {
      setDealer(r.data)
      setLoadDealer(false)
    })
  }

  return (
    <DealerUsersModalView
      control={control}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      formState={formState}
      dealerId={dealerId}
      checkBoxClickHandler={bindUserHandler}
      dealer={dealer}
      loadDealer={loadDealer}
      // errorMessage={errorMessage}
    />
  )
};

export default DealerUsersModal;
