import React from 'react';
import s from './s.module.css'
import {FormsPageFilter} from "modules/filters/FormsPageFilter";
import DealersPageFilter from "../../../filters/DealersPageFilter";
import UsersPageFilter from "../../../filters/UsersPageFilter";
import TodosPageFilter from "../../../filters/TodosPageFilter";
import AnalyticsReportPageFilter from "../../../filters/AnalyticsReportPageFilter";
import {LoggingPageFilter} from "../../../filters/LoggingPageFilter";
import {useEaseSearchParams} from "../../../../hooks/useEaseSearchParams";
import {AppealPageFilter} from "../../../filters/AppealsPageFilter";
import AnalyticUnloadPageFilter from 'modules/filters/AnalyticUnloadPageFilter';
import AnalyticsGraphPageFilter from 'modules/filters/AnalyticsGraphPageFilter';
import AnalyticUnloadCreate from 'modules/filters/AnalyticUnloadCreate';
import AnalyticsAppealsPageFilter from 'modules/filters/AnalyticsAppealsPageFilter';

export enum FILTER_IDS {
  FormFilter = "FormFilter",
  AppealsFilter = "AppealsFilter",
  DealersFilter = 'DealersFilter',
  ChecksFilter = 'ChecksFilter',
  AnalyticsFilter = 'AnalyticsFilter',
  UsersFilter = 'UsersFilter',
  LoggingFilter = 'LoggingFilter',
  TodosFilter = 'TodosFilter',
  AnalyticReportFilter = 'AnalyticReportFilter',
  AnalyticAppealsFilter = 'AnalyticAppealsFilter',
  AnalyticGraphFilter = 'AnalyticGraphFilter',
  AnalyticUnloadFilter = 'AnalyticUnloadFilter',
  AnalyticUnloadCreate = 'AnalyticUnloadCreate',
}

export interface IFilterProps {
  data: FILTER_IDS
}

const resolveFilter = (filterId: FILTER_IDS) => {
  switch (filterId) {
    case FILTER_IDS.FormFilter:
      return <FormsPageFilter />
    case FILTER_IDS.AppealsFilter:
      return <AppealPageFilter />
    case FILTER_IDS.DealersFilter:
      return <DealersPageFilter />
    case FILTER_IDS.UsersFilter:
      return <UsersPageFilter />
    case FILTER_IDS.LoggingFilter:
      return <LoggingPageFilter />
    case FILTER_IDS.TodosFilter:
      return <TodosPageFilter />
    case FILTER_IDS.AnalyticReportFilter:
      return <AnalyticsReportPageFilter />
    case FILTER_IDS.AnalyticAppealsFilter:
      return <AnalyticsAppealsPageFilter />
    case FILTER_IDS.AnalyticGraphFilter:
      return <AnalyticsGraphPageFilter />
    case FILTER_IDS.AnalyticUnloadFilter:
      return <AnalyticUnloadPageFilter />
    case FILTER_IDS.AnalyticUnloadCreate:
      return <AnalyticUnloadCreate />
    default:
      return <></>
  }
}

const Filter = () => {
  const [{filterId}] = useEaseSearchParams()
  return (
    <div className={s.container}>
      {resolveFilter(filterId)}
    </div>
  );
};

export default Filter;
