import { useRef, useState, useEffect } from 'react'

export type useDropzonePropsType = {
  loading?: boolean,
  onDrop: (e: DragEvent) => void
}

// drag'n'drop зона для вставки файлов
export const useDropzone = ({
  loading,
  onDrop,
}: useDropzonePropsType) => {

  const dropRef = useRef<HTMLDivElement>(null)
  const [dragging, setDragging] = useState<boolean>(false)
  const [heightDropzone, setHeightDropzone] = useState<string>('inherit')

  useEffect(() => {
    const handleDrag = (e: DragEvent) => {
      if (!loading) {
        e.preventDefault()
        e.stopPropagation()
      }
    };

    const handleDragIn = (e: DragEvent) => {
      if (!loading) {
        e.preventDefault()
        e.stopPropagation()

        if (e.dataTransfer === null) return false

        if (e.dataTransfer.files) {
          setDragging(true);
        }
      }
    };

    const handleDragOut = (e: DragEvent) => {
      if (!loading) {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
      }
    };

    const handleDrop = (e: DragEvent) => {
      if (!loading) {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)

        if (e.dataTransfer === null) return false

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
          onDrop(e)
          e.dataTransfer.clearData()
        }
      }
    };

    const enableDragEventListeners = () => {
      const div: HTMLElement | null = dropRef && dropRef.current;
      if (div) {
        div.addEventListener('dragenter', handleDragIn);
        div.addEventListener('dragleave', handleDragOut);
        div.addEventListener('dragover', handleDrag);
        div.addEventListener('drop', handleDrop);
      }
    };

    const disableDragEventListeners = () => {
      const div: HTMLElement | null = dropRef && dropRef.current;
      if (div) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDrop);
      }
    };

    enableDragEventListeners()

    return () => {
      disableDragEventListeners()
    };
  }, [loading, dropRef]);

  // Для существующего рефа возвращаем высоту этого же элемента, чтобы при измении содержимого - высота блока не
  // изменялась
  useEffect(() => {
    if (dropRef && dropRef.current) {
      setHeightDropzone(`${dropRef.current.clientHeight}px`)
    }
  }, [dropRef])

  return {
    dropRef,
    dragging,
    heightDropzone,
  }
}
