import {createAsyncThunk} from '@reduxjs/toolkit'
import {addNotification} from 'lib/addNotification'
import {NOTIFICATOR_TYPE} from 'lib/appConst'
import DealersService from '../../services/DealersService'
import {AxiosError} from "axios";

/**
 * Получить диллеров и добавить в стейт
 */
export const getAllDealersAction = createAsyncThunk(
  'dealers/getAll',
  async (params: {
    page: number
    count: number
    stateCode?: string
    distName?: string
    cityName?: string
    groupIds?: string[]
  }): Promise<any> => {
    try {
      const response = await DealersService.getAll<{
        stateCode: string
        distName: string
        cityName: string
        groupIds: string[]
      }>(params)
      return response.data
    } catch (e: any) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  },
)

/**
 * Получить группы диллеров
 */
export const getAllDealersGroupsAction = createAsyncThunk(
  'dealers/getAllGroups',
  async (params: {page: number; count: number}): Promise<any> => {
    try {
      const response = await DealersService.getAllDealerGroup(params)
      return response.data
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  },
)
