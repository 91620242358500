import { useState, useEffect } from 'react'
import {useForm} from "react-hook-form";
import { UIContainer } from 'components/UI/UIContainer'
import UIField from 'components/UI/UIField'
import { UIButton } from 'components/UI/UIButton';
import FormsService from 'services/FormsService';
import UsersService from 'services/UsersService';
import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest';
import {removeModalItem, setModal} from 'lib/setModal';
import { ModalsEnum } from 'modules/ModalController/modalManager';
import { OptionData } from 'models/FilterData';
import { IWave } from 'models/InstanceInterfaces/IWave';
import { IKindForm } from 'models/InstanceInterfaces/IKindForm';
import { ITypeForm } from 'models/InstanceInterfaces/ITypeForm';
import { Roles } from "models/Roles";
import { IIdentifier } from 'models/common';
import { IUser, IUserFilter } from "models/InstanceInterfaces/IUser";
import { useDispatch, useSelector } from 'react-redux';
import {
  setNewFormData,
  setTypeFormId,
  setKindFormId,
  setTypeFormName,
  setKindFormName,
  resetNewFormData
} from 'store/forms';
import { selectNewFormData, selectTypeFormId, selectKindFormId, selectTypeFormName, selectKindFormName } from 'store/forms/reselect';
import addIcon from 'assets/svg/button/cross.svg'
import s from './s.module.css'
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {addNotification} from "lib/addNotification";
import {NOTIFICATOR_TYPE} from "lib/appConst";
import * as yup from "yup";
import { object, string } from 'yup'
import {errorMessages} from "lib/errorMessages";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {RootState} from "store";
import {IDealer} from "models/DealersServiceModel";
import Preloader from "components/UI/Preloader/Preloader";
import {AxiosError} from "axios";

const selectOptionResolver = {name: string().min(1, errorMessages.common.inputRequired).required()}
const resolverSchema = yup.object({
  controlUser:	object(selectOptionResolver),
  wave: object(selectOptionResolver),
  templateForm: object(selectOptionResolver),
  lastDate:	string().required(errorMessages.common.inputRequired)
})

export type TDefaultFormDataType = {
  controlUser: { name:'', value: 0 },
  wave: { name:'', value: 0 },
  templateForm: {name: '', value: 0},
  dealers: (IDealer & IIdentifier)[],
  lastDate: undefined,
}

export const defaultFormData: TDefaultFormDataType = {
  controlUser: { name:'', value: 0 },
  wave: { name:'', value: 0 },
  templateForm: {name: '', value: 0},
  dealers: [],
  lastDate: undefined,
}

const NewFormModal = () => {
  const dispatch = useDispatch()
  const {
    formData,
    typeFormId,
    kindFormId,
    typeFormName,
    kindFormName
  } = useSelector((state: RootState) => ({
    formData: selectNewFormData(state),
    typeFormId: selectTypeFormId(state),
    kindFormId: selectKindFormId(state),
    typeFormName: selectTypeFormName(state),
    kindFormName: selectKindFormName(state),
  }))

  const [dealerIds, setDealerIds] = useState<any>([])
  const [dealersName, setDealersName] = useState<any>([])
  const { control, handleSubmit, watch, reset, formState} = useForm<any>({
    defaultValues: formData,
    resolver: yupResolver(resolverSchema)
  })
  const [, setParams ] = useEaseSearchParams()

  useEffect(() => {
    setDealerIds(formData.dealers.map((item: IDealer & IIdentifier) => item.id))
    setDealersName(formData.dealers.map((item: IDealer) => item.name))
  }, [])

  const onSubmit = async (data: any) => {
    try {
      // получаем год и квартал
      const year = Number(watch('lastDate').format('YYYY'))
      const quarter = Math.floor((Number(watch('lastDate').format('MM')) + 2) / 3);
      // формируем объект для реквеста
      const dataForRequest = {
        year,
        quarter,
        dealerIds,
        controlUserId: Number(data.controlUser.value),
        templateFormId: data.templateForm.value,
        waveId: data.wave.value,
        lastDate: data.lastDate
      }
      await FormsService.createManyForm(dataForRequest)
      reset(defaultFormData)
      removeModal()
      addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Анкета успешно создана!'})
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }

  const removeModal = () => {
    dispatch(resetNewFormData())
    removeModalItem(setParams)
  }

  const handleFormDataToStore = () => {
    const data = watch()
    dispatch(setNewFormData(data))
   }
  const handleTypeFormId = (id: any) => {
    dispatch(setTypeFormId(id))
  }
  const handleKindFormId = (id: any) => {
    dispatch(setKindFormId(id))
  }
  // нужны параметры в эндпоинтах для фильтрации
  useEffect(() => {
    doTemplateFormFilterRefetch()
    doTypeFormFilterRefetch()
  }, [typeFormId])

  useEffect(() => {
    doKindFormFilterRefetch()
  }, [kindFormId])

  // Подгрузка данных в инпуты
  const [controlUserFilter, setControlUserFilter] = useState('')
  const [
    controlUserRowsSelect,
    controlUserPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IUser & IIdentifier), IUserFilter>({
    request: UsersService.getAll,
    filter: controlUserFilter,
    filterField: 'fullName',
    requestFilter: {roleIds: [Roles.auditor]},
    dataConverter: (element): OptionData => ({name: element.fullName, value: element.id})
  })

  const [waveFilter, setWaveFilter] = useState('')
  const [
    waveRowsSelect,
    wavePaginateTrigger,
    ,
    waveExtraData
  ] = usePaginationItemsFromRequest<IWave>({
    request: FormsService.getAllWaves,
    filter: waveFilter,
  })

  const [templateFormFilter, setTemplateFormFilter] = useState('')
  const [
    templateFormRowsSelect,
    templateFormPaginateTrigger,
    doTemplateFormFilterRefetch,
    templateFormExtradata
  ] = usePaginationItemsFromRequest<(ITypeForm)>({
    request: FormsService.getAllFormTemplates,
    filter: templateFormFilter,
    requestFilter: {typeFormId}
  })

  const [kindFormFilter] = useState('')
  const [
    ,
    ,
    doKindFormFilterRefetch,
  ] = usePaginationItemsFromRequest<(IKindForm)>({
    request: FormsService.getAllFormKinds,
    filter: kindFormFilter,
  })

  const [typeFormFilter] = useState('')
  const [
    ,
    ,
    doTypeFormFilterRefetch,
  ] = usePaginationItemsFromRequest<(ITypeForm)>({
    request: FormsService.getAllFormTypes,
    filter: typeFormFilter,
  })

  const addButtonStyle = {
    border: '1px solid #ccc',
    color: '#4D5964',
    backgroundColor: '#fff',
    height: '36px',
    marginBottom:'10px',
  }
  const waveStyle = {
    width:'100%'
  }

  const getSelectedType = async (id: number) => {
    const res = await FormsService.getAllFormTypes({page:1,count:100})
    const name = res.data.rows.filter(i => i.id === id)[0].name
    dispatch(setTypeFormName(name))
  }

  const getSelectedKind = async (id: number) => {
    const res  = await FormsService.getAllFormKinds({page:1,count:100})
    const name = res.data.rows.filter(i => i.id === id)[0].name
    dispatch(setKindFormName(name))
  }

  return (
    <UIContainer.ContentContainer
      header={'Новая анкета'}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={s.wrapper}>
        <UIField.Select
          control={control}
          selectName={'ФИО проверяющего'}
          name={"controlUser"}
          options={controlUserRowsSelect}
          isFilter
          paginateTrigger={controlUserPaginateTrigger}
          filterSetter={setControlUserFilter}
          onHandleChange={() => {
            handleFormDataToStore()
          }}
          errors={formState.errors}
        />
        <div className={s.waveWrap}>
          <UIField.Select
            control={control}
            selectName={"Волна"}
            name={"wave"}
            options={waveRowsSelect}
            isFilter
            paginateTrigger={wavePaginateTrigger}
            filterSetter={setWaveFilter}
            onHandleChange={(evt: any) => {
              const id = waveExtraData.filter((i: any) => i.id === evt.value)[0].typeFormId
              handleTypeFormId(id)
              handleFormDataToStore()
              getSelectedType(id)
            }}
            customStyle={waveStyle}
            errors={formState.errors}
          />
          <UIButton.BorderBtn
            type='button'
            style={{...addButtonStyle, whiteSpace: 'nowrap',}}
            label={'создать волну'}
            handler={() => {
              setModal(ModalsEnum.NewWaveModal, {})
            }}
          />
        </div>
        <UIField.Select
          control={control}
          selectName={"Шаблон анкеты"}
          name={"templateForm"}
          options={templateFormRowsSelect}
          isFilter
          paginateTrigger={templateFormPaginateTrigger}
          filterSetter={setTemplateFormFilter}
          onHandleChange={(evt: any) => {
            handleFormDataToStore()
            const id = templateFormExtradata.filter((i: any) => i.id === evt.value)[0].kindFormId
            handleKindFormId(id)
            handleFormDataToStore()
            getSelectedKind(id)
          }}
          errors={formState.errors}
        />
        <div className={s.uncontrolledField}><div>{typeFormName}</div></div>
        <div className={s.uncontrolledField}><div>{kindFormName}</div></div>
        <UIField.Datepicker
          control={control}
          name="lastDate"
          placeholder={'Укажите крайнюю дату визита'}
          extraHandler={handleFormDataToStore}
          errors={formState.errors}
        />
        <div
          className={s.customInput}
          onClick={() => setModal(ModalsEnum.BindDealerModal, {})}
        >
          {
            dealersName.length
              ? <ul className={s.dealersNameList}>
                  {
                  dealersName.map((item: any, index: number) => (<li key={index}>{item}</li>))
                  }
                </ul>
              : <span className={s.custonInputName} >Привязка к ДЦ</span>
          }
          <img src={addIcon} alt="Иконка привязать к ДЦ" className={s.icon}/>
        </div>
        <UIButton.DefaultBtn
          label={'Создать анкету'}
          leftIcon={formState.isSubmitting ? <Preloader width={16} height={16}/> : <></>}
        />
      </form>
    </UIContainer.ContentContainer>
  )
}

export default NewFormModal
