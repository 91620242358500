import React, {ChangeEvent, useEffect, useState} from 'react';
import {ReactComponent as ArrowIcon} from "assets/svg/TableControlFields/arrow.svg";
import clsx from "clsx";

import sCommon from "../../../TableSelectedItemsControl/s.module.css";
import sCommonFieldsStyle from "../../s.module.css";
import s from "./s.module.css";
import {IDataRequestHook} from "models/common";
import {useInView} from "react-intersection-observer";
import {useDebounce} from "../../../../../../hooks/useDebounce";

export interface ITableFieldDropdown<T> {
  label: string
  useDataRequest: () => IDataRequestHook<T>
  handler: (val: any) => void
  filterKey?: string
}

const TableFieldDropdown = ({label, useDataRequest, handler, filterKey = "name"}: ITableFieldDropdown<any>) => {
  const [isActive, setIsActive] = useState(false)
  const [isFocus, setIsFocus] = useState(false)
  const [filter, setFilter] = useState('')

  const debouncedFilter = useDebounce(filter, 300)

  const toggleActive = (x?: boolean) =>
    setIsActive(p => x !== undefined ? x : !p)


  const {ref, entry} = useInView({
    threshold: 0
  })

  const blurHandler = () => {
    if(!isFocus) {
      setIsActive(false)
      setFilter('')
    }
  }
  const inputHandler = ({target: {value}}: ChangeEvent<HTMLInputElement>) => setFilter(value)

  const dataInstance = useDataRequest()

  useEffect(() => {
    dataInstance && dataInstance.setFilterKey(filterKey)
  }, [])

  useEffect(() => {
    if (entry?.isIntersecting && dataInstance && Object.hasOwn(dataInstance, "pageChange")) {
      const {pageChange} = dataInstance
      pageChange()
    }
  }, [entry])

  useEffect(() => {
    dataInstance && dataInstance.setFilter(debouncedFilter)
  }, [debouncedFilter])

  return (
    <button
      className={clsx(s.dropdownBtn)}
      onMouseOver={() => setIsFocus(true)}
      onMouseLeave={() => setIsFocus(false)}
      onBlur={blurHandler}
    >
      <div className={clsx(s.wrapper, sCommonFieldsStyle.cursor)} onClick={() => toggleActive(true)}>
        {
          isActive
            ? <input
              className={s.dropdownInput}
              type="text"
              style={{width: '100%'}}
              onChange={inputHandler}
              value={filter}
              placeholder={label}
              autoFocus={true}
            />
            : <label className={clsx(sCommon.label, sCommonFieldsStyle.cursor)}>{label}</label>
        }
        <div className={clsx(s.iconWrapper, {
          [s.iconRotate]: isActive
        })}>
          <ArrowIcon/>
        </div>
      </div>
      {
        isActive && (
          <ul className={s.list}>
            {
              !!dataInstance?.data.length && dataInstance?.data.map((el, id) => (
                <React.Fragment key={el?.id || id}>
                  <TableFieldDropdownElement
                    id={el?.id}
                    name={el?.name}
                    handler={() => handler(el?.id)}
                  />
                </React.Fragment>
              ))
            }
            <div ref={ref}/>
          </ul>
        )
      }
    </button>
  );
};

export default TableFieldDropdown;

interface ITableFieldDropdownElement {
  id: number
  name: string
  handler: (val: any) => void
}

const TableFieldDropdownElement = ({id, name, handler}: ITableFieldDropdownElement) => {
  const handleClick = (id: number, name: string) => () => handler({id, name})
  return (
    <li
      className={s.listItem}
      onClick={handleClick(id, name)}
    >{name}</li>
  )
}
