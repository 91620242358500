import {Control} from "react-hook-form";
import React, {Dispatch, memo, SetStateAction} from "react";
import {UIContainer} from "components/UI/UIContainer";
import s from "./s.module.css";
import Select from "components/UI/UIField/items/select";
import {UIButton} from "components/UI/UIButton";
import {ReactSVG} from "react-svg";
import clsx from "clsx";
import ResetSVG from "assets/svg/filter/resetIcon.svg";
import {OptionData} from "models/FilterData";
import {TFormsPageFilterCredentials} from "./index";


/**
 * Интерфейс данных для FormsFilterView
 */
interface FormsFilterViewProps {
  onSubmit: Function
  handleSubmit: Function
  control: Control<TFormsPageFilterCredentials>
  resetHandle: () => void

  waveRowsSelect: OptionData[]
  wavePaginateTrigger: () => void
  setWaveFilter: Dispatch<SetStateAction<string>>

  controlUserRowsSelect: OptionData[]
  controlUserPaginateTrigger: () => void
  setControlUserFilter: Dispatch<SetStateAction<string>>
  controlUserDisabled?: boolean

  typeFormRowsSelect: OptionData[]
  typeFormPaginateTrigger: () => void
  setTypeFormFilter: Dispatch<SetStateAction<string>>

  kindFormRowsSelect: OptionData[]
  kindFormPaginateTrigger: () => void
  setKindFormFilter: Dispatch<SetStateAction<string>>

  dealerRowsSelect: OptionData[]
  dealerPaginateTrigger: () => void
  setDealerFilter: Dispatch<SetStateAction<string>>

  templatesRowsSelect: OptionData[]
  templatesPaginateTrigger: () => void
  setTemplateFormFilter: Dispatch<SetStateAction<string>>

  statusRowsSelect: OptionData[]
  statusPaginateTrigger: () => void
  setStatusFilter: Dispatch<SetStateAction<string>>
}

/**
 * Представление компонента фильтра для страницы с анкетами
 * @constructor
 */
const FormsPageFilterView = ({
                               onSubmit,
                               handleSubmit,
                               control,
                               resetHandle,

                               waveRowsSelect,
                               wavePaginateTrigger,
                               setWaveFilter,

                               controlUserRowsSelect,
                               controlUserPaginateTrigger,
                               setControlUserFilter,
                               controlUserDisabled,

                               typeFormRowsSelect,
                               typeFormPaginateTrigger,
                               setTypeFormFilter,

                               kindFormRowsSelect,
                               kindFormPaginateTrigger,
                               setKindFormFilter,

                               dealerRowsSelect,
                               dealerPaginateTrigger,
                               setDealerFilter,

                               templatesRowsSelect,
                               templatesPaginateTrigger,
                               setTemplateFormFilter,

                               statusRowsSelect,
                               statusPaginateTrigger,
                               setStatusFilter,
                             }: FormsFilterViewProps) =>
  (
    <UIContainer.ContentContainer header={'Фильтрация'} customStyle={{paddingLeft: 20, paddingRight: 20}}>
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <Select
          selectName={"Волна"}
          options={waveRowsSelect}
          paginateTrigger={wavePaginateTrigger}
          filterSetter={setWaveFilter}
          name={"waveIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        {
          !controlUserDisabled && (
            <Select
              selectName={"Аудитор"}
              options={controlUserRowsSelect}
              paginateTrigger={controlUserPaginateTrigger}
              filterSetter={setControlUserFilter}
              name={"controlUserIds"}
              control={control}
              withClearOption
              isFilter
              multiSelect
            />
          )
        }
        <Select
          selectName={"Тип анкеты"}
          options={typeFormRowsSelect}
          paginateTrigger={typeFormPaginateTrigger}
          filterSetter={setTypeFormFilter}
          name={"typeFormIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <Select
          selectName={"Виды анкеты"}
          options={kindFormRowsSelect}
          paginateTrigger={kindFormPaginateTrigger}
          filterSetter={setKindFormFilter}
          name={"kindFormIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <Select
          selectName={"Дилеры"}
          options={dealerRowsSelect}
          paginateTrigger={dealerPaginateTrigger}
          filterSetter={setDealerFilter}
          name={"dealerIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <Select
          selectName={"Шаблон"}
          options={templatesRowsSelect}
          paginateTrigger={templatesPaginateTrigger}
          filterSetter={setTemplateFormFilter}
          name={"templateFormIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <Select
          selectName={"Статус"}
          options={statusRowsSelect}
          paginateTrigger={statusPaginateTrigger}
          filterSetter={setStatusFilter}
          name={"statusIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <div className={s.controls}>
          <UIButton.DefaultBtn
            label={"Показать"}
          />
          <div className={s.resetWrapper} onClick={resetHandle}>
            <div className={s.resetHeader}>Сбросить</div>
            <ReactSVG
              data-testid="resetFilter"
              className={clsx(s.resetElement)}
              src={ResetSVG}
            />
          </div>
        </div>
      </form>
    </UIContainer.ContentContainer>
  )


export default memo(FormsPageFilterView);
