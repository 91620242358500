import {store} from 'store'
import {addModalToStuck, removeAllModals, removeModalToStuck} from 'store/common'
import {ModalsEnum} from '../modules/ModalController/modalManager'

export const setModal = (modalId: ModalsEnum, data?: any) => {
  store.dispatch(addModalToStuck({
    modalId,
    data
  }))
}

/**
 * Очищаем параметры текущей модалки, удаляем инстанс из стака
 */
export const removeModalItem = (setParams: any) => {
  const modalStuck = store.getState().common.modalStuck

  const dataKeys = (modalStuck.length && modalStuck[modalStuck.length - 1]?.data)
    ? Object.keys(modalStuck[modalStuck.length - 1]?.data)
    : []
  setParams((p: any) => Object.keys(p).reduce((acc, el) => {
    if(dataKeys.length && dataKeys.includes(el)) {
      return {...acc, [el]: "", modalId: ""}
    } else {
      return {...acc, modalId: ""}
    }
  }, {...p}))
  setTimeout(() => store.dispatch(removeModalToStuck()), 16)
}

export const removeAllModalStore = (setParams: any) => {
  const modalStuck = store.getState().common.modalStuck

  let dataKeys: string[] = []

  if (modalStuck.length) {
    for (let i = modalStuck.length - 1; i >= 0; i--) {
      if (Object.keys(modalStuck[i]?.data)) {
        dataKeys.push(...Object.keys(modalStuck[i].data))
      }
    }
  }

  setParams((p: any) => Object.keys(p).reduce((acc, el) => {
    if(dataKeys.length && dataKeys.includes(el)) {
      return {...acc, [el]: "", modalId: ""}
    } else {
      return {...acc, modalId: ""}
    }
  }, {...p}))

  setTimeout(() => store.dispatch(removeAllModals()), 16)
}
