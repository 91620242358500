import React from 'react';
import s from './s.module.css'
import clsx from "clsx";
import {UIButtonCommonType} from "../../index";

export type DefaultBtnType = {
  label: string
  leftIcon?: any
  rightIcon?: any
}

const DefaultBtn = ({label, leftIcon, rightIcon, handler, style = {}, type = "submit", title, disabled}: DefaultBtnType & UIButtonCommonType) => {
  return (
    <button
      style={style}
      type={type}
      className={clsx(s.button, 'button')}
      onClick={handler}
      disabled={disabled}
      title={title}
    >
      {leftIcon && leftIcon}
      <span
        className={clsx({
          [s.leftMargin]: leftIcon,
          [s.rightMargin]: rightIcon,
        })}
      >{
        label}
      </span>
      {rightIcon && rightIcon}
    </button>
  );
};

export default DefaultBtn;
