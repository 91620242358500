import UIField from 'components/UI/UIField'
import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest'
import {OptionData} from 'models/FilterData'
import React, {useState} from 'react'
import {Control, FieldValues} from 'react-hook-form'

type Props = {
  control: Control<FieldValues, any>
  placeholder: string
  name: string
  fetchMethod: any
  isArr?: boolean
}
const Selector: React.FC<Props> = ({control, placeholder, name, fetchMethod, isArr}) => {
  const [filter, setFilter] = useState('')

  const [rowsSelect, paginateTrigger] = usePaginationItemsFromRequest<any>({
    request: fetchMethod,
    filter: filter,
    dataConverter: (el, id): OptionData => {
      return {
        name: el[name] || el.name,
        value: isArr ? el.id : id!,
      }
    },
  })

  return (
    <>
      <UIField.Select
        control={control}
        selectName={placeholder}
        name={name}
        options={rowsSelect}
        withClearOption
        isFilter
        paginateTrigger={paginateTrigger}
        filterSetter={setFilter}
      />
    </>
  )
}

export default Selector
