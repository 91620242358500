import { createAsyncThunk } from '@reduxjs/toolkit'

import FormsService from 'services/FormsService'
import {addNotification} from "../../lib/addNotification";
import {NOTIFICATOR_TYPE} from "../../lib/appConst";
import {AxiosError} from "axios";

export const getTemplateToDetail = createAsyncThunk(
  'formDetail/getTemplate',
  async (templateFormId: number) => {
    try {
      const res = await FormsService.getFormTemplateById(templateFormId)
      if(res.data) {
        return res.data
      } else {
        return res.statusText
      }
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)

export const getFormToDetail = createAsyncThunk(
  'formDetail/getForm',
  async (id: number) => {
    try {
      const res = await FormsService.getFormById(Number(id))
      if(res.data) {
        return res.data
      } else {
        return res.statusText
      }
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }
)
