import { OptionData } from 'models/FilterData'
import { calculationOptions, groupOptions } from 'lib/appOptions';

/**
 * Тип данных AnalyticsAppealsPageFilter
 */
export type AnalyticsAppealsPageFilterCredentials = {
  wavesFrom?: OptionData | null,
  wavesTo?: OptionData | null,
  templateFormId: OptionData | null,
  kindFormIds?: OptionData | null,
  typeFormIds?: OptionData | null,
  statusId?: OptionData | null,
  dealerId?: OptionData | null,
  stateCode?: OptionData | null,
  distName?: OptionData | null,
  cityName?: OptionData | null,
}

export type TAnalyticsAppealsPageFilter = {
  wavesFrom?: string,
  wavesTo?: string,
  templateFormId: string,
  kindFormIds?: string,
  typeFormIds?: string,
  statusId?: string,
  dealerId?: string,
  stateCode?: string,
  distName?: string,
  cityName?: string,
}

export const defaultAnalyticsAppealsFilterValues: AnalyticsAppealsPageFilterCredentials = {
  wavesFrom: null,
  wavesTo: null,
  templateFormId: null,
  kindFormIds: null,
  typeFormIds: null,
  statusId: null,
  dealerId: null,
  stateCode: null,
  distName: null,
  cityName: null,
}
