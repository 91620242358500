import { OptionData } from 'models/FilterData'

/**
 * Тип данных AnalyticsUnloadCreate
 */
export type AnalyticsUnloadCreateCredentials = {
  yearFrom?: OptionData | null,
  yearTo?: OptionData | null,
  quarterFrom?: OptionData | null,
  quarterTo?: OptionData | null,
  wavesFrom?: OptionData | null,
  wavesTo?: OptionData | null,
  templateFormId: OptionData | null,
  dealerId?: OptionData | null,
  stateCode?: OptionData | null,
  distName?: OptionData | null,
  cityName?: OptionData | null,
}

export type TAnalyticsUnloadCreate = {
  yearFrom?: string,
  yearTo?: string,
  quarterFrom?: string,
  quarterTo?: string,
  wavesFrom?: string,
  wavesTo?: string,
  templateFormId: string,
  dealerId?: string,
  stateCode?: string,
  distName?: string,
  cityName?: string,
}

export const defaultAnalyticsFormValues: AnalyticsUnloadCreateCredentials = {
  yearFrom: null,
  yearTo: null,
  quarterFrom: null,
  quarterTo: null,
  wavesFrom: null,
  wavesTo: null,
  templateFormId: null,
  dealerId: null,
  stateCode: null,
  distName: null,
  cityName: null,
}
