import s from './s.module.css'


type TStatisticCardProps = {
  count: string,
  name: string,
}

const StatisticCard = ({ count, name }: TStatisticCardProps) => {

  return (
    <div>
      <div className={s.count}>{count}</div>
      <div className={s.name}>{name}</div>
    </div>
  )
}

export default StatisticCard
