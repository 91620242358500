import React, {MouseEventHandler} from 'react';
import s from './s.module.css'

export interface ITextEditorButton {
  active: boolean
  onMouseDown: any
  children: JSX.Element
}

const TextEditorButton = ({active, onMouseDown, children}: ITextEditorButton) => {
  return (
    <button
      type={"button"}
      className={s.btn}
      onMouseDown={onMouseDown}
    >
      {children}
    </button>
  );
};

export default TextEditorButton;
