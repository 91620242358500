import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {UIContainer} from "components/UI/UIContainer";
import UIPageTitle from "components/UI/UIPageTitle";
import s from './s.module.css'
import {FormDetailModules} from "modules/FormDetailModules";
import {useAppDispatch} from "hooks/redux";
import {getFormToDetail} from "../../store/formDetail/thunk";
import {useSelector} from "react-redux";
import { selectFormToDetail, selectFormToDetailError, selectFormToDetailStatus } from '../../store/formDetail/reselect';
import { LOADING_STATUS } from 'lib/appConst';
import NotFoundContent from 'components/NotFoundContent';
import {selectTempQueryParams} from "../../store/forms/reselect";
import {serialiseParams} from "../../lib/parseFilterParams";

const filterToParams = (data: any) => {
  return Object.keys(data).reduce((acc, key) => `${acc ? acc : ''}${data[key] ? `&${key}=${data[key]}` : ''}`, '')
}

const FormDetail = () => {
  const {id} = useParams();
  const dispatch = useAppDispatch()
  const form = useSelector(selectFormToDetail)
  const formStatus = useSelector(selectFormToDetailStatus)
  const formError = useSelector(selectFormToDetailError)
  const tempQueryParams = useSelector(selectTempQueryParams)

  useEffect(() => {
    if(!Number.isNaN(Number(id))) {
      dispatch(getFormToDetail(Number(id)))
    }
  }, [])

  const gotInfo = form?.dealer?.name && form?.dealer?.INN && form?.dealer?.filialCode
  const isActualData = form?.id === Number(id)
  const hasError = formStatus === LOADING_STATUS.IDLE && formError

  return (
    <UIContainer.PageContainer
    >
      <div className={s.container}>
        <UIPageTitle
          label={`Анкета ${id}`}
          subtitle={hasError ? '' : gotInfo && isActualData ? `${form?.dealer?.name} ${form?.dealer?.INN} ${form?.dealer?.filialCode}` : "Загружаем..."}
          withBackBtn
          backPath={`/forms?${filterToParams(serialiseParams(tempQueryParams))}`}
          loading={!hasError && !gotInfo && !isActualData}
        />
        {hasError ? (
          <NotFoundContent />
        ) : (
          <>
            {form && isActualData && (
              <div className={s.modulesWrapper}>
                <div className={s.modulesRow}>
                  <FormDetailModules.InfoBlock form={form}/>
                  <FormDetailModules.PointsBlock form={form}/>
                </div>
                <div className={s.modulesRow}>
                  <FormDetailModules.QuestionBlock form={form}/>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </UIContainer.PageContainer>
  );
};

export default FormDetail;
