import {createAsyncThunk} from '@reduxjs/toolkit'
import DealersService from '../../services/DealersService'
import {netErrorsHandler} from '../../lib/netErrorsHandler'

/**
 * Получить дилеров
 */
export const getAllDealersAction = createAsyncThunk(
  'dealers/getAll',
  async (params: {
    page: number
    count: number
    name?: string
    distName?: string
    cityName?: string
    stateCode?: string
    groupIds?: string[]
  }): Promise<any> => {
    try {
      const response = await DealersService.getAll(params)
      return response.data
    } catch (e: any) {}
  },
)

/**
 * Получить группы дилеров
 */
export const getAllDealersGroupsAction = createAsyncThunk(
  'dealers/getAllGroups',
  async (params: {page: number; count: number}): Promise<any> => {
    try {
      const response = await DealersService.getAllDealerGroup(params)
      return response.data
    } catch (e: any) {}
  },
)

/**
 * Получить федеральные округа
 */
export const getStatesAction = createAsyncThunk(
  'dealers/getStates',
  async (params: {page: number; count: number; name: string}, {rejectWithValue}): Promise<any> => {
    try {
      const response = await DealersService.getStates(params)
      return response.data
    } catch (e: any) {
      return rejectWithValue(netErrorsHandler(e))
    }
  },
)

/**
 * Получить области
 */
export const getDistrictsAction = createAsyncThunk(
  'dealers/getDistricts',
  async (params: {page: number; count: number; name: string}, {rejectWithValue}): Promise<any> => {
    try {
      const response = await DealersService.getAllDistricts(params)
      return response.data
    } catch (e: any) {
      return rejectWithValue(netErrorsHandler(e))
    }
  },
)

/**
 * Получить города
 */
export const getCitiesAction = createAsyncThunk(
  'dealers/getCities',
  async (params: {page: number; count: number; name: string}, {rejectWithValue}): Promise<any> => {
    try {
      const response = await DealersService.getAllCities(params)
      return response.data
    } catch (e: any) {
      return rejectWithValue(netErrorsHandler(e))
    }
  },
)

/**
 * Установить пользователя к дилеру
 */
export const putDealerToUserAction = createAsyncThunk(
  'dealers/putDealerToUser',
  async (params: {dealerId: string; userId: number}, {rejectWithValue}): Promise<any> => {
    try {
      const response = await DealersService.putDealerToUser(params.dealerId, params.userId)
      return response.data
    } catch (e: any) {
      return rejectWithValue(netErrorsHandler(e))
    }
  },
)

/**
 * Удалить пользователя у дилера
 */
export const deleteDealerFromUserAction = createAsyncThunk(
  'dealers/deleteDealerFromUser',
  async (params: {dealerId: string; userId: number}, {rejectWithValue}): Promise<any> => {
    try {
      const response = await DealersService.deleteDealerFromUser(params.dealerId, params.userId)
      return response.data
    } catch (e: any) {
      return rejectWithValue(netErrorsHandler(e))
    }
  },
)
