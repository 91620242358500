import {createSlice, current} from "@reduxjs/toolkit";
import {
  deleteDealerFromUserAction,
  getAllDealersAction,
  getAllDealersGroupsAction,
  getCitiesAction,
  getDistrictsAction,
  getStatesAction, putDealerToUserAction
} from "./thunk";
import dealers from "pages/Dealers";
import {
  DealersSelectOptions,
  DealersState,
  RequestStatus,
  SelectDataStore
} from "models/storeInterface/DealersState";
import {toggleRow} from "../commonReducers";
import {GetDealersI} from "models/DealerServiceType/DealersServiceRequest";
import {selectOptionsCount} from "lib/selectConfig";
import {OptionData} from "models/FilterData";
import {IState} from "models/InstanceInterfaces/IState";
import {IDistrict} from "models/InstanceInterfaces/IDistrict";
import {ICity} from "models/InstanceInterfaces/ICity";
import {LOADING_STATUS} from "lib/appConst";

const name = 'dealers';
const initialRequestStatus: RequestStatus = {
  errorMessage: '',
  isError: false,
  isLoading: LOADING_STATUS.IDLE
}
const initialSelectData: SelectDataStore = {
  requestStatus: initialRequestStatus,
  options: [] as OptionData[],
  optionCount: 0,
  pagination: {
    currentPage: 1,
    isLastPage: false,
    maxPage: 1
  }
}
const initialState: DealersState = {
  dataList: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
    selectedRows: [],
    excludedRows: [],
    selectAll: false,
  },
  dealersSelectData: {
    [DealersSelectOptions.stateOptions]: initialSelectData,
    [DealersSelectOptions.distOptions]: initialSelectData,
    [DealersSelectOptions.cityOptions]: initialSelectData,
    [DealersSelectOptions.dealerGroupsOptions]: initialSelectData
  },
  putUser: initialRequestStatus,
  deleteUser: initialRequestStatus
}

export const dealersSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    toggleDealersRow: (state, {payload}: {payload: number | number[] | undefined}) =>
      toggleRow(initialState)<GetDealersI>(state, payload),

    updateResponsible: (state, {payload}) => {
      state.dataList.rows = state.dataList.rows.map(dealer => {
        if(dealer.id === payload.dealerId) {
          return {
            ...dealer,
            responsible: payload.responsible,
          }
        }
        return dealer
      })
    },
    /**
     * Установить начальное значение текущей страницы пагинации
     * @param state  текущее состояние
     * @param payload - тип запрашиваемых опций
     */
    setDefaultCurrentPage: (state, {payload}: {payload: DealersSelectOptions}) => {
      state.dealersSelectData[payload].pagination.currentPage = 1
    },

    /**
     * Увеличивает значение текущей страницы
     * @param state - текущее состояние
     * @param payload - тип запрашиваемых опций
     */
    increaseCurrentPage: (state, {payload}: {payload: DealersSelectOptions}) => {
      state.dealersSelectData[payload].pagination.currentPage = state.dealersSelectData[payload].pagination.currentPage + 1;
    },
    changeGroupsByDealer: (state, { payload }) => {
      state.dataList.rows = current(state.dataList.rows).map((el: any) => {
        return el.id === payload.id
          ? {...el, DealerGroups: payload.operation === 'increment' ? [...el.DealerGroups, payload.groupItem] : el.DealerGroups.filter((it: any) => it.id !== payload.groupItem.id)}
          : el
      })
    },
    editPrevDealer: (state, { payload }) => {
      state.dataList.rows = current(state.dataList.rows).map((el: any) => {
        return el.id === payload.currentDealer
          ? {
            ...el,
            prevDealer: String(payload.prevDealerData.value),
            predecessor: { ...(el.predecessor ? el.predecessor : {}), name: payload.prevDealerData.name }
          } : el
      })
    }
  },
  extraReducers: {
    [getAllDealersAction.pending.type]: (state) => {
      state.dataList.status = LOADING_STATUS.LOADING
      state.dataList.error = initialState.dataList.error
    },
    [getAllDealersAction.fulfilled.type]: (state, {payload}) => {
      state.dataList = {...state.dataList, ...payload}
      state.dataList.status = LOADING_STATUS.IDLE
    },
    [getAllDealersAction.rejected.type]: (state, {payload}) => {
      state.dataList.error = payload
      state.dataList.status = LOADING_STATUS.IDLE
    },

    /**
     * Обработка запроса федеральных округов
     */
    [getStatesAction.pending.type]: (state) => {
      state.dealersSelectData[DealersSelectOptions.stateOptions].requestStatus.isLoading = LOADING_STATUS.LOADING;
      state.dealersSelectData[DealersSelectOptions.stateOptions].requestStatus.isError = initialState.dealersSelectData[DealersSelectOptions.stateOptions].requestStatus.isError;
      state.dealersSelectData[DealersSelectOptions.stateOptions].requestStatus.errorMessage = initialState.dealersSelectData[DealersSelectOptions.stateOptions].requestStatus.errorMessage;
    },
    [getStatesAction.fulfilled.type]: (state, {payload}) => {
      state.dealersSelectData[DealersSelectOptions.stateOptions].options = payload.rows.map((option: IState) => {
        return {
          name: option.stateCode,
          value: option.stateCode
        } as unknown as OptionData
      });
      state.dealersSelectData[DealersSelectOptions.stateOptions].optionCount = payload.count;
      state.dealersSelectData[DealersSelectOptions.stateOptions].requestStatus = initialState.dealersSelectData[DealersSelectOptions.stateOptions].requestStatus;

      state.dealersSelectData[DealersSelectOptions.stateOptions].pagination.maxPage = Math.ceil(state.dealersSelectData[DealersSelectOptions.stateOptions].optionCount / selectOptionsCount);
      state.dealersSelectData[DealersSelectOptions.stateOptions].pagination.isLastPage = state.dealersSelectData[DealersSelectOptions.stateOptions].pagination.currentPage === state.dealersSelectData[DealersSelectOptions.stateOptions].pagination.maxPage;
    },
    [getStatesAction.rejected.type]: (state, {payload}) => {
      state.dealersSelectData[DealersSelectOptions.stateOptions].requestStatus.isLoading = LOADING_STATUS.IDLE;
      state.dealersSelectData[DealersSelectOptions.stateOptions].requestStatus.isError = true;
      state.dealersSelectData[DealersSelectOptions.stateOptions].requestStatus.errorMessage = payload;
    },

    /**
     * Обработка запроса областей
     */
    [getDistrictsAction.pending.type]: (state) => {
      state.dealersSelectData[DealersSelectOptions.distOptions].requestStatus.isLoading = LOADING_STATUS.LOADING;
      state.dealersSelectData[DealersSelectOptions.distOptions].requestStatus.isError = initialState.dealersSelectData[DealersSelectOptions.distOptions].requestStatus.isError;
      state.dealersSelectData[DealersSelectOptions.distOptions].requestStatus.errorMessage = initialState.dealersSelectData[DealersSelectOptions.distOptions].requestStatus.errorMessage;
    },
    [getDistrictsAction.fulfilled.type]: (state, {payload}) => {
      state.dealersSelectData[DealersSelectOptions.distOptions].options = payload.rows.map((option: IDistrict) => {
        return {
          name: option.distName,
          value: option.distName
        } as unknown as OptionData
      });
      state.dealersSelectData[DealersSelectOptions.distOptions].optionCount = payload.count;
      state.dealersSelectData[DealersSelectOptions.distOptions].requestStatus = initialState.dealersSelectData[DealersSelectOptions.distOptions].requestStatus;

      state.dealersSelectData[DealersSelectOptions.distOptions].pagination.maxPage = Math.ceil(state.dealersSelectData[DealersSelectOptions.distOptions].optionCount / selectOptionsCount);
      state.dealersSelectData[DealersSelectOptions.distOptions].pagination.isLastPage = state.dealersSelectData[DealersSelectOptions.distOptions].pagination.currentPage === state.dealersSelectData[DealersSelectOptions.distOptions].pagination.maxPage;
    },
    [getDistrictsAction.rejected.type]: (state, {payload}) => {
      state.dealersSelectData[DealersSelectOptions.distOptions].requestStatus.isLoading = LOADING_STATUS.IDLE;
      state.dealersSelectData[DealersSelectOptions.distOptions].requestStatus.isError = true;
      state.dealersSelectData[DealersSelectOptions.distOptions].requestStatus.errorMessage = payload;
    },

    /**
     * Обработка запроса городов
     */
    [getCitiesAction.pending.type]: (state) => {
      state.dealersSelectData[DealersSelectOptions.cityOptions].requestStatus.isLoading = LOADING_STATUS.LOADING;
      state.dealersSelectData[DealersSelectOptions.cityOptions].requestStatus.isError = initialState.dealersSelectData[DealersSelectOptions.cityOptions].requestStatus.isError;
      state.dealersSelectData[DealersSelectOptions.cityOptions].requestStatus.errorMessage = initialState.dealersSelectData[DealersSelectOptions.cityOptions].requestStatus.errorMessage;
    },
    [getCitiesAction.fulfilled.type]: (state, {payload}) => {
      state.dealersSelectData[DealersSelectOptions.cityOptions].options = payload.rows.map((option: ICity) => {
        return {
          name: option.cityName,
          value: option.cityName
        } as unknown as OptionData
      });
      state.dealersSelectData[DealersSelectOptions.cityOptions].optionCount = payload.count;
      state.dealersSelectData[DealersSelectOptions.cityOptions].requestStatus = initialState.dealersSelectData[DealersSelectOptions.cityOptions].requestStatus;

      state.dealersSelectData[DealersSelectOptions.cityOptions].pagination.maxPage = Math.ceil(state.dealersSelectData[DealersSelectOptions.cityOptions].optionCount / selectOptionsCount);
      state.dealersSelectData[DealersSelectOptions.cityOptions].pagination.isLastPage = state.dealersSelectData[DealersSelectOptions.cityOptions].pagination.currentPage === state.dealersSelectData[DealersSelectOptions.cityOptions].pagination.maxPage;
    },
    [getCitiesAction.rejected.type]: (state, {payload}) => {
      state.dealersSelectData[DealersSelectOptions.cityOptions].requestStatus.isLoading = LOADING_STATUS.IDLE;
      state.dealersSelectData[DealersSelectOptions.cityOptions].requestStatus.isError = true;
      state.dealersSelectData[DealersSelectOptions.cityOptions].requestStatus.errorMessage = payload;
    },

    /**
     * Обработка запроса групп дилеров
     */
    [getAllDealersGroupsAction.pending.type]: (state) => {
      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].requestStatus.isLoading = LOADING_STATUS.LOADING;
      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].requestStatus.isError = initialState.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].requestStatus.isError;
      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].requestStatus.errorMessage = initialState.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].requestStatus.errorMessage;
    },
    [getAllDealersGroupsAction.fulfilled.type]: (state, {payload}) => {
      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].options = payload.rows.map((option: ICity) => {
        return {
          name: option.cityName,
          value: option.cityName
        } as unknown as OptionData
      });
      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].optionCount = payload.count;
      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].requestStatus = initialState.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].requestStatus;

      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].pagination.maxPage = Math.ceil(state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].optionCount / selectOptionsCount);
      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].pagination.isLastPage = state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].pagination.currentPage === state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].pagination.maxPage;
    },
    [getAllDealersGroupsAction.rejected.type]: (state, {payload}) => {
      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].requestStatus.isLoading = LOADING_STATUS.IDLE;
      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].requestStatus.isError = true;
      state.dealersSelectData[DealersSelectOptions.dealerGroupsOptions].requestStatus.errorMessage = payload;
    },

    /**
     * Обработка запроса установки пользователя к дилеру
     */
    [putDealerToUserAction.pending.type]: (state) => {
      state.putUser = initialRequestStatus;
      state.putUser.isLoading = LOADING_STATUS.LOADING;
    },
    [putDealerToUserAction.fulfilled.type]: (state) => {
      state.putUser = initialRequestStatus;
    },
    [putDealerToUserAction.rejected.type]: (state, {payload}) => {
      state.putUser.isLoading = LOADING_STATUS.IDLE;
      state.putUser.isError = true;
      state.putUser.errorMessage = payload;
    },

    /**
     * Обработка запроса удаления пользователя у дилера
     */
    [deleteDealerFromUserAction.pending.type]: (state) => {
      state.deleteUser = initialRequestStatus;
      state.deleteUser.isLoading = LOADING_STATUS.LOADING;
    },
    [deleteDealerFromUserAction.fulfilled.type]: (state) => {
      state.deleteUser = initialRequestStatus;
    },
    [deleteDealerFromUserAction.rejected.type]: (state, {payload}) => {
      state.deleteUser.isLoading = LOADING_STATUS.IDLE;
      state.deleteUser.isError = true;
      state.deleteUser.errorMessage = payload;
    }
  }

})

export const {
  toggleDealersRow,
  setDefaultCurrentPage,
  increaseCurrentPage,
  updateResponsible,
  changeGroupsByDealer,
  editPrevDealer,
} = dealersSlice.actions
export default dealersSlice.reducer;
