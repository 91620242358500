import React, {useEffect, useState} from 'react';
import s from './s.module.css'
import {ITemplateFormInstance, ITemplateGroup} from "models/InstanceInterfaces/ITemplateForm";
import TemplateQuestionsGroup from "components/TemplateQuestionsGroup";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import clsx from "clsx";
import {LOADING_STATUS} from "lib/appConst";
import {useSelector} from "react-redux";
import {RootState} from "store";
import {getSelectedGroupId, selectToolsState} from "store/formTemplateEditor/reselect";
import {IIterable, IQuestion, IQuestReorderData} from "models/IQuestion";
import {OnQuestionCreate} from "components/TemplateQuestionGen/variants/OnQuestionCreate";
import TemplateQuestionGen from "components/TemplateQuestionGen";
import {reorderQuestion} from "store/formTemplateEditor/thunk";
import {useAppDispatch} from "hooks/redux";
import {getFormTemplateByIdNoLoadHelper} from "../../lib/apiHelpers";

const reorder = (
  list: (IQuestion & IIterable)[],
  startIndex: number,
  endIndex: number,
  mainGroupId: number,
  callback?: (data: IQuestReorderData) => any
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, {...removed, nextQuestionId: 2});

  if (callback) {
    const data: IQuestReorderData = {
      lastPrevQuestionId: list[startIndex - 1]?.id,
      nextPrevQuestionId: (startIndex > endIndex ? list[endIndex - 1]?.id : list[endIndex]?.id),
      moveQuestionId: list[startIndex].id,
      mainGroupId
    }
    callback(data)
  }
  return result;
};

const move = (
  testListOfGroups: any,
  list: (IQuestion & IIterable)[],
  startIndex: number,
  endIndex: number,
  source: { items: (IQuestion & IIterable)[], groupId: number },
  destination: { items: (IQuestion & IIterable)[], groupId: number },
  droppableSource: any,
  droppableDestination: any,
  listOfQuestionList: (IQuestion & IIterable)[][],
  destinationSectorId: number,
  sourceSectorId: number,
  callback?: any,
) => {
  const sourceClone = Array.from(source.items);
  const destClone = Array.from(destination.items);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, {...removed, mainGroupId: destination.groupId});

  const result: any = {};
  result[droppableSource.droppableId] = {items: sourceClone, groupId: source.groupId};
  result[droppableDestination.droppableId] = {items: destClone, groupId: destination.groupId};

  const newQuestionGroups = Object.values({...testListOfGroups, ...result}).reduce((acc: any[], el: any) =>
    ([...acc, el.items]), [])

  //find nextPrev
  const virtualListAfterReordered = newQuestionGroups.slice(0, destinationSectorId + 1).flat()
  const virtualNextIndex = virtualListAfterReordered.findIndex((val) => val.id === removed.id)
  const nextPrev = virtualNextIndex - 1 >= 0 ? { nextPrevQuestionId: virtualListAfterReordered[virtualNextIndex - 1].id } : {}

  //find lastPrev
  const virtualListBeforeReordered = listOfQuestionList.slice(0, sourceSectorId + 1).flat()
  const virtualLastIndex = virtualListBeforeReordered.findIndex((val) => val.id === removed.id)
  const lastPrev = virtualLastIndex - 1 >= 0 ? { lastPrevQuestionId: virtualListBeforeReordered[virtualLastIndex - 1].id } : {}


  if (callback) {
    const data = {
      moveQuestionId: removed.id,
      mainGroupId: destination.groupId,
      ...nextPrev,
      ...lastPrev,
    }
    callback(data)
  }
  return result;
};

export interface IFormsTemplateEditorQuestionGroup {
  templateInstance: ITemplateFormInstance
  loadingStatus: LOADING_STATUS
  templateId: number
  waitRender?: boolean
}

const FormsTemplateEditorQuestionGroup = ({
                                            templateInstance,
                                            templateId,
                                            loadingStatus,
                                            waitRender,
                                          }: IFormsTemplateEditorQuestionGroup) => {

  const dispatch = useAppDispatch()

  const [questionGroups, setQuestionGroups] = useState<{ [key: string]: { items: any[], groupId: number } }>({})
  const listOfQuestionList = templateInstance.mainGroup.map(el => el.questions || [])

  const {
    toolsState: {addQuestion},
    selectedGroup,
  } = useSelector((store: RootState) => ({
    toolsState: selectToolsState(store),
    selectedGroup: getSelectedGroupId(store)
  }))

  useEffect(() => {
    setQuestionGroups(templateInstance.mainGroup.reduce((acc, el, id) => ({
      ...acc,
      ['questionGroup' + id]: {items: el.questions || [], groupId: el.id}
    }), {}))
  }, [templateInstance])

  const onDragEnd = ({source, destination}: any) => {
    if (!destination) {
      return;
    }
    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        questionGroups[source.droppableId].items,
        source.index,
        destination.index,
        questionGroups[source.droppableId].groupId,
        async (data: IQuestReorderData) => {
          await dispatch(reorderQuestion(data))
          await getFormTemplateByIdNoLoadHelper()
        },
      );
      setQuestionGroups(p => ({...p, [destination.droppableId]: {items, groupId: p[destination.droppableId].groupId}}))
    } else {
      const result = move(
        questionGroups,
        questionGroups[source.droppableId].items,
        source.index,
        destination.index,
        questionGroups[source.droppableId],
        questionGroups[destination.droppableId],
        source,
        destination,
        listOfQuestionList,
        Object.keys(questionGroups).indexOf(destination.droppableId),
        Object.keys(questionGroups).indexOf(source.droppableId),
        async (data: IQuestReorderData) => {
          await dispatch(reorderQuestion(data))
          await getFormTemplateByIdNoLoadHelper()
        },
      );
      setQuestionGroups(p => ({...p, ...result}))
    }
  };

  const checkGroupOnQuestions = (groups: ITemplateGroup[]): number => {
    if(groups.length === 0) return 0
    const group = groups.pop()
    if(!group?.questions
      || !group.questions?.length
    ) return checkGroupOnQuestions(groups)
    else return group?.questions[0]?.id || 0
  }

  const getNextQuestionId = (groups: ITemplateGroup[], id: number): number => {
    if(id !== groups?.length) {
      const groupsSlice = [...groups].slice(id + 1)
      return checkGroupOnQuestions(groupsSlice)
    }
    return 0
  }

  return (
    <div className={s.container}>
      <DragDropContext onDragEnd={onDragEnd}>
        {templateInstance.mainGroup.length === Object.values(questionGroups).length
          && templateInstance.mainGroup.map(
            (group, id) =>
              <TemplateQuestionsGroup
                loading={loadingStatus}
                key={group.id}
                templateFormId={templateId}
                group={group}>
                <Droppable droppableId={`questionGroup${id}`}>
                  {(provided, snapshot) => (
                    <>
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={clsx(s.dropContainer, {
                          [s.emptyQuestionList]: !(Object.values(questionGroups).length && Object.values(questionGroups)[id].items.length)
                        })}>
                        {
                          loadingStatus === LOADING_STATUS.IDLE
                          && !!Object.values(questionGroups).length
                          && !!Object.values(questionGroups)[id].items.length
                            ? Object.values(questionGroups)[id].items.map((question, qId) =>
                              <TemplateQuestionGen
                                waitRender={waitRender}
                                isDraggingOver={snapshot.isUsingPlaceholder}
                                key={question.id}
                                itemIndex={qId}
                                templateId={templateId}
                                questionInstance={question}/>)
                            : <p>Перетащите вопрос в эту область или создайте новый</p>
                        }
                        {provided.placeholder}
                      </div>
                      {(addQuestion && selectedGroup === group.id)
                        && <OnQuestionCreate nextQuestionId={getNextQuestionId(templateInstance.mainGroup, id)} templateId={templateId}/>
                      }
                      {(templateInstance.mainGroup.length >= 1 && id <= 0 && !selectedGroup && addQuestion)
                        && <OnQuestionCreate nextQuestionId={getNextQuestionId(templateInstance.mainGroup, id)} templateId={templateId} groupId={group.id}/>
                      }
                    </>
                  )}
                </Droppable>
              </TemplateQuestionsGroup>
          )}
      </DragDropContext>
    </div>
  );
};

export default FormsTemplateEditorQuestionGroup;
