import UIField from 'components/UI/UIField'
import React from 'react'
import {Control} from 'react-hook-form'
import {TParamsDealerGrous} from '.'

type Props = {
  control: Control<TParamsDealerGrous, any>
}

const SearchInput: React.FC<Props> = ({control}) => {
  return (
    <>
      <UIField.Input
        customInputStyle={{padding: '26px 0 12px'}}
        name='name'
        control={control}
        placeholder={'Поиск по имени ДЦ'}
      />
    </>
  )
}

export default SearchInput
