import { createSlice } from '@reduxjs/toolkit'

import { getAllReportAppeals, getAllReportsActions, getAllUnloadsActions, getGraphActions } from './thunk'
import { AnalyticsState } from 'models/storeInterface/AnalyticsState'
import { IQuestion } from 'models/IQuestion'
import { countTotalAppeals } from 'lib/countAppeals'
import { parseQuestionName } from 'lib/parseQuestionName';
import {LOADING_STATUS} from "../../lib/appConst";


const name = 'analytics';

const initialState: AnalyticsState = {
  // Отчеты по вопросам
  reportList: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
    columns: [ { name: '', dataAnchor: '0' } ],
  },

  // Отчеты по аппеляциям
  appealList: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
    columns: [ { name: '', dataAnchor: '0' } ],
  },

  // График
  graphData: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
    columns: [ { name: '', dataAnchor: '0' } ],
  },

  // Выгрузка
  unloadList: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
  }
}

export const analyticsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: {
    [getAllReportsActions.pending.type]: (state) => {
      state.reportList.status = LOADING_STATUS.LOADING
    },
    [getAllReportsActions.fulfilled.type]: (state, { payload }) => {
      state.reportList.status = LOADING_STATUS.IDLE
      state.reportList.columns = [
        { name: '', dataAnchor: '0' },
        ...(payload.columns ? payload.columns.map((name: any, i: number) => ({ name, dataAnchor: String(i + 1) })) : {}),
        { name: 'Итого', dataAnchor: String(payload.columns.length + 1) }
      ]
      state.reportList.rows = payload.rows ? payload.rows.map((el: any) => {
        let total: number = 0;
        return {
          '0': el.name,
          ...(el.column.reduce((a: any, v: any, index: any) => {
            total += v
            return { ...a, [index + 1]: String(v) }
          }, {}) ),
          [el.column.length + 1]: String((parseInt(String(total * 100))) / 100),
        }
      }) : []
    },
    [getAllReportAppeals.pending.type]: (state) => {
      state.appealList.status = LOADING_STATUS.LOADING
    },
    [getAllReportAppeals.fulfilled.type]: (state, { payload }) => {
      state.appealList.status = LOADING_STATUS.IDLE
      state.appealList.columns = payload.questions.map((el: IQuestion, i: number) => ({ name: `${el.num} ${parseQuestionName(el.textQuestion)}`, dataAnchor: `questions.${i}.answer` }))
      state.appealList.rows = [
        ...payload.forms, countTotalAppeals(payload.forms)
      ]
    },
    [getGraphActions.fulfilled.type]: (state, { payload }) => {
      state.graphData.count = payload.data.length
      state.graphData.rows = payload.data
    },
    [getAllUnloadsActions.fulfilled.type]: (state, { payload }) => {
      state.unloadList.count = payload.count
      state.unloadList.rows = payload.rows
    }
  }
})

export default analyticsSlice.reducer


