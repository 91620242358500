import React, {memo, useEffect} from 'react';
import s from './s.module.css'
import clsx from "clsx";
import {clearNotification} from "store/common";
import {store} from "store";
import {INotificatorData} from "models/storeInterface/CommonState";
import {NOTIFICATOR_TYPE} from "lib/appConst";
import {ReactComponent as ApplyIco} from "assets/svg/button/apply.svg";
import {ReactComponent as WarningIco} from "assets/svg/button/warning.svg";
import {ReactComponent as ErrorIco} from "assets/svg/button/error.svg";
import {ReactComponent as CloseIco} from "assets/svg/button/close-x.svg";

const autoClear = (id: number) => setTimeout(() => store.dispatch(clearNotification(id)), 5000)
const manualClear = (id: number) => () => store.dispatch(clearNotification(id))

const NotificationElement = ({id, text, type}: INotificatorData) => {
  const textResolver = () => {
    if(!text) {
      switch (type) {
        case NOTIFICATOR_TYPE.apply:
          return 'Успешно сохранено!'
        case NOTIFICATOR_TYPE.warning:
          return 'Обратите внимание'
        case NOTIFICATOR_TYPE.error:
          return 'Ошибка при сохранении'
      }
    }
    return text
  }

  const icoResolver = () => {
    switch (type) {
      case NOTIFICATOR_TYPE.apply:
        return <ApplyIco className={s.ico} />
      case NOTIFICATOR_TYPE.warning:
        return <WarningIco className={s.ico} />
      case NOTIFICATOR_TYPE.error:
        return <ErrorIco className={s.ico} />
    }
  }

  useEffect(() => { autoClear(id) }, [])

  return (
    <div className={clsx(s.element, {
      [s.borderApply]: type === NOTIFICATOR_TYPE.apply,
      [s.borderWarning]: type === NOTIFICATOR_TYPE.warning,
      [s.borderError]: type === NOTIFICATOR_TYPE.error,
    })}>
      {icoResolver()}
      <p>{textResolver()}</p>
      <CloseIco className={s.close} onClick={manualClear(id)}/>
    </div>
  );
};

export default memo(NotificationElement);
