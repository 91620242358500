import {RootState} from "../index";
import {createSelector} from "@reduxjs/toolkit";
import {IformTemplateEditor} from "../../models/storeInterface/FormTemplateStore";

const getFormTemplates = (store: RootState) => store.formTemplateEditor

export const selectMode = createSelector(getFormTemplates, (formTemplateEditor: IformTemplateEditor) => formTemplateEditor.mode)
export const selectTemplates = createSelector(getFormTemplates, (formTemplateEditor: IformTemplateEditor) => formTemplateEditor.formTemplates)
export const selectAddMainGroupStatus = createSelector(getFormTemplates, (formTemplateEditor: IformTemplateEditor) => formTemplateEditor.addMainGroupStatus)
export const selectCurrentTemplateId = createSelector(getFormTemplates, (formTemplateEditor: IformTemplateEditor) => formTemplateEditor.currentSelectId)
export const selectCurrentTemplateInstance = createSelector(getFormTemplates, (formTemplateEditor: IformTemplateEditor) => formTemplateEditor.selectedTemplate)
export const selectCurrentTemplateHeader = createSelector(getFormTemplates, (formTemplateEditor: IformTemplateEditor) => formTemplateEditor.selectedTemplateHeader)
export const selectToolsState = createSelector(getFormTemplates, (formTemplateEditor: IformTemplateEditor) => formTemplateEditor.toolsState)
export const getSelectedQuestion = createSelector(getFormTemplates, (formTemplateEditor: IformTemplateEditor) => formTemplateEditor.selectedQuestion)
export const getSelectedPrevQuestion = createSelector(getFormTemplates, (formTemplateEditor: IformTemplateEditor) => formTemplateEditor.selectedPrevQuestion)
export const getSelectedGroupId = createSelector(getFormTemplates, (formTemplateEditor: IformTemplateEditor) => formTemplateEditor.selectedGroupId)

