import React, { useCallback, useEffect, useState } from 'react'

import { UIContainer } from 'components/UI/UIContainer'

import { useAppDispatch } from 'hooks/redux'
import { useEaseSearchParams } from 'hooks/useEaseSearchParams'

import { AnalyticsSubmenu, AnalyticsUnloadControl } from 'lib/tableConfigs/controlsPakages/controls'
import { parseFilterParamsForRequest } from 'lib/parseFilterParams'

import TableGenerator from 'modules/TableGenerator'

import { IPaginate } from 'models/common'

import { selectUnloads } from 'store/analytics/reselect'
import { AnalyticsUnloadTableConfig } from 'lib/tableConfigs/configs'
import { getAllUnloadsActions } from 'store/analytics/thunk'
import {
  defaultAnalyticsUnloadFilterValues,
  TAnalyticsUnloadPageFilter,
} from 'modules/filters/AnalyticUnloadPageFilter/utils';


/**
 * Страница для отображения отчетов аналитики
 * @constructor
 */
const AnalyticsUnload = () => {
  const [params] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState<Partial<TAnalyticsUnloadPageFilter>>(parseFilterParamsForRequest(params, defaultAnalyticsUnloadFilterValues) as unknown as Partial<TAnalyticsUnloadPageFilter>)

  const getData = useCallback((param: IPaginate & Partial<TAnalyticsUnloadPageFilter>) =>
    dispatch(getAllUnloadsActions({ ...param })), [])

  useEffect(() => {
    if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultAnalyticsUnloadFilterValues) as unknown as Partial<TAnalyticsUnloadPageFilter>)) {
      setFilter(parseFilterParamsForRequest(params, defaultAnalyticsUnloadFilterValues) as unknown as Partial<TAnalyticsUnloadPageFilter>)
    }
  }, [params])


  return (
    <UIContainer.PageContainer>
      <TableGenerator
        tableName="Выгрузка"
        dataQuery={getData}
        dataSelect={selectUnloads}
        tableConfig={AnalyticsUnloadTableConfig}
        tableControl={AnalyticsUnloadControl}
        subMenu={AnalyticsSubmenu}
        filter={filter}
      />
    </UIContainer.PageContainer>
  );
};

export default AnalyticsUnload;
