import { createSlice } from '@reduxjs/toolkit';
import {ITemplateForm} from "models/InstanceInterfaces/ITemplateForm";
import {getFormToDetail, getTemplateToDetail} from "./thunk";
import {LOADING_STATUS} from "lib/appConst";
import {IIdentifier, IResponseExtraData} from "models/common";
import {IAppeal} from "models/InstanceInterfaces/IAppeal";
import {IForm} from "models/InstanceInterfaces/IForm";


const name = 'formDetail';

const initialState = {
  form: {
    status: LOADING_STATUS.IDLE,
    error: '',
    instance: undefined as IForm & IResponseExtraData & IIdentifier | undefined
  },
  template: {
    status: LOADING_STATUS.IDLE,
    error: '',
    instance: undefined as ITemplateForm & IResponseExtraData & IIdentifier | undefined
  }
}

export const formDetailSlice = createSlice({
  name,
  initialState,
  reducers: {
    changeAnswer: (store, { payload }) => {
      // @ts-ignore
      store.form.instance.answer = store.form.instance.answer.map((el: any) => {
        if (payload.oldAnswerId === el.id) {
          return {
            ...el,
            ...payload.answer,
          }
        }
        return el
      })
    },
    acceptAppealStatus: (store, { payload }) => {
      if(!payload.oldAnswerId) {
        //@ts-ignore
        store.form.instance.answer = [payload.answer]
      }
      if (payload.answer) {
        if(store.form?.instance?.answer?.length) {
          store.form.instance.answer = store.form.instance.answer.map((el: any) => {
            if (payload.oldAnswerId === el.id) {
              return {
                ...el,
                ...payload.answer,
              }
            }
            return el
          })
        }
        else {
          //@ts-ignore
          store.form.instance.answer = [payload.answer]
        }
      }

      // @ts-ignore
      store.form.instance.templateForm.questions = store.form.instance.templateForm.questions.map((el: any) => {
        if (el.id === payload.formQuestionId) {
          return {
            ...el,
            appeal: el.appeal.map((el: any) => {
              if (el.id === payload.appealId) {
                return payload.newAppeal
              }
              return el
            })
          }
        }
        return el
      })
    },
    rejectAppealStatus: (store, { payload }) => {
      if(store?.form?.instance?.templateForm?.questions ) {
        store.form.instance.templateForm.questions = store.form.instance.templateForm.questions.map((el: any) => {
          if(el.id === payload.formQuestionId) {
            return {
              ...el,
              appeal: el.appeal.map((el: any) => {
                if (el.id === payload.appealId) {
                  return payload.newAppeal
                }
                return el
              })
            }
          }
          return el
        })
      }
    },
    addAppealInfo: (store, { payload }: { payload: { formQuestionId: number, answerId: number, appeal: IAppeal & IIdentifier}} ) => {
      // @ts-ignore
      store.form.instance!.templateForm.questions = store.form.instance?.templateForm.questions?.map(el => {
        if (el.id === payload.formQuestionId) {
          return {
            ...el,
            // @ts-ignore
            appeal: [payload.appeal, ...el.appeal]
          }
        }
        return el
      })
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getTemplateToDetail.pending, (store) => {
        store.template.status = LOADING_STATUS.LOADING
      })
      .addCase(getTemplateToDetail.fulfilled, (store, {payload}) => {
        store.template.status = LOADING_STATUS.IDLE
        if(typeof payload !== "string") store.template.instance = payload
        else {
          store.template.instance = initialState.template.instance
          store.template.error = payload
        }
      })
      .addCase(getTemplateToDetail.rejected, (store) => {
        store.template.status = LOADING_STATUS.IDLE
        store.template.instance = initialState.template.instance
        store.template.error = "Неизвестная ошибка"
      })

      .addCase(getFormToDetail.pending, (store) => {
        store.form.status = LOADING_STATUS.LOADING
      })
      .addCase(getFormToDetail.fulfilled, (store, {payload}) => {
        store.form.status = LOADING_STATUS.IDLE
        if (typeof payload !== "string") {
          store.form.instance = payload
          store.form.error = ''
        } else {
          store.form.instance = initialState.form.instance
          store.form.error = payload
        }
      })
      .addCase(getFormToDetail.rejected, (store) => {
        store.form.status = LOADING_STATUS.IDLE
        store.form.instance = initialState.form.instance
        store.form.error = "Неизвестная ошибка"
      })
  }
})

export const {
  addAppealInfo,
  acceptAppealStatus,
  rejectAppealStatus,
} = formDetailSlice.actions
export default formDetailSlice.reducer
