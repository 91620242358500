import React, {CSSProperties} from 'react';
import s from './s.module.css'
import clsx from "clsx";
import CheckedIco from 'assets/svg/fields/apply.svg'
import { ReactSVG } from 'react-svg'


export interface IUICheckbox {
  id?: string
  style?: CSSProperties
  handler: any
  disabled?: boolean
  value: boolean | undefined
  valId?: string | number | string[] | number[]
}

const UICheckbox = ({id, style, handler, value = false, valId, disabled}: IUICheckbox) => {
  return (
    <div
      onClick={() => !disabled && handler && handler(valId)}
      className={clsx(s.box, {
        [s.checked]: value
      })}
      style={style}
    >
      <ReactSVG src={CheckedIco} />
      <input id={id} type="checkbox" onChange={() => {}} checked={value} className={s.input}/>
    </div>
  );
};

export default UICheckbox;
