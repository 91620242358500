import { QUESTION_TYPES } from 'lib/appConst';
import moment from 'moment';

export const reinitFormValue = (answer: any, typeQuestion: QUESTION_TYPES, reset: any, defaultValues: any) => {
  if (!answer.answerString && !answer.answerFloat && answer.Files.length === 0) {
    reset(defaultValues)
  }

  switch (typeQuestion) {
    case QUESTION_TYPES.datePick:
      return reset({ answer: moment(answer.answerString, "DD-MM-YYYY") });
    case QUESTION_TYPES.file:
    case QUESTION_TYPES.image:
    case QUESTION_TYPES.audio:
      return reset({ answer: answer.Files[0].id });
    case QUESTION_TYPES.options:
      return reset({ answer: `${answer.answerFloat}__${answer.answerString}` });
    case QUESTION_TYPES.number:
      return reset({ answer: `${answer.answerFloat}` })
    case QUESTION_TYPES.text:
    case QUESTION_TYPES.timePick:
    case QUESTION_TYPES.textArea:
      return reset({ answer: answer.answerString });
  }
}

export const getValueBeforeSend = (answer: any, typeQuestion: QUESTION_TYPES) => {
  if (!answer) return {}

  switch (typeQuestion) {
    case QUESTION_TYPES.datePick:
      return { answerString: moment(answer).format('DD.MM.YYYY') }
    case QUESTION_TYPES.file:
    case QUESTION_TYPES.image:
    case QUESTION_TYPES.audio:
      return { fileIds: [answer] }
    case QUESTION_TYPES.options:
      return {
        answerFloat: parseInt(answer.split('__')[0]),
        answerString: answer.split('__')[1],
      };
    case QUESTION_TYPES.number:
      return { answerFloat: parseInt(answer) }
    case QUESTION_TYPES.text:
    case QUESTION_TYPES.timePick:
    case QUESTION_TYPES.textArea:
      return { answerString: answer }
  }
}
