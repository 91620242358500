import { Control, Controller, FieldName, RegisterOptions } from 'react-hook-form'
import FilePickerRaw, { FilepickerRawPropsTypes } from 'components/UI/UIField/items/Filepicker/items';

export type FilepickerPropsTypes = FilepickerRawPropsTypes & {
  control: Control<any>
  name: FieldName<any>
  rules?: RegisterOptions
  errors?: any
}

const FilePicker = ({
  name,
  control,
  maxItems,
  className,
  type,
  errors,
  rules,
  isNormalFont,
}: FilepickerPropsTypes) => {

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FilePickerRaw
          {...{
            value,
            onChange,
            maxItems,
            className,
            type,
            isNormalFont,
            error: errors !== undefined ? errors[name] : undefined
          }}
        />
      )}
    />
  )
}

export default FilePicker
