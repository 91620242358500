import FileService from "../services/FileService";
import {addNotification} from "./addNotification";
import {NOTIFICATOR_TYPE} from "./appConst";

export const getFileByStream = async (fileId: number): Promise<[string, (fileName: string) => void]> => {
  const response = await FileService.downloadFileById(fileId)
  if(response.status === 200 || response.status === 201) {
    const blob = await response.blob()

    const hiddenLink = document.createElement("a");
    const url = URL.createObjectURL(blob)

    const downloader = (fileName: string) => {
      document.body.appendChild(hiddenLink)
      hiddenLink.style.display = "none"
      hiddenLink.href = url;
      hiddenLink.download = fileName;
      hiddenLink.click();
      window.URL.revokeObjectURL(url);
    }

    return [url, downloader]
  }

  else {
    addNotification({text: "Искомый файл не найден", type: NOTIFICATOR_TYPE.error})
    const url = ''
    const downloader = () => {}
    return [url, downloader]
  }
}
