import React, {memo} from 'react';
import s from "./s.module.css";
import {getShortFIO} from "../../lib/getShortFIO";

export interface IProfilePlugView {
  fullName: string
  position: string
}

const ProfilePlugView = ({fullName, position}: IProfilePlugView) => {
  return (
    <div className={s.wrapper}>
      {
        fullName
          ? (
            <>
              <p className={s.fullName}>
                {/*<span className={s.welcome}>Добро пожаловать:</span> */}
                {fullName}
              </p>
              <p className={s.shortName}>
                {/*<span className={s.welcome}>Добро пожаловать:</span>*/}
                {getShortFIO(fullName)}
              </p>
              <p className={s.position}>{position}</p>
            </>
          ) : <></>
      }
    </div>
  );
};

export default memo(ProfilePlugView);
