import React, {useEffect, useState, memo} from 'react';
import {OptionData} from "models/FilterData";
import {UIModal} from "components/UI/UIModal";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import DealersService from "services/DealersService";
import {useInView} from "react-intersection-observer";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import DealerCheckbox from "./DealerCheckbox";
import {useSelector} from "react-redux";
import {selectEditUser} from "store/users/reselect";
import FooterButtons from "./FooterButtons";
import {filterValueIsSet} from "../../../../../lib/filterValueIsSet";

type Props = {
  params: {
    stateCode?: OptionData
    distName?: OptionData
    cityName?: OptionData
    name?: string
    groupIds?: OptionData
  }
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
  resetFilterHandler: () => void
}

const DealersCheckbox= ({
    setLoading,
    params,
    resetFilterHandler,
  }: Props) => {
  const {ref, entry} = useInView()
  const editUserData  = useSelector(selectEditUser);
  const [paramsUrl] = useEaseSearchParams()
  const id = editUserData.id || paramsUrl.userId
  const [inProgress, setInProgress] = useState<string[]>([])
  const [isAllChecked, setAllCheked] = useState<boolean | null>(null)
  const [isAllChekedMode, setAllCheckedMode] = useState(false)
  const [dealerIdCache, setDealerIdCache] = useState<Array<number>>([])
  const [rowsSelect, paginateTrigger, doRefetch, , loading, total] = usePaginationItemsFromRequest<any>({
    request: DealersService.getAll,
    count: 20,
    requestFilter: params,
    dataConverter: el => {
      return {
        name: el.name,
        value: el.id,
        isBinded: isAllChecked === null
          ? el.Users.map((i: any) => i.id).includes(id)
          : isAllChecked
      }
    },
  })
  const [rows, setRows] = useState(rowsSelect)

  useEffect(() => {
    if (rowsSelect.length >= 10 && entry?.isIntersecting) {
      paginateTrigger()
    }
  }, [entry])

  useEffect(() => {
    setRows(rowsSelect)
  }, [rowsSelect])

  useEffect(() => {
    setLoading && setLoading(loading)
  }, [loading])

  const [filterIsBeingSet, setFilterIsBeingSet] = useState(false)
  useEffect(() => {
    const filters = {
      cityName: params.cityName,
      groupIds: params.groupIds,
      distName: params.distName,
      stateCode: params.stateCode,
      name: params.name
    }
    if(!filterValueIsSet(filters)) setFilterIsBeingSet(false)
    if(filterValueIsSet(filters) || filterIsBeingSet) {
      doRefetch()
      setFilterIsBeingSet(true)
    }
  }, [params.cityName, params.groupIds, params.distName, params.stateCode, params.name])
  // отслеживает изменение полей фильтра и делает новый запрос
  useEffect(() => {
    doRefetch()
  }, [])

  // функция для лакальной отрисовки чекбоксов выбрать все отменить все
  const checkAllHandler = (isCheked: boolean) => {
    setDealerIdCache([])
    setAllCheked(isCheked)
    setAllCheckedMode(true)
    setRows(row => row.map(el => ({...el, isBinded: isCheked})))
  }

  // для того что бы при прокрутке сохранять статусы чекбоксов
  useEffect(() => {
    if (isAllChekedMode) {
      setRows(row => {
        return row.map(el => {
          if (dealerIdCache.includes(el.value)) {
            return {...el, isBinded: !isAllChecked}
          }
          return {...el, isBinded: isAllChecked}
        })
      })
    }
  }, [isAllChecked, rowsSelect])
  return (
    <>
      <UIModal.ListContainer isEmpty={false}>
        {!!rows.length && (
          rows.map((dealer: any) => {
            const {value, isBinded, name} = dealer
            return (
              <DealerCheckbox
                dealerId={value}
                isBinded={isBinded}
                userId={id}
                name={name}
                inProgress={inProgress.includes(String(value))}
                setInProgress={setInProgress}
                key={value}
                isAllChecked={isAllChecked}
                setRows={setRows}
                setDealerIdCache={setDealerIdCache}
              />
            )
          })
        )}
        <div ref={ref} style={{width: '100%', height: '0px'}}></div>
      </UIModal.ListContainer>
      <FooterButtons
        id={id}
        params={params}
        checkAllHandler={checkAllHandler}
        dealerIdCache={dealerIdCache}
        isAllChecked={isAllChecked}
        setDealerIdCache={setDealerIdCache}
        resetFilterHandler={resetFilterHandler}
        totalDealers={total}
      />
    </>
  )
}

export default memo(DealersCheckbox);
