import React, {useCallback, useEffect, useState} from 'react'
import TableGenerator from 'modules/TableGenerator'
import {useAppDispatch} from 'hooks/redux'
import {getAllDealersGroupsAction} from 'store/dealers/thunk'
import {dealersGroupsTableConfig} from 'lib/tableConfigs/configs'
import {DealersGroupTableControl} from 'lib/tableConfigs/controlsPakages/controls'
import UIPageTitle from 'components/UI/UIPageTitle'
import {UIContainer} from 'components/UI/UIContainer'
import {selectDealersGroups} from 'store/dealersGroups/reselect'
import {ISort} from '../../models/common'
import {useEaseSearchParams} from '../../hooks/useEaseSearchParams'

/**
 * Страница для отображения дилеров
 */
const DealersGroups = () => {
  const [params] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const getDealersGroupsQueryExecute = useCallback(
    (param: {page: number; count: number}) => dispatch(getAllDealersGroupsAction(param)),
    [],
  )
  const [sort, setSort] = useState<ISort>()

  useEffect(() => {
    if (JSON.stringify(sort) !== JSON.stringify({sort: params.sort, direction: params.direction})) {
      setSort({sort: params.sort, direction: params.direction})
    }
  }, [params])

  return (
    <UIContainer.PageContainer>
      <>
        <UIPageTitle
          label={'Группы дилеров'}
          Controls={DealersGroupTableControl}
          withBackBtn
          backPath='/dealers?page=1&count=20'
        />
        <TableGenerator
          dataQuery={getDealersGroupsQueryExecute}
          dataSelect={selectDealersGroups}
          tableConfig={dealersGroupsTableConfig}
          sort={sort}
        />
      </>
    </UIContainer.PageContainer>
  )
}

export default DealersGroups
