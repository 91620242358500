import { UIContainer } from 'components/UI/UIContainer'
import StatisticCard from 'components/StatisticCard'

import { useAppSelector } from 'hooks/redux'
import { selectAppeals } from 'store/statistic/reselect'

import sCommon from "../sCommon.module.css"
import s from "../../../pages/Main/s.module.css"

const Appeals = () => {

  const appeals = useAppSelector(selectAppeals)

  return (
    <UIContainer.ContentContainer
      header={'Апелляции'}
      containerAdditionClass={sCommon.container}
    >
      <div className={s.statisticContainer}>
        {appeals.length > 0 && appeals.map((el, i) => {
          return <StatisticCard key={i} count={el.count} name={el?.Status?.name} />
        })}
      </div>
    </UIContainer.ContentContainer>
  );
};

export default Appeals;
