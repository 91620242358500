import { useState } from 'react'
import { useForm } from 'react-hook-form';

import { UIContainer } from 'components/UI/UIContainer'
import UIField from 'components/UI/UIField'
import { UIButton } from 'components/UI/UIButton';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { selectModalStuck } from 'store/common/reselect';

import FormsService from 'services/FormsService'
import { useEaseSearchParams } from 'hooks/useEaseSearchParams';
import { removeModalItem } from 'lib/setModal';
import { quarterOptions, yearsOptions } from 'lib/appOptions'
import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest';

import {ITypeForm} from 'models/InstanceInterfaces/ITypeForm';

import * as yup from "yup";
import { object, string} from "yup";
import {errorMessages} from "lib/errorMessages";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {addNotification} from "../../../lib/addNotification";
import {NOTIFICATOR_TYPE} from "../../../lib/appConst";

const selectOptionResolver = {name: string().min(1, errorMessages.common.inputRequired).required()}
const resolverSchema = yup.object({
  name:	string().required(errorMessages.common.inputRequired),
  year: object(selectOptionResolver),
  quarter: object(selectOptionResolver),
  typeFormId:	object(selectOptionResolver),
  number: string().required(errorMessages.common.inputRequired),
})

type TNewWaveModalCredentials = {
  name: string,
  year: number | any,
  quarter: number | any,
  typeFormId: number | any,
  number: string,
}

const NewWaveModal = () => {
  const dispatch = useDispatch()
  const { control, handleSubmit, reset, formState } = useForm<TNewWaveModalCredentials>({
    defaultValues: {
      number: '',
      name: '',
      year: {name: ''},
      quarter: {name: ''},
      typeFormId: {name: ''},
    },
    resolver: yupResolver(resolverSchema),
  })
  const [ setParams ] = useEaseSearchParams()

  const {
    modalStuck
  } = useSelector((state: RootState) => ({
    modalStuck: selectModalStuck(state)
  }))

   const onSubmit = async (data: TNewWaveModalCredentials) => {
      try {
        const modifiedData = {
          ...data,
          year: data.year.value,
          quarter: data.quarter.value,
          typeFormId: data.typeFormId.value,
          number: Number(data.number)
        }
        await FormsService.createNewWave(modifiedData)
        addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Волна успешно создана!'})
        reset()
      } catch (err: any) {
        addNotification({type: NOTIFICATOR_TYPE.error, text: err.response.data.message || 'Не удалось создать волну!'})
      }
      removeModalItem(setParams)
    }

  const startYear = new Date().getFullYear() - 10
  const years = new Array(21).fill(startYear).map((el: any, index) => ({name: el + index, value: index}))

 const quarter = [
  { value:1, name:'1' },
  { value:2, name:'2' },
  { value:3, name:'3' },
  { value:4, name:'4' },
 ]

 const inputStyle = {
  padding: '11px 0 0'
 }
 const [typeFormFilter, setTypeFormFilter] = useState('')
 const [
   typeFormRowsSelect,
   typeFormPaginateTrigger,
 ] = usePaginationItemsFromRequest<(ITypeForm)>({
   request: FormsService.getAllFormTypes,
   filter: typeFormFilter,
 })

  return (
    <UIContainer.ContentContainer
      header={'Новая волна'}
    >
      <form
      onSubmit={handleSubmit(onSubmit)}
      style={{width: '407px'}}
      >
        <UIField.Input
          type='number'
          control={control}
          name='number'
          placeholder='Введите номер волны'
          errors={formState.errors}
        />
        <UIField.Select
          selectName='Выберете год'
          control={control}
          options={yearsOptions}
          name={"year"}
          isFilter
          errors={formState.errors}
        />
        <UIField.Select
          selectName='Выберите квартал'
          control={control}
          options={quarterOptions}
          name={"quarter"}
          isFilter
          errors={formState.errors}
        />
         <UIField.Select
          control={control}
          selectName={"Выберете тип анкеты"}
          name={"typeFormId"}
          options={typeFormRowsSelect}
          isFilter
          paginateTrigger={typeFormPaginateTrigger}
          filterSetter={setTypeFormFilter}
          errors={formState.errors}
        />
        <UIField.Input
          placeholder={'Имя новой волны'}
          name={"name"}
          control={control}
          customStyle={inputStyle}
          errors={formState.errors}
        />
        <UIButton.DefaultBtn
          style={{marginTop:'32px'}}
          label='Создать волну'
        />
      </form>
    </UIContainer.ContentContainer>
  )
}

export default NewWaveModal
