import ContentContainer, {ContentContainerPropsType} from "./items/ContentContainer";
import MainContainer, {MainContainerPropsType} from "./items/MainContainer";
import PageContainer, {PageContainerPropsType} from "./items/PageContainer";

export type UIFiledType = {
  ContentContainer: (props: ContentContainerPropsType) => JSX.Element
  MainContainer: (props: MainContainerPropsType) => JSX.Element
  PageContainer: (props: PageContainerPropsType) => JSX.Element
}

export const UIContainer: UIFiledType = {
  ContentContainer: ContentContainer,
  MainContainer: MainContainer,
  PageContainer: PageContainer
}
