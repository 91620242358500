import { createAsyncThunk } from '@reduxjs/toolkit'

import FormsService from 'services/FormsService'
import { IAnswerForm } from 'models/InstanceInterfaces/IQuestion';

/*
* Получить анкету для прохождения и актуальный вопрос
* */
export const getFormWithQuestionAction = createAsyncThunk(
  'form/getFormWithQuestion',
  async (formId: number, { rejectWithValue }) => {
    try {
      const form = await FormsService.getFormById(formId)

      if (typeof form.data === 'string') {
        return rejectWithValue('Такой формы не существует')
      }

      let question = await FormsService.getLastQuestion(formId)
      let hasStarted = false

      if (question.data.order !== 0) {
        hasStarted = true
      } else {
        question = await FormsService.getFirstQuestion(form.data.templateFormId)
      }

      return {
        detail: form.data,
        question: question.data,
        hasStarted: hasStarted
      }
    } catch (e) {
      console.log(e);
    }
  }
)

/*
* Получить вопрос
* */
export const getQuestionAction = createAsyncThunk(
  'form/getQuestion',
  async ({ templateFormId, questionId, isPrev = false, formId }: { templateFormId?: number, questionId: number, isPrev?: boolean, formId: number }, { rejectWithValue }) => {
    try {
      let response = null
      if (isPrev) {
        response = await FormsService.getPrevQuestion({
          templateFormId: (templateFormId as number),
          questionId,
          params: { idForm: formId }
        })
      } else {
        // use Next questionId in questionId param
        response = await FormsService.getCurrentQuestion(questionId, { idForm: formId })
      }
      return response.data
    } catch (e) {
      rejectWithValue('Ошибка при получении вопроса')
    }
  }
)

/*
* Отправить ответ на вопрос
* */
export const setAnswerToQuestionAction = createAsyncThunk(
  'form/setAnswerToQuestion',
  async ({ formId, params, nextQuestionId }: { formId: number, params: IAnswerForm, nextQuestionId: number }) => {
    try {
      await FormsService.setAnswerToQuestion({ formId, params })

      if (nextQuestionId === 0) {
        const form = await FormsService.getFormById(formId)
        return { data: form.data, key: 'detail' }
      } else {
        const question = await FormsService.getCurrentQuestion(nextQuestionId, { idForm: formId })
        return { data: question.data, key: 'question' }
      }
    } catch (err) {

    }
  }
)

/*
* Очистить формуx
* */
export const clearFormAction = createAsyncThunk(
  'form/clearForm',
  async ({ formId, templateFormId }: { formId: number, templateFormId: number }) => {
    await FormsService.deleteForm(formId)
    let question = await FormsService.getFirstQuestion(templateFormId)
    return question.data
  }
)

/*
* Получить анкету для предпросмотра с вопросом
* */
export const getTemplateFormWithQuestion = createAsyncThunk(
  'form/getTemplateFormWithQuestion',
  async ({ templateFormId, questionId }: any) => {
    try {
      const res = await FormsService.getFormTemplateById(templateFormId)
      return res.data
    } catch (err) {
      console.log(err)
    }
  }
)
