import $api from "../http";
import { AxiosResponse } from "axios";
import {IProfile} from "../models/InstanceInterfaces/IUser";
import {Credentials} from "../modules/AuthBox";


/**
 * Класс авторизации пользователя
 */
export default class AuthService {
  //TODO: Временная сигнатура для тестов
  /**
   * Авторизация пользователя на сервере
   */
  static login = (data: Credentials): Promise<AxiosResponse<{token: string, user: IProfile}>> =>
    $api.post('/auth/login', {username: data.login, password: data.password})

  /**
   * Проверка пользователя на сервере
   */
  static updateProfileData = (): Promise<{data: IProfile}> =>
    $api.post('/auth/me')

  /**
   * Выход пользователя из системы
   */
  static logout = (): Promise<{data: any}> =>
    $api.post('/auth/signOut')
}
