import React, {useMemo} from 'react';
import s from './s.module.css'
import {Controller} from "react-hook-form";
import clsx from "clsx";

export type UISwitchPropTypes = IUISwitchRaw & {
  control: any
  name: string
}

const UISwitch = ({control, name, label}: UISwitchPropTypes) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value}}) => (
        <UISwitchRaw
          onChange={onChange}
          value={value}
          label={label}
        />
      )}
    />
  );
};


export interface IUISwitchRawControl {
  onChange: (event: any) => void
  value: boolean
}
export interface IUISwitchRaw {
  label?: string
}
const UISwitchRaw = ({onChange, value, label}: IUISwitchRawControl & IUISwitchRaw) => {
  const htmlFor = useMemo(() => String(Math.random()), [])
  return (
    <div className={s.container}>
      <input
        className={s.hiddenBox}
        type={'checkbox'}
        id={htmlFor}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.checked)
        }}
      />
      <div
        className={clsx(s.body, {[s.active]: value})}
        onClick={() => onChange(!value)}
      >
        <div className={clsx(s.core, {[s.coreActive]: value})} />
      </div>
      {label && <label htmlFor={htmlFor}>{label}</label>}
    </div>
  );
};

export default UISwitch;
