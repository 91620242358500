import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Control, Controller, FieldError, FieldErrors, FieldName, RegisterOptions } from 'react-hook-form'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'

import ArrowDownIco from 'assets/svg/common/arrow-flat-down.svg'
import { getTimeOptions } from './utils'

import { OptionData } from 'models/FilterData'
import UIError from 'components/UI/UIError'

import s from './s.module.css'

export enum TIMEPICKER_TYPES {
  hours = 'h',
  minutes = 'm',
}

export type TimepickerPropsTypes =
  TimepickerRawPropsType
  & {
  control: Control<any>
  name: FieldName<any>
  errors?: any
  rules?: RegisterOptions
}

const Timepicker = ({
  name,
  errors,
  control,
  rules,
}: TimepickerPropsTypes) => {

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <TimepickerRaw
          {...{
            onChange,
            value,
            error: errors !== undefined ? errors[name] : undefined
          }}
        />
      )}
    />
  )
}

export default Timepicker

export type TimepickerRawPropsType = {
  error?: any | FieldError,
}

export type ControllerTypes = {
  onChange: (e: string) => void,
  value: string
}

export const TimepickerRaw = ({
  onChange,
  value,
  error,
}: TimepickerRawPropsType & ControllerTypes) => {

  const getParsedValue = (value: number) => {
    return `${('0' + value).slice(-2)}`
  }

  const onChangeTime = (option: OptionData, type: TIMEPICKER_TYPES = TIMEPICKER_TYPES.hours) => {
    let currentValue = getParsedValue((option.value as number))

    if (value) {
      const time = value.split(":")
      currentValue = type === TIMEPICKER_TYPES.hours ? currentValue + ":" + time[1] : time[0] + ":" + currentValue
    } else {
      if (type === TIMEPICKER_TYPES.hours) {
        currentValue = currentValue + ':00'
      } else {
        currentValue = '00:' + currentValue
      }
    }

    onChange(currentValue)
  }

  const hourOptions = useMemo(() => getTimeOptions(TIMEPICKER_TYPES.hours, 24), [])
  const minuteOptions = useMemo(() => getTimeOptions(TIMEPICKER_TYPES.minutes, 60), [])

  const [hours, minutes] = useMemo(() => {
    const time = ['00','00']

    if (value && typeof value === 'string') {
      if (value.split(':').length === 2) {
        time.push(...value.split(":"))
      }
    }

    return [...time.slice(-2)]
  }, [value])

  return (
    <>
      <div className={s.main}>
        <Picker
          onSelect={onChangeTime}
          label="Часы"
          options={hourOptions}
          type={TIMEPICKER_TYPES.hours}
          value={hours}
        />
        <Picker
          onSelect={onChangeTime}
          label="Минуты"
          options={minuteOptions}
          type={TIMEPICKER_TYPES.minutes}
          value={minutes}
        />
      </div>
      {error && error.message && (
        <UIError errorMessage={error.message}/>
      )}
    </>
  )
}

export interface IPickerPropsType {
  onSelect: (option: OptionData, type: TIMEPICKER_TYPES) => void
  label: string
  options: OptionData[]
  type: TIMEPICKER_TYPES,
  value: string
}

export const Picker = ({ onSelect, label, options, type, value = '' }: IPickerPropsType) => {
  const [focused, setFocused] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const onHandleSelect = (option: OptionData, type: TIMEPICKER_TYPES) => {
    setFocused(false)
    onSelect(option, type)
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (focused) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
          setFocused(false)
        }
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef, focused])

  return (
    <div className={clsx(s.container, { [s.focused]: focused })} ref={wrapperRef}>
      <div className={s.input}>
        <label htmlFor="id" className={clsx(s.label, { [s.filled]: focused || value })}>
          {label}
        </label>
        <input type="text" value={value} onClick={() => setFocused(true)} />
        <ReactSVG
          data-testid="selectControl"
          className={clsx(s.arrow)}
          src={ArrowDownIco}
        />
      </div>
      {
        focused && (
        <div className={s.options}>
          {options.map((option) => {
            return (
              <option
                key={option.value}
                value={option.value}
                onClick={() => onHandleSelect(option, type)}
                className={clsx(s.optionItem, { [s.selected]: option.value === Number(value) })}
              >
                {option.name}
              </option>
            )
          })}
        </div>
      )}
    </div>
  )
}
