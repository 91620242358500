import React, {useEffect, useState} from 'react'

import s from './s.module.css'

import Regular from '../Regular';
import {useEaseSearchParams} from "../../../../hooks/useEaseSearchParams";
import AppealsService from "../../../../services/AppealsService";
import {IAppeal} from "../../../../models/InstanceInterfaces/IAppeal";
import {IStatus} from "../../../../models/Status/Status";
import {StatusService} from "../../../../services/StatusService";
import {IResponseExtraData} from "../../../../models/common";
import moment from "moment";
import {IUser} from "../../../../models/InstanceInterfaces/IUser";

export interface IStatusProps {
  User: IUser
}
const Status = ({name, createdAt, User}: (IStatus & IResponseExtraData & IStatusProps)) => (
  <div className={s.status}>
    <p className={s.statusTitle}>{name}</p>
    <span className={s.statusData}>{moment(createdAt).format("YYYY-MM-DD hh:mm:ss")}</span>
    Автор:
    <span className={s.statusData}>{User.fullName}</span>
  </div>
)

const Acepted = () => {
  const [{apId}] = useEaseSearchParams()
  const [appeal, setAppeal] = useState<IAppeal & IResponseExtraData>()
  const [status, setStatus] = useState<IStatus & IResponseExtraData>()

  const getAppeal = async (): Promise<IAppeal> => (await AppealsService.getById(apId)).data
  const getStatus = async (id: number): Promise<(IStatus & IResponseExtraData)> => (await StatusService.getById(id)).data

  useEffect(() => {
    getAppeal().then(setAppeal)
  }, [])

  useEffect(() => {
    if (appeal) getStatus(appeal.statusId).then(setStatus)
  }, [appeal])
  return status && appeal
    ? <Regular status={<Status User={appeal.User} {...status} createdAt={appeal?.createdAt}/>} appeal={appeal}/>
    : <></>
}

export default Acepted
