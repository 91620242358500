import {createAsyncThunk} from "@reduxjs/toolkit";
import LogService from "services/LogService";


export const getAllLogsAction = createAsyncThunk(
    'log/getAll',
    async(params: {
      page: number,
      count: number,
      userId?: number,
      actionType?: string,
      objectType?: string,
      dateFrom?: string,
      dateTo?: string[]
    }): Promise<any> => {
      try {
        const response = await LogService.getAll(params)
        return response.data;
      } catch (e: any) {
      }
    }
  )