import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from "hooks/redux";
import {
  selectForms,
  selectFormSelectedAllFlag,
  selectSelectedFormsExcludeRow,
  selectSelectedFormsRow
} from "store/forms/reselect";
import {getAllFormsAction} from "store/forms/thunk";
import {formsTableConfig} from "lib/tableConfigs/configs";
import {FormsTableControl} from "lib/tableConfigs/controlsPakages/controls";
import TableGenerator from "modules/TableGenerator";
import {UIContainer} from "../../components/UI/UIContainer";
import {toggleFormRow} from "../../store/forms";
import {useEaseSearchParams} from "../../hooks/useEaseSearchParams";
import {parseFilterParamsForRequest} from "../../lib/parseFilterParams";
import {defaultFormsFilterValues, TFormsPageFilter} from "../../modules/filters/FormsPageFilter";
import {ISort} from "../../models/common";
import {getRole} from "../../lib/getRole";
import {Roles} from "../../models/Roles";

/**
 * Страница для отображения анкет
 * @constructor
 */
const Forms = () => {
  const [params] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const resolveRoleRestrictions = () =>
    !!getRole()
      ? getRole().id === Roles.dealer ||
      getRole().id === Roles.dealerDirector
      : false

  const defaultFormsFilterValuesWithRestrictions = {
    ...defaultFormsFilterValues,
    ...!resolveRoleRestrictions() && {controlUserIds: []}
  }
  const [filter, setFilter] = useState<Partial<TFormsPageFilter>>(parseFilterParamsForRequest(params, defaultFormsFilterValuesWithRestrictions) as Partial<TFormsPageFilter>)
  const [sort, setSort] = useState<ISort>()

  const getFormsExecute = useCallback((param: {page: number, count: number} & Partial<TFormsPageFilter>) =>
    dispatch(getAllFormsAction({...param})), [])

  const selectRow = useCallback((id: number) =>
    dispatch(toggleFormRow(id)), [])

  useEffect(() => {
    if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultFormsFilterValuesWithRestrictions) as unknown as Partial<TFormsPageFilter>)) {
      setFilter(parseFilterParamsForRequest(params, defaultFormsFilterValuesWithRestrictions) as unknown as Partial<TFormsPageFilter>)
    }
    if(JSON.stringify(sort) !== JSON.stringify({sort: params.sort, direction: params.direction})) {
      setSort({sort: params.sort, direction: params.direction})
    }
  }, [params])

  return (
    <UIContainer.PageContainer>
      <TableGenerator
        tableName={'Анкеты'}
        dataQuery={getFormsExecute}
        dataSelect={selectForms}
        tableConfig={formsTableConfig}
        tableControl={FormsTableControl}
        filter={filter}
        sort={sort}

        onRowSelect={selectRow}
        excludedRowsLink={selectSelectedFormsExcludeRow}
        selectedRowsLink={selectSelectedFormsRow}
        isAllSelectFlagLink={selectFormSelectedAllFlag}
        selectable
      />
    </UIContainer.PageContainer>
  );
};

export default Forms;
