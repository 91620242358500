import React from 'react'

import s from './s.module.css'

type UIModalListContainerPropsTypes = {
  children: React.ReactNode
  isEmpty: boolean
}

export const UIModalListContainer = ({
  children,
  isEmpty,
}: UIModalListContainerPropsTypes) => {
  return (
    <div className={s.checkboxList}>
      {isEmpty ? (
        <div className={s.empty}>Ничего не найдено</div>
      ) : children}
    </div>
  )
}
