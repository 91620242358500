import React, {useCallback, useMemo, useState} from 'react';
import s from "./s.module.css"
import Logo from 'assets/svg/header/logo.svg'
import ProfilePlug from "components/ProfilePlug";
import {UIButton} from "components/UI/UIButton";
import {logoutUserAction} from "store/profile/thunk";
import {useAppDispatch} from "hooks/redux";
import {useSelector} from "react-redux";
import {RootState} from "store";
import {selectUserData, selectUserIsAuth} from "store/profile/reselect";
import Navigation from "modules/Navigation";
import { getNavigation } from 'modules/AppRouter/usersRoutes';
import {Link, useNavigate} from "react-router-dom";
import { ReactComponent as LogOutIcon } from 'assets/svg/header/logOutIcon.svg'
import { ReactComponent as ProfileIcon } from 'assets/svg/header/profile.svg'
import clsx from "clsx";
import LogoutPopup from "components/LogoutPopup";

const Header = () => {
  const nav = useNavigate()
  const dispatch = useAppDispatch()
  const {
    isAuth,
    userData: { roleId },
  } = useSelector((store: RootState) => ({
    isAuth: selectUserIsAuth(store),
    userData: selectUserData(store)
  }))
  const [showPopUp, setShowPopUp] = useState(false)
  const handlePopUp = () => setShowPopUp(p => !p)

  const logoutHandler = useCallback(() => {
    dispatch(logoutUserAction()).then(() => {
      document.cookie = 'token=;'
      nav('/')
    })
    setShowPopUp(false)
  }, [])

  const routes = useMemo(() => getNavigation(roleId), [roleId])

  return (
    <div className={s.header}>
      <Link to={'/'} className={s.logoLink}>
        <img src={Logo} alt="logo"/>
      </Link>
      {
        isAuth && (
          <>
            <Navigation links={routes}/>
            <div className={clsx(s.headerRight, s.screenBig)}>
              <ProfilePlug />
              <UIButton.CircleBtn style={{backgroundColor: "#F0F2F4"}} handler={logoutHandler}>
                <LogOutIcon />
              </UIButton.CircleBtn>
            </div>
            <div className={clsx(s.headerRight, s.screenSm)}>
              { showPopUp && <LogoutPopup handler={logoutHandler}/>}
              <UIButton.CircleBtn style={{backgroundColor: "#F0F2F4"}} handler={handlePopUp}>
                <ProfileIcon />
              </UIButton.CircleBtn>
            </div>
          </>
        )
      }
    </div>
  );
};

export default Header;
