import React, {useCallback, useEffect, useState} from 'react';
import {UIContainer} from "components/UI/UIContainer";
import {appealsTableConfig} from "lib/tableConfigs/configs";
import {AppealsTableControl} from "lib/tableConfigs/controlsPakages/controls";
import TableGenerator from "modules/TableGenerator";
import {useAppDispatch} from "hooks/redux";
import {getAppealsAction} from "store/appeals/thunk";
import {selectAppealsDataList} from "store/appeals/reselect";
import {parseFilterParamsForRequest} from "lib/parseFilterParams";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {defaultAppealsFilterValues, TAppealsPageFilter} from "modules/filters/AppealsPageFilter";
import {ISort} from "../../models/common";


/**
 * Страница для отображения апелляций
 * @constructor
 */
const Appeals = () => {
  const [params] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState<TAppealsPageFilter>(parseFilterParamsForRequest(params, defaultAppealsFilterValues) as unknown as TAppealsPageFilter)
  const [sort, setSort] = useState<ISort>()

  const getAppealsQueryExecute = useCallback((param: {page: number, count: number}) =>
    dispatch(getAppealsAction(param)), [])

  useEffect(() => {
    if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultAppealsFilterValues) as unknown as TAppealsPageFilter)) {
      setFilter(parseFilterParamsForRequest(params, defaultAppealsFilterValues) as unknown as TAppealsPageFilter)
    }
    if(JSON.stringify(sort) !== JSON.stringify({sort: params.sort, direction: params.direction})) {
      setSort({sort: params.sort, direction: params.direction})
    }
  }, [params])

  return (
    <UIContainer.PageContainer>
      <TableGenerator
        tableName={'Апелляции'}
        tableConfig={appealsTableConfig}
        tableControl={AppealsTableControl}
        dataQuery={getAppealsQueryExecute}
        dataSelect={selectAppealsDataList}
        filter={filter}
        sort={sort}
        />
    </UIContainer.PageContainer>
  );
};

export default Appeals;
