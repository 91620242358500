import $api from '../http'
import {AxiosResponse} from 'axios'
import {ICity} from '../models/InstanceInterfaces/ICity'
import {DeleteDealerFromGroupI, IDelMultipleDealerFromGroup} from '../models/DealerServiceType/DealersServiceRequest'
import {
  IIdentifier,
  IPaginate,
  IResponseExtraData,
  ISearchByNameWithPaginateRequest,
  ListResponse,
} from '../models/common'
import {IDistrict} from '../models/InstanceInterfaces/IDistrict'
import {IState} from '../models/InstanceInterfaces/IState'
import {IDealer, IDealerExtended} from '../models/DealersServiceModel'
import {TFormsPageFilter} from '../modules/filters/FormsPageFilter'
import { IDealerPageFilter } from 'modules/filters/DealersPageFilter';
import {IFile} from "../models/InstanceInterfaces/IFile";

/**
 * Класс для работы с дилерами
 */
export default class DealersService {
  static get = (id: number): Promise<AxiosResponse<IDealerExtended & IResponseExtraData & IIdentifier>> =>
    $api.get(`/dealers/${id}`)

  static getAll = <ParamsType>(
    params: IPaginate & Partial<TFormsPageFilter> & Partial<ParamsType>,
  ): Promise<AxiosResponse<ListResponse<IDealer & IResponseExtraData & IIdentifier>>> => $api.get('/dealers', {params})

  static getAllForModal = <ParamsType>(
    params: IPaginate & Partial<TFormsPageFilter> & Partial<ParamsType>,
  ): Promise<AxiosResponse<ListResponse<IDealer & IResponseExtraData & IIdentifier>>> => $api.get('/dealers/forForm', {params})

  static sync = () => $api.post('/dealers/import')

  static getAllCities = (params: ISearchByNameWithPaginateRequest): Promise<AxiosResponse<ListResponse<ICity>>> =>
    $api.get('/dealers/cities', {params})

  static getAllDistricts = (
    params: ISearchByNameWithPaginateRequest,
  ): Promise<AxiosResponse<ListResponse<IDistrict>>> => $api.get('/dealers/dist', {params})

  static getStates = (params: ISearchByNameWithPaginateRequest): Promise<AxiosResponse<ListResponse<IState>>> =>
    $api.get('/dealers/state', {params})

  static getAllPrevDealer = (
    params: ISearchByNameWithPaginateRequest,
  ): Promise<AxiosResponse<ListResponse<IDealer & IResponseExtraData & IIdentifier>>> =>
    $api.get('/dealers/prevDealer', {params})

  static getAllDealerGroup = (params: ISearchByNameWithPaginateRequest): Promise<AxiosResponse<any>> =>
    $api.get('/dealers/dealerGroup', {params})

  static postCreateDealerGroup = (name: string): Promise<AxiosResponse<any>> =>
    $api.post(`/dealers/dealerGroup`, {name})

  static putPrevDealer = (dealerId: string, prevDealerId: number): Promise<AxiosResponse<any>> =>
    $api.put(`/dealers/${dealerId}/prevDealer`, { prevDealerId })

  static putDealerToGroup = (dealerId: string, groupId: number): Promise<AxiosResponse<any>> =>
    $api.put(`/dealers/${dealerId}/dealerToGroup`, {groupId})

  static putSetDealerGroup = (dealerId: string, groupId: {groupId: number}): Promise<AxiosResponse<any>> =>
    $api.put(`/dealers/${dealerId}/dealerToGroup`, groupId)

  static putSetMultipleDealersToGroup = (groupId: string, params: any, body?: any): Promise<AxiosResponse<any>> =>
    $api.put(`/dealers/dealersToGroup/${groupId}`, body, {params})

  static putResponsibleDealer = (dealerId: string, params: any): Promise<AxiosResponse<any>> =>
    $api.put(`/dealers/${dealerId}/responsibleDealer`, {...params})

  static putSetMultipleDealersToUser = (userId: string, params: any, body?: any): Promise<AxiosResponse<any>> =>
    $api.put(`/dealers/dealersToUser/${userId}`, body, {params})

  static delSetMultipleDealersToUser = (userId: string, params: any, body?: any): Promise<AxiosResponse<any>> =>
    $api.delete(`/dealers/dealersToUser/${userId}`, {data: body, params})

  static putDealerToUser = (dealerId: string, userId: number): Promise<AxiosResponse<any>> =>
    $api.put(`/dealers/${dealerId}/dealerToUser`, {userId})

  static deleteDealerFromGroup = (dealerId: string, groupId: number): Promise<AxiosResponse<any>> =>
    $api.delete(`/dealers/${dealerId}/dealerFromGroup`, {data: {groupId}})

  static delDealerFromGroup = (dealerId: string, params: DeleteDealerFromGroupI): Promise<AxiosResponse<any>> =>
    $api.delete(`/dealers/${dealerId}/dealerFromGroup`, {data: params})

  static delMultipleDealerFromGroup = (groupId: string, params: any, data: any): Promise<AxiosResponse<any>> =>
    $api.delete(`/dealers/dealersToGroup/${groupId}`, {data, params})

  static deleteDealerFromUser = (dealerId: string, userId: number): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/dealers/${dealerId}/dealerFromUser`, {data: {userId}})

  static downloadExcel = (params: Partial<IDealerPageFilter>): Promise<AxiosResponse<IFile & IIdentifier>> =>
    $api.get(`/dealers/excel`, { params })
}
