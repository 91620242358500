import { INFOFILES_NAME } from 'lib/appConst';
import moment from "moment";

const yearDateGenerator = () => {
  const years = []
  for(let i = 2019; i <= moment(new Date).get('year') + 1; i ++) {
    years.push({
      value: moment(`01.01.${i}`).get('year'),
      name: `${moment(`01.01.${i}`).get('year')}`
    })
  }
  return years
}

export const yearsOptions = yearDateGenerator()

export const quarterOptions = [
  { value: 1, name:'I' },
  { value: 2, name:'II' },
  { value: 3, name:'III' },
  { value: 4, name:'IV' },
]

export const groupValues = ['factor', 'process', 'question']
export const groupOptions = [
  { value: 1, name: 'По факторам' },
  { value: 2, name: 'По процессам' },
  { value: 3, name: 'По вопросам' },
]

export const calculationValues = ['count', 'score']
export const calculationOptions = [
  { value: 1, name: 'Процент выполнения по ответам' },
  { value: 2, name: 'Процент выполнения по баллам' },
]

export const appealOptions = [
  { value: 0, name: 'Да' },
  { value: 1, name: 'Нет' },
]

export const infoFileOptions = [
  { value: 1, name: INFOFILES_NAME.common },
  { value: 2, name: INFOFILES_NAME.clients },
  { value: 3, name: INFOFILES_NAME.portal },
]
