import React, {ChangeEvent, CSSProperties, memo, MouseEventHandler, SyntheticEvent, useEffect, useState} from 'react'
import { Control, Controller, FieldError, FieldName, RegisterOptions } from 'react-hook-form'
import { ReactSVG } from 'react-svg'
import s from './s.module.css'
import clsx from "clsx"
import isHideSVG from 'assets/svg/fields/isHide.svg'
import isShowSVG from 'assets/svg/fields/isShow.svg'
import {AxiosResponse} from "axios";
import {ListResponse} from "models/common";
import {useDebounce} from "hooks/useDebounce";


export interface FocusEvent<T = Element> extends SyntheticEvent<T> {
  relatedTarget: EventTarget | null;
  target: EventTarget & T;
}

//TODO: Понять как прокинуть тип в дженерик контроллера через поле компонента
export type InputPropsType =
  InputRawPropsType
  & {
  control: Control<any>
  name: FieldName<any>
  // errors?: FieldErrors | undefined
  errors?: any
  rules?: RegisterOptions
}

export type ControllerTypes = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: string
}

const Input = ({
                 name,
                 placeholder,
                 control,
                 customStyle,
                 type = 'text',
                 errors,
                 customInputClassName,
                 onBlur,
                 customInputWrapperClassName,
                 maxLength,
                 customInputStyle,
                 readOnly=false,
                 clickHandler=()=>{},
                 extraOnChangeHandler = () => {},
                 rules,
                 suggestQuery,
                 suggestUseAnotherFilterField,
                 suggestUseAnotherGetterField,
               }: InputPropsType) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={
        ({field: {onChange, value}}) =>
          <InputRaw
            {...{
              type,
              name,
              onChange,
              onChangeControl: onChange,
              value,
              placeholder,
              customStyle,
              customInputStyle,
              customInputClassName,
              customInputWrapperClassName,
              onBlur,
              maxLength,
              readOnly,
              clickHandler,
              extraOnChangeHandler,
              suggestQuery,
              suggestUseAnotherFilterField,
              suggestUseAnotherGetterField,
              error: errors !== undefined ? errors[name] : undefined
            }}
          />
      }
    />
  );
};

export default Input;

export type InputRawPropsType = {
  name: string
  placeholder?: string
  customStyle?: CSSProperties | undefined
  type?: 'text' | 'password'  | 'number'
  error?: any | FieldError,
  rightIcon?: any
  customInputClassName?: string
  customInputWrapperClassName?: string
  onBlur?: any
  disabled?: boolean
  maxLength?: number
  customInputStyle?: CSSProperties | undefined
  readOnly?: boolean
  clickHandler?: MouseEventHandler<HTMLInputElement> | undefined
  extraOnChangeHandler?: Function
  suggestQuery?: (params: any) => Promise<AxiosResponse<ListResponse<any>>>
  suggestUseAnotherFilterField?: string
  suggestUseAnotherGetterField?: string
  onChangeControl?: (e: ChangeEvent<HTMLInputElement> | string) => void
}

export const InputRaw = React.forwardRef<HTMLInputElement, (InputRawPropsType & ControllerTypes)>(({
                                            name,
                                            placeholder,
                                            value,
                                            onChange,
                                            onChangeControl,
                                            customStyle,
                                            type,
                                            error,
                                            rightIcon,
                                            customInputClassName,
                                            customInputWrapperClassName,
                                            onBlur,
                                            disabled,
                                            maxLength,
                                            customInputStyle,
                                            readOnly,
                                            clickHandler,
                                            extraOnChangeHandler,
                                            suggestQuery,
                                            suggestUseAnotherFilterField,
                                            suggestUseAnotherGetterField = "name",
                                          }, ref) => {

  const [showPass, setShowPass] = useState(false)
  const [suggestList, setSuggestList] = useState<any[]>([])
  const [showSuggest, setShowSuggest] = useState(false)
  const [manualSelectFlag, setManualSelectFlag] = useState(false)
  const showPassToggle = () => {
    setShowPass(prev => !prev)
  }

  const debouncedStr = useDebounce(value, 300)
  const [oldDebounce, setOldDebounce] = useState('')

  const getSuggest = async () =>
    suggestQuery && oldDebounce !== debouncedStr && setSuggestList((await suggestQuery({[suggestUseAnotherFilterField || "name"]: debouncedStr, count: '5'})).data.rows)

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setSuggestList([])
      setManualSelectFlag(false)
      setShowSuggest(false)
      onBlur && onBlur(e)
    }, 100)
  }

  const applySuggestVal = (val: {[key: string]: string}) =>
    onChangeControl && onChangeControl(val[suggestUseAnotherGetterField])

  useEffect(() => {
    setOldDebounce(debouncedStr)
  }, [debouncedStr])

  useEffect(() => {
    if(debouncedStr && manualSelectFlag) getSuggest()
  }, [debouncedStr, manualSelectFlag])

  useEffect(() => {
    suggestList.length && debouncedStr.length && value.length && manualSelectFlag
      ? setShowSuggest(true)
      : setShowSuggest(false)
  }, [suggestList, debouncedStr])

  const passwordFieldTypeSwitch = type === 'password' && !showPass ? 'password' : 'text'
  return (
    <div className={s.wrapper} style={customStyle}>
      {value && <span className={clsx(s.select__subheader)}>{placeholder}</span>}
      <div className={clsx(s.inputWrapper, customInputWrapperClassName)}>
        <input
          ref={ref}
          autoComplete={"off"}
          className={clsx(s.input, {
            [s.input_filled]: value,
            [s.input_empty]: !value
          }, customInputClassName)}
          type={type === 'password' ? passwordFieldTypeSwitch : type}
          name={name}
          placeholder={placeholder}
          onChange={(e) => {
            setManualSelectFlag(true)
            if(maxLength) {
              if(e.target.value.length <= maxLength) {
                if(onChangeControl) {
                  onChangeControl(e)
                } else {
                  onChange(e)
                }
              }
            } else {
              if(onChangeControl) {
                onChangeControl(e)
              } else {
                onChange(e)
              }
            }
            //TODO Добавить debouncing
            extraOnChangeHandler && extraOnChangeHandler();
          }}
          value={value}
          onBlur={handleBlur}
          disabled={disabled}
          style={customInputStyle}
          readOnly={readOnly}
          onClick={clickHandler}
        />
        {rightIcon && rightIcon}
        {showSuggest &&
          <Suggest
            rows={suggestList}
            getterField={suggestUseAnotherGetterField}
            applySuggestVal={applySuggestVal}
          />
        }
      </div>
      <div className={clsx(s.control, {
        [s.isHide]: true
      })}>
        <ReactSVG
          onClick={showPassToggle}
          data-testid="showPasswordToggle"
          className={clsx(s.controlElement, {
            [s.showControl]: type === 'password'
          })} src={!showPass ? isHideSVG : isShowSVG} />
      </div>
      {error && <p className={"fieldError"}>{error?.message}</p>}
    </div>
  );
})

interface ISuggestProps {
  rows: any[]
  getterField?: string
  applySuggestVal: (val: {[key: string]: string}) => void
}

const Suggest = memo(({
                        rows,
                        getterField = 'name',
                        applySuggestVal,
}: ISuggestProps) => {
  return (
    <ul className={s.suggest}>
      {
        rows.map((el, id) =>
          <li key={id} onClick={() => applySuggestVal(el)}>{el[getterField]}</li>)
      }
    </ul>
  )
})
