import React from 'react'
import s from './s.module.css'
import {ReactComponent as GoBack} from 'assets/svg/header/arrow-left-gray.svg'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import Preloader from "../Preloader/Preloader";

export interface IUIPageTitle {
  label: string
  Controls?: (props: any) => JSX.Element
  withBackBtn?: boolean
  subtitle?: string
  backPath?: string
  loading?: boolean
}
const UIPageTitle = ({label, Controls, withBackBtn, subtitle, backPath, loading}: IUIPageTitle) => {
  const nav = useNavigate()

  return (
    <div className={s.wrapper}>
      <div className={s.titleWrapper}>
        {withBackBtn && (
          <div className={s.pseudoBackBtn} onClick={() => (backPath ? nav(backPath) : window.history.back())}>
            <GoBack />
          </div>
        )}
        <div className={s.labelWrapper}>
          <h3 className={clsx('title', s.marginLeft)}>{label}</h3>
          <p className={clsx(s.subtitle, s.marginLeft)}>{subtitle}</p>
        </div>
        {loading && <Preloader style={{marginLeft: 30}} width={40} height={40}/>}
      </div>
      {Controls && <Controls />}
    </div>
  )
}

export default UIPageTitle
