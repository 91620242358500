import React, {useState} from 'react';
import UIField from "components/UI/UIField";
import {UIButton} from "components/UI/UIButton";
import UISwitch from "components/UI/UISwitch";
import {useForm} from "react-hook-form";
import s from './s.module.css'
import {ReactComponent as ApplyIcon} from "assets/svg/button/apply.svg";
import FormsService from "services/FormsService";
import {IQuestionOption} from "models/InstanceInterfaces/IQuestionOption";
import {IIdentifier} from "models/common";
import {goodRequest} from "../../../../../lib/apiHelpers";

export interface IOptionSelectQuestionForm {
  formQuestionId: number,
  handlerAfterRequest?: () => void
  defaultValues?: IQuestionOption & IIdentifier
}

const OptionSelectQuestionForm = ({formQuestionId, handlerAfterRequest, defaultValues}: IOptionSelectQuestionForm) => {

  const [score, setScore] = useState<number | string>(defaultValues?.score || 0)
  const [noScore, setNoScore] = useState(false)

  const {control, handleSubmit, watch, formState: {isSubmitting}} = useForm<IQuestionOption & {comment: boolean}>({
    defaultValues: {
      textOption: '',
      textComment: 'Пожалуйста, прокомментируйте ваш ответ',
      requiredComment: false,
      comment: !!defaultValues?.textComment,
      ...defaultValues
    }
  })

  const addUserComment = watch('comment')

  const pointsHandle = (val: string) => {
    if(!val) {
      setNoScore(true)
      setScore('')
    }
    else {
      setNoScore(false)
      setScore(isNaN(Number(val)) ? 0 : Number(val))
    }
  }

  const submit = async ({textOption, requiredComment, textComment, comment}: IQuestionOption & {comment: boolean}) => {
    try {
      const data = {
        formQuestionId,
        textOption,
        score: noScore ? null : Number(score),
        requiredComment: Number(requiredComment),
        textComment: comment ? textComment : '',
      } as IQuestionOption

      let res;

      if(!defaultValues) {
        res = await FormsService.createNewQuestionOption(data)
      } else {
        res = await FormsService.editQuestionOption({...data, id: defaultValues.id})
      }
      if(goodRequest(res.status)) {
        handlerAfterRequest && handlerAfterRequest()
      }
    } catch (e) {
      alert("Возникла сетевая ошибка, попробуйте позже или обратитесь в поддержку.")
    }
  }
  console.log(isSubmitting)
  return (
    <div className={s.container}>
      <div className={s.row}>
        <UIField.Input
          name={'textOption'}
          control={control}
          placeholder={"Вариант"}
          customStyle={{width: 346}}
        />
        <UIField.InputRaw
          name={'score'}
          maxLength={3}
          placeholder={"Баллов за ответ"}
          customStyle={{width: 158}}
          onChange={({target: {value}}: React.ChangeEvent<HTMLInputElement>) => pointsHandle(value)}
          value={String(score)}
        />
       <div className={s.marginLeft}>
         <UIButton.EmptyBtn
           title={'Применить редактирование'}
           type={"button"}
           disabled={isSubmitting}
           rightIcon={<ApplyIcon/>}
           handler={handleSubmit(submit)}
         />
       </div>
      </div>
      <div className={s.row}>
        <UISwitch
          label={"Добавить комментарий"}
          control={control}
          name={'comment'}
        />
      </div>
      {addUserComment &&
        <>
          <div className={s.row}>
            <UIField.Input
              name={'textComment'}
              control={control}
              placeholder={"Описание комментария"}
            />
          </div>
          <div className={s.row}>
            <UISwitch
              label={"Обязателен к заполнению"}
              control={control}
              name={'requiredComment'}
            />
          </div>
        </>
      }
    </div>
  );
};

export default OptionSelectQuestionForm;
