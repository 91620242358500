import React, {useEffect, useState} from 'react';
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import s from './s.module.css'
import clsx from "clsx";
import {UIButton} from "components/UI/UIButton";
import {ReactComponent as ArrowRight} from "assets/svg/pagination/arrow-gray-right.svg";
import {ReactComponent as ArrowLeft} from "assets/svg/pagination/arrow-gray-left.svg";

export interface IPagination {
  total: number
  customClassName?: string
  emptyBtn?: boolean
  disabledTableActivity?: boolean
}

const borderBtnStylesheet = {
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 12,
  paddingRight: 12,
  borderRadius: 8,
  fontSize: 12,
  lineHeight: 1,
  border: "1px solid var(--color-lightGray)",
  backgroundColor: 'transparent',
  color: 'var(--color-mainDark)'
}

const pageChunkSize = 6

/**
 * Пагинация таблицы
 * @param total - Колличество строк
 * @param customClassName - Кастомный wrapper
 * @param emptyBtn - отключить текст в кнопках
 */
const Pagination = ({total, customClassName, emptyBtn, disabledTableActivity}: IPagination) => {
  const [{count, page}, setParams] = useEaseSearchParams()
  const [pages, setPages] = useState<number[]>([])
  const [pagesChunks, setPagesChunks] = useState<number[][]>([])
  const [currentPageChunk, setCurrentPageChunk] = useState<number[]>([])

  /**
   * Получить кол-во. страниц
   * @param total - Кол-во. элементов в списке
   * @param count - Кол-во. отображаемых элементов на странице
   */
  const getPagesCount = (total: number, count: number) =>
    Math.ceil(total / count)

  /**
   * Построить линейный массив страниц
   * @param pagesCount - кол-во. страниц
   */
  const createAllPagesArr = (pagesCount: number) => {
    const arr = []
    for (let i = 1; i <= pagesCount; i++) {
      arr.push(i)
    }
    return arr
  }

  /**
   * Получить срезы страниц из линейного массива страниц
   * @param pages - массив страниц
   */
  const getPagesChunks = (pages: number[]) => {
    const pagesToChunksArr: number[][] = []
    for (let i = 0; i <= pages.length; i += i !== 0 ? pageChunkSize - 2 : pageChunkSize) {
      const chunk: number[] = pages
        .slice(
          i === 6
            ? i - 2
            : i !== 0
              ? i - 2
              : i,
          i !== 6
            ? i === 0
              ? i + pageChunkSize
              : i + pageChunkSize - 2
            : i + pageChunkSize - 2
        )
      /**
       * Если получили срез с последним элементом равным pages.length, то нет смысла продолжать построение срезов
       */
      if(chunk[chunk.length - 1] === pages.length) {
        /**
         * Если последний срез меньше pageChunkSize,
         * то докидываем в срез элементы из прошлого среза,
         * что бы уровнять количество элементов до pageChunkSize
         */
        if(chunk.length < pageChunkSize && pagesToChunksArr.length > 0) {
          const getMissing = pagesToChunksArr[pagesToChunksArr.length - 1]
          chunk.unshift(...[...getMissing].slice(chunk.length - 2, getMissing.length - 2))
        }
        pagesToChunksArr.push(chunk)
        break
      }
      pagesToChunksArr.push(chunk)
    }
    return pagesToChunksArr
  }

  /**
   * Получить текущий срез страниц
   * @param page - тек. страницы
   * @param pagesChunks - Массив срезов
   */
  const getCurrentChunk = (page: number, pagesChunks: number[][]) => {
    const targetChunk = pagesChunks.find((el) => el.includes(page)) || []
    const targetChunkIndex = pagesChunks.indexOf(targetChunk)
    const isFinalPageInChunk = page === targetChunk[targetChunk.length - 1]
    const isFirstPageInChunk = page === targetChunk[0]
    if(isFinalPageInChunk) {
      return pagesChunks[targetChunkIndex + 1] || targetChunk
    }
    if(isFirstPageInChunk) {
      return pagesChunks[targetChunkIndex - 1] || targetChunk
    }
    return targetChunk
  }

  /**
   * Ручной выбор страницы
   */
  const setPage = (newPage: number) =>
    !disabledTableActivity && Number(page) !== newPage && setParams((p: any) => ({...p, page: newPage}))

  /**
   * Инкремент страницы
   */
  const handlePageForward = () =>
    !disabledTableActivity && Number(page) + 1 <= pages.length && setParams((p: any) => ({...p, page: Number(page) + 1}))

  /**
   * Декремент страницы
   */
  const handlePageBehind = () =>
    !disabledTableActivity && page - 1 !== 0 && setParams((p: any) => ({...p, page: page - 1}))

  /**
   * К начальной странице
   */
  const handlePageToStart = () =>
    !disabledTableActivity && setParams((p: any) => ({...p, page: 1}))

  /**
   * К последней странице
   */
  const handlePageToEnd = () =>
    !disabledTableActivity && setParams((p: any) => ({...p, page: pages.length}))

  /**
   * Построить массив страниц
   */
  useEffect(() => {
    setPages(createAllPagesArr(getPagesCount(total, count) || 1))
  }, [total, count])

  /**
   * Получить срезы страниц
   */
  useEffect(() => {
    if(pages.length > 0) {
      setPagesChunks(getPagesChunks(pages))
    }
  }, [pages])

  useEffect(() => {
    setCurrentPageChunk(getCurrentChunk(Number(page), pagesChunks))
  }, [page, pagesChunks])

  return (
    <div className={clsx(s.wrapper, customClassName)}>
      <UIButton.BorderBtn
        label={emptyBtn ? '' : 'Назад'}
        handler={handlePageBehind}
        style={borderBtnStylesheet}
        leftIcon={<ArrowLeft/>}
        disabled={disabledTableActivity}
      />
      <div className={clsx(s.paginationPages, {
        [s.disabled]: disabledTableActivity
      })}>
        {
          !!pagesChunks.length && pagesChunks[0][5] <= Number(page) && (
            <>
              <p
                className={clsx(s.paginationLink)}
                onClick={handlePageToStart}
              >
                1
              </p>
              <p className={s.paginationNoLink}>...</p>
            </>
          )
        }
        {
          currentPageChunk.map((el) =>
            <p
              key={el}
              className={clsx(s.paginationLink, {[s.selected]: el === Number(page)})}
              onClick={() => setPage(el)}
            >
              {el}
            </p>
          )
        }
        {
          !!pagesChunks.length && !currentPageChunk.includes(pages.length) && (
            <>
              <p className={s.paginationNoLink}>...</p>
              <p
                className={clsx(s.paginationLink)}
                onClick={handlePageToEnd}
              >
                {!!Number(pages.length) && pages.length}
              </p>
            </>
          )
        }
      </div>
      <UIButton.BorderBtn
        label={emptyBtn ? '' : 'Вперед'}
        handler={handlePageForward}
        style={borderBtnStylesheet}
        rightIcon={<ArrowRight/>}
        disabled={disabledTableActivity}
      />
    </div>
  );
};

export default Pagination;
