import React, { useCallback, useEffect, useState } from 'react'

import { UIContainer } from 'components/UI/UIContainer'
import TableGenerator from 'modules/TableGenerator'
import { useEaseSearchParams } from 'hooks/useEaseSearchParams'
import { useAppDispatch } from 'hooks/redux'
import { getAllFormsAction } from 'store/forms/thunk'
import { IPaginate, ISort } from 'models/common'
import { defaultFormsFilterValues, TFormsPageFilter } from 'modules/filters/FormsPageFilter'
import { selectForms } from 'store/forms/reselect'
import { todosTableConfig } from 'lib/tableConfigs/configs'
import { useSelector } from 'react-redux'
import { selectUserData } from 'store/profile/reselect'
import { parseFilterParamsForRequest } from 'lib/parseFilterParams';
import { TodosTableControl } from 'lib/tableConfigs/controlsPakages/controls'
import {getRole} from "../../lib/getRole";
import {Roles} from "../../models/Roles";

/**
 * Страница с заданиями (список - таблица) для Тайного покупателя
 * @constructor
 */
const Todos = () => {
  const [params] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const resolveRoleRestrictions = () =>
    !!getRole()
      ? getRole().id === Roles.dealer ||
      getRole().id === Roles.dealerDirector
      : false

  const defaultFormsFilterValuesWithRestrictions = {
    ...defaultFormsFilterValues,
    ...!resolveRoleRestrictions() && {controlUserIds: []}
  }
  const [filter, setFilter] = useState<Partial<TFormsPageFilter>>(parseFilterParamsForRequest(params, defaultFormsFilterValuesWithRestrictions) as Partial<TFormsPageFilter>)
  const [sort, setSort] = useState<ISort>()

  const profile = useSelector(selectUserData)

  const getTodos = useCallback((param: IPaginate & Partial<TFormsPageFilter>) =>
    dispatch(getAllFormsAction({ ...param, controlUserIds: [Number(profile.id)] })), [])

  useEffect(() => {
    if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultFormsFilterValuesWithRestrictions) as unknown as Partial<TFormsPageFilter>)) {
      setFilter(parseFilterParamsForRequest(params, defaultFormsFilterValuesWithRestrictions) as unknown as Partial<TFormsPageFilter>)
    }
    if(JSON.stringify(sort) !== JSON.stringify({sort: params.sort, direction: params.direction})) {
      setSort({sort: params.sort, direction: params.direction})
    }
  }, [params])

  return (
    <UIContainer.PageContainer>
      <TableGenerator
        tableName="Задания"
        dataQuery={getTodos}
        dataSelect={selectForms}
        tableConfig={todosTableConfig}
        tableControl={TodosTableControl}
        filter={filter}
        sort={sort}
      />
    </UIContainer.PageContainer>
  );
};

export default Todos;
