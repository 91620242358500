import $api from "../http";
import {AxiosResponse} from "axios";
import {
  IIdentifier,
  ISearchByNameWithPaginateAndIDRequest,
  ListResponse,
  IPaginate,
  IResponseExtraData,
  ISearchByNameWithPaginateRequest, ISort
} from "../models/common";
import {IForm, IFormToEdit} from "../models/InstanceInterfaces/IForm";
import {IWave} from "../models/InstanceInterfaces/IWave";
import {IKindForm} from "../models/InstanceInterfaces/IKindForm";
import {
  IMainGroup,
  ITemplateForm,
  ITemplateFormTransfer,
  ITemplateGroup
} from "../models/InstanceInterfaces/ITemplateForm";
import {ITypeForm} from "../models/InstanceInterfaces/ITypeForm";
import {IQuestionOption} from "../models/InstanceInterfaces/IQuestionOption";
import {IGroup} from "../models/IGroup";
import {IIterable, IQuestion, IQuestReorderData} from "../models/IQuestion";
import {TFormsPageFilter} from "../modules/filters/FormsPageFilter";
import {FormIdParamType, IAnswer, IAnswerForm, IQuestionInstance} from 'models/InstanceInterfaces/IQuestion';
import {TMainPageFilter} from 'modules/filters/MainPageFilter/utils';
import {IFile} from "../models/InstanceInterfaces/IFile";


/**
 * Класс для работы с анкетами
 */
export default class FormsService {

  static getAll = (params: IPaginate & Partial<TFormsPageFilter> & ISort):
    Promise<AxiosResponse<ListResponse<IForm & IIdentifier & IResponseExtraData>>> =>
    $api.get('/form', {params})

  static getArchive = (params: IPaginate & Partial<TFormsPageFilter> & ISort):
    Promise<AxiosResponse<ListResponse<IForm & IIdentifier & IResponseExtraData>>> =>
    $api.get('/form', {params: {...params, archive: true}})

  static create = (data: IForm | any): Promise<AxiosResponse<IForm & IResponseExtraData & IIdentifier>> =>
    $api.post('/form', data)

  static downloadExcel = (params: Partial<TFormsPageFilter>) =>
    $api.get('/form/excel', {params})

  static createManyForm = (data: IForm | any): Promise<AxiosResponse<IForm & IResponseExtraData & IIdentifier>> =>
    $api.post('/form/many', data)

  static edit = (data: IFormToEdit & IIdentifier): Promise<AxiosResponse<0 | 1>> =>
    $api.put(`/form/${data.id}`, data)

  static getById = (id: number): Promise<AxiosResponse<IForm & IResponseExtraData & IIdentifier>> =>
    $api.get(`/form/${id}`)

  static delete = (id: number): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/form/${id}`)

  static getAllWaves = (params: ISearchByNameWithPaginateRequest): Promise<AxiosResponse<ListResponse<IWave & IResponseExtraData>>> =>
    $api.get('/form/wave', {params})

  static createNewWave = (data: IWave): Promise<AxiosResponse<IWave & IResponseExtraData>> =>
    $api.post('/form/wave', data)

  static editWave = (data: IWave): Promise<AxiosResponse<IWave & IResponseExtraData>> =>
    $api.put(`/form/wave/${data.id}`, data)

  static deleteWave = (id: string): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/form/wave/${id}`)

  static getAllFormKinds = (params: ISearchByNameWithPaginateRequest): Promise<AxiosResponse<ListResponse<IKindForm & IResponseExtraData>>> =>
    $api.get('/form/kindForm', {params})

  static createNewFormKind = (data: IKindForm): Promise<AxiosResponse<IKindForm & IResponseExtraData>> =>
    $api.post('/form/kindForm', data)

  static editFormKind = (data: IKindForm): Promise<AxiosResponse<IKindForm & IResponseExtraData>> =>
    $api.put(`/form/kindForm/${data.id}`, data)

  static deleteFormKind = (id: number): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/form/kindForm/${id}`)

  static getAllFormTemplates = (params: ISearchByNameWithPaginateRequest): Promise<AxiosResponse<ListResponse<ITemplateForm & IIdentifier & IResponseExtraData>>> =>
    $api.get('/form/templateForm', {params})

  static getFormTemplateById = (id: number): Promise<AxiosResponse<ITemplateForm & IResponseExtraData & IIdentifier>> =>
    $api.get(`/form/templateForm/${id}`)

  static createNewFormTemplate = (data: ITemplateFormTransfer): Promise<AxiosResponse<ITemplateForm & IResponseExtraData>> =>
    $api.post('/form/templateForm', data)

  static copyFormTemplate = (id: number, name: string): Promise<AxiosResponse<ITemplateForm & IResponseExtraData & IIdentifier>> =>
    $api.post(`/form/templateForm/${id}/copy`, {name})

  static excelFormTemplate = (id: number): Promise<AxiosResponse<IFile & IIdentifier>> =>
    $api.get(`/form/templateForm/${id}/excel`)

  static editFormTemplate = (data: ITemplateFormTransfer): Promise<AxiosResponse<ITemplateForm & IResponseExtraData>> =>
    $api.put(`/form/templateForm/${data.id}`, data)

  static deleteFormTemplate = (id: number): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/form/templateForm/${id}`)

  static getAllFormTypes = (params: ISearchByNameWithPaginateRequest): Promise<AxiosResponse<ListResponse<ITypeForm & IIdentifier & IResponseExtraData>>> =>
    $api.get('/form/typeForm', {params})

  static createNewFormType = (data: ITypeForm): Promise<AxiosResponse<ITypeForm & IResponseExtraData>> =>
    $api.post('/form/typeForm', data)

  static editFormType = (data: (ITypeForm & IIdentifier)): Promise<AxiosResponse<ITypeForm & IResponseExtraData>> =>
    $api.put(`/form/typeForm/${data.id}`, data)

  static addMainGroup = (data: IMainGroup): Promise<AxiosResponse<ITemplateGroup>> =>
    $api.post(`/form/templateForm/mainGroup`, data)

  static editMainGroup = (data: IMainGroup): Promise<any> =>
    $api.put(`/form/templateForm/mainGroup/${data.id}`, data)

  static deleteMainGroup = (id: number): Promise<any> =>
    $api.delete(`/form/templateForm/mainGroup/${id}`)

  static deleteFormType = (id: number): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/form/typeForm/${id}`)

  static createNewQuestion = (data: IQuestion): Promise<AxiosResponse<IQuestion & IResponseExtraData>> =>
    $api.post('/form/question', data)

  static copyQuestion = (id: number | string): Promise<AxiosResponse<IQuestion & IResponseExtraData & IIdentifier>> =>
    $api.post(`/form/question/${id}/copy`)

  static editQuestion = (data: IQuestion & IIterable): Promise<AxiosResponse<IQuestion & IResponseExtraData>> =>
    $api.put(`/form/question/${data.id}`, data)

  static getProcessGroup = (params: ISearchByNameWithPaginateAndIDRequest): Promise<AxiosResponse<ListResponse<IGroup & IResponseExtraData>>> =>
    $api.get(`/form/templateForm/${params.id}/processGroup`, {params})

  static getProcessGroupAll = (id: number, formId?: number): Promise<AxiosResponse<ListResponse<IGroup & IResponseExtraData>>> =>
    $api.get(`/form/templateForm/${id}/processGroup`, {params: {formId}})

  static createProcessGroup = (data: IGroup): Promise<AxiosResponse<IGroup & IIdentifier & IResponseExtraData>> =>
    $api.post(`/form/templateForm/processGroup`, data)

  static editProcessGroup = (data: IGroup & IIdentifier): Promise<AxiosResponse<IGroup & IIdentifier & IResponseExtraData>> =>
    $api.put(`/form/templateForm/processGroup/${data.id}`, data)

  static deleteProcessGroup = (id: number): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/form/templateForm/processGroup/${id}`)

  static getFactorGroup = (params: ISearchByNameWithPaginateAndIDRequest): Promise<AxiosResponse<ListResponse<IGroup & IResponseExtraData>>> =>
    $api.get(`/form/templateForm/${params.id}/factorGroup`, {params})

  static getFactorGroupAll = (id: number, formId?: number): Promise<AxiosResponse<ListResponse<IGroup & IResponseExtraData>>> =>
    $api.get(`/form/templateForm/${id}/factorGroup`, {params: {formId}})

  static createFactorGroup = (data: IGroup): Promise<AxiosResponse<IGroup & IIdentifier & IResponseExtraData>> =>
    $api.post(`/form/templateForm/factorGroup`, data)

  static editFactorGroup = (data: IGroup & IIdentifier): Promise<AxiosResponse<IGroup & IIdentifier & IResponseExtraData>> =>
    $api.put(`/form/templateForm/factorGroup/${data.id}`, data)

  static deleteFactorGroup = (id: number): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/form/templateForm/factorGroup/${id}`)

  static deleteQuestion = (id: number): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/form/question/${id}`)

  static createNewQuestionOption = (data: IQuestionOption): Promise<AxiosResponse<IQuestionOption & IResponseExtraData>> =>
    $api.post('/form/question/option', data)

  static editQuestionOption = (data: IQuestionOption & IIdentifier): Promise<AxiosResponse<IQuestionOption & IResponseExtraData>> =>
    $api.put(`/form/question/option/${data.id}`, data)

  static deleteQuestionOption = (id: number): Promise<AxiosResponse<0 | 1>> =>
    $api.delete(`/form/question/option/${id}`)

  static setNextQuestion = ({
                              id,
                              nextQuestionId
                            }: { id: number, nextQuestionId: number }): Promise<AxiosResponse<IQuestion>> =>
    $api.put(`/form/question/${id}/next`, {nextQuestionId})

  static reorderQuestion = (data: IQuestReorderData): Promise<AxiosResponse<IQuestion>> =>
    $api.put(`/form/question/order`, data)

  static getFormById = (id: number): Promise<AxiosResponse<IForm & IResponseExtraData & IIdentifier>> =>
    $api.get(`/form/${id}`)

  static getLastQuestion = (formId: number): Promise<AxiosResponse<IQuestionInstance>> =>
    $api.get(`/form/${formId}/question/last`)

  static getFirstQuestion = (id: number): Promise<AxiosResponse<IQuestionInstance>> =>
    $api.get<any>(`/form/templateForm/${id}/question/first`)

  static getCurrentQuestion = (id: number, params: FormIdParamType): Promise<AxiosResponse<IQuestionInstance & IIdentifier>> =>
    $api.get<any>(`/form/question/${id}`, {params})

  static setAnswerToQuestion = ({
                                  formId,
                                  params
                                }: { formId: number, params: IAnswerForm }): Promise<AxiosResponse<IAnswer & IIdentifier & IResponseExtraData>> =>
    $api.post(`/form/answer/${formId}`, params)

  static deleteForm = (formId: number): Promise<AxiosResponse<string>> =>
    $api.delete(`/form/${formId}/answer`)

  static getPrevQuestion = ({
                              templateFormId,
                              questionId,
                              params
                            }: { templateFormId: number, questionId: number, params: FormIdParamType }): Promise<AxiosResponse<IQuestionInstance>> =>
    $api.get(`/form/templateForm/${templateFormId}/question/${questionId}/prevQuestion`, {params})

  static getAnswers = (formId: number): Promise<AxiosResponse<any>> =>
    $api.get(`/form/answer/${formId}`)

  static putSelectedFormStatus = (statusId: number, data: { formIds: number[] }): Promise<AxiosResponse<boolean>> =>
    $api.put(`/form/statusForms/${statusId}`, data)

  static putSelectedFormAuditor = (userId: number, data: { formIds: number[] }): Promise<AxiosResponse<boolean>> =>
    $api.put(`/form/auditorForms/${userId}`, data)

  static putSelectedFormWave = (waveId: number, data: { formIds: number[] }): Promise<AxiosResponse<boolean>> =>
    $api.put(`/form/waveForms/${waveId}`, data)

  static getStatistic = (params: TMainPageFilter): Promise<AxiosResponse<any>> =>
    $api.get('/form/statistic', {params})
}
