import {useForm} from "react-hook-form";
import {string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import EditUserModalView from "./EditUserModalDunb";
import {OptionData} from "models/FilterData";
import {IUser} from "models/InstanceInterfaces/IUser";
import React, { useEffect, useState} from "react";
import {editUserAction, getAllUsersAction, getUserAction} from "store/users/thunk";
import {useAppDispatch} from "hooks/redux";
import {useSelector} from "react-redux";
import {selectEditUser, selectEditUserStatus} from "store/users/reselect";
import * as yup from "yup";
import {errorMessages} from "lib/errorMessages";
import {IIdentifier} from "models/common";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import UsersService from "services/UsersService";
import {addNotification} from "lib/addNotification";
import {NOTIFICATOR_TYPE, USER_STATUS} from "lib/appConst";
import {removeModalItem} from "lib/setModal";
import {ITemplateForm} from "models/InstanceInterfaces/ITemplateForm";
import {IDealer} from "models/DealersServiceModel";
import {innerUserRolesOptions, outUserRolesOptions} from "lib/userRoles";
import {RootState, store} from "store";
import {setCacheEditUser} from "store/users";
import {parseFilterParamsForRequest} from "lib/parseFilterParams";
import {defaultUsersFilterValues, TUsersPageFilterCredentials} from "../../../filters/UsersPageFilter";
import {Roles} from "../../../../models/Roles";
import {IsAgencyManager} from "../../../../lib/RolesModel";

/**
 * Роли в зависимости от типа пользователя (есть пароль - внешний)
 */
const roleOptions: (data: any) => OptionData[] = (data) => data?.password ? outUserRolesOptions : innerUserRolesOptions

export const deleteRoles = (roleList: OptionData[], allowRoles?: number[]) => {
  if(!allowRoles?.length) return roleList
  return roleList.reduce((acc: OptionData[], roleEl: OptionData) => {
    if(allowRoles.includes(roleEl.value)) return [...acc, roleEl]
    return acc
  }, [])
}

export const allowEditUserRolesForManager = [Roles.auditor]

/**
 * Возвращает строку с названиями из массива с данными о дилерах или об образцах анкет
 * @param arr - массив с данными о дилерах или об образцах анкет
 */
const getNamesRow = (arr: ITemplateForm[] | IDealer[]): string => {
  return arr.map(unit => unit.name).join(' ');
}

/**
 * Тип данных AddUser
 */
export type EditUserModalCredentials = {
  addRight: string | null,
  fullName:	string,
  login:	string,
  password: string | null,
  email:	string,
  phone:	string | null,
  position:	string,
  roleId:	OptionData,
  statusId: number,
  dealers: string,
  templateForms: string,
  hasChangePass: boolean
}

const resolverSchema = yup.object({
  fullName:	string().required(errorMessages.common.inputRequired),
  roleId:	yup.object({
    value: string().required(errorMessages.common.inputRequired),
  }),
  login: string().required(errorMessages.common.inputRequired),
  email: string().required(errorMessages.common.inputRequired).email(errorMessages.common.incorrectEmail),
  position:	string().required(errorMessages.common.inputRequired),
  phone:	string().required(errorMessages.common.inputRequired)
})

/**
 * HOC модального окна по редактированию анкеты пользователя
 * @constructor
 */
const EditUserModal = () => {
  const [ {userId} ]  = useEaseSearchParams()
  const [userStatusLoading, setUserStatusLoading] = useState(false)
  const dispatch = useAppDispatch();
  const {
    editUserData,
    editUserStatus
  } = useSelector((state: RootState) => ({
    editUserData: selectEditUser(state),
    editUserStatus: selectEditUserStatus(state)
  }));
  const [params, setParams] = useEaseSearchParams()
  const [data, setData] = useState<any>({})

  useEffect(()=>{
    dispatch(getUserAction(userId));
    return () => {
      dispatch(setCacheEditUser({
        ...store.getState().users.editUser,
        ...getValues(),
        roleId: getValues().roleId?.value
      }))
    }
  },[])

  useEffect(() => {
    if (editUserData.id) setData(editUserData)
  }, [editUserData])

  const { control, handleSubmit, formState, setValue, getValues } = useForm<EditUserModalCredentials>({
    defaultValues: {
      //TODO Может не отправлять поле
      addRight: null,
      fullName:	'',
      login:	'',
      password: '',
      email:	'',
      phone:	'',
      position:	'',
      roleId:	{
        name: '',
        value: 0
      },
      statusId: 0,
      dealers: '',
      templateForms: '',
      hasChangePass: false
    },
    resolver: yupResolver(resolverSchema)
  })

  const roleOptionsByAccess = deleteRoles(
    roleOptions(data),
    IsAgencyManager() ? allowEditUserRolesForManager : []
  )

  const onSubmit = async (data: EditUserModalCredentials) => {
    const editedUserData: IUser & IIdentifier = {
      email: data.email,
      id: userId,
      phone: data.phone,
      statusId: data.statusId,
      roleId: (data.roleId.value as number),
      login: data.login,
      position: data.position,
      fullName: data.fullName,
      addRight: editUserData.addRight
    };
    try {
      if (data.hasChangePass && data.password) editedUserData.password = data.password;
      const res = await dispatch(editUserAction(editedUserData))
      if(res.payload) {
        await dispatch(getAllUsersAction(parseFilterParamsForRequest<TUsersPageFilterCredentials>(params, defaultUsersFilterValues)))
        addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Пользователь успешно изменен'})
        removeModalItem(setParams)
      }
    } catch (err: any) {
      addNotification({type: NOTIFICATOR_TYPE.apply, text: err.response})
    }
  }

  const deleteUser = async () => {
    try {
      setUserStatusLoading(true)
      await UsersService.removeUser(String(editUserData.id))
      await dispatch(getAllUsersAction(parseFilterParamsForRequest<TUsersPageFilterCredentials>(params, defaultUsersFilterValues)))
      addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Пользователь успешно удален'})
      setUserStatusLoading(false)
      removeModalItem(setParams)
    } catch (err: any) {
      addNotification({type: NOTIFICATOR_TYPE.error, text: err.response})
    }
  }

  const restoreUser = async () => {
    try {
      setUserStatusLoading(true)
      await UsersService.editUser(editUserData.id, {statusId: USER_STATUS.ACTIVE})
      await dispatch(getAllUsersAction(parseFilterParamsForRequest<TUsersPageFilterCredentials>(params, defaultUsersFilterValues)))
      addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Пользователь успешно восстановлен'})
      setUserStatusLoading(false)
      removeModalItem(setParams)
    } catch (e) {
      if (e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
      else addNotification({type: NOTIFICATOR_TYPE.error, text: "Неизвестная ошибка"})
    }
  }

  useEffect(() => {
    if (data?.id) {
      setValue('fullName', data.fullName);
      setValue('login', data.login);
      setValue('password', '');
      setValue('email', data.email);
      setValue('phone', data.phone ? data.phone : '');
      setValue('position', data.position);
      setValue('roleId', roleOptions(data).filter(option => option.value == data.roleId)[0]);
      setValue('statusId', data.statusId);
      setValue('dealers', data.Dealers?.length ? getNamesRow(data.Dealers) : '');
      setValue('templateForms', data.TemplateForms?.length ? getNamesRow(data.TemplateForms) : '');
      setValue('addRight', data.addRight ? data.addRight : '');
    }
  }, [data])

  return <EditUserModalView
    userStatusLoading={userStatusLoading}
    control={control}
    onSubmit={onSubmit}
    handleSubmit={handleSubmit}
    setValue={setValue}
    roleOptions={roleOptionsByAccess}
    getValues={getValues}
    formState={formState}
    data={data}
    status={editUserStatus}
    errorMessage={''}
    deleteUser={deleteUser}
    restoreUser={restoreUser}
  />
}

export default EditUserModal;
