import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'
import { FieldError } from 'react-hook-form'

import UploadedPreview from './UploadedPreview'

import FilePlusIco from 'assets/svg/fileUpload/file-plus.svg'
import { useDropzone } from 'hooks/useDropzone'

import { UPLOAD_FORMATS, UPLOAD_TITLES } from 'lib/uploadFormats'
import { NOTIFICATOR_TYPE, UPLOAD_FORMAT_TYPES } from 'lib/appConst'
import getGeneratedId from 'lib/getGeneratedId'
import { getInputElementById } from 'lib/getInputElementById'

import FileService from 'services/FileService'

import s from './s.module.css'
import { AxiosResponse } from 'axios';
import UIError from 'components/UI/UIError';
import { addNotification } from 'lib/addNotification'
import { IFile } from 'models/InstanceInterfaces/IFile';
import { IIdentifier, IResponseExtraData } from 'models/common';


export type FilepickerRawPropsTypes = {
  maxItems?: number,
  className?: string | undefined,
  type?: UPLOAD_FORMAT_TYPES
  isNormalFont?: boolean
}

export type ControllerTypes = {
  onChange: (file: number[] | number | null) => void,
  value: number[] | number | null,
  error?: any | FieldError
}

let DOMField: HTMLInputElement | null = null

const FilePickerRaw = ({
  value,
  onChange,
  maxItems = 1,
  className,
  type = UPLOAD_FORMAT_TYPES.all,
  error,
  isNormalFont,
}: FilepickerRawPropsTypes & ControllerTypes) => {

  // state "files" - Массив ID файлов, существует, потому что - value из формы может быть либо одиночным ID, либо
  // массивом ID, либо не иметь значения
  const [files, setFiles] = useState<number[]>(typeof value === 'number' ? [value] : Array.isArray(value) ? value : [])
  const [uploadingFiles, setUploadingFiles] = useState<number[]>([])
  const acceptFiles = UPLOAD_FORMATS[type].join()
  const inputID = useMemo(() => String(getGeneratedId()), [])

  // Загрузка файла через drag'n'drop
  const onDrop = (event: DragEvent) => {
    if (event.dataTransfer === null) return false
    beforeUpload(event.dataTransfer.files)
  }

  // Загрузка файла через label (который ссылается на итоговый input)
  const onInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) return false
    beforeUpload(event.target.files)

    // очищаем значение инпута (иначе после удаления нельзя загрузить тот же файл)
    getInputElementById(DOMField, inputID).value = ''
  }

  // Собираем файлы для отправки на сервер
  const beforeUpload = (filesToUpload: FileList) => {
    const hasIncorrectCount = checkCount(filesToUpload)
    const hasIncorrectType = checkFileTypes(filesToUpload)

    if (hasIncorrectCount || hasIncorrectType) return false

    const filesToSend = []

    for (let i = 0; i < filesToUpload.length; i++) {
      const formDataToUpload = new FormData()
      formDataToUpload.append("file", filesToUpload[i]);
      filesToSend.push(() => sendFileToServer(formDataToUpload))
    }

    toUpload(filesToSend).then((res) => {
      onChange(maxItems === 1
        ? res[0].data.id
        : [ ...files, ...res.map(({ data }) => data.id)]
      )
    }).catch((error) => {
      if(error instanceof Error) {
        addNotification({
          type: NOTIFICATOR_TYPE.error,
          text: `Произошла ошибка: ${error.message}`
        })
      }
      else {
        addNotification({
          type: NOTIFICATOR_TYPE.error,
          text: `Произошла неизвестная ошибка`
        })
      }
    }).finally(() => {
      setUploadingFiles([])
    })
  }

  const toUpload = (files: (() => Promise<AxiosResponse<IFile & IResponseExtraData & IIdentifier>>)[]) => {
    return Promise.all(files.map((cb) => cb()))
  }

  // Проверка на макс. кол-во загружаемых файлов
  const checkCount = (filesToUpload: FileList): boolean => {
    const uploaded = files.length + filesToUpload.length
    if (uploaded > maxItems) {
      addNotification({
        type: NOTIFICATOR_TYPE.error,
        text: `Вы пытаетесь загрузить слишком много файлов. Доступно для загрузки: ${maxItems - files.length}`
      })
      return true;
    }
    return false;
  }

  // Проверка совместимы ли отправляемые файлы
  const checkFileTypes = (filesToUpload: FileList): boolean => {
    let allCorrectTypes = false
    if (type !== UPLOAD_FORMAT_TYPES.all) {
      for (let i = 0; i < filesToUpload.length; i++) {
        if (!acceptFiles.includes(filesToUpload[i].type)) {
          allCorrectTypes = true
        }
      }
    }
    if (allCorrectTypes) {
      addNotification({
        type: NOTIFICATOR_TYPE.error,
        text: `Неверный формат файла`
      })
    }
    return allCorrectTypes
  }

  // Показываем процесс загрузки для каждого файла (upd нужно доправить метод beforeUpload для отображения под каждый
  // файл, но итак норм работает)
  const onUploadProgress = (progressEvent: ProgressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadingFiles([percentCompleted])
  }

  // Загрузка файла на сервер
  const sendFileToServer = async (file: FormData) => {
    return await FileService.sendFile(file, onUploadProgress)
  }

  const onDeleteFile = (id: number) => {
    setFiles(files => files.filter(fileID => fileID !== id))

    if (maxItems === 1) {
      onChange(null)
    } else {
      onChange((value as number[]).filter(fileID => fileID !== id))
    }
  }

  useEffect(() => {
    setFiles(typeof value === 'number' ? [value] : Array.isArray(value) ? value : [])
  }, [value])

  const isLoading = uploadingFiles.length > 0


  // todo нужно доправить, есть иногда проблемы при эвенте
  const { dragging, dropRef, heightDropzone } = useDropzone({ onDrop, loading: isLoading })
  const isShowUpload =  isLoading || (!value || files.length < maxItems)

  return (
    <div className={clsx(s.container, className)}>
      {files.length > 0 && (
        <UploadedPreview
          files={files}
          onDelete={onDeleteFile}
          isNormalFont={isNormalFont}
        />
      )}
      {isShowUpload && (
        <React.Fragment>
          <h5 className={clsx(s.title, {
            [s.extraMargin]: value && files.length > 0,
            [s.normalFont]: isNormalFont,
          })}>
            {UPLOAD_TITLES[type]}
          </h5>
          <div
            className={clsx(s.dropzone, {
              [s.dropzoneActive]: dragging,
            })}
            style={{ height: heightDropzone }}
            ref={dropRef}
          >
            {!isLoading && (
              <>
                <ReactSVG
                  data-testid="file-plus"
                  src={FilePlusIco}
                  className={s.dropzoneIcon}
                />
                {dragging ? (
                  <p className={s.dropzoneText}>
                    Отпустите файл для загрузки
                  </p>
                ) : (
                  <p className={s.dropzoneText}>
                    Перетащите сюда файл <br />или{' '}
                    <label htmlFor={inputID}>кликните для выбора на устройстве</label>
                  </p>
                )}
              </>
            )}
            {isLoading && (
              <div className={s.dropzoneLoading}>
                {uploadingFiles.map((percent, i) => (
                  <div key={i}>Загрузка файла <b>{percent}%</b></div>
                ))}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      <input
        type="file"
        id={inputID}
        accept={acceptFiles}
        className={s.input}
        onChange={onInput}
        multiple={maxItems > 1}
      />
      {error && error.message && (
        <UIError errorMessage={error.message}/>
      )}
    </div>
  )
}

export default FilePickerRaw
