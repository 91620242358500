import {createSlice} from "@reduxjs/toolkit";
import {CommonState, IModalData, INotificatorData} from "../../models/storeInterface/CommonState";

const name = 'common'
const initialState: CommonState = {
  modalStuck: [],
  notificationStack: []
}

export const commonSlice = createSlice({
  name,
  initialState,
  reducers: {
    addModalToStuck(state, { payload }: {payload: IModalData}) {
      state.modalStuck = [...state.modalStuck, payload]
    },
    removeModalToStuck(state) {
      const newStuck = [...state.modalStuck]
      newStuck.pop()
      state.modalStuck = newStuck
    },
    removeAllModals(state) {
      state.modalStuck = []
    },
    setNotification(state, { payload }: {payload: INotificatorData}) {
      state.notificationStack = [...state.notificationStack, payload]
    },
    clearNotification(state, { payload }: {payload: number}) {
      state.notificationStack = state.notificationStack.filter(el => el.id !== payload)
    }
  }
})

export const {
  addModalToStuck,
  removeModalToStuck,
  removeAllModals,
  setNotification,
  clearNotification,
} = commonSlice.actions
export default commonSlice.reducer
