import s from './s.module.css'
import {UIContainer} from 'components/UI/UIContainer';
import React, { useState } from 'react';
import {Control} from "react-hook-form";
import Select from "components/UI/UIField/items/select";
import {UIButton} from "components/UI/UIButton";
import {MainPageFilterCredentials} from "./utils";
import { ReactSVG } from 'react-svg'
import clsx from "clsx";
import ResetSVG from 'assets/svg/filter/resetIcon.svg'
import {FilterData} from "models/FilterData";
import { quarterOptions, yearsOptions } from 'lib/appOptions';
import usePaginationItemsFromRequest from 'hooks/usePaginationItemsFromRequest';
import { IWave } from 'models/InstanceInterfaces/IWave';
import FormsService from 'services/FormsService';
import { ITypeForm } from 'models/InstanceInterfaces/ITypeForm';
import sCommon from "../../MainPageInfoBlocks/sCommon.module.css";

/**
 * Интерфейс данных для MainFilterView
 */
interface MainFilterViewProps {
  onSubmit: Function
  onReset: () => void
  handleSubmit: Function
  control: Control<MainPageFilterCredentials>
}

/**
 * Компонент MainPageFilterView
 * @param onSubmit - функция для кнопки submit формы
 * @param onReset - функция очистки формы
 * @param handleSubmit - обработчик onSubmit
 * @param control - из useForm
 * @constructor
 */
const MainPageFilterView = ({
  onSubmit,
  onReset,
  handleSubmit,
  control
}: MainFilterViewProps) => {

  const [waveFromFilter, setWaveFromFilter] = useState('')
  const [
    waveFromRowsSelect,
    waveFromPaginateTrigger,
  ] = usePaginationItemsFromRequest<IWave>({
    request: FormsService.getAllWaves,
    filter: waveFromFilter,
  })

  const [templateFormFilter, setTemplateFormFilter] = useState('')
  const [
    templateFormRowsSelect,
    templateFormPaginateTrigger,
  ] = usePaginationItemsFromRequest<(ITypeForm)>({
    request: FormsService.getAllFormTemplates,
    filter: templateFormFilter,
  })


  /**
   * Данные для фильтра
   */
  const mainFilterData: FilterData[] = [
    {
      selectHeader: 'Волна',
      options: waveFromRowsSelect,
      paginateTrigger: waveFromPaginateTrigger,
      filterSetter: setWaveFromFilter,
      selectDOMName: 'waveIds',
      isFilter: true,
      multiSelect: true,
    },
    {
      selectHeader: 'Год',
      options: yearsOptions,
      selectDOMName: 'years',
      isFilter: true,
      multiSelect: true,
    },
    {
      selectHeader: 'Квартал',
      options: quarterOptions,
      selectDOMName: 'quarters',
      isFilter: false,
      multiSelect: true,
    },
    {
      selectHeader: 'Тип анкеты',
      options: templateFormRowsSelect,
      paginateTrigger: templateFormPaginateTrigger,
      filterSetter: setTemplateFormFilter,
      selectDOMName: 'templateFormIds',
      isFilter: true,
      multiSelect: true,
    }
  ]

  return (
    <UIContainer.ContentContainer
      header={"Фильтр"}
      containerAdditionClass={sCommon.container}
    >
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        {mainFilterData.map(select =>
          <Select
            key={select.selectDOMName}
            {...select}
            selectName={select.selectHeader}
            options={select.options}
            name={select.selectDOMName}
            control={control}
            isFilter={select.isFilter}
          />
        )}
        <div className={s.controls}>
          <UIButton.DefaultBtn
            label={"Показать"}
            type="submit"
          />
          <div className={s.resetWrapper} onClick={onReset}>
            <div className={s.resetHeader}>Сбросить</div>
            <ReactSVG
              data-testid="resetFilter"
              className={clsx(s.resetElement)}
              src={ResetSVG}
            />
          </div>
        </div>
      </form>
    </UIContainer.ContentContainer>
  )
}

export default MainPageFilterView;
