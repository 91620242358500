import React from 'react';
import s from './s.module.css'
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {selectUserData} from "../../store/profile/reselect";
import {UIButton} from "../UI/UIButton";

const LogoutPopup = ({handler}: { handler: () => void }) => {
  const {
    profileData: {fullName, Role: {name}}
  } = useSelector((store: RootState) => ({
    profileData: selectUserData(store)
  }))
  return (
    <div className={s.container}>
      <h3>{fullName}</h3>
      <p style={{marginTop: 12}}>{name}</p>
      <UIButton.DefaultBtn
        label={"Выйти"}
        style={{
          width: "100%",
          backgroundColor: "#fff",
          zIndex: 44,
          border: "1px solid silver",
          color: "var(--color-mainDark)",
          justifyContent: 'center',
          marginTop: 12,
        }}
        handler={handler}
      />
    </div>
  );
};

export default LogoutPopup;
