import axios from "axios";
import { Token } from "services/Token";

/**
 * Адрес сервера
 */
// export const API_URL = 'http://37.46.135.82:3333';
export const API_URL_CLIENT = process.env.REACT_APP_API_URL_CLIENT; //'https://ms.seotlt.ru';
export const API_URL = process.env.REACT_APP_API_URL; //'https://ms.seotlt.ru/api/v1';

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
})

export const $apiFetch = {
  get: (path: string, config?: {params: any}) => {
    const {params} = config || {}
    const paramArr = params ? Object.keys(params) : []
    const paramsStr = paramArr.length ? "?" + paramArr.map(key => `${key}=${params[key]}`).join('&') : ''
    return fetch(API_URL + path + paramsStr, {
      headers: {
        'Authorization': `Bearer ${Token.getToken()}`
      }
    })
  }
}

$api.interceptors.request.use(config => {
  config.headers = {
    Authorization: `Bearer ${Token.getToken()}`
  }

  return config;
})

export default $api;
