import {Control} from "react-hook-form";
import {UIContainer} from "components/UI/UIContainer";
import s from "../FormsPageFilter/s.module.css";
import {UIButton} from "components/UI/UIButton";
import {ReactSVG} from "react-svg";
import clsx from "clsx";
import ResetSVG from "assets/svg/filter/resetIcon.svg";
import React, {Dispatch, memo, SetStateAction} from "react";
import UIField from "components/UI/UIField";
import Select from "components/UI/UIField/items/select";
import {TUsersPageFilterCredentials} from "./index";
import {OptionData} from "../../../models/FilterData";

/**
 * Интерфейс данных для UsersFilterView
 */
interface UsersFilterViewProps {
  onSubmit: Function
  handleSubmit: Function
  control: Control<TUsersPageFilterCredentials>
  resetHandle: () => void

  roleRowsSelect: OptionData[]
  rolePaginateTrigger: () => void
  setRoleFilter: Dispatch<SetStateAction<string>>

  dealerRowsSelect: OptionData[]
  dealerPaginateTrigger: () => void
  setDealerFilter: Dispatch<SetStateAction<string>>

  formTypeRowsSelect: OptionData[]
  formTypePaginateTrigger: () => void
  setFormTypeFilter: Dispatch<SetStateAction<string>>
}

/**
 * Представление компонента фильтра для страницы с пользователями
 */
const UsersPageFilterView = ({
                               onSubmit,
                               handleSubmit,
                               control,
                               resetHandle,

                               roleRowsSelect,
                               rolePaginateTrigger,
                               setRoleFilter,

                               dealerRowsSelect,
                               dealerPaginateTrigger,
                               setDealerFilter,

                               formTypeRowsSelect,
                               formTypePaginateTrigger,
                               setFormTypeFilter,
                             }: UsersFilterViewProps) => {

  return (
    <UIContainer.ContentContainer header={'Фильтрация'} customStyle={{paddingLeft: 20, paddingRight: 20}}>
      <form className={s.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <UIField.Input
          placeholder={"ID"}
          name={"id"}
          control={control} />
        <UIField.Input
          placeholder={"ФИО"}
          name={"fullName"}
          control={control} />
        <UIField.Input
          placeholder={"Логин"}
          name={"login"}
          control={control} />
        <UIField.Input
          placeholder={"Телефон"}
          name={"phone"}
          control={control} />
        <UIField.Input
          placeholder={"Должность"}
          name={"position"}
          control={control} />
        <Select
          selectName={"Роль"}
          options={roleRowsSelect}
          paginateTrigger={rolePaginateTrigger}
          filterSetter={setRoleFilter}
          name={"roleIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <Select
          selectName={"Тип пользователя"}
          options={[{value: 1, name: "Внешний"}, {value: 0, name: "Внутренний"}]}
          name={"isExternal"}
          control={control}
          withClearOption
        />
        <UIField.Datepicker
          control={control}
          name={"beginLastOnlineDate"}
          label={"Был в сети с:"}
        />
        <UIField.Datepicker
          control={control}
          name={"endLastOnlineDate"}
          label={"Был в сети по:"}
        />
        <UIField.Input
          placeholder={"Email"}
          name={"email"}
          control={control} />
        <Select
          selectName={"ДЦ"}
          options={dealerRowsSelect}
          paginateTrigger={dealerPaginateTrigger}
          filterSetter={setDealerFilter}
          name={"dealerIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <Select
          selectName={"Шаблоны анкет"}
          options={formTypeRowsSelect}
          paginateTrigger={formTypePaginateTrigger}
          filterSetter={setFormTypeFilter}
          name={"templateIds"}
          control={control}
          withClearOption
          isFilter
          multiSelect
        />
        <div className={s.controls}>
          <UIButton.DefaultBtn
            label={"Показать"}
          />
          <div className={s.resetWrapper} onClick={resetHandle}>
            <div className={s.resetHeader}>Сбросить</div>
            <ReactSVG
              data-testid="resetFilter"
              className={clsx(s.resetElement)}
              src={ResetSVG}
            />
          </div>
        </div>
      </form>
    </UIContainer.ContentContainer>
  )
}

export default memo(UsersPageFilterView);
