import React, {Dispatch, SetStateAction} from 'react'
import ClearAllImg from 'assets/svg/button/clear-all.svg'
import SelectAllImg from 'assets/svg/button/select-all.svg'
import s from '../s.module.css'
import {UIButton} from 'components/UI/UIButton'
import {OptionData} from 'models/FilterData'
import {useEaseSearchParams} from 'hooks/useEaseSearchParams'
import {removeModalItem} from 'lib/setModal'
import DealersService from 'services/DealersService'
import {useAppDispatch} from 'hooks/redux'
import {getAllDealersGroupsAction} from 'store/dealersGroups/thunk'
import {addNotification} from 'lib/addNotification'
import {NOTIFICATOR_TYPE} from 'lib/appConst'

type Props = {
  params: {
    stateCode?: OptionData
    distName?: OptionData
    cityName?: OptionData
    name?: string
    dealerGroup?: OptionData
  }
  checkAllHandler: (isCheked: boolean) => void
  dealerIdCache: number[]
  isAllChecked: boolean | null
  setDealerIdCache: Dispatch<SetStateAction<number[]>>
  resetFilterHandler: () => void
}

const ButtomButtons: React.FC<Props> = ({
  params,
  checkAllHandler,
  isAllChecked,
  dealerIdCache,
  setDealerIdCache,
  resetFilterHandler,
}) => {
  const [{id: groupId}, setParams] = useEaseSearchParams()
  const dispatch = useAppDispatch()

  const setAllUsersHandler = (isCheked: boolean) => {
    checkAllHandler(isCheked)
  }

  // удаление модалки очищение адресной строки

  const excludeIds = {
    excludeIds: dealerIdCache.map(id => Number(id)),
  }

  const saveHandler = async () => {
    removeModalItem(setParams)
    if (isAllChecked !== null) {
      try {
        if (isAllChecked) {
          await DealersService.putSetMultipleDealersToGroup(
            groupId,
            params,
            excludeIds.excludeIds.length ? excludeIds : null,
          )
          addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Дилеры успешно добавлены в группу'})
        } else {
          await DealersService.delMultipleDealerFromGroup(
            groupId,
            params,
            excludeIds.excludeIds.length ? excludeIds : null,
          )
          addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Дилеры успешно удалены из группы'})
        }
      } catch (error) {
        addNotification({
          type: NOTIFICATOR_TYPE.error,
          text: 'Не удалось сохранить добавленных/снятых к группе дилеров',
        })
      }
    }

    // запрос за новыми данными что бы в самой таблице (не модалке) отобразить актуальное количество дилеров
    dispatch(getAllDealersGroupsAction({page: 1, count: 20}))
    setDealerIdCache([])
  }

  return (
    <div className={s.icons}>
      <div className={s.iconsItem} onClick={() => setAllUsersHandler(true)}>
        <img src={SelectAllImg} alt={'Выбрать всё'}/>
        Выбрать всё
      </div>
      <div className={s.iconsItem} onClick={() => setAllUsersHandler(false)}>
        <img src={ClearAllImg} alt={'Снять выбор'} />
        Снять выбор
      </div>
      <div className={s.footer__button_wrapper}>
        <UIButton.BorderBtn label={'Сброс фильтров'} style={{ backgroundColor: 'var(--color-white)' }} type={'button'} handler={resetFilterHandler} />
        {isAllChecked !== null && (
          <UIButton.DefaultBtn
            label={'Сохранить'}
            type={'button'}
            style={{marginLeft: 'auto', marginRight: '8px'}}
            handler={saveHandler}
          />
        )}
      </div>
    </div>
  )
}

export default ButtomButtons
