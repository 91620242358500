import { createSlice } from '@reduxjs/toolkit'
import { getStatisticAction } from 'store/statistic/thunk'
import { StatisticState } from 'models/storeInterface/StatisticState';
import {LOADING_STATUS} from "lib/appConst";

const name = 'statistic'
const initialState: StatisticState = {
  forms: [],
  appeals: [],
  requestStatus: {
    isLoading: LOADING_STATUS.IDLE,
    isError: false,
    errorMessage: ''
  },
}

const statisticSlice = createSlice({
  name,
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(getStatisticAction.pending, (state) => {
        state.requestStatus.isLoading = LOADING_STATUS.LOADING
        state.requestStatus.isError = false
      })
      .addCase(getStatisticAction.fulfilled, (state, { payload }) => {
        state.requestStatus.isLoading = LOADING_STATUS.IDLE
        state.forms = payload.forms
        state.appeals = payload.appeals
      })
      .addCase(getStatisticAction.rejected, (state) => {
        state.requestStatus.isLoading = LOADING_STATUS.IDLE
        state.requestStatus.isError = true
      })
  }
})


export default statisticSlice.reducer
