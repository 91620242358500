import React from 'react';
import UICheckbox from "components/UI/UICheckbox";
import clsx from "clsx";

import sCommonFieldsStyle from "../../s.module.css";
import s from "../../../TableSelectedItemsControl/s.module.css";

export interface ITableFieldSelectAll {
  label: string
  count: number
  handler: any
  isAllSelected?: boolean
  excludedRows?: number[]
  selectedRows?: number[]
}
const TableFieldSelectAll = ({label, count, isAllSelected, excludedRows, selectedRows, handler}: ITableFieldSelectAll) => {
  return (
    <>
      <UICheckbox id="selectAll" handler={handler} value={isAllSelected && !excludedRows?.length} />
      <p className={s.selectedCount}>{
        isAllSelected
          ? excludedRows && Number(count) - Number(excludedRows?.length)
          : selectedRows && Number(selectedRows.length)
      }</p>
      <label htmlFor="selectAll" className={clsx(s.label, s.marginLeft, sCommonFieldsStyle.cursor)}>{label}</label>
    </>
  );
};

export default TableFieldSelectAll;
