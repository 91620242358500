import { memo } from 'react'

import s from './s.module.css'

type ProgressBarPropsType = {
  currentQuestion: number
  total: number
}

const ProgressBar = memo(({ currentQuestion, total }: ProgressBarPropsType) => {

  const filledPercent = currentQuestion / total * 100

  return (
    <div className={s.container}>
      <div className={s.bar}>
        <span className={s.filled} style={{ width: `${filledPercent}%` }} />
      </div>
      <div className={s.value}>
        {currentQuestion} из {total}
      </div>
    </div>
  )
})

export default ProgressBar
