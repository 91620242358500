import { createAsyncThunk } from '@reduxjs/toolkit'
import FormsService from 'services/FormsService'
import { netErrorsHandler } from 'lib/netErrorsHandler'
import { TMainPageFilter } from 'modules/filters/MainPageFilter/utils';

/**
 * Запрос на получение статистики по статусам анкет и аппеляциям
 */
export const getStatisticAction = createAsyncThunk(
  'statistic/getStatisticAction',
  async(params: TMainPageFilter, { rejectWithValue }) => {
    try {
      const response = await FormsService.getStatistic(params);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(netErrorsHandler(e));
    }
  }
)
