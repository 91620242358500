import React, {useEffect, useMemo, useRef} from 'react';
import s from './s.module.css'
import DragAnchor from "../DragAnchor";
import {IIterable, IQuestion} from "models/IQuestion";
import { NOTIFICATOR_TYPE, QUESTION_TYPES, QUESTION_TYPES_NAMES } from '../../lib/appConst';
import {Draggable} from "react-beautiful-dnd";
import {useInView} from "react-intersection-observer";
import clsx from "clsx";
import {getSelectedGroupId, getSelectedQuestion, selectToolsState} from "../../store/formTemplateEditor/reselect";
import {selectGroup, selectPrevQuestion, selectQuestion} from "../../store/formTemplateEditor";
import {useAppDispatch} from "../../hooks/redux";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import QuestionForm from "./QuestionForm";
import {editQuestion} from "../../store/formTemplateEditor/thunk";
import {getFormTemplateByIdNoLoadHelper} from "../../lib/apiHelpers";
import { addNotification } from 'lib/addNotification';

function delayInvoke(f: any, delay = 300) {
  if (!f) {
    //@ts-ignore
    clearTimeout(delayInvoke.timer)
    //@ts-ignore
    delayInvoke.timer = undefined
  }
  //@ts-ignore
  if (delayInvoke.timer) {
    //@ts-ignore
    clearTimeout(delayInvoke.timer)
    //@ts-ignore
    delayInvoke.timer = undefined
  }
  //@ts-ignore
  delayInvoke.timer = setTimeout(f, delay)
}

const parseQuestionToForm = (question: IQuestion) => {
  if (question.isCreate) return {}
  const formObj: any = {}
  formObj['num'] = question.num
  if (question.typeQuestion) {
    formObj['typeQuestion'] = {
      value: Object.values(QUESTION_TYPES).indexOf(question.typeQuestion),
      enumKey: question.typeQuestion,
      name: QUESTION_TYPES_NAMES[question.typeQuestion]
    }
  }
  try {
    formObj['textQuestion'] = question.textQuestion ? JSON.parse(question.textQuestion) : [{
      //@ts-ignore
      type: 'paragraph',
      children: [{text: ''}]
    }]
  } catch (e) {
    console.error('Не удалось десериализавать данные для rich text editor')
  }
  formObj['processGroup'] = question.processGroup && {value: question.processGroup.id, name: question.processGroup.name}
  formObj['factorGroup'] = question.factorGroup && {value: question.factorGroup.id, name: question.factorGroup.name}
  formObj['textComment'] = question.textComment
  formObj['required'] = question.required
  return formObj
}

export interface ITemplateQuestionGen {
  itemIndex: number
  templateId: number
  questionInstance: IQuestion & IIterable
  isDraggingOver?: boolean
  waitRender?: boolean
}

const TemplateQuestionGen = ({
                               templateId,
                               questionInstance,
                               itemIndex,
                               isDraggingOver,
                               waitRender,
                             }: ITemplateQuestionGen) => {

  return (
    <Draggable draggableId={String(questionInstance.id)} index={itemIndex}>
      {(provided, snapshot) => {
        return (
          <Question
            waitRender={waitRender}
            isDraggingOver={isDraggingOver}
            isDragging={snapshot.isDragging}
            isAnimateRun={snapshot.isDropAnimating}
            itemIndex={itemIndex}
            provided={provided}
            questionInstance={questionInstance}
            templateId={templateId}
          />
        )
      }}
    </Draggable>
  );
};


export interface IQuestionDragged extends ITemplateQuestionGen {
  isAnimateRun?: boolean
  provided?: any
  isDragging?: boolean
  waitRender?: boolean
}

export const Question = ({
                           templateId,
                           questionInstance,
                           isDragging,
                           isDraggingOver,
                           isAnimateRun,
                           provided,
                           waitRender,
                         }: IQuestionDragged) => {
  const {
    selectedQuestion,
    toolsState: {addQuestion}
  } = useSelector((store: RootState) => ({
    selectedQuestion: getSelectedQuestion(store),
    toolsState: selectToolsState(store),
    selectedGroup: getSelectedGroupId(store),
  }))

  const dispatch = useAppDispatch()

  const onSubmit = async (data: any) => {
    try {
      await dispatch(editQuestion({
        ...data,
        id: questionInstance.id,
        typeQuestion: Object.values(QUESTION_TYPES)[data.typeQuestion.value],
        textQuestion: JSON.stringify(data.textQuestion),
        processGroupId: data?.processGroup && data.processGroup.value,
        factorGroupId: data?.factorGroup && data.factorGroup.value,
      }))
      addNotification({ text: "Изменения сохранены", type: NOTIFICATOR_TYPE.apply })
      getFormTemplateByIdNoLoadHelper()
    } catch (e) {
      alert('Сетевая ошибка')
    }
  }

  const questionTypeAsList = useMemo(() => Object.values(QUESTION_TYPES).map((el, id) => ({
    value: id,
    enumKey: el,
    name: QUESTION_TYPES_NAMES[el]
  })), [])

  const {ref, entry} = useInView({
    rootMargin: "-58% 0px -40% 0px",
  });

  const onQuestionSelect = ({
                              id,
                              elementPos,
                              groupId
                            }: { id: number | undefined, elementPos: number, groupId: number }) => {
    if (!addQuestion) {
      dispatch(selectQuestion({id, elementPos}))
      dispatch(selectGroup(groupId))
    }
  }

  const questionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (entry?.isIntersecting
      && questionRef.current
      && questionRef.current.offsetTop
      && (!isDraggingOver && !isAnimateRun)
      && questionInstance.mainGroupId) {
      onQuestionSelect({
        id: questionInstance.id,
        elementPos: questionRef.current.offsetTop,
        groupId: questionInstance.mainGroupId
      })
    }
  }, [entry])

  useEffect(() => {
    if (!!(!waitRender
      && !isDragging
      && !isAnimateRun
      && questionRef.current
      && selectedQuestion
      && questionInstance.mainGroupId)) {
      delayInvoke(() =>
        onQuestionSelect({
          id: questionInstance.id,
          //@ts-ignore
          elementPos: questionRef.current?.offsetTop || 0,
          //@ts-ignore
          groupId: questionInstance.mainGroupId
        }), 200)
    }
  }, [isDragging, isAnimateRun])

  useEffect(() => {
    if(!questionInstance.nextQuestionId && addQuestion) {
      const element = document.getElementById(String(questionInstance.id))
      dispatch(selectPrevQuestion({id: questionInstance.id, elementPos: element?.offsetTop}))
    }
  }, [questionInstance])

  return (
    <div ref={ref} id={String(questionInstance.id)}>
      <div ref={questionRef}/>
      <div
        onClick={
          () =>
            questionRef.current
            && questionInstance.mainGroupId
            && onQuestionSelect({
              id: questionInstance.id,
              elementPos: questionRef.current.offsetTop,
              groupId: questionInstance.mainGroupId
            })}
        ref={provided.innerRef}
        {...provided.draggableProps}
        className={clsx(s.container, {
          [s.selected]: selectedQuestion.id === questionInstance.id && !addQuestion
        })}>
        <QuestionForm
          questionId={questionInstance.id}
          questionOptions={questionInstance.option}
          onSubmit={onSubmit}
          defaultValues={parseQuestionToForm(questionInstance)}
          questionTypeAsList={questionTypeAsList}
          templateId={templateId}
        >
          <div className={s.dragContainer}>
            <div {...provided.dragHandleProps}>
              <DragAnchor/>
            </div>
          </div>
        </QuestionForm>
      </div>
    </div>
  )
}

export default TemplateQuestionGen;
