import {IIdentifier, IResponseExtraData} from "../models/common";
import {IDealer} from "../models/DealersServiceModel";

/**
 * Проверяет вхождение пользователя в список прикреплённых пользователей к типу анкет/дилеру
 * @param arr - массив с прикреплёнными пользователями
 * @param id - идентификатор проверяемого пользователя
 */
export const getIsBinded = (id: number, arr?: (IDealer & IResponseExtraData & IIdentifier)[]): boolean => {
  let result = false;
  if (arr && arr.length) {
    arr.forEach(user => {
      if (user.id === id) {
        result = true;
      }
    })
  }
  return result;
}
