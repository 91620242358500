import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { UIContainer } from 'components/UI/UIContainer'
import { UIButton } from 'components/UI/UIButton'

import ContentBox from 'pages/FormPass/components/ContentBox'
import { getValueBeforeSend, reinitFormValue } from 'pages/FormPass/utils'

import FormsService from 'services/FormsService'
import { IQuestionRaw } from 'models/InstanceInterfaces/IQuestion'
import { IIdentifier } from 'models/common'
import { removeAllModalStore, removeModalItem } from 'lib/setModal'

import { useEaseSearchParams } from 'hooks/useEaseSearchParams'

import s from '../FormAppealQuestion/s.module.css'
import { acceptAppealStatus } from 'store/formDetail';

const defaultValues = { answer: '' }

const FormAuditorAnswerModal = () => {
  const dispatch = useDispatch()
  const [{ questionId, idForm }, setParams] = useEaseSearchParams()
  const [question, setQuestion] = useState<IQuestionRaw | undefined>()

  const { control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({ defaultValues })

  const getQuestion = async () => (await FormsService.getCurrentQuestion(questionId, { idForm })).data.question

  useEffect(() => {
    getQuestion()
      .then(res => {
        setQuestion(res)
        return res
      })
      .then((res: any) => {
        reInit(res)
      })
  }, [])

  const reInit = (currentQuestion: any) => {
    if (currentQuestion) {
      if (currentQuestion.hasOwnProperty('answer') && Array.isArray(currentQuestion.answer) && currentQuestion.answer.length > 0) {
        reinitFormValue(currentQuestion.answer[0], currentQuestion.typeQuestion, reset, defaultValues)
      } else {
        reset(defaultValues)
      }
    }
  }

  const removeAllModal = () => removeAllModalStore(setParams)

  const goBack = () => {
    removeModalItem(setParams)
  }

  const onSubmit = async (values: any) => {
    try {
      const params = {
        formQuestionId: (question as (IQuestionRaw & IIdentifier)).id,
        formId: parseInt(idForm),
        answerFloat: 0,
        answerString: '',
        fileIds: [],
        isCorrection: true,
        ...getValueBeforeSend(values.answer, (question as IQuestionRaw).typeQuestion)
      }
      const res = await FormsService.setAnswerToQuestion({ formId: parseInt(idForm), params })
      if (res.data) {
        dispatch(acceptAppealStatus({
          formQuestionId: parseInt(questionId),
          answer: res.data,
          oldAnswerId: question?.answer[0].id, // для замены из ответа с бека, так как там создается новый элемент
        }))
      }
      await removeAllModal()
    } catch (e) {
      console.log('onSubmit error: ', e);
    }
  }

  return (
    <UIContainer.ContentContainer header='Редактирование ответа'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentBox
          control={control}
          errors={errors}
          question={question}
        />
        <div className={s.actions}>
          <UIButton.DefaultBtn
            label={'Вернуться'}
            handler={goBack}
            disabled={isSubmitting}
            style={{
              color: "#89929B",
              backgroundColor: "#fff",
              border: "1px solid #DCDEE0",
            }}
          />
          <UIButton.DefaultBtn
            label="Сохранить"
            disabled={isSubmitting}
            type="submit"
          />
        </div>
      </form>
    </UIContainer.ContentContainer>
  )
}

export default FormAuditorAnswerModal
