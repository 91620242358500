import $api from '../http'
import { IPaginate } from 'models/common'
import { TAnalyticsPageFilter } from 'modules/filters/AnalyticsReportPageFilter/utils'
import { TAnalyticsUnloadPageFilter } from 'modules/filters/AnalyticUnloadPageFilter/utils'
import { TAnalyticsUnloadCreate } from 'modules/filters/AnalyticUnloadCreate/utils'
import { TAnalyticsAppealsPageFilter } from 'modules/filters/AnalyticsAppealsPageFilter/utils';

/**
 * Класс работы с аналитикой
 */
export default class AnalyticsService {

  static report = (params: IPaginate & Partial<TAnalyticsPageFilter>) =>
    $api.get('/analytics/report', {params})

  static reportXls = (params: Partial<TAnalyticsPageFilter>) =>
    $api.get('/analytics/report/xls', {params})

  static downloadReportExcel = (params: Partial<TAnalyticsUnloadCreate>) =>
    $api.get('/analytics/excel', {params})

  static graph = (params: IPaginate & Partial<TAnalyticsPageFilter>) =>
    $api.get('/analytics/graph', {params})

  static unload = (params: IPaginate & Partial<TAnalyticsUnloadPageFilter>) =>
    $api.get('/analytics/reportList', {params})

  static appeals = (params: Partial<TAnalyticsAppealsPageFilter>) =>
    $api.get('/analytics/appeal', {params})

  static appealsExcel = (params: Partial<TAnalyticsAppealsPageFilter>) =>
    $api.get('/analytics/appeal/excel', {params})

}

