import {createSlice} from "@reduxjs/toolkit";
import {getAppealsAction} from "./thunk";
import {AppealsState, IAppealAnswerCache} from "../../models/storeInterface/AppealsState";
import {LOADING_STATUS} from "../../lib/appConst";

const name = 'appeals'
const initialState: AppealsState = {
  dataList: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],

    selectedRows: [],
    excludedRows: [],
    selectAll: false,
  },
  appealAnswer: {
    text: '',
    filesIds: []
  },
}

export const appealsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setAppealAnswerCache: (state, {payload}: {payload: IAppealAnswerCache}) => {
      state.appealAnswer = payload
    },
    resetAppealAnswerCache: (state) => {
      state.appealAnswer = initialState.appealAnswer
    },
  },
  extraReducers: {
    [getAppealsAction.pending.type]: (state) => {
      state.dataList.status = LOADING_STATUS.LOADING
      state.dataList.error = initialState.dataList.error
    },
    [getAppealsAction.fulfilled.type]: (state, { payload }) => {
      state.dataList = {...state.dataList, ...payload}
      state.dataList.status = LOADING_STATUS.IDLE
    },
    [getAppealsAction.rejected.type]: (state, { payload }) => {
      state.dataList.error = payload
      state.dataList.status = LOADING_STATUS.IDLE
    },
  }
})

export const {
  setAppealAnswerCache,
  resetAppealAnswerCache
} = appealsSlice.actions
export default appealsSlice.reducer
