import React, { ReactHTMLElement, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'

import { UIContainer } from 'components/UI/UIContainer'

import { useAppDispatch } from 'hooks/redux'
import { useEaseSearchParams } from 'hooks/useEaseSearchParams'

import { AnalyticsReportTableControl, AnalyticsSubmenu } from 'lib/tableConfigs/controlsPakages/controls'
import { parseFilterParamsForRequest } from 'lib/parseFilterParams'

import TableGenerator from 'modules/TableGenerator'
import { defaultAnalyticsFilterValues, TAnalyticsPageFilter } from 'modules/filters/AnalyticsReportPageFilter/utils'

import { IPaginate } from 'models/common'

import { getAllReportsActions } from 'store/analytics/thunk'
import { selectReports } from 'store/analytics/reselect'

import s from './s.module.css'
import FormsService from 'services/FormsService';
import { calculationValues, groupValues } from 'lib/appOptions'

/**
* Страница для отображения отчетов аналитики
* @constructor
*/
const AnalyticsReport = () => {
  const [params, setParams] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState<Partial<TAnalyticsPageFilter>>(parseFilterParamsForRequest(params, defaultAnalyticsFilterValues) as unknown as Partial<TAnalyticsPageFilter>)
  const [isLeftScrolled, setIsLeftScrolled] = useState<boolean>(true)
  const [isRightScrolled, setIsRightScrolled] = useState<boolean>(false)

  const id = 'table'

  const structureConfig = useSelector(selectReports).columns
  const analyticsTableConfig = { structureConfig }

  const getData = useCallback((params: IPaginate & Partial<TAnalyticsPageFilter>) => {
    const parsedParams = {
      ...params,
      ...(params.group ? { group: groupValues[parseInt(params.group) - 1] } : {}),
      ...(params.typeCalculation ? { typeCalculation: calculationValues[parseInt(params.typeCalculation) - 1]} : {}),
    }

    return dispatch(getAllReportsActions(parsedParams as IPaginate & Partial<TAnalyticsPageFilter>));
  }, [])

  useEffect(() => {
    async function getDefaultTemplate() {
      // @ts-ignore
      return FormsService.getAllFormTemplates()
    }

    if (!params.hasOwnProperty('templateFormId')) {
      getDefaultTemplate().then((res) => {
        setParams({ ...params, templateFormId: res.data.rows[0].id, templateFormIdFieldName: res.data.rows[0].name }, true)
      })
    } else {
      if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultAnalyticsFilterValues) as unknown as Partial<TAnalyticsPageFilter>)) {
        setFilter(parseFilterParamsForRequest(params, defaultAnalyticsFilterValues) as unknown as Partial<TAnalyticsPageFilter>)
      }
    }
  }, [params])

  useEffect(() => {
    const element: HTMLElement | null = document.getElementById(id)

    function checkScrollPosition() {
      if (element) {
        if (element.scrollLeft === 0) {
          setIsLeftScrolled(true)
        } else {
          setIsLeftScrolled(false)
        }

        if (element.scrollLeft + element.clientWidth >= element.scrollWidth) {
          setIsRightScrolled(true)
        } else {
          setIsRightScrolled(false)
        }
      }
    }

    if (element) {
      element.addEventListener("scroll", checkScrollPosition, false)
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", checkScrollPosition)
      }
    }
  }, [])

  return (
      <UIContainer.PageContainer>
        <div className={clsx(s.tableContainer, {
          [s.isLeftScrolled]: isLeftScrolled,
          [s.isRightScrolled]: isRightScrolled,
        })}>
          {params.hasOwnProperty('templateFormId') && (
            <TableGenerator
              tableName="Отчет по вопросам анкеты по Дилеру"
              dataQuery={getData}
              dataSelect={selectReports}
              tableConfig={analyticsTableConfig}
              tableControl={AnalyticsReportTableControl}
              subMenu={AnalyticsSubmenu}
              filter={filter}
              withoutPagination
              id={id}
            />
          )}
        </div>
      </UIContainer.PageContainer>
  );
};

export default AnalyticsReport;
