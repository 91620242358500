import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from "hooks/redux";
import {formsArchiveTableConfig} from "lib/tableConfigs/configs";
import TableGenerator from "modules/TableGenerator";
import {UIContainer} from "components/UI/UIContainer";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {TFormsPageFilter} from "modules/filters/FormsPageFilter";
import {ISort} from "models/common";
import {getAllFormsArchiveAction} from "store/formsArchive/thunk";
import {selectFormsArchive} from "store/formsArchive/reselect";

/**
 * Страница для отображения архива пройденных анкет
 * @constructor
 */
const FormsArchive = () => {
  const [params] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const [sort, setSort] = useState<ISort>()

  const getFormsExecute = useCallback((param: {page: number, count: number} & Partial<TFormsPageFilter>) =>
    dispatch(getAllFormsArchiveAction({...param})), [])

  useEffect(() => {
    if(JSON.stringify(sort) !== JSON.stringify({sort: params.sort, direction: params.direction})) {
      setSort({sort: params.sort, direction: params.direction})
    }
  }, [params])


  return (
    <UIContainer.PageContainer>
      <TableGenerator
        tableName={'Архив анкеты'}
        dataQuery={getFormsExecute}
        dataSelect={selectFormsArchive}
        tableConfig={formsArchiveTableConfig}
        sort={sort}
      />
    </UIContainer.PageContainer>
  );
};

export default FormsArchive;
