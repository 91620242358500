import React from 'react';
import Info from "./Info";
import Appeals from "./Appeals";
import Status from "./Status";
import Filter from "./Filter";

// export interface IMainPageInfoBlocks {
//   Appeals: React.FC
//   Status: React.FC
//   Filter: React.FC
// }
const MainPageInfoBlocks = {
  Info: Info,
  Appeals: Appeals,
  Status: Status,
  Filter: Filter
};

export default MainPageInfoBlocks;
