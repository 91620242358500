import {current, PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {getAllDealersAction, getAllDealersGroupsAction} from './thunk'
import {DealersGroupsState} from 'models/storeInterface/DealersGroupsState'
import {LOADING_STATUS} from "../../lib/appConst";

const name = 'dealersGroups'
const initialState: DealersGroupsState = {
  datalist: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
    selectedRows: [],
    excludedRows: [],
    selectAll: false,
  },
  dealers: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
  },
}

export const dealersSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    incrementDealerInGroupsById: (state, { payload }) => {
      state.datalist.rows = current(state.datalist.rows).map((el) => el.id === payload.groupId ? { ...el, Dealer: [ ...el.Dealer, { id: payload.dealerId }] } : el)
    },
    decrementDealerInGroupsById: (state, { payload }) => {
      state.datalist.rows = current(state.datalist.rows).map((el) => {
        if (el.id === payload.groupId) {
          return {
            ...el,
            Dealer: el.Dealer.filter((dealer: any) => dealer.id !== payload.dealerId)
          }
        }
        return el
      })
    }
  },
  extraReducers: {
    [getAllDealersGroupsAction.pending.type]: state => {
      state.datalist.status = LOADING_STATUS.LOADING
      state.datalist.error = initialState.datalist.error
    },
    [getAllDealersGroupsAction.fulfilled.type]: (state, {payload}) => {
      state.datalist = {...state.datalist, ...payload}
      state.datalist.status = LOADING_STATUS.IDLE
    },
    [getAllDealersGroupsAction.rejected.type]: (state, {payload}) => {
      state.datalist.error = payload
      state.datalist.status = LOADING_STATUS.IDLE
    },

    [getAllDealersAction.pending.type]: state => {
      state.dealers.status = LOADING_STATUS.LOADING
      state.dealers.error = initialState.datalist.error
    },
    [getAllDealersAction.fulfilled.type]: (
      state,
      {payload: {count, rows}}: PayloadAction<{count: number; rows: any[]}>,
    ) => {
      state.dealers.rows = [...state.dealers.rows, ...rows]
      state.dealers.count = count
      state.dealers.status = LOADING_STATUS.IDLE
    },
    [getAllDealersAction.rejected.type]: (state, {payload}) => {
      state.dealers.error = payload
      state.dealers.status = LOADING_STATUS.IDLE
    },
  },
})

export const { incrementDealerInGroupsById, decrementDealerInGroupsById } = dealersSlice.actions
export default dealersSlice.reducer
