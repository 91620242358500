import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'

import {UIContainer} from 'components/UI/UIContainer'
import {UIButton} from 'components/UI/UIButton'

import ContentBox from 'pages/FormPass/components/ContentBox'
import {getValueBeforeSend, reinitFormValue} from 'pages/FormPass/utils'

import FormsService from 'services/FormsService'
import {IQuestionRaw} from 'models/InstanceInterfaces/IQuestion'
import {IIdentifier} from 'models/common'
import {removeAllModalStore, removeModalItem} from 'lib/setModal'

import {useEaseSearchParams} from 'hooks/useEaseSearchParams'

import s from './s.module.css'
import {acceptAppealStatus} from 'store/formDetail'
import AppealsService from 'services/AppealsService'
import {resetAppealAnswerCache} from "store/appeals";
import {addNotification} from "lib/addNotification";
import {NOTIFICATOR_TYPE} from "lib/appConst";
import {selectAppealAnswerCache} from "store/appeals/reselect";
import {AxiosError} from "axios";

const defaultValues = {answer: ''}

const FormAppealQuestion = () => {
  const dispatch = useDispatch()
  const [{questionId, idForm, apId}, setParams] = useEaseSearchParams()
  const [question, setQuestion] = useState<IQuestionRaw | undefined>()
  const {text, filesIds} = useSelector(selectAppealAnswerCache)

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors, isSubmitting}
  } = useForm({defaultValues})

  const getQuestion = async () => (await FormsService.getCurrentQuestion(questionId, {idForm})).data.question

  useEffect(() => {
    getQuestion()
      .then(res => {
        setQuestion(res)
        return res
      })
      .then((res: any) => {
        reInit(res)
      })
  }, [])

  const reInit = (currentQuestion: any) => {
    if (currentQuestion) {
      if (currentQuestion.hasOwnProperty('answer') && Array.isArray(currentQuestion.answer) && currentQuestion.answer.length > 0) {
        reinitFormValue(currentQuestion.answer[0], currentQuestion.typeQuestion, reset, defaultValues)
      } else {
        reset(defaultValues)
      }
    }
  }

  const removeAllModal = () => {
    dispatch(resetAppealAnswerCache())
    removeAllModalStore(setParams)
  }

  const goBack = () => {
    removeModalItem(setParams)
  }

  const onSubmit = async (values: any) => {
    try {
      const params = {
        formQuestionId: (question as (IQuestionRaw & IIdentifier))?.id,
        formId: parseInt(idForm),
        answerFloat: 0,
        answerString: '',
        fileIds: [],
        ...getValueBeforeSend(values.answer, (question as IQuestionRaw).typeQuestion)
      }
      const answerRes = await FormsService.setAnswerToQuestion({formId: parseInt(idForm), params})
      const changeStatusResponse = await AppealsService.changeStatus(apId, 8)
      const newAppeal = (await AppealsService.answerOnAppeal(apId, {
        appealAnswerText: text,
        fileIds: filesIds
      })).data
      if (changeStatusResponse.data && answerRes.data) {
        dispatch(acceptAppealStatus({
          File: [],
          formQuestionId: parseInt(questionId),
          appealId: parseInt(apId),
          newAppeal,
          answer: answerRes.data,
          oldAnswerId: question?.answer[0]?.id
        }))
      }
      await removeAllModal()
      addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Апелляция принята'})
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }

  return (
    <UIContainer.ContentContainer header='Редактирование ответа'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentBox
          control={control}
          errors={errors}
          question={question}
        />
        <div className={s.actions}>
          <UIButton.DefaultBtn
            label={'Вернуться'}
            handler={goBack}
            disabled={isSubmitting}
            style={{
              color: "#89929B",
              backgroundColor: "#fff",
              border: "1px solid #DCDEE0",
            }}
          />
          <UIButton.DefaultBtn
            label="Сохранить"
            disabled={isSubmitting}
            type="submit"
          />
        </div>
      </form>
    </UIContainer.ContentContainer>
  )
}

export default FormAppealQuestion
