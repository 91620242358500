import React from 'react';
import s from './s.module.css'
import {useNavigate} from "react-router-dom";
import clsx from "clsx";
import {getAccessToAppealsDetails, getAccessToAppealsPass} from "lib/RolesModel";
import {IStatus} from "../../models/Status/Status";
import {useDispatch} from "react-redux";
import {useEaseSearchParams} from "../../hooks/useEaseSearchParams";
import {setTempQueryParams} from "../../store/forms";
import {IExtraSignature, parseFilterParamsForRequest} from "../../lib/parseFilterParams";
import {
  defaultFormsFilterValues,
  TFormsPageFilterCredentials
} from "../../modules/filters/FormsPageFilter";
import {IPaginate} from "../../models/common";

const statusToPass = [9, 10]

export interface IStatusMark {
  val: IStatus
  row: any
}

export interface IStatusMarkUser {
  val: string
  row: any
}

type TStatusUnload = {
  val: string
}

export const StatusUnload = ({ val }: TStatusUnload) => {
  return (
    <div className={s.statusMark}>
      {val}
    </div>
  )
}

export const StatusCell = ({val}: IStatusMark) => {

  return (
    <div
      className={s.statusMark}
    >
      {val.name}
    </div>
  );
};

export const StatusCellForms = ({val, row}: IStatusMark) => {
  const [params] = useEaseSearchParams()
  const dispatch = useDispatch()
  const nav = useNavigate()
  const roleAccessToPass = getAccessToAppealsPass(statusToPass, val.id)
  const roleAccessToDetail = getAccessToAppealsDetails(statusToPass, val.id)

  const goToFormDetail = () => {
    dispatch(setTempQueryParams(parseFilterParamsForRequest(
      params,
      {
        ...defaultFormsFilterValues,
        ...params,
      } as (TFormsPageFilterCredentials & IPaginate & IExtraSignature)
    )))
    if (roleAccessToPass) nav(`/form-pass/${row.id}`)
    if (roleAccessToDetail) nav(`/formDetail/${row.id}`)
  }

  return (
    <div
      className={clsx(s.statusMark, {
        [s.cursorPointer]: roleAccessToDetail || roleAccessToPass
      })}
      onClick={goToFormDetail}
    >
      {val.name}
    </div>
  );
};

export const StatusCellUser = ({val}: IStatusMarkUser) => {
  return (
    <div
      className={clsx(s.statusMark, {
        [s.blocked]: val.toLowerCase() === 'заблокирован'
      })}
    >
      {val}
    </div>
  );
};
