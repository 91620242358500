import {createAsyncThunk} from "@reduxjs/toolkit";
import AppealsService from "../../services/AppealsService";

/**
 * Получить анкеты
 */
export const getAppealsAction = createAsyncThunk(
  'forms/getAppeals',
  async(params: {page: number, count: number}): Promise<any> => {
    try {
      const response = await AppealsService.getAll(params)
      return response.data;
    } catch (e: any) {
    }
  }
)
