import { Roles } from '../models/Roles';
import { getRole } from './getRole';

export const IsController = (): boolean => getRole().id === Roles.controller
export const IsAuditor = (): boolean => getRole().id === Roles.auditor
export const IsSystemAdmin = (): boolean => getRole().id === Roles.systemAdmin
export const IsAgencyManager = (): boolean => getRole().id === Roles.agencyManager
export const IsAgency = (): boolean => [
  Roles.agencyAdmin,
  Roles.agencyManager,
].includes(getRole().id)
export const IsDealer = (): boolean => getRole().id === Roles.dealer

//Доступ к созданию анкеты
export const formsCreationAccess = () => [
  Roles.systemAdmin,
  Roles.agencyAdmin,
  Roles.auditor,
  Roles.controller,
].includes(getRole()?.id)

//Доступ к созданию типа анкеты и вида анкеты
export const formsCreationTypesWithKindsAccess = () => [
  Roles.systemAdmin,
  Roles.agencyAdmin,
  Roles.auditor,
].includes(getRole()?.id)

//Доступ к управляющим полям в таблицах
export const allTableControlAccess = () => [
  Roles.systemAdmin,
  Roles.agencyAdmin,
  Roles.agencyManager,
  Roles.auditor,
  Roles.controller,
].includes(getRole()?.id)

//Урезание доступа к управляющим полям в таблицах
export const tableControlAccess = (label: string) => {
  switch (label) {
    case "Изменить волну":
      return !(IsController() || IsAuditor())
    case "Изменить аудитора":
      return !IsAuditor()
    default:
      return true
  }
}

//Доступ к просмотрам деталей апелляции из списка анкет
export const getAccessToAppealsDetails = (statusToPass: number[], statusId: number): boolean => {
  const {id} = getRole()
  return (!statusToPass.includes(statusId) &&
    id === Roles.systemAdmin ||
    id === Roles.agencyAdmin ||
    id === Roles.regionalManager ||
    id === Roles.divisionChief ||
    id === Roles.processingCenter ||
    id === Roles.controller ||
    id === Roles.dealer ||
    id === Roles.dealerDirector ||
    id === Roles.agencyManager) || (statusId === 12 && id === Roles.auditor)
}

//Доступ к прохождению апелляции из списка анкет
export const getAccessToAppealsPass = (statusToPass: number[], statusId: number): boolean => {
  const {id} = getRole()
  return statusToPass.includes(statusId) &&
    (id === Roles.systemAdmin ||
    id === Roles.agencyAdmin ||
    id === Roles.auditor)
}

//Доступ на изменение статуса анкеты
export const getAccessToFormStatusEdit = (statusToPass: number[], statusId: number): boolean => {
  const {id} = getRole()
  if (id === Roles.systemAdmin) return true
  if ([Roles.agencyAdmin, Roles.agencyManager, Roles.controller].includes(id) && statusToPass.includes(statusId)) return true
  // [11, 12, 13, 15, 16, 18] - значения статусов при котором три роли могут менять статус
  // [9, 10, 11, 12, 13, 14, 17] - значения статусов - на которые три роли могут менять статус
  // Новая, Распределена, Пройдена, Проблемная, Отклонена, Опубликована, Апелляция обработана агентством

  // if ([Roles.dealer, Roles.dealerDirector, Roles.auditor, Roles.regionalManager, Roles.departmentChief, Roles.divisionChief, Roles.processingCenter].includes(id)) return false
  return false
}

//Доступ на изменение статуса анкеты на определенный статус
export const getAccessToFormStatusEditSpecific = (statusToPass: number[], statusId: number): boolean => {
  const {id} = getRole()
  if (id === Roles.systemAdmin) return true
  if (id === Roles.auditor) return statusId === 11
  if ([Roles.agencyAdmin, Roles.agencyManager, Roles.controller].includes(id) && statusToPass.includes(statusId)) return true
  // [11, 12, 13, 15, 16, 18] - значения статусов при котором три роли могут менять статус
  // [9, 10, 11, 12, 13, 14, 17] - значения статусов - на которые три роли могут менять статус
  // Новая, Распределена, Пройдена, Проблемная, Отклонена, Опубликована, Апелляция обработана агентством
  return false
}

//Загрузка информационных ресурсов
export const getAccessToUploadInfoFiles = (): boolean => {
  if(getRole()) {
    const {id} = getRole()
    return id === Roles.systemAdmin
  }
  return false
}

// Ограничения просмотра для контроллера
export const getAccessToMainPageModules = (): boolean => {
  if(getRole()) {
    const { id } = getRole()
    return id !== Roles.controller
  }
  return false
}

//Синхронизация пользователей
export const getAccessToUserSync = (): boolean => {
  if(getRole()) {
    const { id } = getRole()
    return id === Roles.systemAdmin
  }
  return false
}

//Добавлять шаблон юзерам по фильтру
export const getAccessToUserSetTemplateByFilter = (): boolean => {
  if(getRole()) {
    const { id } = getRole()
    return id === Roles.systemAdmin
    || id === Roles.agencyAdmin
  }
  return false
}

//Блокирование пользователей
export const getAccessToUserBlock = (): boolean => {
  if(getRole()) {
    const { id } = getRole()
    return id !== Roles.agencyManager
  }
  return false
}
