import {useForm} from "react-hook-form";
import DealersPageFilterView from "./DealersPageFilterDunb";
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {parseFilterParams, resetParams, serialiseParams} from "lib/parseFilterParams";
import {useState} from "react";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import DealersService from "services/DealersService";
import {OptionData} from "models/FilterData";
import {IUser, IUserFilter} from "models/InstanceInterfaces/IUser";
import {IIdentifier} from "models/common";
import UsersService from "services/UsersService";
import {useAppDispatch} from "hooks/redux";
import {removeModalToStuck} from "store/common";
import {StatusService} from "../../../services/StatusService";
import {IStatus} from "../../../models/Status/Status";
import {IDealerGroup} from "../../../models/IDealerGroup";

/**
 * Тип данных DealersPageFilter
 */
export type DealersPageFilterCredentials = {
  name: string
  INN: string
  filialCode: string
  stateCode: string
  distName: string
  cityName: string
  address: string
  statusIds: OptionData[]
  groupIds: OptionData[]
  userIds: OptionData[]
}

export interface IDealerPageFilter {
  name: string
  INN: string
  filialCode: string
  stateCode: string
  distName: string
  cityName: string
  address: string
  statusIds: []
  groupIds: []
  userIds: []
}

export const defaultDealersFilterValues: DealersPageFilterCredentials = {
  name: '',
  INN: '',
  filialCode: '',
  stateCode: '',
  distName: '',
  cityName: '',
  address: '',
  statusIds: [],
  groupIds: [],
  userIds: [],
}

export const DealersPageFilter = () => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useEaseSearchParams()
  const {control, handleSubmit, reset} = useForm<DealersPageFilterCredentials>({
    defaultValues: parseFilterParams<DealersPageFilterCredentials>(params, defaultDealersFilterValues)
  })
  const resetHandle = () => {
    reset(defaultDealersFilterValues)
    setParams((p: any) => ({...p, page: 1, ...resetParams<DealersPageFilterCredentials>(defaultDealersFilterValues), modalId: undefined}))
    dispatch(removeModalToStuck())
  }

  const onSubmit = (data: any) => {
    setParams((p: any) => ({...p, page: 1, ...serialiseParams(data), modalId: undefined}))
    dispatch(removeModalToStuck())
  }

  const [userFilter, setUserFilter] = useState('')
  const [
    userRowsSelect,
    userPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IUser & IIdentifier), IUserFilter>({
    request: UsersService.getAll,
    filter: userFilter,
    filterField: 'fullName',
    dataConverter: (element): OptionData => ({name: element.fullName, value: element.id})
  })

  const [statusFilter, setStatusFilter] = useState('')
  const [
    statusRowsSelect,
    statusPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IStatus & IIdentifier)>({
    request: StatusService.getAllDealersStatus,
    filter: statusFilter,
  })

  const [dealerGroupFilter, setDealerGroupFilter] = useState('')
  const [
    dealerGroupRowsSelect,
    dealerGroupPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IDealerGroup & IIdentifier)>({
    request: DealersService.getAllDealerGroup,
    filter: dealerGroupFilter,
  })

  //TODO: Доделать статус как только будет сервис
  // const [statusFilter, setStatusFilter] = useState('')
  // const [
  //   statusRowsSelect,
  //   statusPaginateTrigger,
  // ] = usePaginationItemsFromRequest<(IUser & IIdentifier), IUserFilter>({
  //   request: ,
  //   filter: statusFilter,
  // })

  return (
    <DealersPageFilterView
      control={control}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      resetHandle={resetHandle}

      userRowsSelect={userRowsSelect}
      userPaginateTrigger={userPaginateTrigger}
      setUserFilter={setUserFilter}

      dealerGroupRowsSelect={dealerGroupRowsSelect}
      dealerGroupPaginateTrigger={dealerGroupPaginateTrigger}
      setDealerGroupFilter={setDealerGroupFilter}

      statusRowsSelect={statusRowsSelect}
      statusPaginateTrigger={statusPaginateTrigger}
      setStatusFilter={setStatusFilter}
    />
  )
}

export default DealersPageFilter
