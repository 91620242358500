import React, {CSSProperties} from 'react';
import clsx from "clsx";
import s from './s.module.css'

export type MainContainerPropsType = {
  children?: JSX.Element
  header: string
  containerAdditionClass?: string
  customStyle?: CSSProperties | undefined
}

const MainContainer = ({children, header, containerAdditionClass, customStyle}: MainContainerPropsType) => {
  return (
    <div className={clsx(s.container, containerAdditionClass)} style={customStyle}>
      <h4 className={s.header}>{header}</h4>
      <div>
        {children}
      </div>
    </div>
  );
};

export default MainContainer;