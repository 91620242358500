import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { IQuestionInstance } from 'models/InstanceInterfaces/IQuestion'
import { IForm } from 'models/InstanceInterfaces/IForm'
import { Roles } from 'models/Roles'

import Container from './components/Container'
import ContentBox from './components/ContentBox'
import ProgressBar from './components/ProgressBar'
import Actions from './components/Actions'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import {
  clearFormAction,
  getFormWithQuestionAction,
  getQuestionAction,
  setAnswerToQuestionAction,
} from 'store/form/thunk'
import { selectFormDetail, selectFormRequestStatus, selectHasStarted, selectQuestion } from 'store/form/reselect'
import { clearAllFormData, setHasStarted } from 'store/form'
import { selectUserData } from 'store/profile/reselect'
import { getValueBeforeSend, reinitFormValue } from './utils'
import {LOADING_STATUS} from "lib/appConst";

const defaultValues = { answer: '' }

/**
 * Страница для прохождения анкеты Тайным покупателем
 * @constructor
 */
const FormPass = () => {
  const { id } = useParams()
  const formId = Number(id)
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isSubmitting }
  } = useForm<any>({ defaultValues })

  const { form, currentQuestion, hasStarted, requestStatus, profile } = useAppSelector(state => ({
    form: selectFormDetail(state),
    currentQuestion: selectQuestion(state),
    hasStarted: selectHasStarted(state),
    requestStatus: selectFormRequestStatus(state),
    profile: selectUserData(state),
  }))

  const isLoading = requestStatus.isLoading === LOADING_STATUS.LOADING

  const question = currentQuestion?.question
  const order = currentQuestion?.order || 1
  const isShowPrev = order > 1

  useEffect(() => {
    dispatch(getFormWithQuestionAction(formId))

    return () => {
      // Очистить стору по тайному покупателю при уходе с этой страницы
      dispatch(clearAllFormData())
    }
  }, [])

  const reInit = (currentQuestion: IQuestionInstance) => {
    if (currentQuestion && currentQuestion.question) {
      const { question } = currentQuestion

      if (question.hasOwnProperty('answer') && Array.isArray(question.answer) && question.answer.length > 0) {
        reinitFormValue(question.answer[0], question.typeQuestion, reset, defaultValues)
      } else {
        reset(defaultValues)
      }
    }
  }

  const onSubmit = async (data: any) => {
    const { question } = (currentQuestion as IQuestionInstance)

    if (!isDirty) {
      if (question.hasOwnProperty('answer') && Array.isArray(question.answer) && question.answer.length > 0) {
        return goNextQuestion()
      }
    }

    dispatch(setAnswerToQuestionAction({
      formId,
      nextQuestionId: question.nextQuestionId,
      params: {
        formQuestionId: question.id,
        formId,

        // defaultValues
        answerFloat: 0,
        answerString: '',
        fileIds: [],
        ...getValueBeforeSend(data.answer, question.typeQuestion),
      },
    })).then(({ payload }: any) => {
      if (payload.key === 'question') {
        reInit(payload.data)
      }
    })
  }

  const continueForm = useCallback(() => {
    dispatch(setHasStarted(false))
  }, [])

  const clearForm = useCallback(() => {
    dispatch(clearFormAction({ formId, templateFormId: (form as IForm).templateFormId }))
  }, [form])

  const goBack = useCallback(() => {
    switch (profile.roleId) {
      case Roles.systemAdmin:
        return navigate('/forms')
      default:
        return navigate('/')
    }
  }, [])

  // Вернуться к предыдущему вопросу
  const goPrevQuestion = useCallback(() => {
    const { question } = (currentQuestion as IQuestionInstance)
    dispatch(getQuestionAction({
      templateFormId: question.templateFormId,
      questionId: question.id,
      isPrev: true,
      formId,
    })).then(({ payload }) => {
      reInit(payload as IQuestionInstance)
    })
  }, [currentQuestion])

  // Перейти к след вопросу (если текущий был заполнен ранее и значение не менялось)
  const goNextQuestion = async () => {
    const { question } = (currentQuestion as IQuestionInstance)
    dispatch(getQuestionAction({ questionId: question.nextQuestionId, formId }))
      .then(({ payload }) => {
        reInit(payload as IQuestionInstance)
      })
  }

  if (!form) return null

  const total = (form as IForm)?.templateForm?.questions?.length || 0

  return (
    <Container
      title={question?.mainGroup.name}
      hasPassed={form?.statusId === 11}
      hasStarted={hasStarted}
      continueForm={continueForm}
      clearForm={clearForm}
      goBack={goBack}
      requestStatus={requestStatus}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentBox
          question={question}
          control={control}
          errors={errors}
        />
        <ProgressBar
          currentQuestion={order - 1}
          total={total}
        />
        <Actions
          isShowPrev={isShowPrev}
          prevHandler={goPrevQuestion}
          isDisabledButtons={isSubmitting || isLoading}
        />
      </form>
    </Container>
  )
}

export default FormPass
