import React, {Component} from "react";
import AuthServiceOIDC from "../services/OIDC/AuthService";

export const AuthContext = React.createContext({
    login: () => ({}),
    signinRedirectCallback: () => ({}),
    logout: () => ({}),
    signinRedirect: () => ({}),
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
    render() {
        return <AuthContext.Provider value={new AuthServiceOIDC()}>{this.props.children}</AuthContext.Provider>;
    }
}
