import React, {useState} from 'react'
import { ReactSVG } from 'react-svg'

import { getFileExtension, getFileSize } from 'components/UI/UIField/items/Filepicker/items/File'

import { ModalsEnum } from 'modules/ModalController/modalManager'
import { IInfoFileResponse } from 'models/IInfoFiles'

import { setModal } from 'lib/setModal'

import { ReactComponent as PdfIco } from 'assets/svg/fileExtensions/pdf.svg'
import { ReactComponent as AudioIco } from 'assets/svg/fileExtensions/audio.svg'
import { ReactComponent as AndroidIco } from 'assets/svg/fileExtensions/android.svg'
import { ReactComponent as FileGlyph } from 'assets/svg/glyph/file.svg'
import PencilIco from 'assets/svg/TableControlFields/edit.svg'

import { serialiseParams } from 'lib/parseFilterParams'

import s from './styles.module.css'
import {getFileByStream} from "../../../../../lib/getFileByStream";

type TInfoFileProps = {
  file: IInfoFileResponse
  isCanEdit: boolean
}

export const FileIco = ({ extension }: {  extension: string}) => {
  switch (extension) {
    case 'pdf':
      return <PdfIco />
    case 'apk':
      return <AndroidIco />
    case 'mp3':
      return <AudioIco />
    default:
      return <FileGlyph />
  }
}

const InfoFile = ({ file, isCanEdit }: TInfoFileProps) => {

  const [loading, setLoading] = useState(false)

  const onOpen = () => {
    setLoading(true)
    const {id, fileName} = file?.File || {}
    if(id && fileName) {
      getFileByStream(id)
        .then(([_, downloader]) => {
          downloader(fileName)
          setLoading(false)
        })
    }
  }

  const onOpenModal = () => {
    const data = serialiseParams({ id: file.id, fileId: file.fileId, name: file.name })
    setModal(ModalsEnum.InfoListModal, { type: file.type, ...data })
  }

  return (
    <div className={s.container}>
      <div className={s.icon}>
        <FileIco extension={getFileExtension(file.File.fileName).toLowerCase()} />
      </div>
      <div className={s.file}>
        {loading && (
          <div className={s.preloadText}>
            Загрузка...
          </div>
        )
        }
        <div className={s.name} style={loading ? {opacity: .3} : {}} onClick={loading ? () => {} : onOpen}>
          {file.name}
        </div>
        <div className={s.desc}>
          {getFileExtension(file.File.fileName)}, {getFileSize(file.File.fileSize)}
        </div>
      </div>
      {isCanEdit && (
        <div
          className={s.actions}
          onClick={onOpenModal}
        >
          <ReactSVG src={PencilIco} />
        </div>
      )}
    </div>
  )
}

export default InfoFile

