import React from 'react';
import s from './s.module.css'
import clsx from "clsx";
import {UIButtonCommonType} from "../../index";

export type DefaultBtnType = {
  label: string
  leftIcon?: any
  rightIcon?: any
}

const BorderBtn = ({
                     label,
                     leftIcon,
                     rightIcon,
                     handler,
                     style = {},
                     type = "submit",
                     title,
                     disabled,
  className
                   }: DefaultBtnType & UIButtonCommonType) => {
  return (
    <button
      style={style}
      type={type}
      className={clsx(s.button, 'button', {[s.disabled]: disabled}, className)}
      onClick={!disabled ? handler : () => {}}
      title={title}
    >
      {leftIcon && leftIcon}
      {label && (
        <span
          className={clsx({
            [s.leftMargin]: leftIcon,
            [s.rightMargin]: rightIcon,
          })}
        >
        {
          label
        }
      </span>
      )}
      {rightIcon && rightIcon}
    </button>
  );
};

export default BorderBtn;
