import React, {memo} from 'react';
import s from './s.module.css'
import TableSelectedItemsControl from "./TableParts/TableSelectedItemsControl";
import TableHeader from "./TableParts/TableHeader";
import TableFooter from "./TableParts/TableFooter";
import TableBody from "./TableParts/TableBody";
import clsx from "clsx";

import {ITableConfig} from "./index";
import {ListData} from "../../models/common";
import {allTableControlAccess} from "../../lib/RolesModel";

export interface ITableGeneratorView {
  disabledTableActivity?: boolean
  tableConfig: ITableConfig
  tableName?: string
  requestCounts: number[]
  TableControl?: (props: any) => JSX.Element
  Submenu?: (props: any) => JSX.Element
  selectable?: boolean
  data: ListData<any>
  onRowSelect?: any
  selectedRows?: number[]
  excludedRows?: number[]
  isAllSelected?: boolean
  withoutPagination?: boolean
  id?: string
}

/**
 * Компонент отображения таблицы
 * Сигнатура аналогична родителю за исключением работы с данными и запросами
 */
const TableGeneratorView = (
  {
    tableConfig: {
      structureConfig,
      controlConfig,
    },
    tableName,
    TableControl,
    selectable,
    data: {
      count,
      rows,
      status,
    },
    onRowSelect,
    selectedRows,
    excludedRows,
    requestCounts,
    isAllSelected,
    withoutPagination,
    id,
    Submenu,
    disabledTableActivity,
  }: ITableGeneratorView) => {
  return (
    <div className={s.container}>
      <header className={clsx(s.tableTop, {
        [s.headerContainer]: tableName || TableControl
      })}>
        <p className={"title"}>{tableName}</p>
        {TableControl && <TableControl disabledTableActivity={disabledTableActivity}/>}
      </header>
      {Submenu && <div className={s.submenu}><Submenu/></div>}
      {allTableControlAccess() && (selectable) &&
        <TableSelectedItemsControl
          controlConfig={controlConfig}
          onRowSelect={onRowSelect}
          isAllSelected={isAllSelected}
          selectedRows={selectedRows}
          excludedRows={excludedRows}
          count={count}
        />
      }
      <div
        className={clsx(s.wrapper)}
        id={id}
      >
        <table className={s.table}>
          <TableHeader
            disabledTableActivity={disabledTableActivity}
            structureConfig={structureConfig}
            selectable={selectable}
            rows={rows}
          />
          <TableBody
            structureConfig={structureConfig}
            onRowSelect={onRowSelect}
            selectedRows={selectedRows}
            excludedRows={excludedRows}
            isAllSelected={isAllSelected}
            selectable={selectable}
            rows={rows}
          />
        </table>
      </div>
      {!withoutPagination && (
        <TableFooter
          count={count}
          status={status}
          requestCounts={requestCounts}
          disabledTableActivity={disabledTableActivity}
        />
      )}
    </div>
  );
};

export default memo(TableGeneratorView);
