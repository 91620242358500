import MainPageFilterView from "./MainPageFilterDunb";
import {useForm} from "react-hook-form";
import { useAppDispatch } from 'hooks/redux';
import { getStatisticAction } from 'store/statistic/thunk';
import { useEffect, useState } from 'react';
import { useEaseSearchParams } from 'hooks/useEaseSearchParams';
import { parseFilterParams, parseFilterParamsForRequest, resetParams, serialiseParams } from 'lib/parseFilterParams';
import {
  defaultMainPageFilter,
  MainPageFilterCredentials,
  TMainPageFilter,
} from 'modules/filters/MainPageFilter/utils';

/**
 * Компонент MainPageFilter
 * @constructor
 */
export const MainPageFilter = () => {
  const [params, setParams] = useEaseSearchParams()
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState<Partial<TMainPageFilter>>(parseFilterParamsForRequest(params, defaultMainPageFilter) as Partial<TMainPageFilter>)

  const { control, handleSubmit, reset } = useForm<MainPageFilterCredentials>({
    defaultValues: parseFilterParams<MainPageFilterCredentials>(params, defaultMainPageFilter)
  });

  useEffect(() => {
    dispatch(getStatisticAction(filter))
  }, [filter])

  useEffect(() => {
    if(JSON.stringify(filter) !== JSON.stringify(parseFilterParamsForRequest(params, defaultMainPageFilter) as unknown as Partial<TMainPageFilter>)) {
      setFilter(parseFilterParamsForRequest(params, defaultMainPageFilter) as unknown as Partial<TMainPageFilter>)
    }
  }, [params])

  const onSubmit = (values: MainPageFilterCredentials) => {
    setParams((p: any) => ({ ...p, ...serialiseParams(values) }))
  }

  const resetHandle = () => {
    reset(defaultMainPageFilter)
    setParams((p: any) => ({ ...p, ...resetParams<MainPageFilterCredentials>(defaultMainPageFilter) }))
  }

  return (
    <MainPageFilterView
      control={control}
      onSubmit={onSubmit}
      onReset={resetHandle}
      handleSubmit={handleSubmit}
    />
  )
}
