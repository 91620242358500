import {ListDataWithSelect} from "../models/common";

export const toggleRow = (initialState: any) =>
  <T>(state: { dataList: ListDataWithSelect<T> }, payload: number | number[] | undefined) => {
    /**
     * Проверка: Если исключения === count, то переключаем флаг в selectAll и наоборот
     */
    if (state.dataList.excludedRows.length === state.dataList.count) {
      state.dataList.selectAll = false
      state.dataList.excludedRows = initialState.dataList.excludedRows
    }
    if (state.dataList.selectedRows.length === state.dataList.count) {
      state.dataList.selectAll = true
      state.dataList.selectedRows = initialState.dataList.selectedRows
    }

    if (!payload) {
      if (state.dataList.selectAll) {
        if (state.dataList.excludedRows.length) {
          state.dataList.excludedRows = initialState.dataList.excludedRows
        } else {
          state.dataList.excludedRows = initialState.dataList.excludedRows
          state.dataList.selectAll = false
        }
      } else {
        state.dataList.excludedRows = initialState.dataList.excludedRows
        state.dataList.selectedRows = initialState.dataList.selectedRows
        state.dataList.selectAll = true
      }
    }

    if (Array.isArray(payload)) {
      state.dataList.selectedRows = [...payload]
    }

    if (!Array.isArray(payload) && !!payload) {
      if (state.dataList.selectAll) {
        if (state.dataList.excludedRows.includes(payload)) {
          state.dataList.excludedRows = state.dataList.excludedRows.filter(el => el !== payload)
        } else {
          state.dataList.excludedRows = [...state.dataList.excludedRows, payload]
        }
      } else {
        if (state.dataList.selectedRows.includes(payload)) {
          state.dataList.selectedRows = state.dataList.selectedRows.filter(el => el !== payload)
        } else {
          state.dataList.selectedRows = [...state.dataList.selectedRows, payload]
        }
      }
    }
  }

