import React, {CSSProperties} from 'react';
import s from './s.module.css'

export type PageContainerPropsType = {
  children?: JSX.Element
  customStyle?: CSSProperties | undefined
}

const PageContainer = ({children, customStyle}: PageContainerPropsType) => {
  return (
    <div className={s.container} style={customStyle}>
      {children}
    </div>
  );
};

export default PageContainer;

