import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getAllFormsAction, getFormTypesAction} from "./thunk";
import {FormsState} from "../../models/storeInterface/FormsState";
import {toggleRow} from "../commonReducers";
import {TFormsPageFilterCredentials} from "modules/filters/FormsPageFilter";
import {defaultFormData} from 'modules/Modals/NewFormModal/index';
import {defaultFilters} from 'modules/Modals/BindDealerModal/index'
import {IDealer} from "../../models/DealersServiceModel";
import {IIdentifier, IPaginate} from "../../models/common";
import {LOADING_STATUS} from "../../lib/appConst";

const name = 'forms';

const initialState: FormsState = {
  dataList: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
    selectedRows: [],
    excludedRows: [],
    selectAll: false,
  },

  wavesList: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
  },

  formTypesList: {
    status: LOADING_STATUS.IDLE,
    error: '',
    count: 0,
    rows: [],
  },

  bindedDealers: [],

  bindedDealersFilters: defaultFilters,

  isAllCheckedBindedDealers: false,

  newFormData: defaultFormData,

  typeFormId: null,
  kindFormId: null,
  typeFormName: 'Тип анкеты',
  kindFormName: 'Вид анкеты',

  formFilterState: {} as TFormsPageFilterCredentials,

  tempQueryParams: {} as TFormsPageFilterCredentials & IPaginate
}

export const formsSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    toggleFormRow: (state, {payload}: {payload: number | number[] | undefined}) =>
      toggleRow(initialState)<any>(state, payload),

    // Обработка массива диллеров в модалке BindDealerModal (чекбоксы)
    setBindedDealer: (state, action) => {
      state.bindedDealers = action.payload
    },
    checkAllBindedDealers: (state) => {
      state.isAllCheckedBindedDealers = true
      state.bindedDealers = state.bindedDealers.map(item => {
        return {...item, isBinded: true}
      })
    },
    uncheckAllBindedDealers: (state) => {
      state.isAllCheckedBindedDealers = false
      state.bindedDealers = state.bindedDealers.map(item => {
        return {...item, isBinded: false}
      })
    },
    toggleBindedDealer: (state, {payload: {id}}) => {
      state.newFormData.dealers = state.newFormData.dealers.find((el: IDealer & IIdentifier) => el.id === id)
        ? state.newFormData.dealers.filter((el: IDealer & IIdentifier) => el.id !== id)
        : [...state.newFormData.dealers, ...state.bindedDealers.filter((el) => el.id === id)]

      // state.bindedDealers =  state.bindedDealers.map(item => {
      //   return action.payload.id === item.id
      //     ? {...item, isBinded: !item.isBinded}
      //     : item
      // })
    },

    // обработка фильтров в модалке BindDealerModal
    setBindedDealersFilters: (state, action) => {
      state.bindedDealersFilters = action.payload
    },
    // добавления данных из инпутов в стейт модалка NewFormModal
    setNewFormData: (state, action) => {
      state.newFormData = action.payload
    },
    setTypeFormId: (state, action) => {
      state.typeFormId = action.payload
    },
    setKindFormId: (state, action) => {
      state.kindFormId = action.payload
    },
    setTypeFormName: (state, action) => {
      state.typeFormName = action.payload
    },
    setKindFormName: (state, action) => {
      state.kindFormName = action.payload
    },
    resetNewFormData: (state) => {
      state.newFormData = initialState.newFormData
    },
    setTempQueryParams: (state, action: PayloadAction<TFormsPageFilterCredentials & IPaginate>) => {
      state.tempQueryParams = action.payload
    }
  },
  extraReducers: {
    [getAllFormsAction.pending.type]: (state) => {
      state.dataList.status = LOADING_STATUS.LOADING
      state.dataList.error = initialState.dataList.error
    },
    [getAllFormsAction.fulfilled.type]: (state, { payload }) => {
      state.dataList = {...state.dataList, ...payload}
      state.dataList.status = LOADING_STATUS.IDLE
    },
    [getAllFormsAction.rejected.type]: (state, { payload }) => {
      state.dataList.error = payload
      state.dataList.status = LOADING_STATUS.IDLE
    },

    /**
     * Обработка запроса типов анкет
     */
    [getFormTypesAction.pending.type]: (state) => {
      state.formTypesList.status = LOADING_STATUS.LOADING
      state.formTypesList.error = initialState.formTypesList.error
    },
    [getFormTypesAction.fulfilled.type]: (state, { payload }) => {
      state.formTypesList = {...state.formTypesList, ...payload}
      state.formTypesList.status = LOADING_STATUS.IDLE
    },
    [getFormTypesAction.rejected.type]: (state, { payload }) => {
      state.formTypesList.error = payload
      state.formTypesList.status = LOADING_STATUS.IDLE
    },
  }
})
export const {
  toggleFormRow,
  setBindedDealer,
  checkAllBindedDealers,
  uncheckAllBindedDealers,
  toggleBindedDealer,
  setBindedDealersFilters,
  setNewFormData,
  setTypeFormId,
  setKindFormId,
  setTypeFormName,
  setKindFormName,
  resetNewFormData,
  setTempQueryParams,
} = formsSlice.actions
export default formsSlice.reducer;
