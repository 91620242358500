import {RootState} from '../index'
import {createSelector} from '@reduxjs/toolkit'

const getForms = (store: RootState) => store.forms

export const selectForms = createSelector(getForms, forms => forms.dataList)
export const selectFormById = createSelector(
  getForms,
  forms => (id: number) => forms.dataList.rows.find(el => el.id === id),
)
export const selectSelectedFormsRow = createSelector(
  getForms,
  forms => forms.dataList.selectedRows,
)
export const selectSelectedFormsExcludeRow = createSelector(
  getForms,
  forms => forms.dataList.excludedRows,
)
export const selectFormSelectedAllFlag = createSelector(
  getForms,
  forms => forms.dataList.selectAll,
)

export const selectFormWavesList = createSelector(
  getForms,
  forms => forms.wavesList,
)
export const selectFormTypesList = createSelector(
  getForms,
  forms => forms.formTypesList,
)

export const selectFormFilterState = createSelector(
  getForms,
  forms => forms.formFilterState,
)

export const selectFormBindedDealers = createSelector(getForms, forms => forms.bindedDealers)
export const selectFormBindedDealersFilters = createSelector(getForms, forms => forms.bindedDealersFilters)
export const selectNewFormData = createSelector(getForms, forms => forms.newFormData)
export const selectTypeFormId = createSelector(getForms, forms => forms.typeFormId)
export const selectKindFormId = createSelector(getForms, forms => forms.kindFormId)
export const selectTypeFormName = createSelector(getForms, forms => forms.typeFormName)
export const selectKindFormName = createSelector(getForms, forms => forms.kindFormName)
export const selectAllCheckedBindenDealers = createSelector(getForms, forms => forms.isAllCheckedBindedDealers)
export const selectTempQueryParams = createSelector(getForms, forms => forms.tempQueryParams)
