import DefaultBtn, {DefaultBtnType} from "./items/DefaultBtn";
import CircleBtn, {CircleBtnType} from "./items/CircleBtn";
import {CSSProperties} from "react";
import EmptyBtn, {EmptyBtnType} from "./items/EmptyBtn";
import BorderBtn from "./items/BorderBtn";
import LinkBtn from "./items/LinkBtn";

export type UIButtonCommonType = {
  style?: CSSProperties
  type?: 'submit' | "button"
  handler?: () => void
  disabled?: boolean
  title?: string
  className?: string
}

export type UIButtonType = {
  DefaultBtn: (props: DefaultBtnType & UIButtonCommonType) => JSX.Element
  LinkBtn: (props: DefaultBtnType & UIButtonCommonType) => JSX.Element
  BorderBtn: (props: DefaultBtnType & UIButtonCommonType) => JSX.Element
  CircleBtn: (props: CircleBtnType & UIButtonCommonType) => JSX.Element
  EmptyBtn: (props: EmptyBtnType & UIButtonCommonType) => JSX.Element
}

export const UIButton: UIButtonType = {
  DefaultBtn: DefaultBtn,
  LinkBtn: LinkBtn,
  BorderBtn: BorderBtn,
  CircleBtn: CircleBtn,
  EmptyBtn: EmptyBtn,
};
