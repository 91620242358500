import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form';
import {UIContainer} from 'components/UI/UIContainer'
import UIField from 'components/UI/UIField'
import s from './s.module.css'
import {UIButton} from 'components/UI/UIButton';
import {useEaseSearchParams} from 'hooks/useEaseSearchParams';
import FormsService from "services/FormsService";
import {TextEditorRaw} from "components/UI/UIField/items/TextEditor";
import Preloader from "components/UI/Preloader/Preloader";
import {IQuestionRaw} from "models/InstanceInterfaces/IQuestion";
import AppealsService from "services/AppealsService";
import {removeModalItem, setModal} from "lib/setModal";
import {ModalsEnum} from 'modules/ModalController/modalManager';
import UITextArea from "components/UI/UITextArea";
import {addNotification} from "lib/addNotification";
import {useDispatch, useSelector} from "react-redux";
import {NOTIFICATOR_TYPE} from "lib/appConst";
import {acceptAppealStatus, addAppealInfo, rejectAppealStatus} from 'store/formDetail';
import {IsSystemAdmin} from 'lib/RolesModel';
import {IIdentifier} from "models/common";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {string} from "yup";
import {errorMessages} from "lib/errorMessages";
import {resetAppealAnswerCache, setAppealAnswerCache} from "store/appeals";
import {selectAppealAnswerCache} from "store/appeals/reselect";
import {AxiosError} from "axios";

const resolverSchema = yup.object({
  appealText: string().required(errorMessages.common.inputRequired),
})

const Regular = ({status}: any) => {
  const {text, filesIds} = useSelector(selectAppealAnswerCache)
  const dispatch = useDispatch()
  const {control, handleSubmit, watch, formState: {errors}} = useForm({
    defaultValues: {
      appealText: text,
      fileIds: filesIds
    },
    resolver: yupResolver(resolverSchema)
  })
  const [isApply, setIsApply] = useState<number | undefined>()
  const appealTextWatcher = watch('appealText')
  const fileIdsWatcher = watch('fileIds')

  const [{questionId, idForm, apId}, setParams] = useEaseSearchParams()
  const [question, setQuestion] = useState<IQuestionRaw & IIdentifier | undefined>()
  const closeModalHandle = () => removeModalItem(setParams)

  const getQuestion = async () => (await FormsService.getCurrentQuestion(questionId, {idForm})).data.question

  const onSubmit = async (data: any) => {
    const answerId = question?.answer[question?.answer.length - 1]?.id
    const params = {
      ...data,
      formQuestionId: question?.id,
      formId: idForm,
      answerId,
    }
    try {
      const appeal = (await AppealsService.create({...params})).data
      if(appeal) {
        addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Апелляция отправлена'})
        dispatch(addAppealInfo({ answerId: Number(answerId), appeal, formQuestionId: parseInt(questionId) }))
        closeModalHandle()
        dispatch(resetAppealAnswerCache())
      }
    } catch (e) {
      addNotification({type: NOTIFICATOR_TYPE.error, text: 'Неизвестная ошибка'})
    }
  }

  useEffect(() => {
    getQuestion()
      .then(setQuestion)
  }, [])

  const dismissHandle = async (data: any) => {
    try {
      if(apId) {
        if((await AppealsService.changeStatus(apId, 13)).data) {
          if(appealTextWatcher) {
            const newAppeal = (await AppealsService.answerOnAppeal(apId, {appealAnswerText: appealTextWatcher, fileIds: fileIdsWatcher})).data
            closeModalHandle()
            dispatch(rejectAppealStatus({
              ...data,
              formQuestionId: parseInt(questionId),
              appealId: parseInt(apId),
              newAppeal,
              status: { id: 13, name: 'Отклонена' },
            }))
            dispatch(resetAppealAnswerCache())
            addNotification({type: NOTIFICATOR_TYPE.apply, text: 'Апелляция отклонена'})
          }
        } else {
          addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла ошибка сервера'})
          alert('Произошла ошибка сервера')
        }
      }
    } catch (e) {
      if(e instanceof AxiosError && !!e?.response?.data?.message) addNotification({type: NOTIFICATOR_TYPE.error, text: e?.response?.data?.message})
      else {
        if(e instanceof Error) addNotification({type: NOTIFICATOR_TYPE.error, text: e.message})
        else addNotification({type: NOTIFICATOR_TYPE.error, text: 'Произошла неизвестная ошибка'})
      }
    }
  }

  const accessHandler = () => {
    if (IsSystemAdmin()) {
      dispatch(setAppealAnswerCache({
        text: appealTextWatcher,
        filesIds: fileIdsWatcher
      }))
      setModal(ModalsEnum.FormAppealQuestion, { questionId, idForm, apId })
    } else {
      dispatch(acceptAppealStatus({
        formQuestionId: parseInt(questionId),
        appealId: parseInt(apId),
        status: { id: 6, name: 'Обработана' },
      }))
    }
  }

  return question ? (
      <UIContainer.ContentContainer
        header={status ? 'Ответить на апелляцию' : 'Подать апелляцию'}
      >
        <form onSubmit={handleSubmit(status ? isApply ? accessHandler : dismissHandle : onSubmit)} className={s.container}>
          <div className={s.question}>
            {question?.textQuestion && <TextEditorRaw value={JSON.parse(question.textQuestion)} readOnly/>}
            <hr/>
            {question?.answer
              ? question?.answer.map((answer) => (<p key={answer.id}>{answer.answerString}</p>))
              : <p>Ответ отсутствует</p>
            }
          </div>
          <div>
            {
              <UITextArea
                customFieldWrapperStyle={{
                  border: "1px solid silver",
                  margin: "16px 0"
                }}
                name='appealText'
                placeholder={status ? 'Ответ' : 'Текст сообщения'}
                control={control}
                error={errors.appealText}
              />
            }
          </div>
          {!status && <hr/>}
          <div className={s.wrapper}>
            {
              status
                ?
                <div className={s.bottomFormWrapper}>
                  <UIField.FilePicker
                    name='fileIds'
                    control={control}
                    maxItems={10}
                  />
                  <div className={s.bottomFormButtonWrapper}>
                    <UIButton.DefaultBtn
                      label={'Принять'}
                      handler={() => setIsApply(1)}
                    />
                    <UIButton.DefaultBtn
                      label={'Отклонить'}
                      handler={() => setIsApply(0)}
                    />
                  </div>
                </div>
                :
                <div className={s.bottomFormWrapper}>
                  <UIField.FilePicker
                    name='fileIds'
                    control={control}
                    maxItems={10}
                  />
                  <UIButton.DefaultBtn
                    label={'отправить'}
                  />
                </div>
            }
          </div>
          {status}
        </form>
      </UIContainer.ContentContainer>
    )
    : <Preloader/>
}

export default Regular
