import React, {useEffect, useState} from 'react';
import s from './s.module.css'
import UIField from "components/UI/UIField";
import FormsService from "services/FormsService";
import {LOADING_STATUS} from "lib/appConst";
import clsx from "clsx";
import {IMainGroup, ITemplateGroup} from "models/InstanceInterfaces/ITemplateForm";
import {ReactComponent as TrashBin} from "assets/svg/templateTool/delete.svg";
import {getFormTemplateByIdNoLoadHelper} from "lib/apiHelpers";

export interface IGroupTitle {
  templateFormId: number,
  group: ITemplateGroup
  loading?: LOADING_STATUS
}

export interface ITemplateQuestionsGroup extends IGroupTitle {
  children?: JSX.Element
}

const TemplateQuestionsGroup = ({templateFormId, group, loading, children}: ITemplateQuestionsGroup) => {
  return (
    <div className={s.container}>
      <GroupTitle {...{
        group,
        loading,
        templateFormId
      }} />
      {children}
    </div>
  );
};

export default TemplateQuestionsGroup;

const GroupTitle = ({templateFormId, group, loading}: IGroupTitle) => {

  const isLoading = loading !== LOADING_STATUS.IDLE
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [showVariants, setShowVariants] = useState(false)

  const checkDifference = () => {
    if (group && group.id && name !== group?.name || code !== group?.code) {
      if(code && name) editGroup({id: group?.id, name, code, templateFormId})
    }
  }

  const editGroup = async (data: IMainGroup) => {
    const res = await FormsService.editMainGroup(data)
    const newGroup = res.data
    if (newGroup) getFormTemplateByIdNoLoadHelper()
  }

  const handleContextMenu = () => setShowVariants(p => !p)

  const handleGroupDelete = () => {
    setShowVariants(false)
    if(group.id) FormsService.deleteMainGroup(group.id).then(getFormTemplateByIdNoLoadHelper)
  }

  useEffect(() => {
    if (group) {
      setName(group.name)
      setCode(group.code)
    }
  }, [group])

  return (
    <div className={clsx(s.titleContainer, {[s.loading]: isLoading})}>
      <UIField.InputRaw
        disabled={isLoading}
        customStyle={{flexGrow: 1, width: 'auto'}}
        customInputClassName={s.input}
        name={'section'}
        placeholder={"Название раздела"}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        value={name}
        onBlur={() => {
          group && checkDifference()
        }}
      />
      <UIField.InputRaw
        disabled={isLoading}
        customStyle={{width: 'auto'}}
        customInputClassName={s.input}
        name={'address'}
        placeholder={"Адрес"}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
        value={code}
        onBlur={() => {
          group && checkDifference()
        }}
      />
      <div className={s.btn} onClick={handleContextMenu}>
        <span className={s.dot}/>
        <span className={s.dot}/>
        <span className={s.dot}/>
      </div>
      { showVariants && (
        <div className={s.contextMenu}>
          <div className={s.variant} onClick={handleGroupDelete}>
            <TrashBin />
            <p>Удалить</p>
          </div>
        </div>
      )}
    </div>
  )
}
