import { useEffect, useState } from 'react'
import { Control, Controller, FieldError, FieldErrors, FieldName, RegisterOptions } from 'react-hook-form'
import clsx from 'clsx'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import 'moment/locale/ru'

import ArrowLeftIco from 'assets/svg/common/arrow-flat-left.svg'
import ArrowRightIco from 'assets/svg/common/arrow-flat-right.svg'
import CalendarIco from 'assets/svg/common/calendar.svg'

import 'react-dates/initialize'
import './_datepicker.css'

import { getInputElementById } from 'lib/getInputElementById'

import s from './s.module.css'
import UIError from 'components/UI/UIError';
import getGeneratedId from "../../../../../lib/getGeneratedId";

moment.locale('ru')

export type DatepickerPropsTypes = {
  placeholder?: string
  label?: string
  control: Control<any>
  name: FieldName<any>
  // errors?: FieldErrors
  errors?: any
  extraHandler?: (date: moment.Moment | null) => void
  rules?: RegisterOptions
}

const Datepicker = ({
  label = '',
  placeholder = '',
  name,
  errors,
  control,
  extraHandler = () => {},
  rules,
}: DatepickerPropsTypes) => {

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <DatepickerRaw
          onChange={(val) => {
            onChange(val)
            extraHandler(val)
          }}
          {...{
            label,
            placeholder,
            extraHandler,
            value,
            error: errors !== undefined ? errors[name] : undefined
          }}
        />
      )}
    />
  )
}

export default Datepicker

export type DatepickerRawPropsType = {
  placeholder?: string
  label?: string
  error?: any | FieldError,
}

export type ControllerTypes = {
  onChange: (date: moment.Moment | null) => void,
  value: moment.Moment | string | null
}

let DOMField: HTMLInputElement | null = null

export const DatepickerRaw = ({
  label = '',
  placeholder = '',
  onChange,
  value,
  error,
}: DatepickerRawPropsType & ControllerTypes) => {
  const [focused, setFocused] = useState<boolean>(false)

  const id = String(getGeneratedId())

  useEffect(() => {
    if (!value) getInputElementById(DOMField, id).value = ''
  }, [focused])

  let date = null

  if(!moment.isMoment(value) && typeof value === "string" && value !== "") {
    date = moment(new Date(value))
  }
  if(value === null || moment.isMoment(value)) {
    date = value
  }

  return (
    <div className={s.container}>
      <SingleDatePicker
        date={date}
        onDateChange={date => onChange(date)}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id={id}
        numberOfMonths={1}
        daySize={35}
        placeholder={''}
        customInputIcon={<img src={CalendarIco} alt="calendar"/>}
        navPrev={<img src={ArrowLeftIco} alt="prev"/>}
        navNext={<img src={ArrowRightIco} alt="next"/>}
        isOutsideRange={() => false}
      />
      <label htmlFor={id} className={clsx(s.label, { [s.filled]: focused || value })}>
        {label || placeholder || 'Укажите дату'}
      </label>
      {error && error.message && (
        <UIError errorMessage={error.message}/>
      )}
    </div>
  )
}
