import {useForm} from "react-hook-form";
import FormsService from "services/FormsService";
import {IWave} from "models/InstanceInterfaces/IWave";
import {useState} from "react";
import usePaginationItemsFromRequest from "hooks/usePaginationItemsFromRequest";
import { IIdentifier, IResponseExtraData } from 'models/common';
import {useEaseSearchParams} from "hooks/useEaseSearchParams";
import {parseFilterParams, resetParams, serialiseParams} from "lib/parseFilterParams";
import {ITypeForm} from "models/InstanceInterfaces/ITypeForm";
import DealersService from "services/DealersService";
import {IDealer} from "models/DealersServiceModel";
import {removeModalToStuck} from "store/common";
import {useAppDispatch} from "hooks/redux";
import AppealsPageFilterDunb from "./AppealsPageFilterDunb";
import moment from "moment";
import {StatusService} from "../../../services/StatusService";
import {IStatus} from "../../../models/Status/Status";

/**
 * Тип данных FormsPageFilter
 */
export type AppealsPageFilterCredentials = {
  dateFrom: moment.Moment | string | null,
  dateTo: moment.Moment | string | null,
  stateCode: string,
  distName: string,
  cityName: string,
  address: string,
  formId: string,
  typeFormId: string,
  waveId: string,
  statusId: string,
  dealerId: string,
}

export type TAppealsPageFilter =  {
  dateFrom: '',
  dateTo: '',
  stateCode: '',
  distName: '',
  cityName: '',
  address: '',
  formId: '',
  typeFormId: '',
  waveId: '',
  statusId: '',
  dealerId: '',
}

export const defaultAppealsFilterValues: AppealsPageFilterCredentials = {
  dateFrom: null,
  dateTo: null,
  stateCode: '',
  distName: '',
  cityName: '',
  address: '',
  formId: '',
  typeFormId: '',
  waveId: '',
  statusId: '',
  dealerId: '',
}

/**
 * Компонент фильтр для страницы с анкетами
 * @constructor
 */
export const AppealPageFilter = () => {
  const dispatch = useAppDispatch()
  const [params, setParams] = useEaseSearchParams()
  const { control, handleSubmit, reset } = useForm<AppealsPageFilterCredentials>({
    defaultValues: parseFilterParams<AppealsPageFilterCredentials>(params, defaultAppealsFilterValues)
  });

  const resetHandle = () => {
    reset(defaultAppealsFilterValues)
    setParams((p: any) => ({...p, page: 1, ...resetParams<AppealsPageFilterCredentials>(defaultAppealsFilterValues), modalId: undefined}))
    dispatch(removeModalToStuck())
  }

  const onSubmit = (data: TAppealsPageFilter) => {
    setParams((p: any) => ({...p, page: 1, ...serialiseParams(data), modalId: undefined}))
    dispatch(removeModalToStuck())
  }

  const [waveFilter, setWaveFilter] = useState('')
  const [
    waveRowsSelect,
    wavePaginateTrigger,
  ] = usePaginationItemsFromRequest<IWave>({
    request: FormsService.getAllWaves,
    filter: waveFilter,
  })

  const [statusFilter, setStatusFilter] = useState('')
  const [
    statusRowsSelect,
    statusPaginateTrigger,
  ] = usePaginationItemsFromRequest<IStatus>({
    request: StatusService.getAllAppealsStatus,
    filter: statusFilter,
  })

  const [typeFormFilter, setTypeFormFilter] = useState('')
  const [
    typeFormRowsSelect,
    typeFormPaginateTrigger,
  ] = usePaginationItemsFromRequest<(ITypeForm)>({
    request: FormsService.getAllFormTypes,
    filter: typeFormFilter,
  })

  const [dealerFilter, setDealerFilter] = useState('')
  const [
    dealerRowsSelect,
    dealerPaginateTrigger,
  ] = usePaginationItemsFromRequest<(IDealer & IResponseExtraData & IIdentifier)>({
    request: DealersService.getAll,
    filter: dealerFilter,
  })

  // на автоматизацию постройки селектов не было времени
  return (
    <AppealsPageFilterDunb
      control={control}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      resetHandle={resetHandle}

      waveRowsSelect={waveRowsSelect}
      wavePaginateTrigger={wavePaginateTrigger}
      setWaveFilter={setWaveFilter}

      statusRowsSelect={statusRowsSelect}
      statusPaginateTrigger={statusPaginateTrigger}
      setStatusFilter={setStatusFilter}

      typeFormRowsSelect={typeFormRowsSelect}
      typeFormPaginateTrigger={typeFormPaginateTrigger}
      setTypeFormFilter={setTypeFormFilter}

      dealerRowsSelect={dealerRowsSelect}
      dealerPaginateTrigger={dealerPaginateTrigger}
      setDealerFilter={setDealerFilter}
    />
  )
}
