import React, {CSSProperties} from 'react';
import clsx from "clsx";
import s from './s.module.css'
import Preloader from "../../../Preloader/Preloader";

export type ContentContainerPropsType = {
  children?: React.ReactNode
  header?: string
  containerAdditionClass?: string
  customStyle?: CSSProperties | undefined
  loading?: boolean
}

const ContentContainer = ({children, header, containerAdditionClass, customStyle, loading}: ContentContainerPropsType) => {
  return (
    <div className={clsx(s.container, containerAdditionClass)} style={customStyle}>
      {
        header && (
          <header className={s.headerWrapper}>
            <h3 className={s.header} style={{marginRight: loading ? 10 : 0}}>{header}</h3>
            {loading && <Preloader width={25} height={25}/>}
          </header>
        )
      }
      <div style={{height: "100%", width: "100%"}}>
        {children}
      </div>
    </div>
  );
};

export default ContentContainer;

