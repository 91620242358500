import { createSlice } from "@reduxjs/toolkit";
import { getAllLogsAction } from "./thunk";
import {LOADING_STATUS} from "../../lib/appConst";


const name = 'logs';

const initialState: any = {
    dataList: {
        status: LOADING_STATUS.IDLE,
        error: '',
        count: 0,
        rows: [],
    },
}

const logsSlice = createSlice({
    name,
    initialState,
    reducers: { },
    extraReducers: {
        [getAllLogsAction.pending.type]: (state) => {
          state.dataList.status = LOADING_STATUS.LOADING
          state.dataList.error = initialState.dataList.error
        },
        [getAllLogsAction.fulfilled.type]: (state, {payload}) => {
          state.dataList = {...state.dataList, ...payload}
          state.dataList.status = LOADING_STATUS.IDLE
        },
        [getAllLogsAction.rejected.type]: (state, {payload}) => {
          state.dataList.error = payload
          state.dataList.status = LOADING_STATUS.IDLE
        },
    }
})

export const {} = logsSlice.actions;
export default logsSlice.reducer;
